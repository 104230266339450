import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormControl, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {InputConfig} from "../input";
import {TranslationService} from "@core/shared/services/translation.service";
import "@lib/ckeditor/build/translations/de";
import "@lib/ckeditor/build/translations/en";
import "@lib/ckeditor/build/translations/pt";
import "@lib/ckeditor/build/translations/es";
import "@lib/ckeditor/build/translations/it";
import {CKEditor5} from "@ckeditor/ckeditor5-angular";
import {getDefaultCkeditorConfig} from "@core/shared/data/ckeditor";
import {TranslateService} from "@ngx-translate/core";
import {UserService} from "@core/shared/services/user.service";

/**
 * Configuration paramètres du champ
 */
export interface CkeditorConfig extends InputConfig {

    /**
     * Attributs du champ de formulaire
     */
    attrs: {

        required?: boolean;

        label?: string;

        toolbar?: string[];

        maxLength?: number;
    };

    stripTagOnPaste?: boolean;

    validatorCallbacks?: ((control: FormControl) => void)[];

    markAsTouched?: boolean;

    /**
     * Éditeur
     */
    editor: any;
}

/**
 * Champ ckeditor
 */
@Component({
    selector: 'app-ckeditor',
    templateUrl: './ckeditor.component.html',
    styleUrls: ['./ckeditor.component.scss']
})
export class CkeditorComponent implements OnInit, AfterViewInit {

    /**
     * Paramètres du champ
     */
    @Input() public config: CkeditorConfig;

    @Input() ckeditorConfig: CKEditor5.Config;

    /**
     * Formulaire
     */
    @Input() public form: UntypedFormGroup;

    public count: number = 0;

    public constructor(
        private _translateService: TranslateService,
        private _userService: UserService,
        public translationService: TranslationService)
    {}

    /**
     * Implémentation du formControl, des validateurs et de la config du CKEditor
     */
    public ngOnInit(): void {

        this._initCkeditorConfig();

        /**
         * Validateurs du champs
         */
        const validators: any[] = [];

        this.form.addControl(this.config.id, new UntypedFormControl(''));

        if (('required' in this.config.attrs) && this.config.attrs.required) {

            validators.push(Validators.required);
        }

        if ('maxLength' in this.config.attrs) {

            validators.push((control: AbstractControl): { [key: string]: any } => {

                if (!control.value) {

                    return null;
                }

                if ((control.value.replace(/(<([^>]+)>)/gi, '').replace(/&nbsp;/gi, ' ')).length <= this.config.attrs.maxLength) {

                    return null;
                }

                return {
                    'textMaxLength': {
                        valid: false
                    }
                };
            });
        }

        if(this.config.validatorCallbacks && Boolean(this.config.validatorCallbacks.length)){

            this.config.validatorCallbacks.forEach((callback: (control: FormControl) => void): void => {

                validators.push(callback);
            });
        }

        this.form.controls[this.config.id].setValidators(validators);

        if(this.config.markAsTouched){

            this.form.controls[this.config.id].markAsTouched({onlySelf: true, emitEvent: false});
        }
    }

    public ngAfterViewInit(): void {

        const control: UntypedFormControl = this.form.controls[this.config.id] as UntypedFormControl;

        control.valueChanges.subscribe((): void => {

            this._handleCount();
        });

        setTimeout((): void => this._handleCount());
    }

    private _initCkeditorConfig(): void {

        if(this.ckeditorConfig){

            return;
        }

        this.ckeditorConfig = getDefaultCkeditorConfig(this._translateService, this._userService);
    }

    private _handleCount(): void {

        const control: UntypedFormControl = this.form.controls[this.config.id] as UntypedFormControl;

        this.count = control.value ? (control.value.replace(/(<([^>]+)>)/gi, '').replace(/&nbsp;/gi, ' ')).length : 0;
    }

    public onReady(editor: any) {

        if(!this.config.stripTagOnPaste) {

            return;
        }

        editor.editing.view.document.on('clipboardInput', (event: {stop: () => void}, data: InputEvent) => {

            event.stop();

            const pastedText: string = data.dataTransfer.getData('text/plain');

            editor.model.change((writer: {createText: (value: string) => string}) => {

                editor.model.insertContent(writer.createText(pastedText));
            });
        });
    }
}
