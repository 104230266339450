import {AfterViewInit, Component, EventEmitter, OnInit, Output, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {FilterBuilder, FilterComponent, FilterField} from "@core/shared/models/filter";
import {User} from "@core/shared/models/user";
import {UserService} from "@core/shared/services/user.service";
import {ArticleFilterKey} from "@core/shared/models/article/article-search";
import {ArticleSearchService} from "@core/shared/services/article/article-search.service";
import {ArticleType} from "@core/shared/models/article/article-type";
import {ARTICLE_CONFIGURATION_TARGET_MARKETS} from "@core/shared/data/article/article-configuration/article-configuration-target-market";
import {CustomerTypology} from "@core/shared/models/customer-typology";
import {ArticleTypeService} from "@core/shared/services/article/article-type.service";
import {CustomerTypologyService} from "@core/shared/services/customer-typology.service";
import {TranslationService} from "@core/shared/services/translation.service";
import {ArticleConfigurationTargetMarket} from "@core/shared/models/article/article-configuration";
import {TranslateService} from "@ngx-translate/core";
import {SelectArrayMultipleFilterPanelComponent} from "@core/components/filter/select-array-multiple-filter-panel/select-array-multiple-filter-panel.component";
import {TargetMarketFilterPanelComponent} from "@core/components/target-market/target-market-filter-panel/target-market-filter-panel.component";

@Component({
    selector: 'app-core-article-filter',
    templateUrl: './article-filter.component.html',
    styleUrls: ['./article-filter.component.scss']
})
export class ArticleFilterComponent implements OnInit, AfterViewInit {

    @ViewChildren(`
        typeFilter,
        customerTypologyFilter,
        targetMarketFilter
    `) filterComponents: QueryList<FilterComponent>;

    @ViewChild('typeFilter', {static: false}) typeFilterComponent: SelectArrayMultipleFilterPanelComponent<{ id: string, label: string }>;

    @ViewChild('customerTypologyFilter', {static: false}) customerTypologyFilterComponent: SelectArrayMultipleFilterPanelComponent<{ id: string, label: string }>;

    @ViewChild('targetMarketFilter', {static: false}) targetMarketFilterComponent: TargetMarketFilterPanelComponent<{ id: string, label: string }>;

    @Output() openSidePanelOverlay: EventEmitter<void> = new EventEmitter<void>();

    protected readonly ArticleFilterKey = ArticleFilterKey;

    public currentUser: User = null;

    public types: { id: number, tag: string, label: string }[] = [];

    public targetMarkets: { id: string, label: string }[] = [];

    public customerTypologies: { id: number, tag: string, label: string }[] = [];

    public overlays: Partial<{ [p in ArticleFilterKey]: { open: boolean } }> = {
        [ArticleFilterKey.Type]: { open: false },
        [ArticleFilterKey.CustomerTypology]: { open: false },
        [ArticleFilterKey.TargetMarket]: { open: false }
    }

    constructor(
        private _translateService: TranslateService,
        private _translationService: TranslationService,
        private _articleTypeService: ArticleTypeService,
        private _customerTypologyService: CustomerTypologyService,
        public userService: UserService,
        public articleSearchService: ArticleSearchService
    ) {}

    ngOnInit() {

        this.currentUser = this.userService.currentUser.value;

        this._initTypes();

        this._initTargetMarkets();

        this._initCustomerTypologies();

        this._initEvents();
    }

    ngAfterViewInit(): void {

        setTimeout((): void => {

            this.initFilters();
        });
    }

    private _initTypes(): void {

        this._articleTypeService.getItemsAPI().subscribe((items: ArticleType[]): void => {

            this.types = items.map((item: ArticleType): { id: number, tag: string, label: string } => {

                return {
                    id: item.id,
                    tag: item.tag,
                    label: this._translationService.getFallbackTranslation(item.translations).label
                };
            });
        });
    }

    private _initTargetMarkets(): void {

        this.targetMarkets = ARTICLE_CONFIGURATION_TARGET_MARKETS.map((item: ArticleConfigurationTargetMarket): { id: string, label: string } => {

            return {
                id: item,
                label: this._translateService.instant(`article.targetMarket.${item.toString().toLowerCase()}.value`)
            };
        });
    }

    private _initCustomerTypologies(): void {

        this._customerTypologyService.getItemsAPI().subscribe((items: CustomerTypology[]): void => {

            this.customerTypologies = items.map((item: CustomerTypology): { id: number, tag: string, label: string } => {

                return {
                    id: item.id,
                    tag: item.tag,
                    label: this._translationService.getFallbackTranslation(item.translations).name
                };
            });
        });
    }

    private _initEvents(): void {

        this.articleSearchService.deleteActiveFilterField$.subscribe((field: FilterField): void => {

            const key: ArticleFilterKey = field.key as ArticleFilterKey;

            switch (key){

                case ArticleFilterKey.Type:

                    this.typeFilterComponent.parsedValuesUpdated.emit(this.typeFilterComponent.parsedValues);

                    break;

                case ArticleFilterKey.CustomerTypology:

                    this.customerTypologyFilterComponent.parsedValuesUpdated.emit(this.customerTypologyFilterComponent.parsedValues);

                    break;

                case ArticleFilterKey.TargetMarket:

                    this.targetMarketFilterComponent.parsedValuesUpdated.emit(this.targetMarketFilterComponent.parsedValues);

                    break;
            }
        });
    }

    public initFilters(): void {

        if (!this.articleSearchService.sessionEnabled || !this.articleSearchService.hasSessionFilters) {

            return;
        }

        // Type

        if(this.articleSearchService.isFilterEnabled(ArticleFilterKey.Type) && this.articleSearchService.hasSessionFilter(ArticleFilterKey.Type)){

            this.typeFilterComponent.values = this.articleSearchService.getSessionFilter(ArticleFilterKey.Type).value;
        }

        // Type de clientèle

        if(this.articleSearchService.isFilterEnabled(ArticleFilterKey.CustomerTypology) && this.articleSearchService.hasSessionFilter(ArticleFilterKey.CustomerTypology)){

            this.customerTypologyFilterComponent.values = this.articleSearchService.getSessionFilter(ArticleFilterKey.CustomerTypology).value;
        }

        // Cible marché

        if(this.articleSearchService.isFilterEnabled(ArticleFilterKey.TargetMarket) && this.articleSearchService.hasSessionFilter(ArticleFilterKey.TargetMarket)){

            this.targetMarketFilterComponent.values = this.articleSearchService.getSessionFilter(ArticleFilterKey.TargetMarket).value;
        }
    }

    public reset(): void {

        this.articleSearchService.resetFilter$.next();
    }

    public updatePlaceholderValue(element: HTMLInputElement, value: string): void {

        element.value = value;
    }

    public openOverlay(item: ArticleFilterKey): void {

        setTimeout((): void => {

            this.overlays[item].open = true;
        });
    }

    public closeOverlay(item: ArticleFilterKey): void {

        this.overlays[item].open = false;
    }

    public isOverlayOpen(item: ArticleFilterKey): boolean {

        return this.overlays[item].open;
    }

    public overlayContainerStyles(item: ArticleFilterKey): { [p: string]: string } {

        return {
            'opacity': this.isOverlayOpen(item) ? '1' : '0',
            'visibility': this.isOverlayOpen(item) ? 'visible' : 'hidden'
        };
    }

    get filterBuilder(): FilterBuilder {

        return this.articleSearchService.filterBuilder;
    }

    get typeIconClassPropertyCallback(): (type: { id: number, tag: string, label: string }) => string {


        return (type: { id: number, tag: string, label: string }): string => {

            return `type_${ type.tag }`;
        };
    }

    get customerTypologyIconClassPropertyCallback(): (type: { id: number, tag: string, label: string }) => string {

        return (type: { id: number, tag: string, label: string }): string => {

            return `customer_typology_${ type.tag }`;
        };
    }

}
