import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {CKEditor5} from "@ckeditor/ckeditor5-angular";

@Injectable({
    providedIn: 'root'
})
export class TextService {

    /**
     * API URL
     */
    private API_URL: string;

    /**
     * PREFIX URL
     */
    private readonly PREFIX_URL: string = '/text';

    private _editor: any;

    private _ckeditorConfig: CKEditor5.Config;

    /**
     * @param _http
     */
    constructor(private _http: HttpClient) {

    }

    /**
     * Définit l'URL d'accès à l'API
     *
     * @param apiUrl
     */
    public setApiUrl(apiUrl: string): void {

        this.API_URL = apiUrl;
    }


    /**
     * Retourne l'api prefix
     */
    public getApiUrl(withPrefix: boolean): string {

        if (withPrefix) {
            return this.API_URL + this.PREFIX_URL;
        }

        return this.API_URL;
    }

    get editor(): any {

        return this._editor;
    }

    set editor(value: any) {

        this._editor = value;
    }

    get ckeditorConfig(): CKEditor5.Config {

        return this._ckeditorConfig;
    }

    set ckeditorConfig(value: CKEditor5.Config) {

        this._ckeditorConfig = value;
    }
}
