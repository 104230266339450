import {Component, OnInit} from '@angular/core';
import {ArticleSearchService} from "@core/shared/services/article/article-search.service";
import {ArticleCardService} from "@core/shared/services/article/article-card.service";
import {User} from "@core/shared/models/user";
import {UserService} from "@core/shared/services/user.service";
import {Observable} from "rxjs";
import {Pagination} from "@core/shared/models/pagination";
import {ArticleService} from "@core/shared/services/article.service";
import {ArticleFilterKey} from "@core/shared/models/article/article-search";
import {Article} from "@core/shared/models/article";

@Component({
    selector: 'app-core-page-article-search',
    templateUrl: './page-article-search.component.html',
    styleUrls: ['./page-article-search.component.scss'],
    providers: [
        ArticleSearchService,
        ArticleCardService
    ]
})
export class PageArticleSearchComponent implements OnInit {

    public currentUser: User = null;

    constructor(
        private _articleService: ArticleService,
        private _articleSearchService: ArticleSearchService,
        public userService: UserService,
    ) {}

    ngOnInit() {

        this.currentUser = this.userService.currentUser.value;

        this._configureSearchService();
    }

    private _configureSearchService(): void {

        this._articleSearchService.sessionEnabled = true;

        Object.assign(this._articleSearchService.enabledFilters, {
            [ArticleFilterKey.Type]: true,
            [ArticleFilterKey.CustomerTypology]: true,
            [ArticleFilterKey.TargetMarket]: true,
            [ArticleFilterKey.Locale]: true,
            [ArticleFilterKey.SelfAuthor]: true,
            [ArticleFilterKey.TywinAuthor]: true,
            [ArticleFilterKey.Author]: true,
            [ArticleFilterKey.Region]: true,
            [ArticleFilterKey.Theme]: true,
            [ArticleFilterKey.Status]: true,
            [ArticleFilterKey.UpdatedAt]: true,
            [ArticleFilterKey.PublishedAt]: true
        } as { [p in ArticleFilterKey]: boolean });
    };

    get loadItemsSourceCallback(): (params?: string[]) => Observable<Pagination<Article>> {

        return (params?: string[]): Observable<Pagination<Article>> => {

            return this._articleService.getPaginationItemsAPI(params);
        }
    }
}
