<div class="visuel">
    <img [src]="visualUrl" (error)="setDefaultVisualUrl()" [alt]="getTranslation(item.translations).title">
</div>

<div class="article">
    <div class="ck-content infos_article">
        <h2 class="titre_h3 title">{{getTranslation(item.translations).title}}</h2>
        <div [innerHTML]="sanitize(getTranslation(item.translations).description)"></div>
        <div class="published">
            <p><strong>{{ 'publishedBy.value' | translationForLocaleWithFallback: locale | async }} Ty-Win</strong></p>
            <p>{{ 'page.updatedAt.value' | translationForLocaleWithFallback: locale | async }} : {{item.updatedAt | date : 'shortDate' : null : translationService.getFallbackLocale(locale)}}</p>
        </div>
    </div>

    <app-core-cms-content [content]="getTranslation(item.translations).content"></app-core-cms-content>
</div>

