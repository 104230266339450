<div class="form-group">
    <div class="app-toggle">

        <ng-container *ngIf="labelPosition === 'before'">
            <ng-container *ngTemplateOutlet="labelReference"></ng-container>
        </ng-container>

        <mat-slide-toggle id="toggle-boolean-filter-{{ key }}" [(ngModel)]="value" (ngModelChange)="updateValue()"></mat-slide-toggle>

        <ng-container *ngIf="labelPosition === 'after'">
            <ng-container *ngTemplateOutlet="labelReference"></ng-container>
        </ng-container>

    </div>
</div>

<ng-template #labelReference>
    <label for="toggle-boolean-filter-{{ key }}">{{ label }}</label>
</ng-template>
