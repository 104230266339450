<div class="filters_list">
    <p class="title">{{ label }}</p>
    <div class="row">

        <ng-content select="[beforeOptions]"></ng-content>

        <div class="col_12 option_full" *ngFor="let item of displayedOptions; index as i">
            <div class="option no_padding" [ngClass]="isSelectedItem(item) ? 'selected' : ''">
                <input type="checkbox" id="{{ key }}-{{ i }}" [checked]="isSelectedItem(item)" (click)="handleItemSelection(item)">
                <label for="{{ key }}-{{ i }}">{{ getLabelPropertyValue(item) }}</label>
            </div>
        </div>
    </div>
    <ng-container *ngIf="displayToggleOptionsAction">
        <button type="button" class="more_option icon-tywin-chevron" (click)="toggleOptionDisplay()" [ngClass]="allOptionsDisplayed ? 'open': ''">
            {{ (allOptionsDisplayed ? 'option.plural.display.less.value' : 'option.plural.display.more.value') | translate }}
        </button>
    </ng-container>
</div>
