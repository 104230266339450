import {Component, OnInit} from '@angular/core';
import {CrudService} from "@core/shared/services/crud.service";

@Component({
    selector: 'app-core-page-article-update',
    templateUrl: './page-article-update.component.html',
    styleUrl: './page-article-update.component.scss',
    providers: [
        CrudService
    ]
})
export class PageArticleUpdateComponent implements OnInit {

    constructor(
        private _crudService: CrudService
    ) {}

    ngOnInit() {

        this._crudService.setAction('update');
    }
}
