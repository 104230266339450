<ng-container [ngSwitch]="true">

    <ng-container *ngSwitchCase="isCurrentView(View.Form)">
        <ng-container *ngTemplateOutlet="formTemplate"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="isCurrentView(View.Confirmation)">
        <ng-container *ngTemplateOutlet="confirmationTemplate"></ng-container>
    </ng-container>

</ng-container>

<ng-template #formTemplate>

    <form [formGroup]="form" (ngSubmit)="formService.submit()">

        <div class="page_title">
            <div class="row marges">

                <div class="col y_items_center">
                    <h1 class="title">{{ 'statistic.plural.value' | translate }}</h1>
                </div>

                <!-- Actions -->

                <div class="col_content y_items_center">

                    <!-- Enregistrer -->

                    <button class="btn_cta btn_little" type="submit">
                        {{ 'export.request.action.value' | translate }}
                    </button>

                </div>
            </div>
        </div>

        <div class="row marges ligne_form">
            <div class="col_12">
                <div class="form-group">
                    <div class="form_part">
                        <p class="title_part">{{ 'user.plural.selection.value' | translate }}</p>
                    </div>
                    <div class="form-group">
                        <div class="checkbox min-size">
                            <input type="checkbox" id="all-user" formControlName="allSocieties">
                            <label for="all-user">{{ 'user.plural.all.value' | translate }}</label>
                        </div>
                    </div>
                    <ng-container *ngIf="!form.get('allSocieties').value">
                        <div class="form-group">
                            <app-core-select-search [configuration]="societySearchConfiguration" [sourceCallback]="societySearchSourceCallback" [formGroupReference]="form" [formControlNameReference]="'societies'"></app-core-select-search>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>

        <div class="row marges ligne_form" style="margin-top: 0" *ngIf="form.get('societies').errors">
            <div class="col_12">
                <div class="form-group">
                    <mat-error style="padding: 0">
                        <div class="invalid-feedback">
                            <div>{{ 'visibility.configuration.required.value' | translate }}</div>
                        </div>
                    </mat-error>
                </div>
            </div>
        </div>

        <div class="row marges ligne_form">
            <div class="col_12">
                <div class="form-group">
                    <div class="form_part">
                        <p class="title_part">{{ 'data.plural.toExport.value' | translate }}</p>
                    </div>
                </div>
                <div class="form-group">
                    <mat-select class="form-control" formControlName="types" multiple>
                        <mat-option *ngFor="let item of types" [value]="item">
                            {{ item.label | translate }}
                        </mat-option>
                    </mat-select>
                    <mat-label class="required">{{ 'data.plural.toExport.value' | translate }}</mat-label>
                    <mat-error *ngIf="form.get('types').errors && (form.get('types').dirty || form.get('types').touched)">
                        <div class="invalid-feedback">
                            <div *ngIf="form.get('types').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                        </div>
                    </mat-error>
                </div>
            </div>
        </div>

        <div class="row marges ligne_form">

            <div class="col_12">
                <div class="form-group">
                    <div class="form_part">
                        <p class="title_part">{{ 'period.selection.value' | translate }}</p>
                    </div>
                </div>
            </div>

            <div class="col_12 col_md_6">
                <div class="form-group">
                    <input type="text" class="required datetimepicker" id="dateStart" placeholder=" " matInput formControlName="dateStart" [matDatepicker]="dateStartPicker" [max]="maxDate" (click)="dateStartPicker.open()" (dateChange)="form.get('dateEnd').patchValue(null); dateEndPicker.open()">
                    <label for="dateStart" class="required">{{ 'filter.dateInterval.start.value' | translate }}</label>
                    <mat-datepicker #dateStartPicker></mat-datepicker>
                    <mat-error *ngIf="form.get('dateStart').errors && (form.get('dateStart').dirty || form.get('dateStart').touched)">
                        <div class="invalid-feedback">
                            <div *ngIf="form.get('dateStart').errors.required">{{ 'form.validation.error.required' | translate }}</div>
                        </div>
                    </mat-error>
                </div>
            </div>

            <div class="col_12 col_md_6">
                <div class="form-group">
                    <input type="text" class="datetimepicker" id="dateEnd" placeholder=" " matInput formControlName="dateEnd" [matDatepicker]="dateEndPicker" [min]="form.get('dateStart').value" [max]="maxDate" (click)="dateEndPicker.open()">
                    <label for="dateEnd">{{ 'filter.dateInterval.end.value' | translate }}</label>
                    <mat-datepicker #dateEndPicker></mat-datepicker>
                    <mat-error *ngIf="form.get('dateEnd').errors && (form.get('dateEnd').dirty || form.get('dateEnd').touched)">
                        <div class="invalid-feedback">
                            <div *ngIf="form.get('dateEnd').errors.required">{{ 'form.validation.error.required' | translate }}</div>
                        </div>
                    </mat-error>
                </div>
            </div>

        </div>

    </form>

</ng-template>

<ng-template #confirmationTemplate>

    <div class="row marges ligne_form">
        <div class="col_12" style="margin-top: 24px;">
            <div class="subscription_information">
                <p [innerHTML]="'statistic.export.request.success.value' | translate"></p>
            </div>
        </div>
        <div class="col_12 x_center" style="margin-top: 24px;">
            <button class="btn_cta btn_little" type="button" (click)="refreshRoute()">
                {{ 'request.new.action.value' | translate }}
            </button>
        </div>
    </div>

</ng-template>
