<div *ngIf="loaderPayment | async" class="overlay">
    <div>
        <mat-progress-spinner class="spinner" color="primary" mode="indeterminate" value="50"></mat-progress-spinner>
    </div>
</div>

<div class="form_paymment">

    <form [formGroup]="form" (ngSubmit)="formService.submit()">
        <div class="page_title">
            <div class="row marges">
                <div class="col y_items_center">
                    <h1 class="title">{{ 'payment.title.value' | translate }}</h1>
                </div>

            </div>
        </div>
        <div class="warning" *ngIf="defaultPayment">{{'subscription.defaultPayment.value' | translate }}</div>

        <div class="bloc_payment">

            <ng-container *ngIf="toProcessSubscription">

                <div class="row marges ligne_form">

                    <div class="col_12">
                        <div class="form-group">
                            <p class="read">
                                <strong>{{'form.user.subscription.fields.periodicity.title.value' | translate}} : </strong>{{ 'subscription.form.periodicity.' + toProcessSubscription.periodicity + '.value' | translate }}
                            </p>
                            <p class="read">
                                <strong>{{ 'form.user.subscription.value' | translate  }} : </strong>{{ toProcessSubscription.subscription.isUnlimitedMaxOffer ? ('subscription.unlimited.title.value' | translate) : ('form.user.subscription.fields.subscription.group.value' | translate :{'nbOffer': toProcessSubscription.subscription.maxOffer}) }}
                            </p>
                        </div>
                    </div>

                </div>

            </ng-container>

            <ng-container *ngIf="isPaymentDataRequired">

                <div class="payment_form">

                    <div class="row marges payment_methods x_center">
                        <div class="col_content">
                            <span class="method_item cb"></span>
                        </div>
                        <div class="col_content">
                            <span class="method_item visa"></span>
                        </div>
                        <div class="col_content">
                            <span class="method_item mastercard"></span>
                        </div>
                        <div class="col_content">
                            <span class="method_item maestro"></span>
                        </div>
                    </div>

                </div>

                <div class="row marges ligne_form">
                    <div class="col_12">
                        <div class="form-group">
                            <input type="text" number-card class="form-control" formControlName="cardNumber" id="cardNumber" required="required" [control]="form.get('cardNumber')">
                            <label for="cardNumber">{{'payment.fields.cardNumber.value' | translate}}</label>
                            <mat-error
                                *ngIf="form.get('cardNumber').errors && (form.get('cardNumber').dirty || form.get('cardNumber').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="form.get('cardNumber').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                    <div *ngIf="form.get('cardNumber').errors.invalidNUmberCard">{{ 'payment.fields.error.invalidNUmberCard.value' | translate }}</div>

                                </div>
                            </mat-error>
                        </div>
                    </div>
                </div>

                <div class="row marges ligne_form">
                    <div class="col_6">
                        <div class="form-group">
                            <input type="text" class="form-control" date-card formControlName="cardExpirationDate" id="cardExpirationDate" required="required" [control]="form.get('cardExpirationDate')">
                            <label for="cardExpirationDate">{{'payment.fields.cardExpirationDate.value' | translate}}</label>
                            <mat-error
                                *ngIf="form.get('cardExpirationDate').errors && (form.get('cardExpirationDate').dirty || form.get('cardExpirationDate').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="form.get('cardExpirationDate').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                    <div *ngIf="form.get('cardExpirationDate').errors.invalidFormatDate">{{ 'payment.fields.error.invalidFormatDate.value' | translate }}</div>
                                    <div *ngIf="form.get('cardExpirationDate').errors.invalidDatePast">{{ 'payment.fields.error.invalidDatePast.value' | translate }}</div>

                                </div>
                            </mat-error>
                        </div>
                    </div>
                    <div class="col_6">
                        <div class="form-group">
                            <input type="text" class="form-control" cvv-card formControlName="cardCvx" id="cardCvx" required="required" [control]="form.get('cardCvx')">
                            <label for="cardCvx">{{'payment.fields.cardCvx.value' | translate}}</label>
                            <mat-error
                                *ngIf="form.get('cardCvx').errors && (form.get('cardCvx').dirty || form.get('cardCvx').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="form.get('cardCvx').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                    <div *ngIf="form.get('cardCvx').errors.invalidFormatCvv">{{ 'payment.fields.error.invalidFormatCvv.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>
                </div>

            </ng-container>

            <ng-container *ngIf="toProcessSubscription">

                <div class="row marges ligne_form">

                    <div class="col_12 y_items_center recap_payment">
                        <div class="form-group ">
                            <table>

                                <!-- Prix de l'abonnement -->

                                <tr>
                                    <td class="total">{{ toProcessSubscription.subscription.isUnlimitedMaxOffer ? ('subscription.unlimited.title.value' | translate) : ('form.user.subscription.fields.subscription.group.value' | translate :{'nbOffer': toProcessSubscription.subscription.maxOffer}) }}</td>
                                    <td>{{ formatPrice(toProcessSubscription.totalPrice) }} {{ 'tax.excluded.value' | translate }}</td>
                                </tr>

                                <!-- Promotion sur l'abonnement -->

                                <tr *ngIf="toProcessSubscription.promotionPrice">
                                    <td>{{ 'payment.promo.value' | translate }}</td>
                                    <td>- {{ formatPrice(toProcessSubscription.promotionPrice)}} {{ 'tax.excluded.value' | translate }}</td>
                                </tr>

                                <!-- Frais d'entrée -->

                                <tr *ngIf="toProcessSubscription.admissionPrice">
                                    <td>{{ 'payment.admissionPrice.value' | translate }}</td>
                                    <td>{{ formatPrice(toProcessSubscription.admissionPrice)}} {{ 'tax.excluded.value' | translate }}</td>
                                </tr>

                                <!-- Promotion sur les frais d'entrée -->

                                <tr *ngIf="toProcessSubscription.promotionAdmissionPrice">
                                    <td>{{ 'payment.promoAdmission.value' | translate }}</td>
                                    <td>- {{ formatPrice(toProcessSubscription.promotionAdmissionPrice)}} {{ 'tax.excluded.value' | translate }}</td>
                                </tr>

                                <!-- Crédit -->

                                <tr *ngIf="toProcessSubscription.creditedPrice">
                                    <td>{{ 'payment.credit.value'| translate  }} </td>
                                    <td>- {{ formatPrice(toProcessSubscription.creditedPrice)}} {{ 'tax.excluded.value' | translate }}</td>
                                </tr>

                                <!-- Prix total à payer HT -->

                                <tr>
                                    <td class="total">{{'form.user.subscription.fields.price.ht.value' | translate}} </td>
                                    <td>{{ formatPrice(toProcessSubscription.totalPriceToPay) }}</td>
                                </tr>

                                <!-- Prix total à payer TTC -->

                                <tr>
                                    <td class="total">{{'form.user.subscription.fields.price.ttc.value' | translate}} </td>
                                    <td>{{ formatPrice(toProcessSubscription.totalPriceToPayTTC) }}</td>
                                </tr>

                            </table>
                        </div>
                    </div>

                    <ng-container *ngIf="isCardLoaded">

                        <ng-container *ngIf="!card && toProcessSubscription && (toProcessSubscription.totalPriceToPay === 0)">

                            <div class="col_content y_items_center">
                                <div class="without_payment_information">
                                    <p [innerHTML]="'subscription.without_payment_information.card.registration.value' | translate"></p>
                                    <br>
                                    <p [innerHTML]="'subscription.without_payment_information.authentication_verification.value' | translate"></p>
                                </div>
                            </div>

                        </ng-container>

                    </ng-container>

                    <ng-container *ngIf="!isPaymentDataRequired">

                        <div class="col_content y_items_center">
                            <div class="without_payment_information">
                                <p [innerHTML]="'subscription.without_card_registration.value' | translate"></p>
                            </div>
                        </div>

                    </ng-container>

                    <div class="col_content y_items_center">

                        <!-- Soumission -->

                        <button class="btn_cta btn_little" type="submit" [disabled]="!isSubmitAllowed">
                            {{ 'form.user.subscription.action.value'|translate }}
                        </button>
                    </div>

                </div>

            </ng-container>
        </div>

    </form>
</div>
