import {Component, OnInit} from '@angular/core';
import {Config, ICmsParams} from "@lib/cms/cms";
import {UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {PageService} from "@core/shared/services/page.service";
import * as ClassicEditor from "@lib/ckeditor";
import {CkeditorConfig} from "@lib/form/fields/ckeditor/ckeditor.component";
import {FormService} from "@core/shared/services/form.service";
import {ApiService} from "@core/shared/services/api.service";
import {Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";
import {LOCALE_ITEMS, LocaleItem, TranslationBuilder} from "@core/shared/models/translation";
import {ImageConfig} from "@lib/form/fields/image/image.component";
import {FieldCollection} from "@lib/form/field";
import {ShortSociety} from "@core/shared/models/society";
import {Role} from "@core/shared/models/role";
import {Observable, of} from "rxjs";
import {getDefaultCkeditorConfig} from "@core/shared/data/ckeditor";
import {UserService} from "@core/shared/services/user.service";
import {ITranslation} from '@lib/cms/translation/translation';
import {PageTranslation} from '@core/shared/models/page-translation';
import {Page} from '@core/shared/models/page';
import moment, {Moment} from 'moment/moment';
import {Locale} from '@core/shared/models/locale';
import {CmsContentService} from "@core/shared/services/cms/cms-content.service";
import {SocietyService} from "@core/shared/services/society.service";
import {CmsService} from "@core/shared/services/cms.service";
import {IElement, IElementConfigType} from "@lib/cms/element/element";
import {ITemplate} from "@lib/cms/template/template";
import {map} from "rxjs/operators";
import {IRow} from "@lib/cms/row/row";
import {IColumn} from "@lib/cms/column/column";
import {AccordionItem} from "@lib/cms/element/type/accordion-item/accordion-item";
import {Slideshow} from "@lib/cms/element/type/slideshow/slideshow";

@Component({
    selector: 'app-page-create',
    templateUrl: './page-create.component.html',
    styleUrls: ['./page-create.component.scss'],
    providers: [
        FormService,
        CmsContentService
    ]
})
export class PageCreateComponent implements OnInit {

    public editor = ClassicEditor;

    public translationBuilder: TranslationBuilder;

    public societies: ShortSociety[] = [];

    public roles: Role[] = [];

    public locales$: Observable<LocaleItem[]>;

    public dataCms = {
        content: {
            context: 'cms_content_page',
            id: 'tag',
            entityFieldName: 'content',
            entityName: 'Page',
            translations: []
        }
    };

    public cmsParams: ICmsParams;

    public fieldCollection: FieldCollection = new FieldCollection();

    public localeViewPreview: Locale;

    public localeCardPreview: Locale;

    public constructor(
        private _router: Router,
        private _formBuilder: UntypedFormBuilder,
        private _translateService: TranslateService,
        private _societyService: SocietyService,
        private _userService: UserService,
        private _snackBar: MatSnackBar,
        private _cmsContentService: CmsContentService,
        private _apiService: ApiService,
        private _cmsService: CmsService,
        public pageService: PageService,
        public formService: FormService
    ) {}

    ngOnInit(): void {

        this.roles = ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'];

        this._configureCmsContentService();

        this.formService.form = this._formBuilder.group({
            published: [''],
            content: [''],
            typeUser: ['role', Validators.required],
            role: [''],
            roles: [['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']],
            societies: [[]],
            translations: new UntypedFormArray([]),
            target: ['_self', Validators.required]
        });

        this._initLocales();

        this._buildForm();

        this._initTranslationsForm();

        this._loadSocieties();

        this.form.get('target').patchValue('_self');

        this._initCms();

        this.form.get('locales').valueChanges.subscribe((): void => {

            this._handleTranslationControls();

            this.form.get('locales').value.forEach((locale: string): void => {

                const exist: ITranslation = this.dataCms.content.translations.find((item: ITranslation): boolean => {

                    return item.locale === locale;
                });

                if (!exist) {

                    this.dataCms.content.translations.push({"id": null, "locale": locale, "rows": []});
                }
            });

            this.dataCms.content.translations.forEach((item, index): void => {

                if (!this.form.get('locales').value.includes(item.locale)) {

                    this.dataCms.content.translations.splice(index, 1)
                }
            });
        });

        this.formService.submitCallback = (): void => {

            const formValue = this.form.value;

            const data: object = Object.assign(formValue, {
                translations: formValue.translations.map((translation: Partial<PageTranslation>): PageTranslation => {

                    return Object.assign(translation, {
                        content: formValue.content.translations.find((item: ITranslation): boolean => {

                            item.rows.forEach((row: IRow): void => {

                                row.columns.forEach((column: IColumn): void => {

                                    column.elements.forEach((element: IElement): void => {

                                        switch(element.type){

                                            case 'slideshow':

                                                delete (element.content as Slideshow).formatUpdated$;

                                                break;

                                            case 'accordionItem':

                                                delete (element.content as AccordionItem).open;
                                                delete (element.content as AccordionItem).updateState$;

                                                break;
                                        }
                                    });
                                });
                            });

                            return item.locale === translation.locale;
                        })
                    } as Partial<PageTranslation>) as PageTranslation;
                })
            } as Partial<Page>);

            this.pageService.createItemAPI(data).subscribe((): void => {

                this._snackBar.open(this._translateService.instant('page.add.success.value'), this._translateService.instant('notification.close.action.value'), {
                    duration: 5000
                });

                this.redirectToList();
            });
        };
    }

    private _initTranslationsForm(): void {

        this.translationBuilder = new TranslationBuilder(this._formBuilder);

        this.translationBuilder.form = this.form;

        this.translationBuilder.addItemCallback = (): UntypedFormGroup => {

            return this._formBuilder.group({
                title: ['', [Validators.required]],
                description: ['', [Validators.required]],
                picture: this._formBuilder.group({
                    copyright: [''],
                    image: this._formBuilder.group({
                        image: ['', [Validators.required]]
                    })
                }),
            });
        };

        this.translationBuilder.addDefaultItem();
    }

    private _configureCmsContentService(): void {

        this._cmsContentService.imageAsBinary = true;
    }

    private _loadSocieties(): void {

        this._societyService.getShortItemsAPI().subscribe((items: ShortSociety[]): void => {

            this.societies.push(...items);
        });
    }

    private _initCms(): void {

        const templateContext: string = 'page';

        this.cmsParams = {
            configSource: this._cmsService.getConfig().pipe(map((config: Config): Config => {

                // Ajout de l'élément soufflet

                const accordionItemIdentifier: string = 'accordionItem';

                Object.assign(config.element.types, {
                    [accordionItemIdentifier]: {
                        label: this._translateService.instant('cms.element.type.accordionItem.value')
                    } as IElementConfigType
                });

                config.templates.forEach((template: ITemplate): void => {

                    Object.assign(template, {
                        allowedTypes: [...template.allowedTypes, {
                            id: accordionItemIdentifier,
                            label: this._translateService.instant('cms.element.type.accordionItem.value')
                        }]
                    } as ITemplate);
                });

                Object.keys(config.contexts[templateContext]).forEach((templateId: string): void => {

                    Object.assign(config.contexts[templateContext][templateId], {
                        types: [...config.contexts[templateContext][templateId].types, accordionItemIdentifier]
                    });
                });

                return config;
            })),
            context: 'cms_content_page',
            blocLabel: 'Bloc Label',
            ckeditor: {
                editor: this.editor,
                config: getDefaultCkeditorConfig(this._translateService, this._userService)
            },
            customButtons: [],
            entityName: 'Page',
            enableMultilingual: true,
            tag: 'tag',
            entityFieldName: 'content',
            parentContainer: document.getElementById('admin-sidenav-content'),
            apiUrl: `${this._apiService.getApiUrl(false, true)}`,
            optionsImage: {
                enableAlignment: true,
                enableTitle: true,
                enableSubtitle: false,
                enableAlt: false,
                enableLink: false,
                enableTargetBlank: false,
                enableWatermark: true
            },
            templateContext: templateContext
        };
    }

    private _initLocales(): void {

        this.locales$ = of(LOCALE_ITEMS);
    }

    private _buildForm(): void {

        this.form.addControl('locales', new UntypedFormControl([], [Validators.required]));

        this.fieldCollection.addField({
            type: 'select',
            config: {
                id: 'target',
                attrs: {
                    required: true,
                    label: this._translateService.instant('page.target.value'),
                    placeholder: this._translateService.instant('page.target.value'),
                    choices: [
                        {
                            '_self': 'Non',
                            '_blank': 'Oui'
                        }
                    ]
                }
            }
        });

        this.fieldCollection.addField({
            type: 'select-search',
            config: {
                id: 'societies',
                attrs: {
                    label: this._translateService.instant('menu.users.title.value'),
                    required: false,
                    choices: this.societies,
                    multiple: true,
                }
            }
        });
    }

    private _handleTranslationControls(): void {

        const locales: string[] = this.form.get('locales').value;

        this.translationBuilder.updateItems(locales);
    }

    public patchRolesValue(role: Role): void {

        if (this.roles.indexOf(role) > -1) {

            const index: number = this.roles.indexOf(role);

            this.roles.splice(index, 1);

        } else {

            this.roles.push(role);
        }

        this.form.get('roles').setValue(this.roles);
    }

    public redirectToList(): void {

        this._router.navigate(['page/list']);
    }

    public changeSocieties(event): void {

        this.form.get('societies').patchValue(event.value);
    }

    public hasRole(role: Role): boolean {

        return this.roles.indexOf(role) >= 0;
    }

    get form(): UntypedFormGroup {

        return this.formService.form;
    }

    get imageConfig(): ImageConfig {

        return {
            id: 'image',
            gallery_context: 'card_contenu',
            required: true,
            uploadApiUrl: this._apiService.getApiUrl(false, true),
            options: {
                enableTitle: false,
                enableSubtitle: false,
                enableAlt: false,
                enableLink: false,
                enableTargetBlank: false,
                enableFormatSelection: false
            }
        };
    }

    get descriptionEditorConfig(): CkeditorConfig {

        return {
            id: 'description',
            editor: this.editor,
            attrs: {
                required: true
            }
        };
    }

    get publishedConfig() {

        return {
            id: 'published',
            attrs: {
                required: true,
                label: "Publié"
            }
        };
    }

    get selectedLocales(): string[] {

        if (!this.form || !this.form.get('locales')) {

            return [];
        }

        return this.form.get('locales').value;
    }

    get pageFormValue(): Page {

        if (this.form.invalid) {

            return;
        }

        const formValue = {...this.form.getRawValue()};

        const now: Moment = moment();

        const data: Partial<Page> = Object.assign(formValue, {
            publishedAt: now.format('YYYY-MM-DD'),
            updatedAt: now.format('YYYY-MM-DD'),
            translations: formValue.translations.map((translation: Partial<PageTranslation>): PageTranslation => {

                return Object.assign(translation, {
                    content: formValue.content.translations.find((item: ITranslation): boolean => {

                        return item.locale === translation.locale;
                    })
                } as Partial<PageTranslation>) as PageTranslation;
            })
        } as Partial<Page>);

        if(!this.localeViewPreview) {

            this.localeViewPreview = data.locales.find((): boolean => true);
        }

        if(!this.localeCardPreview) {

            this.localeCardPreview = data.locales.find((): boolean => true);
        }

        return data as Page;
    }
}
