import {ChangeDetectorRef, Component, DoCheck, Input} from '@angular/core';
import {ApiService} from "@core/shared/services/api.service";
import {Image, ImageFormat} from "@lib/media/image/image";
import {ImageService} from "@lib/media/image/image.service";
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";
import {CmsContentService} from "@core/shared/services/cms/cms-content.service";
import {ImageEventService} from "@lib/media/image/image.event.service";

@Component({
    selector: 'app-cms-image',
    templateUrl: './image.component.html',
    styleUrls: ['./image.component.scss']
})
export class CmsImageComponent implements DoCheck {

    @Input() image: Image;

    @Input() width: number = 1500;

    @Input() quality: number = 90;

    public sourceUrl: SafeUrl = null;

    public selectedFormat: ImageFormat = null;

    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _sanitizer: DomSanitizer,
        private _apiService: ApiService,
        private _imageService: ImageService,
        private _imageEventService: ImageEventService,
        private _cmsContentService: CmsContentService
    ) {}

    ngDoCheck(): void {

        if(!this.image || !this.image.id){

            return;
        }

        if(!this.selectedFormat || (this.getSelectedFormat(this.image.formats) !== this.selectedFormat)){

            this.selectedFormat = this.getSelectedFormat(this.image.formats);

            this._changeDetectorRef.detectChanges();

            if(this._cmsContentService.imageAsBinary){

                const loadVisualCallback = (): void => {

                    this._imageService.getBinaryImage(this.image, this.selectedFormat.name, this.width, this.quality, null).subscribe((blob: Blob): void => {

                        const objectURL: string = URL.createObjectURL(blob);

                        this.sourceUrl = this._sanitizer.bypassSecurityTrustUrl(objectURL);
                    });
                };

                loadVisualCallback();

                this._imageEventService.imageCropped.subscribe((item: Image): void => {

                    if(item.id !== this.image.id){

                        return;
                    }

                    loadVisualCallback();
                });
            }
            else{

                if(this.image.watermark){

                    this.sourceUrl = this._imageService.getImageUrlWithNoCache(`${this._apiService.getApiUrl(false, false)}/public/cms/image/${this.image.id}/filter/${this.selectedFormat.name}/${this.width}/${this.quality}/${this.image.watermark.id}`);
                }
                else{

                    this.sourceUrl = this._imageService.getImageUrlWithNoCache(`${this._apiService.getApiUrl(false, false)}/public/cms/image/${this.image.id}/filter/${this.selectedFormat.name}/${this.width}/${this.quality}`);
                }
            }
        }
    }

    public getSelectedFormat(formats: ImageFormat[]): ImageFormat
    {
        if (!Array.isArray(formats)){

            return null;
        }

        return formats.find((format: ImageFormat): boolean => {

            return format.selected;

        }) || null;
    }
}
