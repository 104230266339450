<div class="bloc_offers_filters_more">

    <div class="row marges">
        <div class="col_12 col_lg_4">
            <div class="bloc_filter">
                <span class="filters_title">{{'offer.list.filter.more.travellers.value' | translate}}</span>

                <!-- Voyageurs -->

                <p class="filter_section_title">{{'offer.list.filter.travellers.value' | translate}}</p>

                <div class="filter_duo_group">

                    <!-- Adultes -->

                    <div class="filter_item">
                        <app-core-text-filter #filter #adultFilter [builder]="filterBuilder"
                                              [label]="'offer.list.filter.adult.value' | translate" [key]="prefixFilter + 'adult'"
                                              [operator]="'eq'" [disableAutoSubmit]="true"></app-core-text-filter>
                    </div>

                    <!-- Enfants -->
                    <div class="filter_item">
                        <app-core-text-filter #filter #childFilter [builder]="filterBuilder"
                                              [label]="'offer.list.filter.child.value' | translate" [key]="prefixFilter + 'child'"
                                              [operator]="'eq'" [disableAutoSubmit]="true"></app-core-text-filter>
                    </div>
                </div>

                <!-- Type de clientèle -->
                <div class="filter_item">
                    <app-core-select-array-multiple-filter #filter #customerTypologyFilter [builder]="filterBuilder"
                                                           [label]="'offer.list.filter.offerType.value' | translate"
                                                           [key]="prefixFilter + 'customerTypology.id'" [operator]="'in'"
                                                           [itemValueProperty]="'id'" [itemLabelProperty]="'name'"
                                                           [items]="translatedType" [disableAutoSubmit]="true"></app-core-select-array-multiple-filter>
                </div>

            </div>
            <div class="bloc_filter">
                <span class="filters_title">{{'offer.list.filter.more.date.value' | translate}}</span>

                <!-- Dates -->

                <div class="filter_item filter_item_range">
                    <app-core-date-interval-filter #filter #dateFilter [builder]="filterBuilder" [key]="prefixFilter + 'date'" [startOperator]="'gte'"
                                                   [endOperator]="'lte'" [disableAutoSubmit]="true" [dateFormat]="'YYYY-MM-DD'" [startPlaceholder] ="'filter.dateInterval.stay.value' | translate" [endPlaceholder] = "''" ></app-core-date-interval-filter>
                </div>

                <!-- Duree -->

                <div class="filter_item">
                    <app-core-select-array-multiple-filter #filter #durationFilter [builder]="filterBuilder"
                                                           [label]="'offer.list.filter.duration.value' | translate"
                                                           [key]="prefixFilter + 'duration.id'" [operator]="'in'"
                                                           [itemValueProperty]="'id'" [itemLabelProperty]="'label'"
                                                           [items]="translatedDuration" [disableAutoSubmit]="true"></app-core-select-array-multiple-filter>
                </div>

                <!-- Destinations -->

                <!--<div class="filter_item">
                    <app-core-select-array-multiple-filter #filter #destinationsFilter [builder]="filterBuilder"
                                                           [label]="'offer.list.filter.destination.value' | translate"
                                                           [key]="prefixFilter + 'attributes.attribute.destinations'" [operator]="'in'"
                                                           [itemValueProperty]="'id'" [itemLabelProperty]="'label'"
                                                           [items]="offerAttributeTouristsDestinations" [disableAutoSubmit]="true"></app-core-select-array-multiple-filter>
                </div>-->

                <!-- Localisation -->

                <div class="filter_item">
                    <app-core-text-filter #filter #cityFilter [builder]="filterBuilder"
                                          [label]="'offer.list.filter.localisation.city.value' | translate"
                                          [key]="prefixFilter + 'locations.city'" [operator]="'lk'" [disableAutoSubmit]="true"></app-core-text-filter>
                </div>

            </div>
        </div>
        <div class="col_12 col_lg_4">
            <div class="bloc_filter">
                <span class="filters_title">{{'offer.list.filter.more.stay.value' | translate}}</span>

                <!-- Type de séjour -->

                <div class="filter_item">
                    <app-core-select-array-multiple-filter #filter #typesFilter [builder]="filterBuilder"
                                                           [label]="'offer.list.filter.type.value' | translate"
                                                           [key]="prefixFilter + 'attributes.attribute.types'" [operator]="'in'"
                                                           [itemValueProperty]="'id'" [itemLabelProperty]="'label'"
                                                           [items]="offerAttributeTypes" [disableAutoSubmit]="true"></app-core-select-array-multiple-filter>
                </div>

                <!-- Thematiques -->

                <div class="filter_item">
                    <app-core-select-array-multiple-filter #filter #themesFilter [builder]="filterBuilder"
                                                           [label]="'offer.list.filter.themes.value' | translate"
                                                           [key]="prefixFilter + 'attributes.attribute.themes'" [operator]="'in'"
                                                           [itemValueProperty]="'id'" [itemLabelProperty]="'label'"
                                                           [items]="offerAttributeThemes"
                                                           [disableAutoSubmit]="true"
                    ></app-core-select-array-multiple-filter>
                </div>

                <!-- Hebergement -->

                <div class="filter_item">
                    <app-core-select-array-multiple-filter #filter #accomodationsFilter [builder]="filterBuilder"
                                                           [label]="'offer.list.filter.accomodations.value' | translate"
                                                           [key]="prefixFilter + 'attributes.attribute.accommodation'" [operator]="'in'"
                                                           [itemValueProperty]="'id'" [itemLabelProperty]="'label'"
                                                           [items]="offerAttributeAccomodations" [disableAutoSubmit]="true"></app-core-select-array-multiple-filter>
                </div>

                <!-- Restauration -->

                <div class="filter_item">
                    <app-core-select-array-multiple-filter #filter #restaurationsFilter [builder]="filterBuilder"
                                                           [label]="'offer.list.filter.restoration.value' | translate"
                                                           [key]="prefixFilter + 'attributes.attribute.restoration'" [operator]="'in'"
                                                           [itemValueProperty]="'id'" [itemLabelProperty]="'label'"
                                                           [items]="offerAttributeRestoration" [disableAutoSubmit]="true"></app-core-select-array-multiple-filter>
                </div>

                <!-- Régions -->

                <div class="filter_item">
                    <app-core-select-array-multiple-filter #filter #regionsFilter [builder]="filterBuilder"
                                                           [label]="'offer.list.filter.region.value' | translate"
                                                           [key]="prefixFilter + 'attributes.attribute.region'" [operator]="'in'"
                                                           [itemValueProperty]="'id'" [itemLabelProperty]="'label'"
                                                           [items]="offerAttributeRegions" [disableAutoSubmit]="true"></app-core-select-array-multiple-filter>
                </div>

                <!-- Activites -->
                <div class="filter_item">
                    <app-core-select-array-multiple-filter #filter #activitiesFilter [builder]="filterBuilder"
                                                           [label]="'offer.list.filter.activities.value' | translate"
                                                           [key]="prefixFilter + 'attributes.attribute.activities'" [operator]="'in'"
                                                           [itemValueProperty]="'id'" [itemLabelProperty]="'label'"
                                                           [items]="offerAttributeActivities" [disableAutoSubmit]="true"></app-core-select-array-multiple-filter>
                </div>

            </div>
        </div>
        <div class="col_12 col_lg_4">

            <div class="bloc_filter">
                <span class="filters_title">{{'offer.list.filter.more.tywin.value' | translate}}</span>

                <!-- Date de mise à jour -->

                <ng-container *ngIf="hasOneOfThisRoles(['ROLE_OFFER_DISTRIBUTOR', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION', 'ROLE_OFFER_CREATOR', 'ROLE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_PROVIDER']) || isOneOfTheseModes(['catalog', 'offer-permanent-option-catalog', 'hashtag-catalog', 'article-catalog'])">

                    <div class="filter_item filter_item_range filter_dates">

                        <app-core-date-interval-filter  #filter #updatedAtFilter [builder]="filterBuilder" [key]="prefixFilter + 'updatedAt'" [startOperator]="'gte'"
                                                        [endOperator]="'lte'" [disableAutoSubmit]="false" [startPlaceholder] ="'filter.dateInterval.maj.value' | translate" [endPlaceholder] = "''"></app-core-date-interval-filter>

                    </div>

                </ng-container>

                <ng-container [ngSwitch]="true">

                    <ng-container  *ngSwitchCase="hasOneOfThisRoles(['ROLE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_OFFER_CREATOR', 'ROLE_OFFER_DISTRIBUTOR', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION']) && providerNetwork.length > 0">
                        <ng-container *ngTemplateOutlet="networkProviders"></ng-container>
                    </ng-container>

                    <ng-container *ngSwitchCase="(hasRole('ROLE_OFFER_CREATOR') && mode !== 'reservoir' && provider.length > 0)">
                        <ng-container *ngTemplateOutlet="providers"></ng-container>
                    </ng-container>

                    <ng-container *ngSwitchCase="hasOneOfThisRoles(['ROLE_OFFER_CREATOR', 'ROLE_OFFER_DISTRIBUTOR', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION']) && society?.hasRestrictedNetworkProvider">
                        <ng-container *ngTemplateOutlet="restrictedProviders"></ng-container>
                    </ng-container>

                    <ng-container  *ngSwitchCase="hasOneOfThisRoles(['ROLE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_OFFER_DISTRIBUTOR', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION']) && mode !== 'personnal-offers' && mode !== 'offer-permanent-option-personnal-offers'">
                        <ng-container *ngTemplateOutlet="networkOfferCreators"></ng-container>
                    </ng-container>

                    <ng-container *ngSwitchCase="hasOneOfThisRoles(['ROLE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_PROVIDER', 'ROLE_OFFER_DISTRIBUTOR', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION'])">
                        <ng-container *ngTemplateOutlet="offerCreators"></ng-container>
                    </ng-container>
                </ng-container>

                <ng-template #networkProviders>

                    <!-- Reseaux de prestataires -->
                    <div class="filter_item">
                        <app-core-select-array-multiple-filter #filter #groupNetworkProvidersFilter [builder]="filterBuilder"
                                                               [label]="'offer.list.filter.networkProvider.value' | translate"
                                                               [key]="prefixFilter + 'providerSocieties.networkProviders.groupNetworkProvider.id'" [operator]="'in'"
                                                               [itemValueProperty]="'id'"
                                                               [itemLabelProperty]="'label'"
                                                               [items]="providerNetwork"
                                                               [disableAutoSubmit]="true"
                        ></app-core-select-array-multiple-filter>
                    </div>

                </ng-template>

                <ng-template #networkOfferCreators>

                    <!-- Reseaux createur d'offres-->
                    <div class="filter_item">
                        <app-core-select-array-multiple-filter #filter #networkOfferCreatorsFilter [builder]="filterBuilder"
                                                               [label]="'offer.list.filter.offerCreatorNetwork.value' | translate"
                                                               [key]="prefixFilter + 'society.networkOfferCreators.id'" [operator]="'in'"
                                                               [itemValueProperty]="'id'"
                                                               [itemLabelProperty]="'name'"
                                                               [items]="offerCreatorNetwork"
                                                               [disableAutoSubmit]="true"
                        ></app-core-select-array-multiple-filter>
                    </div>

                </ng-template>

                <ng-template #offerCreators>

                    <!-- Createur d'offre -->
                    <div class="filter_item">
                        <app-core-select-array-multiple-filter #filter #offerCreatorFilter [builder]="filterBuilder"
                                                               [label]="'offer.list.filter.offerCreator.value' | translate"
                                                               [key]="prefixFilter + 'society.id'" [operator]="'in'"
                                                               [itemValueProperty]="'id'"
                                                               [itemLabelProperty]="'name'"
                                                               [items]="offerCreator"
                                                               [disableAutoSubmit]="true"
                        ></app-core-select-array-multiple-filter>
                    </div>
                </ng-template>

                <ng-template #providers>

                    <!-- Prestataires -->
                    <div class="filter_item">
                        <app-core-select-array-multiple-filter #filter #providersFilter [builder]="filterBuilder"
                                                               [label]="'offer.list.filter.provider.value' | translate"
                                                               [key]="prefixFilter + 'providerSocieties.id'" [operator]="'in'"
                                                               [itemValueProperty]="'id'"
                                                               [itemLabelProperty]="'name'"
                                                               [items]="provider"
                                                               [disableAutoSubmit]="true"
                        ></app-core-select-array-multiple-filter>
                    </div>
                </ng-template>

                <ng-template #restrictedProviders>

                    <!-- Prestataires restreints -->
                    <div class="filter_item">
                        <app-core-select-array-multiple-filter #filter #restrictedProvidersFilter [builder]="filterBuilder"
                                                               [label]="'offer.list.filter.restrictedProviders.value' | translate"
                                                               [key]="prefixFilter + 'restrictedProviderSocieties.id'" [operator]="'in'"
                                                               [itemValueProperty]="'id'"
                                                               [itemLabelProperty]="'name'"
                                                               [items]="restrictedSocietyProviders"
                                                               [disableAutoSubmit]="true"
                        ></app-core-select-array-multiple-filter>
                    </div>
                </ng-template>

                <ng-container *ngIf="isOneOfTheseModes(['reservoir', 'hashtag-reservoir', 'article-reservoir']) && hasOneOfThisRoles(['ROLE_OFFER_CREATOR', 'ROLE_OFFER_DISTRIBUTOR', 'ROLE_PROVIDER', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION'])">
                    <ng-container *ngTemplateOutlet="offerAdded"></ng-container>
                </ng-container>

                <ng-container *ngIf="isOneOfTheseModes(['reservoir', 'personnal-offers', 'offer-permanent-option-personnal-offers', 'hashtag-reservoir', 'article-reservoir'])">

                    <div class="filter_item">
                        <app-core-checkbox-boolean-filter #filter #notInCatalogFilter [builder]="filterBuilder" [label]="'offer.list.filter.notInCatalog.value' | translate" [key]="prefixFilter + 'notInCatalog'"></app-core-checkbox-boolean-filter>
                    </div>

                </ng-container>

                <ng-template #offerAdded >

                    <!-- Offre ajoutées aux catalogue -->
                    <div class="filter_item" >
                        <app-core-checkbox-boolean-filter #filter #offersAddedFilter [builder]="filterBuilder"  [key]="prefixFilter + 'offerAdded'" [label]="'offer.list.filter.offerCatalog.value' | translate" ></app-core-checkbox-boolean-filter>
                    </div>

                </ng-template>

            </div>
            <div class="bloc_filter" *ngIf="hasRole('ROLE_OFFER_CREATOR')">
                <span class="filters_title">{{'offer.list.filter.more.vigilance.value' | translate}}</span>

                <!-- Vigilance -->

                <div class="filter_item">
                    <app-core-select-boolean-filter #filter #isOnAlertFilter [builder]="filterBuilder" [label]="'offer.list.filter.isOnAlert.value' | translate" [key]="prefixFilter + 'isOnAlert'" [truthyLabel]="'offer.isOnAlert.yes.value' | translate" [falsyLabel]="'offer.isOnAlert.no.value' | translate"></app-core-select-boolean-filter>
                </div>

            </div>
        </div>
    </div>

    <div class="row marges">
        <div class="col_12 x_end">
            <button type="button" (click)="submit()" class="btn_cta btn_little">{{'filter.dateInterval.submit.value' | translate}}</button>
        </div>
    </div>

</div>
