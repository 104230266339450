<div [ngStyle]="getColumnStyle()" class="app-column">
    <div class="actions">

        <button type="button" mat-stroked-button [disabled]="!isDecreaseAllowed" (click)="decreasePosition.emit()" matTooltip="{{ 'column.move.left.action' | translate }}" matTooltipPosition="above">
            <mat-icon>arrow_back</mat-icon>
        </button>

        <button type="button" mat-stroked-button [disabled]="!isIncreaseAllowed" (click)="increasePosition.emit()" matTooltip="{{ 'column.move.right.action' | translate }}" matTooltipPosition="above">
            <mat-icon>arrow_forward</mat-icon>
        </button>

        <button type="button" mat-stroked-button (click)="resetContent()" class="mat-warn" matTooltip="{{ 'column.reset.action' | translate }}" matTooltipPosition="above">
            <mat-icon>loop</mat-icon>
        </button>

    </div>

    <ng-container *ngIf="cmsService.getColumnParams().enableBackgroundColor">

        <div class="bloc">
            <div class="infos-bloc">
                <div>
                    <span class="label">{{ 'element.backgroundColor.label' | translate }}</span>
                    <select [(ngModel)]="column.backgroundColor" [compareWith]="compareByOptionTag">
                        <option [ngValue]="null">{{ 'element.backgroundColor.choice' | translate }}</option>
                        <option *ngFor="let backgroundColor of backgroundColors" [ngValue]="backgroundColor">
                            {{ backgroundColor.label }}
                        </option>
                    </select>
                </div>
            </div>
        </div>

    </ng-container>

    <div class="elements">
        <app-element
                *ngFor="let element of column.elements | orderBy : ['position']"
                [element]="element"
                [allowedTypes]="template.allowedTypes"
                [isDecreaseAllowed]="isDecreaseElementAllowed(element)"
                [isIncreaseAllowed]="isIncreaseElementAllowed(element)"
                [deleteable]="column.elements.length >= 2"
                (decreaseContent)="decreaseContentElement(element)"
                (increaseContent)="increaseContentElement(element)"
                (delete)="deleteElement(element)">
        </app-element>
        <div class="add_element" (click)="addElement()"><span>+</span><span>{{ 'element.action.add' | translate }}</span></div>
    </div>
</div>

