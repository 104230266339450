import {Component, OnInit} from '@angular/core';
import {Article} from '@core/shared/models/article';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {BreadcrumbItem} from '@core/shared/models/breadcrumb/breadcrumb-item';
import {TranslationItem} from '@core/shared/models/translation';
import {TranslationService} from '@core/shared/services/translation.service';
import {CmsContentService} from "@core/shared/services/cms/cms-content.service";
import {UserService} from "@core/shared/services/user.service";
import {User} from "@core/shared/models/user";
import {OfferService} from "@core/shared/services/offer.service";
import {OfferCatalogService} from "@core/shared/services/offer/offer-catalog.service";
import {Pagination} from "@core/shared/models/pagination";
import {OfferCatalog} from "@core/shared/models/offer/offer-catalog";
import {Offer} from "@core/shared/models/offer";
import {OfferAttribute} from "@core/shared/models/offer-attribute";
import {CustomerTypology} from "@core/shared/models/customer-typology";
import {OfferDuration} from '@core/shared/models/offer/offer-duration';
import {StatisticService} from "@core/shared/services/statistic.service";
import {Statistic, StatisticEvent} from "@core/shared/models/statistic";
import {plainToClass} from "class-transformer";
import {StatisticTypeTag} from "@core/shared/models/statistic/statistic-type";

enum AutomaticOfferFilterType {
    API = 'API',
    SEARCH = 'SEARCH'
}

@Component({
    selector: 'app-core-page-article-read',
    templateUrl: './page-article-read.component.html',
    styleUrl: './page-article-read.component.scss',
    providers: [
        CmsContentService
    ]
})
export class PageArticleReadComponent implements OnInit {

    public article: Article;

    public breadcrumbItems: BreadcrumbItem[];

    public locale: string;

    public currentUser: User = null;

    public exploreOffersEnabled: boolean = false;

    public listRedirectionAllowed: boolean = false;

    constructor(
        private _activatedRoute: ActivatedRoute,
        private _translationService: TranslationService,
        private _offerService: OfferService,
        private _offerCatalogService: OfferCatalogService,
        private _statisticService: StatisticService,
        private _router: Router,
        public userService: UserService
    ) {}

    ngOnInit() {

        this.currentUser = this.userService.currentUser.getValue();

        this.article = (this._activatedRoute.snapshot.data as { article: Article }).article;

        this.locale = this._translationService.getFallbackTranslation(this.article.translations).locale;

        this._handleListRedirection();

        this._initBreadcrumb();

        this._initExploreOffersState();
    }

    private _handleListRedirection(): void {

        if(!this._activatedRoute.snapshot.queryParams['listRedirectionAllowed']){

            return;
        }

        this.listRedirectionAllowed = Boolean(parseInt(this._activatedRoute.snapshot.queryParams['listRedirectionAllowed']));
    }

    private _initBreadcrumb(): void
    {
        this.breadcrumbItems = [
            {
                label: this._translationService.getTranslationForLocaleWithFallback(this.locale, 'trend.management.value'),
                onClickCallback: (): void => {

                    this._router.navigate(['account/article/search']);
                }
            },
            {
                label: this.getTranslation(this.article.translations).title,
                onClickCallback: null
            }
        ];
    }

    private _initExploreOffersState(): void {

        const params: string[] = [];

        const prefix: string = (this.userService.hasRole(this.currentUser, 'ROLE_OFFER_CREATOR') && !this.userService.hasRole(this.currentUser, 'ROLE_OFFER_DISTRIBUTOR')) ? 'offer.' : '';

        if(this.article.offerConfiguration.mode === 'manual'){

            params.push(`${prefix}articles.offerConfiguration.article.id[eq]=${ this.article.id }`);
        }
        else{

            params.push(...this.getAutomaticOfferFilters(AutomaticOfferFilterType.API));
        }

        if(this.userService.hasRole(this.currentUser, 'ROLE_OFFER_CREATOR') && !this.userService.hasRole(this.currentUser, 'ROLE_OFFER_DISTRIBUTOR')){

            if(!this.userService.hasAccess(this.currentUser, 'OFFER_CATALOG_LIST')){

                return;
            }

            this._offerCatalogService.getPaginationItemsAPI(params).subscribe((pagination: Pagination<OfferCatalog>): void => {

                this.exploreOffersEnabled = pagination.totalItems > 0;
            });
        }
        else{

            if(!this.userService.hasAccess(this.currentUser, 'OFFER_LIST')){

                return;
            }

            this._offerService.getPaginationItemsAPI(params).subscribe((pagination: Pagination<Offer>): void => {

                this.exploreOffersEnabled = pagination.totalItems > 0;
            });
        }
    }

    public getTranslation<T extends TranslationItem>(translations: Array<T>): T {

        return translations.find((translation: T): boolean => {

            return translation.locale === this.locale;

        }) || translations[0];
    }

    public updateLocale(locale: string): void {

        this.locale = locale;

        this._initBreadcrumb();

        const statistic: Statistic = plainToClass(Statistic, {
            resourceType: StatisticTypeTag.Article,
            event: StatisticEvent.ItemRead,
            resourceIdentifier: this.article.id.toString(),
            resourceLocale: this.locale
        } as Statistic);

        this._statisticService.createItemAPI(statistic).subscribe();
    }

    public exploreOffers(): void {

        const queryParams: Params = {};

        const filtersParams: string[] = [];

        if(this.article.offerConfiguration.mode === 'manual'){

            Object.assign(queryParams, {
                articleTitle: this.getTranslation(this.article.translations).title
            });

            filtersParams.push(`articleId[eq]=${this.article.id}`);
        }
        else{

            filtersParams.push(...this.getAutomaticOfferFilters(AutomaticOfferFilterType.SEARCH));
        }

        Object.assign(queryParams, {
            origin: 'articleRead',
            articleId: this.article.id,
            filters: filtersParams.join('|')
        });

        if(!this.userService.hasOneOfThisRoles(this.currentUser, ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'])){

            const statistic: Statistic = plainToClass(Statistic, {
                resourceType: StatisticTypeTag.Article,
                event: StatisticEvent.AssociatedOfferList,
                resourceIdentifier: this.article.id.toString(),
                resourceLocale: this.locale
            } as Statistic);

            this._statisticService.createItemAPI(statistic).subscribe();
        }

        if(this.userService.hasRole(this.currentUser, 'ROLE_OFFER_CREATOR') && !this.userService.hasRole(this.currentUser, 'ROLE_OFFER_DISTRIBUTOR')){

            this._router.navigate(['account/offer/list/catalog'], { queryParams: queryParams});
        }
        else{

            this._router.navigate(['account/offer/list/reservoir'], { queryParams: queryParams});
        }
    }

    public getAutomaticOfferFilters(type: AutomaticOfferFilterType): string[] {

        const params: string[] = [];

        const prefix: string = (type === AutomaticOfferFilterType.API) ? (this.userService.hasRole(this.currentUser, 'ROLE_OFFER_CREATOR') && !this.userService.hasRole(this.currentUser, 'ROLE_OFFER_DISTRIBUTOR') ? 'offer.' : '' ) : '';

        // Types de clientèle

        this.article.configuration.customerTypologies.forEach((item: CustomerTypology): void => {

            params.push(`${prefix}customerTypology.id[in][]=${item.id}`);
        });

        // Thématiques

        this.article.configuration.themes.forEach((item: OfferAttribute): void => {

            params.push(`${prefix}attributes.attribute.themes[in][]=${item.id}`);
        });

        // Régions

        this.article.configuration.regions.forEach((item: OfferAttribute): void => {

            params.push(`${prefix}attributes.attribute.region[in][]=${item.id}`);
        });

        // Types de séjour

        this.article.configuration.types.forEach((item: OfferAttribute): void => {

            params.push(`${prefix}attributes.attribute.types[in][]=${item.id}`);
        });

        // Nombre de voyageurs

        switch (type){

            case AutomaticOfferFilterType.API:

                if(this.article.offerConfiguration.nbAdult){

                    params.push(`${prefix}adultMin[lte]=${ this.article.offerConfiguration.nbAdult }`);

                    params.push(`${prefix}adultMax[gte]=${ this.article.offerConfiguration.nbAdult }`);
                }

                if(this.article.offerConfiguration.nbChild){

                    params.push(`${prefix}childMin[lte]=${ this.article.offerConfiguration.nbChild }`);

                    params.push(`${prefix}childMax[gte]=${ this.article.offerConfiguration.nbChild }`);
                }

                break;

            case AutomaticOfferFilterType.SEARCH:

                if(this.article.offerConfiguration.nbAdult){

                    params.push(`${prefix}adult[eq]=${ this.article.offerConfiguration.nbAdult }`);
                }

                if(this.article.offerConfiguration.nbChild){

                    params.push(`${prefix}child[eq]=${ this.article.offerConfiguration.nbChild }`);
                }

                break;
        }

        // Période de départ

        switch (type) {

            case AutomaticOfferFilterType.API:

                if (this.article.offerConfiguration.dateStart && this.article.offerConfiguration.dateEnd) {

                    params.push(`${prefix}date[gte]=${this.article.offerConfiguration.dateStart}`);

                    params.push(`${prefix}date[lte]=${this.article.offerConfiguration.dateEnd}`);
                }

                break;

            case AutomaticOfferFilterType.SEARCH:

                if (this.article.offerConfiguration.dateStart && this.article.offerConfiguration.dateEnd) {

                    params.push(`${prefix}date[eq]=${ JSON.stringify({ start: this.article.offerConfiguration.dateStart, end: this.article.offerConfiguration.dateEnd }) }`);
                }

                break;
        }

        // Durées

        this.article.offerConfiguration.durations.forEach((item: OfferDuration): void => {

            params.push(`${prefix}duration.id[in][]=${item.id}`);
        });

        // Ville

        if (this.article.offerConfiguration.city) {

            params.push(`${prefix}locations.city[lk]=${this.article.offerConfiguration.city}`);
        }

        // Hébergements

        this.article.offerConfiguration.accommodations.forEach((item: OfferAttribute): void => {

            params.push(`${prefix}attributes.attribute.accommodation[in][]=${item.id}`);
        });

        // Restaurations

        this.article.offerConfiguration.restorations.forEach((item: OfferAttribute): void => {

            params.push(`${prefix}attributes.attribute.restoration[in][]=${item.id}`);
        });

        // Activités payantes incluses dans l’offre

        this.article.offerConfiguration.activities.forEach((item: OfferAttribute): void => {

            params.push(`${prefix}attributes.attribute.activities[in][]=${item.id}`);
        });

        return params;
    }

    public redirectToList(): void {

        this._router.navigate(['account/article/search']);
    }
}
