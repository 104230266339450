import {Pipe, PipeTransform} from '@angular/core';
import {TranslationService} from "@core/shared/services/translation.service";
import {Observable, of} from "rxjs";
import {map} from "rxjs/operators";

@Pipe({
    name: 'translationForLocaleWithFallback'
})
export class TranslationForLocaleWithFallbackPipe implements PipeTransform {

    constructor(
        private _translationService: TranslationService
    ) {}

    transform(key: string, locale: string): Observable<string> {

        if(this._translationService.translationsLoaded$.getValue()){

            return of(this._translationService.getTranslationForLocaleWithFallback(locale, key));
        }

        return this._translationService.translationsLoaded$.pipe(map((): string => {

            return this._translationService.getTranslationForLocaleWithFallback(locale, key);
        }));
    }
}
