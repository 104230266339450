import {Injectable} from '@angular/core';
import {ApiService} from "@core/shared/services/api.service";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {Pagination} from "@core/shared/models/pagination";
import {Article} from "@core/shared/models/article";

@Injectable({
    providedIn: 'root'
})
export class ArticleService {

    constructor(
        private _apiService: ApiService,
        private _httpClient: HttpClient
    ) {}

    public getPaginationItemsAPI(params?: string[]): Observable<Pagination<Article>> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/articles`;

        params = params || [];

        if (params.length > 0) {

            url = `${url}?${params.join('&')}`;
        }

        return this._httpClient.get<Pagination<Article>>(url, {
            headers: headers
        });
    }

    public getItemAPI(id: number): Observable<Article> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/articles/${id}`;

        return this._httpClient.get<Article>(url,{
            headers: headers
        });
    }

    public createItemAPI(data: object): Observable<Article> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.post<Article>(`${this._apiService.getApiUrl(false, true)}/articles`, data, {
            headers: headers
        });
    }

    public createSocietyItemAPI(societyId: number, data: object): Observable<Article> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.post<Article>(`${this._apiService.getApiUrl(false, true)}/societies/${societyId}/articles`, data, {
            headers: headers
        });
    }

    public updateItemAPI(id: number, data: object): Observable<Article> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.patch<Article>(`${this._apiService.getApiUrl(false, true)}/articles/${id}`, data, {
            headers: headers
        });
    }

    public deleteItemAPI(id: number): Observable<void> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.delete<void>(`${this._apiService.getApiUrl(false, true)}/articles/${id}`, {
            headers: headers
        });
    }
}
