<div class="row marges">

    <!-- Type -->

    <ng-container *ngIf="articleSearchService.isFilterEnabled(ArticleFilterKey.Type)">

        <div class="col_6 col_md">

            <div class="filter_container">

                <div class="filter_item" [ngClass]="isOverlayOpen(ArticleFilterKey.Type) ? 'open' : ''">
                    <div class="form-group">
                        <input #typePlaceholder type="text" placeholder=" " class="form-control" id="type-filter" readonly (click)="openOverlay(ArticleFilterKey.Type)">
                        <label for="type-filter">{{ 'article.type.plural.value' | translate }}</label>
                        <span class="icon_filter icon-tywin-news"></span>
                    </div>
                </div>

                <app-core-overlay [ngStyle]="overlayContainerStyles(ArticleFilterKey.Type)" [target]="typePlaceholder" (closed)="closeOverlay(ArticleFilterKey.Type)">

                    <app-core-select-array-multiple-filter-panel
                        #typeFilter
                        [builder]="filterBuilder"
                        [label]="'article.type.selection.placeholder.value' | translate"
                        [key]="ArticleFilterKey.Type"
                        [itemValueProperty]="'id'"
                        [itemLabelProperty]="'label'"
                        [itemIconClassPropertyCallback]="typeIconClassPropertyCallback"
                        [items]="types"
                        (selectionChanged)="closeOverlay(ArticleFilterKey.Type)"
                        (parsedValuesUpdated)="updatePlaceholderValue(typePlaceholder, $event)"
                    ></app-core-select-array-multiple-filter-panel>

                </app-core-overlay>

            </div>

        </div>

    </ng-container>

    <!-- Type de clientèle -->

    <ng-container *ngIf="articleSearchService.isFilterEnabled(ArticleFilterKey.CustomerTypology)">

        <div class="col_6 col_md">

            <div class="filter_container">

                <div class="filter_item" [ngClass]="isOverlayOpen(ArticleFilterKey.CustomerTypology) ? 'open' : ''">
                    <div class="form-group">
                        <input #customerTypologyPlaceholder type="text" placeholder=" " class="form-control" id="customerTypology-filter" readonly (click)="openOverlay(ArticleFilterKey.CustomerTypology)">
                        <label for="customerTypology-filter">{{ 'customerTypology.plural.value' | translate }}</label>
                        <span class="icon_filter icon-tywin-users"></span>
                    </div>
                </div>

                <app-core-overlay [ngStyle]="overlayContainerStyles(ArticleFilterKey.CustomerTypology)" [target]="customerTypologyPlaceholder" (closed)="closeOverlay(ArticleFilterKey.CustomerTypology)">

                    <app-core-select-array-multiple-filter-panel
                        #customerTypologyFilter
                        [builder]="filterBuilder"
                        [label]="'article.customerTypology.selection.placeholder.value' | translate"
                        [key]="ArticleFilterKey.CustomerTypology"
                        [itemValueProperty]="'id'"
                        [itemLabelProperty]="'label'"
                        [itemIconClassPropertyCallback]="customerTypologyIconClassPropertyCallback"
                        [items]="customerTypologies"
                        (selectionChanged)="closeOverlay(ArticleFilterKey.CustomerTypology)"
                        (parsedValuesUpdated)="updatePlaceholderValue(customerTypologyPlaceholder, $event)"
                    ></app-core-select-array-multiple-filter-panel>

                </app-core-overlay>

            </div>

        </div>

    </ng-container>

    <!-- Cible marché -->

    <ng-container *ngIf="articleSearchService.isFilterEnabled(ArticleFilterKey.TargetMarket)">

        <div class="col_6 col_md">

            <div class="filter_container overlay_100">

                <div class="filter_item" [ngClass]="isOverlayOpen(ArticleFilterKey.TargetMarket) ? 'open' : ''">
                    <div class="form-group">
                        <input #targetMarketPlaceholder type="text" placeholder=" " class="form-control" id="targetMarket-filter" readonly (click)="openOverlay(ArticleFilterKey.TargetMarket)">
                        <label for="targetMarket-filter">{{ 'article.targetMarket.plural.value' | translate }}</label>
                        <span class="icon_filter icon-tywin-cible"></span>
                    </div>
                </div>

                <app-core-overlay [ngStyle]="overlayContainerStyles(ArticleFilterKey.TargetMarket)" [target]="targetMarketPlaceholder" (closed)="closeOverlay(ArticleFilterKey.TargetMarket)">

                    <app-core-target-market-filter-panel
                        #targetMarketFilter
                        [builder]="filterBuilder"
                        [label]="'article.targetMarket.selection.placeholder.value' | translate"
                        [key]="ArticleFilterKey.TargetMarket"
                        [itemValueProperty]="'id'"
                        [itemLabelProperty]="'label'"
                        [items]="targetMarkets"
                        (selectionChanged)="closeOverlay(ArticleFilterKey.TargetMarket)"
                        (parsedValuesUpdated)="updatePlaceholderValue(targetMarketPlaceholder, $event)"
                    ></app-core-target-market-filter-panel>

                </app-core-overlay>

            </div>

        </div>

    </ng-container>

    <div class="col_6 col_content_md y_items_center">

        <div class="row marges x_end">

            <!-- Plus de filtres -->

            <div class="col_content y_items_center">

                <button type="button" class="btn_cta btn_lines btn_small more_filters" (click)="openSidePanelOverlay.emit()"><span class="icon-tywin-filters">{{ 'offer.list.filter.value' | translate }}</span></button>

            </div>

            <!-- Réinitialisation des filtres -->

            <div class="col_content y_items_center">

                <button type="button" class="icon-tywin-reset reset_filters" (click)="reset()">{{ 'offer.list.filter.reinitialise.value' | translate }}</button>

            </div>

        </div>

    </div>

</div>
