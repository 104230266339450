import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Page} from "@core/shared/models/page";
import {TranslationService} from "@core/shared/services/translation.service";
import {Location} from '@angular/common';
import {CmsContentService} from "@core/shared/services/cms/cms-content.service";
import {TranslationItem} from '@core/shared/models/translation';
import {User} from '@core/shared/models/user';
import {UserService} from '@core/shared/services/user.service';

@Component({
    selector: 'page-read',
    templateUrl: './page-read.component.html',
    styleUrls: ['./page-read.component.scss'],
    providers: [
        CmsContentService
    ]
})
export class PageReadComponent implements OnInit {

    public page: Page;

    public currentUser: User;

    constructor(
        private _activatedRoute: ActivatedRoute,
        private _location: Location,
        public translationService: TranslationService,
        public userService: UserService
    ) {
    }

    ngOnInit() {

        this._activatedRoute.data.subscribe((data: { page: Page }): void => {

            this.page = data.page;

            this.currentUser = this.userService.currentUser.getValue();
        });
    }

    public backClicked(): void {

        this._location.back();
    }

    public getTranslation<T extends TranslationItem>(translations: Array<T>): T {

        return translations.find((translation: T): boolean => {

            return translation.locale === this.currentUser.locale;

        }) || translations[0];
    }

    get localeId(): string {

        return this.translationService.getUserLocale();
    }

    get backEnabled(): boolean {

        const param: string = this._activatedRoute.snapshot.queryParams['backEnabled'];

        return !!param ? Boolean(parseInt(param)) : false;
    }
}
