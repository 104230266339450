<div class="overlay_title">
    <p>{{ label }}</p>
</div>
<div class="overlay_content">
    <div class="row content_options">

        <div class="col_content" *ngFor="let item of items">
            <div class="option" [ngClass]="optionClasses(item)" (click)="handleItemSelection(item)">
                <span class="icon {{ getIconClassPropertyValue(item) }}"></span>
                <span class="label">{{ getLabelPropertyValue(item) }}</span>
            </div>
        </div>

    </div>
</div>
<div class="overlay_actions">
    <div class="row marges x_end">
        <div class="col_content y_items_center">
            <button type="button" class="icon-tywin-reset reset_filters" (click)="reset(true)">
                {{ 'form.field.action.reset' | translate }}
            </button>
        </div>
        <div class="col_content y_items_center">
            <button type="button" class="btn_cta btn_lines btn_little" [disabled]="isEmptySelection" (click)="filter()">
                <span>{{ 'result.plural.display.action.value' | translate }}</span>
            </button>
        </div>
    </div>
</div>