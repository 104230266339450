<div class="form-group" style="margin-bottom:0;">
    <div class="row marges">
        <span class="col_content y_items_center mat-label">Ajouter des images :</span>
        <button type="button" (click)="openGalleryDialog()" class="btn_cta btn_little">{{ 'media.image.choice.action' | translate }}</button>
    </div>
</div>
<div class="liste-images">
    <div *ngFor="let image of images | orderByImpure: '+position'" class="image">
        <div class="actions">

            <button type="button" mat-stroked-button [disabled]="!isDecreaseAllowed(image)" (click)="decreasePosition(image)" matTooltip="{{ 'media.image.moveLeft.action' | translate }}" matTooltipPosition="left">
                <mat-icon>arrow_upward</mat-icon>
            </button>

            <button type="button" mat-stroked-button [disabled]="!isIncreaseAllowed(image)" (click)="increasePosition(image)" matTooltip="{{ 'media.image.moveRight.action' | translate }}" matTooltipPosition="left">
                <mat-icon>arrow_downward</mat-icon>
            </button>

            <button type="button" mat-stroked-button (click)="delete(image)" class="delete mat-warn" matTooltip="{{ 'media.image.delete.action' | translate }}" matTooltipPosition="left">
                <mat-icon>delete</mat-icon>
            </button>

        </div>
        <div class="infos">
            <div class="big-label" *ngIf="options.enableTitle">
                <span class="label">{{ 'element.types.image.title' | translate }}</span>
                <input type="text" [(ngModel)]="image.title" (ngModelChange)="update(image)"/>
            </div>
            <div class="big-label" *ngIf="options.enableSubtitle">
                <span class="label">{{ 'element.types.image.subtitle' | translate }}</span>
                <input type="text" [(ngModel)]="image.subtitle" (ngModelChange)="update(image)"/>
            </div>
            <div class="big-label" *ngIf="options.enableAlt">
                <span class="label">{{ 'element.types.image.alt' | translate }}</span>
                <input type="text" [(ngModel)]="image.alt" (ngModelChange)="update(image)"/>
            </div>
            <div class="big-label" *ngIf="options.enableLink">
                <span class="label">{{ 'element.types.image.link' | translate }}</span>
                <input type="text" [(ngModel)]="image.link" (ngModelChange)="update(image)"/>
            </div>
            <div class="no-padding" *ngIf="options.enableTargetBlank">
                <label class="input-checkbox">
                    <input class="checkbox-target" type="checkbox" [(ngModel)]="image.target" (ngModelChange)="update(image)"/>
                    <span class="label">{{ 'element.types.image.targetBlank' | translate }}</span>
                </label>
            </div>
        </div>
        <div class="formats">
            <div *ngFor="let format of getDisplayedFormats(image) | orderBy: '+ratio'" class="format image-element" [hidden]="!format.selected">

                <div [ngClass]="format.selected ? 'selected' : ''">
                    <mat-icon>check</mat-icon>
                    <div class="visuel">
                        <img
                                [src]="getThumbnailPath(image, format)"
                                [height]="(thumbnailWidth / format.ratio)"
                                [width]="thumbnailWidth"
                                (load)="format.loaded = true;"
                        />
                    </div>
                    <span class="loading" [style.display]="format.loaded ? 'none' : 'block'"></span>
                </div>
                <div class="actions">
                    <button type="button" (click)="openCropperDialog(image, format)"><mat-icon>crop</mat-icon>{{ 'media.image.format.modify.action' | translate }}</button>
                </div>
            </div>
            <div class="big-label" *ngIf="options.enableWatermark">
                <span class="label">{{ 'offer.form.fields.copyright.value' | translate }}</span>
                <input type="text" [(ngModel)]="image.copyright" (ngModelChange)="update(image)" [disabled]="!isCopyrightAvailableItems[image.id]">
            </div>
        </div>
    </div>
</div>
