import {Injectable} from '@angular/core';
import {FilterBuilder, FilterField} from "@core/shared/models/filter";
import {BehaviorSubject, Subject} from "rxjs";
import {JwtToken} from "@core/shared/models/jwt";
import {AuthenticationService} from "@core/shared/services/authentication.service";
import {Article} from "@core/shared/models/article";
import {ARTICLE_SEARCH_SESSION_STORAGE_IDENTIFIER, ArticleFilterKey, ArticleSearchSessionFilter} from "@core/shared/models/article/article-search";

@Injectable()
export class ArticleSearchService {

    private _totalItems: number = 0;

    public sessionEnabled: boolean = false;

    public filterBuilder: FilterBuilder = new FilterBuilder();

    public filtersMetadata$: BehaviorSubject<{ [p in ArticleFilterKey]: any }> = new BehaviorSubject({
        [ArticleFilterKey.Type]: null,
        [ArticleFilterKey.CustomerTypology]: null,
        [ArticleFilterKey.TargetMarket]: null,
        [ArticleFilterKey.Locale]: null,
        [ArticleFilterKey.SelfAuthor]: null,
        [ArticleFilterKey.TywinAuthor]: null,
        [ArticleFilterKey.Author]: null,
        [ArticleFilterKey.Region]: null,
        [ArticleFilterKey.Theme]: null,
        [ArticleFilterKey.UpdatedAt]: null,
        [ArticleFilterKey.PublishedAt]: null,
        [ArticleFilterKey.Status]: null
    });

    public enabledFilters: { [p in ArticleFilterKey]: boolean } = {
        [ArticleFilterKey.Type]: false,
        [ArticleFilterKey.CustomerTypology]: false,
        [ArticleFilterKey.TargetMarket]: false,
        [ArticleFilterKey.Locale]: false,
        [ArticleFilterKey.SelfAuthor]: false,
        [ArticleFilterKey.TywinAuthor]: false,
        [ArticleFilterKey.Author]: false,
        [ArticleFilterKey.Region]: false,
        [ArticleFilterKey.Theme]: false,
        [ArticleFilterKey.UpdatedAt]: false,
        [ArticleFilterKey.PublishedAt]: false,
        [ArticleFilterKey.Status]: false
    };

    public resetFilter$: Subject<void> = new Subject();

    public selectedItems$: BehaviorSubject<Article[]> = new BehaviorSubject([]);

    public deleteActiveFilterField$: Subject<FilterField> = new Subject();

    public selectItemEnabled: boolean = false;

    public selectItemAllowed: boolean = false;

    public unselectItemAllowed: boolean = false;

    public allItemsSelected: boolean = false;

    public isItemSelectionAllowedCallback: (item: Article) => boolean = () => { return true };

    public isItemUnselectionAllowedCallback: (item: Article) => boolean = () => { return true };

    public additionalFilterParams$: BehaviorSubject<string[]> = new BehaviorSubject([]);

    public refreshItems: Subject<void> = new Subject();

    constructor(
        private _authenticationService: AuthenticationService
    ) {
        this._handleSession();
    }

    private _handleSession(): void {

        this._authenticationService.jwtToken.subscribe((value: JwtToken): void => {

            if(!value.isAuthenticated){

                sessionStorage.removeItem(ARTICLE_SEARCH_SESSION_STORAGE_IDENTIFIER);
            }
        });
    }

    public getSessionFilter(key: string): ArticleSearchSessionFilter {

        const filters: ArticleSearchSessionFilter[] = this.sessionFilters;

        if (!filters || !Boolean(filters.length)){

            return null;
        }

        return filters.find((sessionFilter: ArticleSearchSessionFilter): boolean => {

            return sessionFilter.key === key;
        });
    }

    public hasSessionFilter(key: string): boolean {

        const filters: ArticleSearchSessionFilter[] = this.sessionFilters;

        if (!filters || !Boolean(filters.length)){

            return false;
        }

        return !!filters.find((sessionFilter: ArticleSearchSessionFilter): boolean => {

            return sessionFilter.key === key;
        });
    }

    public selectItem(item: Article): void {

        if(!this.selectItemAllowed || !this.isItemSelectionAllowed(item)){

            return;
        }

        const items: Article[] = this.selectedItems$.value;

        items.push(item);

        this.selectedItems$.next(items);
    }

    public unselectItem(item: Article): void {

        if(!this.unselectItemAllowed || !this.isItemUnselectionAllowed(item)){

            return;
        }

        const items: Article[] = this.selectedItems$.value;

        const index: number = items.findIndex((article: Article): boolean => {

            return item.id === article.id;
        });

        items.splice(index, 1);

        this.selectedItems$.next(items);
    }

    public isSelectedItem(item: Article): boolean {

        const match: Article = this.selectedItems$.value.find((article: Article): boolean => {

            return item.id === article.id;
        });

        return !!match;
    }

    public isItemSelectionAllowed(item: Article): boolean {

        return this.isItemSelectionAllowedCallback(item);
    }

    public isItemUnselectionAllowed(item: Article): boolean {

        return this.isItemUnselectionAllowedCallback(item);
    }

    public getFilterMetadata(key: ArticleFilterKey): any {

        const filtersMetadata = this.filtersMetadata$.getValue();

        return filtersMetadata[key];
    }

    public updateFilterMetadata(key: ArticleFilterKey, value: any): void {

        const filtersMetadata = this.filtersMetadata$.getValue();

        filtersMetadata[key] = value;

        this.filtersMetadata$.next(filtersMetadata);
    }

    public isFilterEnabled(key: ArticleFilterKey): boolean {

        return this.enabledFilters[key];
    }

    public resetTotalItems(): void {

        this._totalItems = 0;
    }

    public resetFilterFields(): void {

        this.filterBuilder.resetFields();
    }

    get hasSessionFilters(): boolean {

        return !!sessionStorage.getItem(ARTICLE_SEARCH_SESSION_STORAGE_IDENTIFIER);
    }

    get sessionFilters(): ArticleSearchSessionFilter[] {

        return JSON.parse(sessionStorage.getItem(ARTICLE_SEARCH_SESSION_STORAGE_IDENTIFIER));
    }

    set sessionFilters(value: ArticleSearchSessionFilter[]) {

        sessionStorage.setItem(ARTICLE_SEARCH_SESSION_STORAGE_IDENTIFIER, JSON.stringify(value));
    }

    get totalItems(): number {

        return this._totalItems;
    }

    set totalItems(value: number) {

        this._totalItems = value;
    }
}
