import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {User} from "@core/shared/models/user";
import {UserService} from "@core/shared/services/user.service";
import {Article, ArticleStatus} from "@core/shared/models/article";
import {ArticleCardService} from "@core/shared/services/article/article-card.service";
import {TranslationService} from "@core/shared/services/translation.service";
import {ImageService} from "@lib/media/image/image.service";
import {ApiService} from "@core/shared/services/api.service";
import moment from "moment";
import {Router} from "@angular/router";
import {Image} from '@lib/media/image/image';
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";
import {ImageEventService} from "@lib/media/image/image.event.service";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ConfirmDialogComponent} from "@lib/confirm-dialog/confirm-dialog.component";
import {ConfirmDialogData} from "@lib/confirm-dialog/confirm-dialog";
import {TranslateService} from "@ngx-translate/core";
import {ArticleService} from "@core/shared/services/article.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ArticleSearchService} from "@core/shared/services/article/article-search.service";

export type Mode = 'preview' | 'view';

@Component({
    selector: 'app-core-article-card',
    templateUrl: './article-card.component.html',
    styleUrls: ['./article-card.component.scss']
})
export class ArticleCardComponent implements OnInit {

    @Output() public clickCardEvent: EventEmitter<any> = new EventEmitter();

    @Input() item: Article;

    @Input() mode: Mode;

    @Input() locale?: string;

    @HostListener('document:click', ['$event'])
    onClickOutside(): void {

        this.actionsPanelOpen = false;
    }

    protected readonly moment = moment;

    public currentUser: User = null;

    public visualUrl: SafeUrl = null;

    public societyLogoUrl: SafeUrl = null;

    public defaultVisualUrl: string = 'assets/images/offer/preview/1.jpg';

    public defaultSocietyLogoUrl: string = 'assets/images/no_pics.png';

    public statusLabels: { [p in ArticleStatus]: string } = {
        'published': 'article.status.published.value',
        'draft': 'article.status.draft.value'
    };

    public actionsPanelOpen: boolean = false;

    constructor(
        private _sanitizer: DomSanitizer,
        private _router: Router,
        private _dialog: MatDialog,
        private _snackBar: MatSnackBar,
        private _imageService: ImageService,
        private _imageEventService: ImageEventService,
        private _articleSearchService: ArticleSearchService,
        private _apiService: ApiService,
        private _articleService: ArticleService,
        private _translateService: TranslateService,
        public translationService: TranslationService,
        public userService: UserService,
        public articleCardService: ArticleCardService
    ) {}

    ngOnInit() {

        this.currentUser = this.userService.currentUser.value;

        if(this.mode === 'view') {

            this.locale = this.currentUser.locale;
        }

        this._loadVisual();

        this._loadSocietyLogo();
    }

    private _loadVisual(): void {

        if(this.mode === 'view'){

            this.visualUrl = this._imageService.getImageUrlWithNoCache(`${this._apiService.getApiUrl(false, false)}/public/articles/${this.item.id}/picture/filter/landscape/1500/90`);
        }
        else{

            const image: Image = this.item.picture.image.image as Image;

            const loadVisualCallback = (): void => {

                this._imageService.getBinaryImage(image, 'landscape', 1500, 90, null).subscribe((blob: Blob): void => {

                    const objectURL: string = URL.createObjectURL(blob);

                    this.visualUrl = this._sanitizer.bypassSecurityTrustUrl(objectURL);
                });
            };

            loadVisualCallback();

            this._imageEventService.imageCropped.subscribe((item: Image): void => {

                if(item.id !== image.id){

                    return;
                }

                loadVisualCallback();
            });
        }
    }

    private _loadSocietyLogo(): void {

        if(!this.item.society){

            return null;
        }

        this.societyLogoUrl = `${this._apiService.getApiUrl(false, false)}/public/societies/${this.item.society.id}/logo`;
    }

    public setDefaultVisualUrl(): void {

        this.visualUrl = this.defaultVisualUrl;
    }

    public setDefaultSocietyLogoUrl(): void {

        this.societyLogoUrl = this.defaultSocietyLogoUrl;
    }

    public toggleActionsPanel(event : Event): void {

        event.stopPropagation();

        this.actionsPanelOpen = !this.actionsPanelOpen;
    }

    public redirectToUpdate(event: Event): void {

        event.stopPropagation();

        this.actionsPanelOpen = false;

        if(this.mode === 'preview') {

            return;
        }

        this._router.navigate(['/account/article/update', this.item.id]);
    }

    public redirectToRead(event: Event): void {

        if(this.actionsPanelOpen){

            return;
        }

        if(this.mode === 'preview') {

            this.clickCardEvent.emit(event);

            return;
        }

        this._router.navigate(['/account/article/read', this.item.id], {
            queryParams: {
                listRedirectionAllowed: 0
            }
        });
    }

    public openDeleteDialog(event: Event): void {

        event.stopPropagation();

        this.actionsPanelOpen = false;

        if(this.mode === 'preview') {

            return;
        }

        if(!this.userService.hasOneOfTheseAccesses(this.currentUser, ['ARTICLE_DELETE', 'ARTICLE_DELETE_IS_MINE'])){

            return;
        }

        const title : string = this._translateService.instant('article.delete.value');

        const content : string = this._translateService.instant('article.delete.content.value');

        const dialogRef: MatDialogRef<ConfirmDialogComponent> = this._dialog.open(ConfirmDialogComponent, {
            width: '500px',
            data: {
                title: title,
                content: content,
                contentAlignment: 'center'
            } as ConfirmDialogData
        });

        dialogRef.componentInstance.confirm.subscribe((): void => {

            this._articleService.deleteItemAPI(this.item.id).subscribe((): void => {

                this._snackBar.open(this._translateService.instant('article.delete.success.value'), this._translateService.instant('notification.close.action.value'), {
                    duration: 5000
                });

                this._articleSearchService.refreshItems.next();
            });
        });
    }

    get cardClasses(): { [p: string]: boolean } {

        return {
            'highlighted': this.articleCardService.isItemHighlighted(this.item),
            [this.articleCardService.itemHighlightColor(this.item)]: this.articleCardService.isItemHighlighted(this.item)
        };
    }

    get isMine(): boolean {

        if(this.userService.hasOneOfThisRoles(this.currentUser, ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'])){

            return !this.item.society;
        }

        return this.item.society ? (this.item.society.id === this.currentUser.society.id) : false;
    }

    get statusTagClasses(): { [p: string]: boolean } {

        return {
            [this.item.status]: true
        };
    }

    get actionsPanelClasses(): { [p: string]: boolean } {

        return {
            'open': this.actionsPanelOpen
        };
    }

    get localeId(): string {

        return this._translateService.currentLang;
    }
}
