<div class="content_article">

    <div class="box_lg marges">

        <ng-container *ngIf="listRedirectionAllowed">
            <div class="breadcrumb">
                <span><a (click)="redirectToList()" class="icon-tywin-chevron">{{ 'article.search.back.value' | translate}}</a></span>
            </div>
        </ng-container>

        <app-core-breadcrumb [items]="breadcrumbItems"></app-core-breadcrumb>

        <app-core-article-read [item]="article" [mode]="'view'" [locale]="locale" (onLocaleUpdated)="updateLocale($event)"></app-core-article-read>

        <ng-container *ngIf="exploreOffersEnabled">
            <div class="widget_offers" (click)="exploreOffers()">
                <span class="txt">{{ 'offer.plural.explore.txt.value' | translate }}</span>
                <span class="btn_cta btn_little btn_highlight">
                    <span class="icon-tywin-explore">{{ 'offer.plural.explore.action.value' | translate }}</span>
                </span>
            </div>
        </ng-container>

    </div>
</div>




