<!-- Statut publication -->

<ng-container *ngIf="(publishes.length >= 1) && hasOneOfThisRoles(['ROLE_SUPER_ADMIN','ROLE_ADMIN', 'ROLE_OFFER_CREATOR', 'ROLE_OFFER_DISTRIBUTOR', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION']) && isOneOfTheseModes(['personnal-offers', 'reservoir', 'catalog', 'channel', 'offer-permanent-option-personnal-offers', 'offer-permanent-option-catalog', 'tender-personnal-offers', 'hashtag-reservoir', 'hashtag-catalog', 'article-reservoir', 'article-catalog'])">


    <div class="filter_item">

        <app-core-select-filter #filter #publishedFilter
                                [builder]="filterBuilder"
                                [label]="'offer.list.filter.status.value' | translate"
                                [key]="prefixFilter + 'published'"
                                [items]="publishes"
                                [itemLabelProperty]="'label'"
                                [itemValueProperty]="'name'"
                                [operator]="'eq'"
        ></app-core-select-filter>

    </div>

</ng-container>

<!-- Nouveaute -->

<div class="filter_item">
    <app-core-checkbox-boolean-filter #filter #createdAtFilter [builder]="filterBuilder" [label]="'offer.list.filter.new.value' | translate" [key]="prefixFilter + 'createdAt'"></app-core-checkbox-boolean-filter>
</div>

<!-- Promotion -->

<div class="filter_item">
    <app-core-checkbox-boolean-filter #filter #promotionsFilter [builder]="filterBuilder" [label]="'offer.list.filter.promotion.value' | translate" [key]="prefixFilter + 'promotions'"></app-core-checkbox-boolean-filter>
</div>

<!-- Vente en ligne -->

<div class="filter_item">
    <app-core-checkbox-boolean-filter #filter #onlineSaleFilter [builder]="filterBuilder" [label]="'offer.list.filter.onlineSale.value' | translate" [key]="prefixFilter + 'onlineSale.enable'" (valueChanges)="updateFilter('onlineSale.enable', $event)"></app-core-checkbox-boolean-filter>
</div>

<!--   Bon cadeau -->

<div class="filter_item">
    <app-core-checkbox-boolean-filter #filter #giftVoucherFilter [builder]="filterBuilder" [label]="'offer.list.filter.giftVoucher.value' | translate" [key]="prefixFilter + 'giftVoucher.enable'" (valueChanges)="updateFilter('giftVoucher.enable', $event)"></app-core-checkbox-boolean-filter>
</div>

<!-- Offre ciblée -->

<ng-container *ngIf="hasRole('ROLE_OFFER_CREATOR') && isOneOfTheseModes(['personnal-offers', 'catalog', 'channel', 'offer-permanent-option-catalog', 'offer-permanent-option-personnal-offers', 'hashtag-catalog', 'article-catalog'])">

    <div class="filter_item">
        <app-core-checkbox-boolean-filter #filter #isRestrictedFilter [builder]="filterBuilder" [label]="'offer.list.filter.isRestricted.value' | translate" [key]="prefixFilter + 'isRestricted'"></app-core-checkbox-boolean-filter>
    </div>

</ng-container>

<!-- Option assurance -->

<ng-container *ngIf="isOneOfTheseModes(['offer-permanent-option-personnal-offers', 'offer-permanent-option-catalog'])">

    <div class="filter_item">

        <app-core-checkbox-boolean-filter #filter #permanentOptionFilter [builder]="filterBuilder" [label]="'offer.list.filter.permanentOption.value' | translate" [key]="prefixFilter + 'permanentOption'" (valueChanges)="updateFilter('permanentOption', $event)"></app-core-checkbox-boolean-filter>

    </div>

</ng-container>

<!-- Options assurances -->

<ng-container *ngIf="hasOneOfThisRoles(['ROLE_OFFER_CREATOR', 'ROLE_OFFER_DISTRIBUTOR', 'ROLE_PROVIDER', 'ROLE_FEDERATION', 'ROLE_INSTITUTIONAL']) && isOneOfTheseModes(['personnal-offers', 'catalog', 'hashtag-catalog', 'article-catalog'])">

    <div class="filter_item">

        <app-core-checkbox-boolean-filter #filter #permanentOptionsFilter [builder]="filterBuilder" [label]="'offer.list.filter.permanentOption.value' | translate" [key]="prefixFilter + 'permanentOptions.id'"></app-core-checkbox-boolean-filter>

    </div>

</ng-container>

<!-- Options assurances en fonction de la langue-->

<ng-container *ngIf="isMode('channel')">

    <div class="filter_item">

        <app-core-checkbox-boolean-filter #filter #permanentOptionsLocaleFilter [builder]="filterBuilder" [label]="'offer.list.filter.permanentOption.value' | translate" [key]="prefixFilter + 'permanentOptions.offerPermanentOption.locales'"></app-core-checkbox-boolean-filter>

    </div>

</ng-container>

<!-- Langue -->

<div class="filter_item">
    <app-core-select-array-multiple-filter #filter #localesFilter [builder]="filterBuilder"
                                           [label]="'offer.list.filter.locale.value' | translate"
                                           [key]="prefixFilter + 'locales'"
                                           [operator]="'lkin'" [itemValueProperty]="'id'"
                                           [itemLabelProperty]="'label'"
                                           [items]="locales"
                                           (selectionChanged)="updateLocales($event)"
    ></app-core-select-array-multiple-filter>
</div>

<!-- Nom de l'offre -->

<div class="filter_item">
    <app-core-text-filter #filter #nameFilter [builder]="filterBuilder" [label]="'offer.list.filter.name.value' | translate"
                          [key]="prefixFilter + 'translations.name'" [operator]="'lk'"></app-core-text-filter>
</div>

<!-- Reference -->

<div class="filter_item">
    <app-core-text-filter #filter #referenceFilter [builder]="filterBuilder"
                          [label]="'offer.list.filter.reference.value' | translate"
                          [key]="prefixFilter + 'reference'" [operator]="'lk'"></app-core-text-filter>
</div>

<!-- Type de réponse -->

<ng-container *ngIf="isOneOfTheseModes(['tender-proposed-offers'])">

    <div class="filter_item">
        <app-core-select-filter
            #filter
            #responseTypeFilter
            [builder]="filterBuilder"
            [label]="'response.type.value' | translate"
            [key]="prefixFilter + 'responseType'"
            [items]="responseTypes"
            [itemValueProperty]="'value'"
            [itemLabelProperty]="'label'"
            [operator]="'eq'"
        ></app-core-select-filter>
    </div>

</ng-container>

<div class="filter_item filter_item_range filter_dates">
    <button type="button" class="btn_cta btn_small filters_reinit" (click)="reset()">{{'offer.list.filter.reinitialise.value' | translate}}</button>
</div>
