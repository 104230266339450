import {Injectable} from '@angular/core';
import {CanActivate} from '@angular/router';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {SocietyService} from '@core/shared/services/society.service';
import {Society} from '@core/shared/models/society';

@Injectable({
    providedIn: 'root'
})
export class CollaboratorGuard implements CanActivate {

    constructor(
        private _societyService: SocietyService
    ) {
    }

    canActivate(): Observable<boolean> {

        return this._societyService.getItemSelfAPI().pipe(
            map((society: Society): boolean => {

                return society.countAllowedCollaborators === null || (society.countAllowedCollaborators > 0 && society.countCollaborators < society.countAllowedCollaborators);
            })
        );
    }
}
