<h1 mat-dialog-title>
    {{ data.title }}
</h1>
<div mat-dialog-content>
    <subscription-form [dialogRef]="dialogRef" [subscriptions]="subscriptions" [isDisabled]="false" [redirectionToSubscriptionViewEnabled]="false">

        <!-- Projection de contenu pour la fermeture de la modale -->

        <div class="col_content projected_content" cancelAction>
            <button type="button" class="btn_cta btn_little btn_red" (click)="cancel.emit()">{{ 'action.close' | translate }}</button>
        </div>

    </subscription-form>
</div>
