import {Exclude, Expose, Transform} from "class-transformer";
import {UserService} from "@core/shared/services/user.service";
import {StatisticTypeTag} from "@core/shared/models/statistic/statistic-type";

export enum StatisticEvent {

    // Générique

    ItemRead = 'ITEM_READ',

    // Article

    AssociatedOfferList = 'ASSOCIATED_OFFER_LIST'
}

@Exclude()
export class Statistic {

    @Expose()
    resourceType: StatisticTypeTag;

    @Expose()
    event: StatisticEvent;

    @Expose()
    @Transform(({value}) => value || null, {toClassOnly: true})
    resourceIdentifier: string;

    @Expose()
    resourceLocale: string;

    @Expose()
    @Transform(({value}) => value || {}, {toClassOnly: true})
    extraData: object;

    get hasResourceIdentifier(): boolean {

        return this.resourceIdentifier !== null;
    }
}

export interface StatisticRoutingConfiguration {

    enableCallback: (userService: UserService) => boolean;

    resourceType: StatisticTypeTag;

    event: StatisticEvent;

    hasResourceIdentifier: boolean;

    resourceIdentifierRouteParam?: string;
}
