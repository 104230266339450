import {Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2} from '@angular/core';

@Component({
    selector: 'app-core-overlay',
    templateUrl: './overlay.component.html',
    styleUrls: ['./overlay.component.scss']
})
export class OverlayComponent implements OnInit {

    @Input() target: HTMLElement;

    @Input() styles: { [p: string]: string };

    @Output() closed: EventEmitter<void> = new EventEmitter<void>();

    constructor(
        private renderer: Renderer2,
        private elementRef: ElementRef
    ) {}

    ngOnInit() {

        this.renderer.listen('document', 'click', (event: MouseEvent): void => {

            const targetElement: HTMLElement = event.target as HTMLElement;

            const exceptions: boolean[] = [
                this._hasParentWithClass(targetElement, 'cdk-overlay-container')
            ];

            if(exceptions.some(exception => exception)){

                return;
            }

            if (!this.elementRef.nativeElement.contains(targetElement)) {

                this.closed.emit();
            }
        });
    }

    private _hasParentWithClass(element: HTMLElement, className: string): boolean {

        while (element) {

            if (element.classList && element.classList.contains(className)) {

                return true;
            }

            element = element.parentElement;
        }

        return false;
    }
}
