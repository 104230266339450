import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from "rxjs";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {ApiService} from "./api.service";
import {Offer} from "../models/offer";
import {map} from "rxjs/operators";
import {plainToClass} from "class-transformer";
import {Pagination} from "@core/shared/models/pagination";
import {Society} from "@core/shared/models/society";
import {AdditionalHeader} from "@core/shared/models/http-client";

@Injectable({
    providedIn: 'root'
})
export class OfferService {

    public providersSocieties$: BehaviorSubject<Society[]> = new BehaviorSubject<Society[]>([]);

    public providerLocationsUpdate$: Subject<{provider: Society, selected: boolean}> = new Subject<{provider: Society; selected: boolean}>();

    public displayedLocales$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

    constructor(
        private _apiService: ApiService,
        private _httpClient: HttpClient,
    ) {
    }

    public getItemsAPI(params?: string[]): Observable<Offer[]> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/offers`;

        params = params || [];

        params.push('disablePagination=1');

        if (params.length > 0) {

            url = `${url}?${params.join('&')}`;
        }

        return this._httpClient.get<Offer[]>(url, {
            headers: headers
        }).pipe(
            map((items: object[]): Offer[] => {

                return items.map((item: object): Offer => {

                    return plainToClass(Offer, item);
                });
            })
        );
    }

    public getPaginationItemsAPI(params?: string[], additionalHeaders?: AdditionalHeader[]): Observable<Pagination<Offer>> {

        let headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        additionalHeaders?.forEach(header => headers = headers.append(header.name, header.value.toString()));

        let url: string = `${this._apiService.getApiUrl(false, true)}/offers`;

        params = params || [];

        if (params.length > 0) {

            url = `${url}?${params.join('&')}`;
        }

        return this._httpClient.get<Pagination<Offer>>(url, {
            headers: headers
        }).pipe(
            map((pagination: Pagination<Offer>): Pagination<Offer> => {

                return Object.assign(pagination, {
                    items: pagination.items.map((item: Offer): Offer => {

                        return plainToClass(Offer, item);
                    })
                });
            })
        );
    }

    public getItemAPI(id: number): Observable<Offer> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.get<Offer>(`${this._apiService.getApiUrl(false, true)}/offers/${id}`, {
            headers: headers
        }).pipe(
            map((item: object): Offer => {

                return plainToClass(Offer, item);
            })
        );
    }

    public getItemsSocietyAPI(idSociety: number, params?: string[]): Observable<Offer[]> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/societies/${idSociety}/offers`;

        params = params || [];

        params.push('disablePagination=1');

        if (params.length > 0) {

            url = `${url}?${params.join('&')}`;
        }

        return this._httpClient.get<Offer[]>(url, {
            headers: headers
        }).pipe(
            map((items: object[]): Offer[] => {

                return items.map((item: object): Offer => {

                    return plainToClass(Offer, item);
                });
            })
        );
    }

    public getPaginationItemsSocietyAPI(idSociety: number, params?: string[]): Observable<Pagination<Offer>> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/societies/${idSociety}/offers`;

        params = params || [];

        if (params.length > 0) {

            url = `${url}?${params.join('&')}`;
        }

        return this._httpClient.get<Pagination<Offer>>(url, {
            headers: headers
        }).pipe(
            map((pagination: Pagination<Offer>): Pagination<Offer> => {

                return Object.assign(pagination, {
                    items: pagination.items.map((item: Offer): Offer => {

                        return plainToClass(Offer, item);
                    })
                });
            })
        );
    }

    public createItemAPI(data: object, id: number): Observable<Offer> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.post<Offer>(`${this._apiService.getApiUrl(false, true)}/societies/${id}/offers`, data, {
            headers: headers
        }).pipe(
            map((item: object): Offer => {

                return plainToClass(Offer, item);
            })
        );
    }

    public updateItemAPI(id: number, data: object): Observable<Offer> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.patch<Offer>(`${this._apiService.getApiUrl(false, true)}/offers/${id}`, data, {
            headers: headers
        }).pipe(
            map((item: object): Offer => {

                return plainToClass(Offer, item);
            })
        );
    }

    public updateItemsAPI(data: object): Observable<Offer[]> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.patch<Offer[]>(`${this._apiService.getApiUrl(false, true)}/offers/multiple`, data, {
            headers: headers
        });
    }

    public deleteItemAPI(id: number): Observable<void> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.delete<void>(`${this._apiService.getApiUrl(false, true)}/offers/${id}`,{
            headers: headers
        });
    }

    public offerReportsProviders(id:number): Observable<void>{
        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.get<void>(`${this._apiService.getApiUrl(false, true)}/offers/${id}/reports/providers`, {
            headers: headers
        });
    }

    public duplicateDatesItemAPI(sourceId: number, destinationId: number): Observable<void> {

        const url = `${this._apiService.getApiUrl(false, true)}/offers/${sourceId}/dates/duplicate/${destinationId}`;

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'text')
            .set('Accept', 'application/json');

        return this._httpClient.post<void>(url, {}, {
            headers: headers
        });
    }
}
