import {Component, OnInit} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {CategoryService} from "@core/shared/services/category.service";
import * as ClassicEditor from "@lib/ckeditor";
import {CkeditorConfig} from "@lib/form/fields/ckeditor/ckeditor.component";
import {FormService} from "@core/shared/services/form.service";
import {ApiService} from "@core/shared/services/api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";
import {LOCALE_ITEMS, LocaleItem, TranslationBuilder} from "@core/shared/models/translation";
import {Society} from "@core/shared/models/society";
import {FieldCollection} from "@lib/form/field";
import {Page} from "@core/shared/models/page";
import {Role} from "@core/shared/models/role";
import {Observable, of} from "rxjs";
import {TranslationService} from "@core/shared/services/translation.service";


@Component({
    selector: 'app-page-category-create',
    templateUrl: './page-category-create.component.html',
    styleUrls: ['./page-category-create.component.scss'],
    providers: [
        FormService
    ]
})
export class PageCategoryCreateComponent implements OnInit {

    public editor = ClassicEditor;

    public translationBuilder: TranslationBuilder;

    public societies: Object[] = [];

    public pages: Object[] = [];

    public roles : string[] = [];

    public fieldCollection = new FieldCollection();

    public locales$: Observable<LocaleItem[]>;

    /**
     *
     * @param _formBuilder
     * @param _translationService
     * @param categoryService
     * @param _translateService
     * @param _snackBar
     * @param formService
     * @param _apiService
     * @param _router
     * @param _activatedRoute
     */
    public constructor(private _formBuilder: UntypedFormBuilder, private _translationService: TranslationService,public categoryService: CategoryService, private _translateService: TranslateService, private _snackBar: MatSnackBar, public formService: FormService, private _apiService: ApiService, private _router: Router,
                       private _activatedRoute: ActivatedRoute) {
        this._initLocales();

        this.roles = ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'];
        this.formService.form = this._formBuilder.group({
            published: [''],
            type_user: ['role', Validators.required],
            role: [''],
            roles: [['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']],
            societies: [[]],
            locales: [[], [Validators.required]],
            translations: new UntypedFormArray([]),
        });


        this._buildForm();

        this._initTranslationsForm();
    }

    private _initLocales(): void {
        this.locales$ = of(LOCALE_ITEMS);
    }

    private _initTranslationsForm(): void {

        this.translationBuilder = new TranslationBuilder(this._formBuilder);

        this.translationBuilder.form = this.form;

        this.translationBuilder.addItemCallback = (): UntypedFormGroup => {

            return this._formBuilder.group({
                titre: ['', [Validators.required]],
                description: ['', [Validators.required]],
                pages: [[]],
            });
        };

        this.translationBuilder.addDefaultItem();
    }

    public ngOnInit(): void {

        this._activatedRoute.data.subscribe((data: { societies: Society[], pages: Page[] }): void => {

            data.societies.forEach((society: Society) => {
                this.societies.push(
                    {
                        id: society.id,
                        name: society.name
                    });
            });

            data.pages.forEach((page: Page) => {
                this.pages.push(
                    {
                        id: page.id,
                        name: this._translationService.getFallbackTranslation(page.translations).title
                    });
            });
        });

        this.formService.submitCallback = (): void => {

            const data: object = this.form.value;

            this.categoryService.createItemAPI(data).subscribe((): void => {

                this._snackBar.open(this._translateService.instant('category.add.success.value'), this._translateService.instant('notification.close.action.value'), {
                    duration: 5000
                });

                this.redirectToList();
            });
        };

        this.form.get('locales').valueChanges.subscribe((): void => {

            this._handleTranslationControls();
        });
    }

    public patchRolesValue(role){
        if(this.roles.indexOf(role) > -1){
            const index: number = this.roles.indexOf(role);
            this.roles.splice(index, 1);
        }else{
            this.roles.push(role);
        }
        this.form.get('roles').setValue(this.roles);
    }

    public redirectToList(): void {

        this._router.navigate(['category/list']);
    }

    get descriptionEditorConfig(): CkeditorConfig {

        return {
            id: 'description',
            editor: this.editor,
            attrs: {
                required: true
            }
        };
    }

    get publishedConfig() {

        return {
            id: 'published',
            attrs: {
                required: true,
                label : "Publié"
            }
        };
    }

    public getTranslation(index: number): UntypedFormGroup {

        return this.translationsControl.controls[index] as UntypedFormGroup;
    }

    get translationsControl(): UntypedFormArray {

        return this.form.get('translations') as UntypedFormArray;
    }

    get form(): UntypedFormGroup {

        return this.formService.form;
    }

    private _buildForm(): void {
        this.form.addControl('locales', new UntypedFormControl([], [Validators.required]));

        this.fieldCollection.addField({
            type: 'select-search',
            config: {
                id: 'societies',
                attrs: {
                    label: this._translateService.instant('menu.users.title.value'),
                    required: false,
                    choices: this.societies,
                    multiple: true,
                }
            }
        })

        this.fieldCollection.addField({
            type: 'select-search',
            config: {
                id: 'pages',
                attrs: {
                    label: this._translateService.instant('menu.pages.title.value'),
                    required: false,
                    choices: this.pages,
                    multiple: true,
                }
            }
        })
    }

    public changeSocieties(event){
        this.form.get('societies').patchValue(event.value);
    }

    public changePages(event, locale){
        this.translationBuilder.getItemControlByLocale(locale).get('pages').patchValue(event.value);
    }

    public hasRole(role: Role): boolean {
        return this.roles.indexOf(role) >= 0;
    }

    get selectedLocales(): string[] {
        if(!this.form || !this.form.get('locales')){
            return [];
        }

        return this.form.get('locales').value;
    }

    private _handleTranslationControls(): void {

        const locales: string[] = this.form.get('locales').value;
        this.translationBuilder.updateItems(locales);
    }
}
