import {Injectable} from '@angular/core';
import {ArticleCardAction} from "@core/shared/models/article/article-card";
import {Article} from "@core/shared/models/article";

@Injectable()
export class ArticleCardService {

    public customActions: ArticleCardAction[] = [];

    public isItemHighlightedCallback: (item: Article) => boolean = () => { return false };

    public itemHighlightColorCallback: (item: Article) => string = () => { return null };

    public isItemHighlighted(item: Article): boolean {

        return this.isItemHighlightedCallback(item);
    }

    public itemHighlightColor(item: Article): string {

        return this.itemHighlightColorCallback(item);
    }
}
