import {Component, Input, OnInit} from '@angular/core';
import {Society} from "@core/shared/models/society";
import {CustomerTypology} from "@core/shared/models/customer-typology";
import {TranslationService} from "@core/shared/services/translation.service";
import {OfferAttribute} from "@core/shared/models/offer-attribute";
import {OfferDurationType} from "@core/shared/models/offer/offer-duration";
import {TranslateService} from "@ngx-translate/core";
import {UserService} from "@core/shared/services/user.service";
import {User} from "@core/shared/models/user";
import {OfferCreatorSearchService} from "@core/shared/services/offer-creator/offer-creator-search.service";
import {OfferCreatorModeType} from "@core/shared/models/offer/offer-creator-search";
import {OfferCreatorCardService} from "@core/shared/services/offer-creator/offer-creator-card.service";
import {SubscriptionTag} from '@core/shared/models/subscription';

@Component({
    selector: 'app-core-offer-creator-card',
    templateUrl: './offer-creator-card.component.html',
    styleUrls: ['./offer-creator-card.component.scss']
})
export class OfferCreatorCardComponent implements OnInit {

    @Input() item: Society;

    @Input() mode: OfferCreatorModeType;

    public currentUser: User = null;

    public cardClosed: boolean = true;

    public defaultVisualUrl: string = 'assets/images/offer/preview/1.jpg';

    constructor(
        private _translateService: TranslateService,
        private _translationService: TranslationService,
        public userService: UserService,
        public offerCreatorSearchService: OfferCreatorSearchService,
        public offerCreatorCardService: OfferCreatorCardService
    ) {}

    ngOnInit() {

        this.currentUser = this.userService.currentUser.value;
    }

    public isOneOfTheseModes(modes: OfferCreatorModeType[]): boolean {

        return modes.includes(this.mode);
    }

    get parsedTargets(): string {

        return this.item.details.customerTypologies.map((item: CustomerTypology): string => {

            return this._translationService.getFallbackTranslation(item.translations).name;

        }).join(', ');
    }

    get parsedOfferTypes(): string {

        return this.item.details.durationTypes.map((type: OfferDurationType): string => {

            return this._translateService.instant(`offer.type.${type}.value`);

        }).join(', ');
    }

    get parsedRegions(): string {

        return this.item.details.regions.map((item: OfferAttribute): string => {

            return this._translationService.getFallbackTranslation(item.translations).label;

        }).join(', ');
    }

    get cardClasses(): { [p: string]: boolean } {

        return {
            'highlighted': this.offerCreatorSearchService.isOfferCreatorHighlighted(this.item),
            [this.offerCreatorSearchService.offerCreatorHighlightColor(this.item)]: this.offerCreatorSearchService.isOfferCreatorHighlighted(this.item)
        }
    }

    get isPremium(): boolean {

        return this.item.subscriptionTag === SubscriptionTag.Premium;
    }
}
