<div>

    <div class="page_title">
        <div class="row marges">
            <div class="col y_items_center">
                <h1 class="title">{{ 'menu.list.title.value' | translate }}</h1>
            </div>

            <div class="col_content y_items_center">
                <button (click)="saveTree()" class="btn_cta btn_little">{{ 'menu.actions.save.value' | translate }}</button>
            </div>
            <div class="col_content y_items_center">
                <button (click)="createItem()" class="btn_cta btn_little btn_add">{{ 'menu.actions.add.value' | translate }}</button>
            </div>
        </div>
    </div>

    <tree-root
        #tree
        [nodes]="nodes"
        [options]="options"
        [focused]="true"
    >
        <ng-template #treeNodeTemplate let-node>
            <span *ngFor="let locale of node.data.locales" class="flag flag-{{ locale }}"></span>
            <span title="{{ translationService.getFallbackTranslation(node.data.translations).name }}">{{ translationService.getFallbackTranslation(node.data.translations).name }} </span><br/>

            <mat-accordion *ngIf="node.data.typePageIntern" >
                <mat-expansion-panel (opened)="panelOpenState = true"
                                     (closed)="panelOpenState = false">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            {{ 'menu.tree'| translate }}
                        </mat-panel-title>
                        <mat-panel-description>

                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div class="arbo" *ngIf="node.data.typePageIntern && node.data.typePageIntern == 'categories' && node.data.category">
                        <div *ngFor="let translation of node.data.category.translations">
                            <ul>
                                <li class="cat">
                                    <span  class="flag flag-{{ translation.locale }}"></span>{{'menu.typePageIntern.categories.value'| translate}} : {{ translation.titre}}
                                </li>
                                <li>
                                    <ul>
                                        <li *ngFor="let page of translation.pages">
                                            <mat-icon  [matTooltip]="'menu.warning.language' | translate" *ngIf="!(getLocaleTranslation(page.translations, translation.locale))" class="tooltip_icon">warning</mat-icon> {{ (getLocaleTranslation(page.translations, translation.locale)) ? getLocaleTranslation(page.translations, translation.locale).title : page.translations[0].titre }}
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="arbo" *ngIf="node.data.typePageIntern && node.data.typePageIntern == 'pages' && node.data.page">
                        <div *ngFor="let translation of node.data.page.translations">
                            <ul>
                                <li class="cat">
                                    <span  class="flag flag-{{ translation.locale }}"></span>{{'menu.typePageIntern.pages.value'| translate}} : {{ translation.title}}
                                </li>
                            </ul>
                        </div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>

            <!--            <span *ngIf="node.data.typePageIntern">-->
            <!--               - ({{ ((node.data.typePageIntern == 'categories') ? 'menu.typePageIntern.categories.value' : 'menu.typePageIntern.pages.value') | translate  }} : {{ (node.data.typePageIntern == 'categories') ? translationService.getFallbackTranslation(node.data.category.translations).titre : translationService.getFallbackTranslation(node.data.page.translations).titre}} )-->
            <!--            </span>-->
            <button mat-icon-button (click)="updateItem(node.data)" *ngIf="node.data.updatable" class="btn_edit">
                <mat-icon>edit</mat-icon>
            </button>
            <button mat-icon-button (click)="openDeleteItemDialog(node.data.id)" *ngIf="node.data.deletable && node.data.children.length < 1" class="btn_delete">
                <mat-icon>delete</mat-icon>
            </button>
            <button mat-button matTooltip="{{'actions.duplicate.label.value' | translate}}" class="btn_duplicate" (click)="duplicate(node.data.id)">
                <mat-icon>file_copy</mat-icon>
            </button>
        </ng-template>
    </tree-root>

</div>
