<h1 mat-dialog-title class="x_center">{{ serviceTranslation.name }}</h1>
<mat-dialog-content>

    <div class="row">

        <div class="col">
            <ng-container *ngIf="data.service.hasAdmissionPrice">

                <p class="price">
                    {{ 'services.admissionPrice.value' | translate }} : {{ formatPrice(data.service.admissionPriceTTC, 'TTC') }}
                    <span class="price_ht">({{ formatPrice(data.service.admissionPriceHT, 'HT') }})</span>
                </p>

            </ng-container>

            <ng-container *ngIf="data.service.isWithSubscription">

                <!--  Prix par mois -->

                <p class="price">
                   {{ formatPrice(data.service.monthlyPriceTTC, 'TTC') }} <span>{{ 'services.list.item.perMonth.value' | translate }}</span>
                </p>

                <!--  Prix par an -->

                <p class="price">
                    {{ formatPrice(data.service.yearlyPriceTTC, 'TTC') }} <span>{{ 'services.list.item.perYear.value' | translate }}</span>
                </p>

            </ng-container>

        </div>

    </div>

    <div class="row">

        <div class="col description ck-content" [innerHTML]="serviceContent"></div>

    </div>

    <ng-container *ngIf="data.service.isValidatedMangoPayAccountRequired">

        <div class="row">
            <div class="col ck-content"><i>{{ 'services.list.item.mangopay.required.value' | translate }}</i></div>
        </div>

    </ng-container>

</mat-dialog-content>

<mat-dialog-actions>
    <div class="row x_center marges">
        <div class="col_content">
            <button class="btn_cta btn_little btn_red" (click)="back.emit()">{{ 'services.list.item.action.service.back.value' | translate }}</button>
        </div>
        <ng-container *ngIf="data.isRegistrationEnabled">
            <div class="col_content">
                <button class="btn_cta btn_little" [disabled]="data.service.isValidatedMangoPayAccountRequired && !data.hasValidMangoPayAccount" (click)="select.emit()">{{ 'services.list.item.action.service.subscribe.value' | translate }}</button>
            </div>
        </ng-container>
    </div>
</mat-dialog-actions>
