import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {fromEvent, Observable} from "rxjs";
import {Pagination} from "@core/shared/models/pagination";
import {User} from "@core/shared/models/user";
import {TranslateService} from "@ngx-translate/core";
import {UserService} from "@core/shared/services/user.service";
import {FilterCollectionField} from "@core/shared/models/filter/filter-collection";
import {ArrayFilterField} from "@core/shared/models/filter/array-filter-field";
import {FilterBuilder, FilterComponent, FilterField} from "@core/shared/models/filter";
import {debounceTime, distinctUntilChanged, map} from "rxjs/operators";
import {Article} from "@core/shared/models/article";
import {ArticleFilterComponent} from "@core/components/article/article-filter/article-filter.component";
import {ArticleSearchService} from "@core/shared/services/article/article-search.service";
import {ArticleFilterKey, ArticleSearchSessionFilter} from "@core/shared/models/article/article-search";
import {ArticleFilterPanelComponent} from "@core/components/article/article-filter-panel/article-filter-panel.component";
import {Router} from "@angular/router";
import {TextFilterField} from "@core/shared/models/filter/text-filter-field";
import {TranslationService} from "@core/shared/services/translation.service";
import {DateIntervalFilterField, DateIntervalFilterValue} from "@core/shared/models/filter/date-interval-filter-field";
import * as moment from "moment/moment";
import {Moment} from "moment/moment";

@Component({
    selector: 'app-core-article-search',
    templateUrl: './article-search.component.html',
    styleUrls: ['./article-search.component.scss']
})
export class ArticleSearchComponent implements OnInit, AfterViewInit {

    @Input() loadItemsSourceCallback: (params?: string[]) => Observable<Pagination<Article>>;

    @ViewChild('searchContent', { static: true }) searchContentReference: ElementRef<HTMLInputElement>;

    @ViewChild(ArticleFilterComponent, { static: true }) articleFilterComponent: ArticleFilterComponent;

    @ViewChild(ArticleFilterPanelComponent, { static: true }) articleFilterPanelComponent: ArticleFilterPanelComponent;

    public currentUser: User = null;

    public itemOffset: number = 0;

    public itemPerPage: number = 24;

    public items: Article[] = [];

    public sourceLoaded: boolean = false;

    public searchContentValue: string = null;

    public sidePanelOverlay: { open: boolean } = {
        open: false
    };

    constructor(
        private _router: Router,
        private _translateService: TranslateService,
        private _articleSearchService: ArticleSearchService,
        public translationService: TranslationService,
        public userService: UserService
    ) {}

    ngOnInit() {

        this.currentUser = this.userService.currentUser.value;

        this._initFilterBuilder();

        setTimeout((): void => {

            if(this._articleSearchService.sessionEnabled){

                if (this._articleSearchService.hasSessionFilters) {

                    this._initFiltersValues();
                }
                else{

                    this._initDefaultFiltersValues();
                }
            }

            this._loadItems(true);
        });

        this._articleSearchService.refreshItems.subscribe((): void => {

            this._loadItems(true);
        });
    }

    ngAfterViewInit() {

        fromEvent(this.searchContentReference.nativeElement, 'input')
            .pipe(
                map((event: any) => {
                    return event.target.value;
                }),
                debounceTime(500),
                distinctUntilChanged()
            )
            .subscribe((term: string): void => {

                this.searchContentValue = term;

                this.filterBuilder.filter();
            })
        ;
    }

    private _initFilterBuilder(): void {

        this.filterBuilder.filterCallback = (): void => {

            this._loadItems(true);
        };

        this._articleSearchService.resetFilter$.subscribe((): void => {

            this.articleFilterComponent.filterComponents.forEach((filterComponent: FilterComponent): void => {

                filterComponent.reset();
            });

            this.articleFilterPanelComponent.filterComponents.forEach((filterComponent: FilterComponent): void => {

                filterComponent.reset();
            });

            this.filterBuilder.resetFields();

            this._loadItems(true);
        });
    }

    private _initDefaultFiltersValues(): void {

    }

    private _initFiltersValues(): void {

        this.filterBuilder.resetFields();

        // Type

        if (this._articleSearchService.isFilterEnabled(ArticleFilterKey.Type) && this._articleSearchService.hasSessionFilter(ArticleFilterKey.Type)){

            (this._articleSearchService.getSessionFilter(ArticleFilterKey.Type).value as string[]).forEach((value: string): void => {

                (this.filterBuilder.getFieldByKey(ArticleFilterKey.Type) as FilterCollectionField).fields.push(new ArrayFilterField(ArticleFilterKey.Type, 'in', value));
            });
        }

        // Type de clientèle

        if (this._articleSearchService.isFilterEnabled(ArticleFilterKey.CustomerTypology) && this._articleSearchService.hasSessionFilter(ArticleFilterKey.CustomerTypology)){

            (this._articleSearchService.getSessionFilter(ArticleFilterKey.CustomerTypology).value as string[]).forEach((value: string): void => {

                (this.filterBuilder.getFieldByKey(ArticleFilterKey.CustomerTypology) as FilterCollectionField).fields.push(new ArrayFilterField(ArticleFilterKey.CustomerTypology, 'in', value));
            });
        }

        // Cible marché

        if (this._articleSearchService.isFilterEnabled(ArticleFilterKey.TargetMarket) && this._articleSearchService.hasSessionFilter(ArticleFilterKey.TargetMarket)){

            (this._articleSearchService.getSessionFilter(ArticleFilterKey.TargetMarket).value as string[]).forEach((value: string): void => {

                (this.filterBuilder.getFieldByKey(ArticleFilterKey.TargetMarket) as FilterCollectionField).fields.push(new ArrayFilterField(ArticleFilterKey.TargetMarket, 'in', value));
            });
        }

        // Langue

        if (this._articleSearchService.isFilterEnabled(ArticleFilterKey.Locale) && this._articleSearchService.hasSessionFilter(ArticleFilterKey.Locale)){

            (this._articleSearchService.getSessionFilter(ArticleFilterKey.Locale).value as string[]).forEach((value: string): void => {

                (this.filterBuilder.getFieldByKey(ArticleFilterKey.Locale) as FilterCollectionField).fields.push(new ArrayFilterField(ArticleFilterKey.Locale, 'in', value));
            });
        }

        // Mes publications

        if (this._articleSearchService.isFilterEnabled(ArticleFilterKey.SelfAuthor) && this._articleSearchService.hasSessionFilter(ArticleFilterKey.SelfAuthor)){

            (this.filterBuilder.getFieldByKey(ArticleFilterKey.SelfAuthor) as TextFilterField).value = this._articleSearchService.getSessionFilter(ArticleFilterKey.SelfAuthor).value || null;
        }

        // Auteur Ty-Win

        if (this._articleSearchService.isFilterEnabled(ArticleFilterKey.TywinAuthor) && this._articleSearchService.hasSessionFilter(ArticleFilterKey.TywinAuthor)){

            (this.filterBuilder.getFieldByKey(ArticleFilterKey.TywinAuthor) as TextFilterField).value = this._articleSearchService.getSessionFilter(ArticleFilterKey.TywinAuthor).value || null;
        }

        // Auteur

        if (this._articleSearchService.isFilterEnabled(ArticleFilterKey.Author) && this._articleSearchService.hasSessionFilter(ArticleFilterKey.Author)){

            (this._articleSearchService.getSessionFilter(ArticleFilterKey.Author).value as string[]).forEach((value: string): void => {

                (this.filterBuilder.getFieldByKey(ArticleFilterKey.Author) as FilterCollectionField).fields.push(new ArrayFilterField(ArticleFilterKey.Author, 'in', value));
            });
        }

        // Destination

        if (this._articleSearchService.isFilterEnabled(ArticleFilterKey.Region) && this._articleSearchService.hasSessionFilter(ArticleFilterKey.Region)){

            (this._articleSearchService.getSessionFilter(ArticleFilterKey.Region).value as string[]).forEach((value: string): void => {

                (this.filterBuilder.getFieldByKey(ArticleFilterKey.Region) as FilterCollectionField).fields.push(new ArrayFilterField(ArticleFilterKey.Region, 'in', value));
            });
        }

        // Thématique

        if (this._articleSearchService.isFilterEnabled(ArticleFilterKey.Theme) && this._articleSearchService.hasSessionFilter(ArticleFilterKey.Theme)){

            (this._articleSearchService.getSessionFilter(ArticleFilterKey.Theme).value as string[]).forEach((value: string): void => {

                (this.filterBuilder.getFieldByKey(ArticleFilterKey.Theme) as FilterCollectionField).fields.push(new ArrayFilterField(ArticleFilterKey.Theme, 'in', value));
            });
        }

        // Statut

        if (this._articleSearchService.isFilterEnabled(ArticleFilterKey.Status) && this._articleSearchService.hasSessionFilter(ArticleFilterKey.Status)){

            (this._articleSearchService.getSessionFilter(ArticleFilterKey.Status).value as string[]).forEach((value: string): void => {

                (this.filterBuilder.getFieldByKey(ArticleFilterKey.Status) as FilterCollectionField).fields.push(new ArrayFilterField(ArticleFilterKey.Status, 'in', value));
            });
        }

        // Date de mise à jour

        if (this._articleSearchService.isFilterEnabled(ArticleFilterKey.UpdatedAt) && this._articleSearchService.hasSessionFilter(ArticleFilterKey.UpdatedAt)){

            (this.filterBuilder.getFieldByKey(ArticleFilterKey.UpdatedAt) as DateIntervalFilterField).value = this._articleSearchService.getSessionFilter(ArticleFilterKey.UpdatedAt).value || null;
        }

        // Date de publication

        if (this._articleSearchService.isFilterEnabled(ArticleFilterKey.PublishedAt) && this._articleSearchService.hasSessionFilter(ArticleFilterKey.PublishedAt)){

            (this.filterBuilder.getFieldByKey(ArticleFilterKey.PublishedAt) as DateIntervalFilterField).value = this._articleSearchService.getSessionFilter(ArticleFilterKey.PublishedAt).value || null;
        }
    }

    private _storeSessionFilters(): void {

        const sessionFilters: ArticleSearchSessionFilter[] = [];

        // Type

        if(this._articleSearchService.isFilterEnabled(ArticleFilterKey.Type)){

            sessionFilters.push({
                key: ArticleFilterKey.Type,
                value: [].concat.apply([], (this.filterBuilder.getFieldsByKey(ArticleFilterKey.Type) as FilterCollectionField[]).map((collectionField: FilterCollectionField): string[] => {

                    return collectionField.fields.map((field: FilterField): string => {

                        return field.value;
                    });
                }))
            });
        }

        // Type de clientèle

        if(this._articleSearchService.isFilterEnabled(ArticleFilterKey.CustomerTypology)){

            sessionFilters.push({
                key: ArticleFilterKey.CustomerTypology,
                value: [].concat.apply([], (this.filterBuilder.getFieldsByKey(ArticleFilterKey.CustomerTypology) as FilterCollectionField[]).map((collectionField: FilterCollectionField): string[] => {

                    return collectionField.fields.map((field: FilterField): string => {

                        return field.value;
                    });
                }))
            });
        }

        // Cible marché

        if(this._articleSearchService.isFilterEnabled(ArticleFilterKey.TargetMarket)){

            sessionFilters.push({
                key: ArticleFilterKey.TargetMarket,
                value: [].concat.apply([], (this.filterBuilder.getFieldsByKey(ArticleFilterKey.TargetMarket) as FilterCollectionField[]).map((collectionField: FilterCollectionField): string[] => {

                    return collectionField.fields.map((field: FilterField): string => {

                        return field.value;
                    });
                }))
            });
        }

        // Langue

        if(this._articleSearchService.isFilterEnabled(ArticleFilterKey.Locale)){

            sessionFilters.push({
                key: ArticleFilterKey.Locale,
                value: [].concat.apply([], (this.filterBuilder.getFieldsByKey(ArticleFilterKey.Locale) as FilterCollectionField[]).map((collectionField: FilterCollectionField): string[] => {

                    return collectionField.fields.map((field: FilterField): string => {

                        return field.value;
                    });
                }))
            });
        }

        // Mes publications

        if(this._articleSearchService.isFilterEnabled(ArticleFilterKey.SelfAuthor)){

            sessionFilters.push({
                key: ArticleFilterKey.SelfAuthor,
                value: this.filterBuilder.getFieldByKey(ArticleFilterKey.SelfAuthor).value || null
            });
        }

        // Auteur Ty-Win

        if(this._articleSearchService.isFilterEnabled(ArticleFilterKey.TywinAuthor)){

            sessionFilters.push({
                key: ArticleFilterKey.TywinAuthor,
                value: this.filterBuilder.getFieldByKey(ArticleFilterKey.TywinAuthor).value || null
            });
        }

        // Auteur

        if(this._articleSearchService.isFilterEnabled(ArticleFilterKey.Author)){

            sessionFilters.push({
                key: ArticleFilterKey.Author,
                value: [].concat.apply([], (this.filterBuilder.getFieldsByKey(ArticleFilterKey.Author) as FilterCollectionField[]).map((collectionField: FilterCollectionField): string[] => {

                    return collectionField.fields.map((field: FilterField): string => {

                        return field.value;
                    });
                }))
            });
        }

        // Destination

        if(this._articleSearchService.isFilterEnabled(ArticleFilterKey.Region)){

            sessionFilters.push({
                key: ArticleFilterKey.Region,
                value: [].concat.apply([], (this.filterBuilder.getFieldsByKey(ArticleFilterKey.Region) as FilterCollectionField[]).map((collectionField: FilterCollectionField): string[] => {

                    return collectionField.fields.map((field: FilterField): string => {

                        return field.value;
                    });
                }))
            });
        }

        // Thématique

        if(this._articleSearchService.isFilterEnabled(ArticleFilterKey.Theme)){

            sessionFilters.push({
                key: ArticleFilterKey.Theme,
                value: [].concat.apply([], (this.filterBuilder.getFieldsByKey(ArticleFilterKey.Theme) as FilterCollectionField[]).map((collectionField: FilterCollectionField): string[] => {

                    return collectionField.fields.map((field: FilterField): string => {

                        return field.value;
                    });
                }))
            });
        }

        // Statut

        if(this._articleSearchService.isFilterEnabled(ArticleFilterKey.Status)){

            sessionFilters.push({
                key: ArticleFilterKey.Status,
                value: [].concat.apply([], (this.filterBuilder.getFieldsByKey(ArticleFilterKey.Status) as FilterCollectionField[]).map((collectionField: FilterCollectionField): string[] => {

                    return collectionField.fields.map((field: FilterField): string => {

                        return field.value;
                    });
                }))
            });
        }

        // Date de mise à jour

        if(this._articleSearchService.isFilterEnabled(ArticleFilterKey.UpdatedAt)){

            sessionFilters.push({
                key: ArticleFilterKey.UpdatedAt,
                value: this.filterBuilder.getFieldByKey(ArticleFilterKey.UpdatedAt).value || null
            });
        }

        // Date de publication

        if(this._articleSearchService.isFilterEnabled(ArticleFilterKey.PublishedAt)){

            sessionFilters.push({
                key: ArticleFilterKey.PublishedAt,
                value: this.filterBuilder.getFieldByKey(ArticleFilterKey.PublishedAt).value || null
            });
        }

        // Enregistrement de la session

        this._articleSearchService.sessionFilters = sessionFilters;
    }

    private _loadItems(resetOffset: boolean): void {

        if(this._articleSearchService.sessionEnabled){

            this._storeSessionFilters();
        }

        if (resetOffset) {

            this.itemOffset = 0;
        }

        this.sourceLoaded = false;

        this.loadItemsSourceCallback(this.itemsApiParams).pipe(map(this.mapItemPaginationApiResult))
            .subscribe((items: Article[]): void => {

                this.hydrateItems(items, resetOffset);

                this.sourceLoaded = true;
            })
        ;
    }

    public hydrateItems(items: Article[], reset: boolean): void {

        if (reset) {

            this.items = [];
        }

        this.items.push(...items);
    }

    public removeFilter(field: FilterField): void {

        const key: ArticleFilterKey = field.key as ArticleFilterKey;

        switch (key){

            case ArticleFilterKey.Type:
            case ArticleFilterKey.CustomerTypology:
            case ArticleFilterKey.TargetMarket:
            case ArticleFilterKey.Locale:
            case ArticleFilterKey.Author:
            case ArticleFilterKey.Region:
            case ArticleFilterKey.Theme:
            case ArticleFilterKey.Status:

                const fields: FilterCollectionField[] = this.filterBuilder.getFieldsByKey(key) as FilterCollectionField[];

                fields.forEach((collectionField: FilterCollectionField): void => {

                    const fieldIndex: number = collectionField.fields.findIndex((item: ArrayFilterField): boolean => {

                        return item.value === field.value;
                    });

                    if(fieldIndex >= 0){

                        collectionField.fields.splice(fieldIndex, 1);
                    }
                });

                break;

            default:

                this.filterBuilder.resetFieldValueByKey(key);
        }

        this._articleSearchService.deleteActiveFilterField$.next(field);

        if(this._articleSearchService.sessionEnabled){

            this._storeSessionFilters();
        }

        this.articleFilterComponent.initFilters();

        this.filterBuilder.filter();
    }

    public loadMoreItems(): void {

        this.itemOffset++;

        this._loadItems(false);
    }

    public openSidePanelOverlay(): void {

        setTimeout((): void => {

            this.sidePanelOverlay.open = true;
        });
    }

    public closeSidePanelOverlay(): void {

        this.sidePanelOverlay.open = false;
    }

    public sidePanelOverlayStyles(target: HTMLElement): { [p: string]: string } {

        const rect: DOMRect = target.getBoundingClientRect();

        return {
            top: `${ rect.bottom }px`,
            bottom: `12px`,
            right: `12px`,
            width: `400px`,
        };
    }

    public createItem(): void {

        this._router.navigate(['account/article/create']);
    }

    public clearSearchInput(): void {

        this.searchContentReference.nativeElement.value = '';

        this.searchContentValue = null;

        this.filterBuilder.filter();

    }

    get itemsApiParams(): string[] {

        const params: string[] = [];

        params.push(`sort[updatedAt]=desc`);

        params.push(`page=${this.itemOffset + 1}`);

        params.push(`limit=${this.itemPerPage}`);

        params.push(...this.mapFilterParams);

        params.push(...this._articleSearchService.additionalFilterParams$.getValue());

        return params;
    }

    get mapFilterParams(): string[] {

        const params: string[] = [];

        const fields: FilterField[] = this.filterBuilder.fields.filter((field: FilterField): boolean => {

            return Boolean(field.serialize.length);
        });

        fields.forEach((field: FilterField): void => {

            const key: ArticleFilterKey = field.key as ArticleFilterKey;

            switch (key){

                case ArticleFilterKey.Type:

                    params.push(...(field as FilterCollectionField).fields.map((field: FilterField): string => {

                        return `configuration.type.id[in][]=${field.value}`;
                    }));

                    break;

                case ArticleFilterKey.CustomerTypology:

                    params.push(...(field as FilterCollectionField).fields.map((field: FilterField): string => {

                        return `configuration.customerTypologies.id[in][]=${field.value}`;
                    }));

                    break;

                case ArticleFilterKey.TargetMarket:

                    params.push(...(field as FilterCollectionField).fields.map((field: FilterField): string => {

                        return `configuration.targetMarkets[lkin][]=${field.value}`;
                    }));

                    break;

                case ArticleFilterKey.Locale:

                    params.push(...(field as FilterCollectionField).fields.map((field: FilterField): string => {

                        return `locales[lkin][]=${field.value}`;
                    }));

                    break;

                case ArticleFilterKey.SelfAuthor:

                    if(field.value as boolean){

                        this.userService.hasOneOfThisRoles(this.currentUser, ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']) ? params.push(`society.id[null]=1`) : params.push(`society.id[eq]=${ this.currentUser.society.id }`);
                    }

                    break;

                case ArticleFilterKey.TywinAuthor:

                    if(field.value as boolean){

                        params.push(`society.id[null]=1`);
                    }

                    break;

                case ArticleFilterKey.Author:

                    params.push(...(field as FilterCollectionField).fields.map((field: FilterField): string => {

                        return `society.id[in][]=${field.value}`;
                    }));

                    break;

                case ArticleFilterKey.Region:

                    params.push(...(field as FilterCollectionField).fields.map((field: FilterField): string => {

                        return `configuration.regions.id[in][]=${field.value}`;
                    }));

                    break;

                case ArticleFilterKey.Theme:

                    params.push(...(field as FilterCollectionField).fields.map((field: FilterField): string => {

                        return `configuration.themes.id[in][]=${field.value}`;
                    }));

                    break;

                case ArticleFilterKey.Status:

                    params.push(...(field as FilterCollectionField).fields.map((field: FilterField): string => {

                        return `status[in][]=${field.value}`;
                    }));

                    break;

                case ArticleFilterKey.UpdatedAt:

                    if(field.value){

                        params.push(`updatedAt[gte]=${(field.value as DateIntervalFilterValue).start}`);

                        params.push(`updatedAt[lte]=${(field.value as DateIntervalFilterValue).end}`);
                    }

                    break;

                case ArticleFilterKey.PublishedAt:

                    if(field.value){

                        params.push(`publishedAt[gte]=${(field.value as DateIntervalFilterValue).start}`);

                        params.push(`publishedAt[lte]=${(field.value as DateIntervalFilterValue).end}`);
                    }

                    break;

                default:

                    params.push(field.serialize);
            }
        });

        if(this.searchContentValue && this.searchContentValue.length){

            params.push(`searchEngineContent[lk]=${this.searchContentValue}`);
        }

        return params;
    }

    get currentFilters(): { field: FilterField, formattedValue: string }[] {

        const items: { field: FilterField, formattedValue: string }[] = [];

        const fields: FilterField[] = this.filterBuilder.fields.filter((field: FilterField): boolean => {

            return Boolean(field.serialize.length);
        });

        fields.forEach((field: FilterField): void => {

            const key: ArticleFilterKey = field.key as ArticleFilterKey;

            switch (key){

                case ArticleFilterKey.Type:
                case ArticleFilterKey.CustomerTypology:
                case ArticleFilterKey.TargetMarket:
                case ArticleFilterKey.Locale:
                case ArticleFilterKey.Author:
                case ArticleFilterKey.Region:
                case ArticleFilterKey.Theme:
                case ArticleFilterKey.Status:

                    const fields: FilterField[] = (field as FilterCollectionField).fields;

                    const choices: { id: number, label: string }[] = (field as FilterCollectionField).choices;

                    fields.forEach((field: FilterField): void => {

                        const choice: { id: number, label: string } = choices.find((choice: { id: number, label: string }): boolean => {

                            return choice.id === field.value;
                        });

                        items.push({
                            field: field,
                            formattedValue: choice ? choice.label : null
                        });
                    });

                    break;

                case ArticleFilterKey.SelfAuthor:
                case ArticleFilterKey.TywinAuthor:

                    items.push({
                        field: field,
                        formattedValue: `${ this._translateService.instant(`article.filter.${(key as string).toLowerCase()}.value`) }`
                    });

                    break;

                case ArticleFilterKey.UpdatedAt:

                    const updatedAtStart: (Moment|string) = (field.value.start) ? moment(field.value.start) : '';

                    const updatedAtEnd: (Moment|string) = (field.value.end) ? moment(field.value.end) : '';

                    let updatedAtDates: string = (field.value.start) ? (updatedAtStart as Moment).format('DD/MM/YYYY') : '';

                    updatedAtDates += (field.value.end) ? ' - ' + (updatedAtEnd as Moment).format('DD/MM/YYYY') : '';

                    items.push({
                        field: field,
                        formattedValue: `${ this._translateService.instant('filter.dateInterval.maj.value') } : ${ updatedAtDates }`
                    });

                    break;

                case ArticleFilterKey.PublishedAt:

                    const publishedAtStart: (Moment|string) = (field.value.start) ? moment(field.value.start) : '';

                    const publishedAtEnd: (Moment|string) = (field.value.end) ? moment(field.value.end) : '';

                    let publishedAtDates: string = (field.value.start) ? (publishedAtStart as Moment).format('DD/MM/YYYY') : '';

                    publishedAtDates += (field.value.end) ? ' - ' + (publishedAtEnd as Moment).format('DD/MM/YYYY') : '';

                    items.push({
                        field: field,
                        formattedValue: `${ this._translateService.instant('publication.date.value') } : ${ publishedAtDates }`
                    });

                    break;

                default:

                    items.push({
                        field: field,
                        formattedValue: `${ this._translateService.instant(`article.filter.${(key as string).toLowerCase()}.value`) } : ${ field.isCollection ? (field as FilterCollectionField).fields.map((field: FilterField): string => {

                            return field.value.toString();

                        }).join(', ') : field.value }`
                    });
            }
        });

        return items;
    }

    get mapItemPaginationApiResult(): (data: Pagination<Article>) => Article[] {

        return (data: Pagination<Article>) => {

            this._articleSearchService.totalItems = data.totalItems;

            return data.items;
        };
    }

    get hasMoreItems(): boolean {

        return this.items.length < this.totalItems;
    }

    get filterBuilder(): FilterBuilder {

        return this._articleSearchService.filterBuilder;
    }

    get totalItems(): number {

        return this._articleSearchService.totalItems;
    }

    get sidePanelOverlayContainerStyles(): { [p: string]: string } {

        return {
            'opacity': this.sidePanelOverlay.open ? '1' : '0',
            'visibility': this.sidePanelOverlay.open ? 'visible' : 'hidden'
        };
    }

    get countAvailableArticlesDetail(): string {

        const items: string[] = [];

        if(this.currentUser.society.countCreatedArticles){

            items.push(this.translationService.getTranslationFromQuantity(this.currentUser.society.countCreatedArticles, null, 'article.created.singular.count.value', 'article.created.plural.count.value'));
        }

        if(this.currentUser.society.countDraftArticles){

            items.push(this.translationService.getTranslationFromQuantity(this.currentUser.society.countDraftArticles, null, 'article.draft.singular.count.value', 'article.draft.plural.count.value'));
        }

        if(this.currentUser.society.countPublishedArticles){

            items.push(this.translationService.getTranslationFromQuantity(this.currentUser.society.countPublishedArticles, null, 'article.published.singular.count.value', 'article.published.plural.count.value'));
        }

        return items.join(' / ');
    }

    get displayAvailableArticlesDetail(): boolean {

        const items: number[] = [
            this.currentUser.society.countCreatedArticles,
            this.currentUser.society.countDraftArticles,
            this.currentUser.society.countPublishedArticles
        ];

        return Boolean(items.reduce((previousValue: number, currentValue: number): number => { return previousValue + currentValue; }, 0));
    }
}
