import {Component, Input} from '@angular/core';
import {AccordionItem} from "@lib/cms/element/type/accordion-item/accordion-item";

@Component({
  selector: 'app-cms-accordion-item',
  templateUrl: './accordion-item.component.html',
  styleUrl: './accordion-item.component.scss'
})
export class AccordionItemComponent {

    @Input() item: AccordionItem;
}
