import {Component, Input, OnInit} from '@angular/core';
import {FilterBuilder, FilterOperator} from "@core/shared/models/filter";
import {DateIntervalFilterField, DateIntervalFilterValue} from "@core/shared/models/filter/date-interval-filter-field";
import {Moment} from "moment";

@Component({
    selector: 'app-core-date-interval-filter',
    templateUrl: './date-interval-filter.component.html',
    styleUrls: ['./date-interval-filter.component.scss']
})
export class DateIntervalFilterComponent implements OnInit {

    @Input() builder: FilterBuilder;

    @Input() key: string;

    @Input() startPlaceholder: string = 'filter.dateInterval.start.value';

    @Input() endPlaceholder: string = 'filter.dateInterval.end.value';

    @Input() startOperator: FilterOperator;

    @Input() endOperator: FilterOperator;

    @Input() disableAutoHydrate: boolean = false;

    @Input() disableAutoSubmit: boolean;

    @Input() dateFormat: string = 'YYYY-MM-DD HH:mm:ss';

    @Input() displayFilterAction: boolean = true;

    @Input() displayResetAction: boolean = true;

    value: DateIntervalFilterValue;

    filterField: DateIntervalFilterField;

    start: Moment;

    end: Moment;

    constructor() {

        this.value = null;
    }

    ngOnInit(): void {

        this.filterField = new DateIntervalFilterField(this.key, null, this.startOperator, this.endOperator);

        this.builder.addField(this.filterField);
    }

    public hydrateValue(): void {

        if(this.start){

            this.start.set({
                hour:0,
                minute:0,
                second:0
            });
        }

        if(this.end){

            this.end.set({
                hour:23,
                minute:59,
                second:59
            });
        }

        this.filterField.value = {
            start: (this.start === null || this.start === undefined) ? null : this.start.format(this.dateFormat),
            end: (this.end === null || this.end === undefined) ? null : this.end.format(this.dateFormat),
        }
    }

    public filter(): void {

        if(this.start && this.end) {

            this.hydrateValue();

            if (this.disableAutoSubmit) {

                return;
            }

            this.builder.filter();
        }
    }

    public updateValue(): void {

        if(this.start && this.end) {

            if(!this.disableAutoHydrate){

                this.hydrateValue();
            }

            if (this.disableAutoSubmit) {

                return;
            }

            this.builder.filter();
        }
    }

    public reset(triggerFilter?: boolean): void {

        this.value = null;

        this.start = null;

        this.end = null;

        if(triggerFilter){

            this.filter();
        }
    }

    get filterEnabled(): boolean {

        return !this.disableAutoSubmit && (!!this.start && !!this.end);
    }
}
