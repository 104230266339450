import {Role} from "@core/shared/models/role";
import {Exclude, Expose, Transform, TransformFnParams} from "class-transformer";

export const GIFT_VOUCHER_SERVICE_LABEL = 'online-sale';
export const ADDITIONAL_WEBSITE_SERVICE_LABEL = 'additional-website';

export const GIFT_VOUCHER_URL_ORIGIN = 'gift-voucher';

export type ServiceTag = 'online-sale' | 'additional-website' | 'multiple-geolocation' | 'unlimited-channel' | 'unlimited-provider' | 'automatic-translation';

@Exclude()
export class Service {

    @Expose()
    id: number;

    @Expose()
    tag: ServiceTag;

    @Expose()
    monthlyPriceHT: number;

    @Expose()
    monthlyPriceTTC: number;

    @Expose()
    yearlyPriceHT: number;

    @Expose()
    yearlyPriceTTC: number;

    @Expose()
    applyAdmission: boolean;

    @Expose()
    admissionPriceHT: number;

    @Expose()
    admissionPriceTTC: number;

    @Expose()
    roles: Role[];

    @Expose()
    translations: ServiceTranslation[];

    @Expose()
    isValidatedMangoPayAccountRequired: boolean;

    @Expose()
    vatPercent: number;

    @Expose()
    isWithSubscription: boolean;

    @Expose()
    hasAdmissionPrice: boolean;

    @Expose()
    hasUsingPrice: boolean;

    @Expose()
    @Transform((params: TransformFnParams): number => {

        const value: number = params.value;

        return value !== null ? value * 24 : null;

    }, {toClassOnly: true})
    unsubscriptionDelay: number;

    @Expose()
    min: number;

    @Expose()
    max: number;
}

export class ServiceTranslation {

    name: string;

    description: string;

    content: string;

    locale: string;

    deleteDescription : string;

    mangoPayDocumentDescription: string;

    priceDescription: string;

    paymentDescription: string;
}
