import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {Image, ImageFormat, Watermark} from "./image";
import {ApiService} from "@core/shared/services/api.service";

@Injectable({
    providedIn: 'root'
})
export class ImageService {

    /**
     * API URL
     */
    private API_URL: string;

    private readonly MEDIA_URL: string = '/media';

    private readonly IMAGE_URL: string = '/images';

    private readonly FORMAT_URL: string = '/format';

    private _iterator: number;

    public constructor(private _http: HttpClient, private _apiService: ApiService) {

        this._iterator = 1;
    }

    public setApiUrl(apiUrl: string): void {

        this.API_URL = apiUrl;
    }

    public getApiUrl(): string {

        return this.API_URL;
    }

    public getImageUrl(): string {

        return this.IMAGE_URL;
    }

    public getMediaUrl(): string {

        return this.MEDIA_URL;
    }

    public getPath(image: Image, format: ImageFormat, width: number, quality: number): string {

        return `${this._apiService.getApiUrl(false, false)}/public${this.MEDIA_URL}${this.IMAGE_URL}/${image.id}/filter/${format.name}/${width}/${quality}`;
    }

    public crop(image: Image): Observable<object> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        const httpOptions: object = {
            headers: headers
        };

        return this._http.post(`${this._apiService.getApiUrl(false, true)}${this.IMAGE_URL}/${image.id}/crop`, {
            formats: image.formats
        }, httpOptions);
    }

    public incrementIterator(): void {

        this._iterator++;
    }

    public getIterator(): number {

        return this._iterator;
    }

    public setFormatImage(image: Image): Observable<object> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        const httpOptions: object = {
            headers: headers
        };

        return this._http.post(`${this.API_URL}${this.IMAGE_URL}/${image.id}${this.FORMAT_URL}`, {
            image: image
        }, httpOptions);
    }

    public getCloneImage(image: Image): Observable<Image>{

        return this._http.get<Image>(`${this._apiService.getApiUrl(false, true)}${this.IMAGE_URL}/${ image.id }/clone`);
    }

    public getImageUrlWithNoCache(url: string): string {

        const timestamp: number = new Date().getTime();

        return `${url}?t=${timestamp}`;
    }

    public getBinaryImage(image: Image, format: string, width: number, quality: number, watermark: Watermark): Observable<Blob> {

        let url: string = `${this._apiService.getApiUrl(false, false)}/public/cms/image/${image.id}/binary/${format}/${width}/${quality}`;

        if(watermark){

            url += `/${watermark.id}`;
        }

        return this._http.get(url, {
            responseType: "blob"
        });
    }
}
