import {AfterViewInit, Component, EventEmitter, OnInit, Output, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {LOCALE_ITEMS, LocaleItem} from "@core/shared/models/translation";
import {TranslateService} from "@ngx-translate/core";
import {OfferAttributeTypeTagType} from "@core/shared/models/offer-attribute-type";
import {OfferAttribute} from "@core/shared/models/offer-attribute";
import {ArrayFilterField} from "@core/shared/models/filter/array-filter-field";
import {OfferAttributeService} from "@core/shared/services/offer-attribute.service";
import {TranslationService} from "@core/shared/services/translation.service";
import {FilterBuilder, FilterComponent, FilterField} from "@core/shared/models/filter";
import {ArticleSearchService} from "@core/shared/services/article/article-search.service";
import {ArticleFilterKey} from "@core/shared/models/article/article-search";
import {CheckboxListFilterComponent} from "@core/components/filter/checkbox-list-filter/checkbox-list-filter.component";
import {SocietyService} from "@core/shared/services/society.service";
import {ShortSociety} from "@core/shared/models/society";
import {ToggleBooleanFilterComponent} from "@core/components/filter/toggle-boolean-filter/toggle-boolean-filter.component";
import {ArticleStatus} from "@core/shared/models/article";
import {DateIntervalFilterComponent} from "@core/components/filter/date-interval-filter/date-interval-filter.component";
import {DateIntervalFilterValue} from "@core/shared/models/filter/date-interval-filter-field";
import * as moment from "moment/moment";

@Component({
    selector: 'app-core-article-filter-panel',
    templateUrl: './article-filter-panel.component.html',
    styleUrl: './article-filter-panel.component.scss'
})
export class ArticleFilterPanelComponent implements OnInit, AfterViewInit {

    @Output() submit: EventEmitter<void> = new EventEmitter<void>();

    @Output() close: EventEmitter<void> = new EventEmitter<void>();

    @ViewChildren(`
        localeFilter,
        selfAuthorFilter,
        tywinAuthorFilter,
        authorFilter,
        regionFilter,
        themeFilter,
        statusFilter,
        updatedAtFilter,
        publishedAtFilter
    `) filterComponents: QueryList<FilterComponent>;

    @ViewChild('localeFilter', {static: false}) localeFilterComponent: CheckboxListFilterComponent<LocaleItem>;

    @ViewChild('selfAuthorFilter', {static: false}) selfAuthorFilterComponent: ToggleBooleanFilterComponent;

    @ViewChild('tywinAuthorFilter', {static: false}) tywinAuthorFilterComponent: ToggleBooleanFilterComponent;

    @ViewChild('authorFilter', {static: false}) authorFilterComponent: CheckboxListFilterComponent<{ id: number, label: string }>;

    @ViewChild('regionFilter', {static: false}) regionFilterComponent: CheckboxListFilterComponent<{ id: number, label: string }>;

    @ViewChild('themeFilter', {static: false}) themeFilterComponent: CheckboxListFilterComponent<{ id: number, label: string }>;

    @ViewChild('statusFilter', {static: false}) statusFilterComponent: CheckboxListFilterComponent<{ id: ArticleStatus, label: string }>;

    @ViewChild('updatedAtFilter', {static: false}) updatedAtFilterComponent: DateIntervalFilterComponent;

    @ViewChild('publishedAtFilter', {static: false}) publishedAtFilterComponent: DateIntervalFilterComponent;

    protected readonly ArticleFilterKey = ArticleFilterKey;

    public locales: LocaleItem[] = [];

    public authors: { id: number, label: string }[] = [];

    public regions: { id: number, label: string }[] = [];

    public themes: { id: number, label: string }[] = [];

    public statusItems: { id: ArticleStatus, label: string }[] = [];

    constructor(
        private _translateService: TranslateService,
        private _offerAttributeService: OfferAttributeService,
        private _societyService: SocietyService,
        public translationService: TranslationService,
        public articleSearchService: ArticleSearchService
    ){}

    ngOnInit() {

        this._initLocales();

        this._initAuthors();

        this._initRegions();

        this._initThemes();

        this._initStatusItems();

        this._initEvents();
    }

    ngAfterViewInit(): void {

        setTimeout((): void => {

            this.initFilters();
        });
    }

    private _initLocales(): void {

        this.locales = LOCALE_ITEMS.map((item: LocaleItem): LocaleItem => {

            return {
                id: item.id,
                label: this._translateService.instant(item.label)
            };
        }).sort((a: LocaleItem, b: LocaleItem): number => {

            return a.label.localeCompare(b.label);
        });
    }

    private _initAuthors(): void {

        const params: string[] = [
            'sort[name]=asc'
        ];

        this._societyService.getArticleShortItemsAPI(params).subscribe((items: ShortSociety[]): void => {

            this.authors = items.map((item: ShortSociety): { id: number, label: string } => {

                return {
                    id: item.id,
                    label: item.name
                };
            });
        });
    }

    private _initRegions(): void {

        const tag: OfferAttributeTypeTagType = 'region';

        const typeFilter: ArrayFilterField = new ArrayFilterField('type.tag', 'andin', tag);

        const params: string[] = [
            typeFilter.serialize,
            'sort[translations.label]=asc'
        ];

        this._offerAttributeService.getItemsAPI(params).subscribe((items: OfferAttribute[]): void => {

            this.regions = items.map((item: OfferAttribute): { id: number, label: string } => {

                return {
                    id: item.id,
                    label: this.translationService.getFallbackTranslation(item.translations).label
                };
            });
        })
    }

    private _initThemes(): void {

        const tag: OfferAttributeTypeTagType = 'themes';

        const typeFilter: ArrayFilterField = new ArrayFilterField('type.tag', 'andin', tag);

        const params: string[] = [
            typeFilter.serialize,
            'sort[translations.label]=asc'
        ];

        this._offerAttributeService.getItemsAPI(params).subscribe((items: OfferAttribute[]): void => {

            this.themes = items.map((item: OfferAttribute): { id: number, label: string } => {

                return {
                    id: item.id,
                    label: this.translationService.getFallbackTranslation(item.translations).label
                };
            });
        })
    }

    private _initStatusItems(): void {

        this.statusItems = [
            {
                id: 'published',
                label: this._translateService.instant('article.status.published.value')
            },
            {
                id: 'draft',
                label: this._translateService.instant('article.status.draft.value')
            }
        ];
    }

    private _initEvents(): void {

        this.articleSearchService.deleteActiveFilterField$.subscribe((field: FilterField): void => {

            const key: ArticleFilterKey = field.key as ArticleFilterKey;

            switch (key){

                case ArticleFilterKey.Locale:

                    this.localeFilterComponent.handleItemSelection(this.locales.find((item: { id: string }): boolean => {

                        return item.id === field.value;
                    }));

                    break;

                case ArticleFilterKey.SelfAuthor:

                    this.selfAuthorFilterComponent.reset();

                    break;

                case ArticleFilterKey.TywinAuthor:

                    this.tywinAuthorFilterComponent.reset();

                    break;

                case ArticleFilterKey.Author:

                    this.authorFilterComponent.handleItemSelection(this.authors.find((item: { id: number }): boolean => {

                        return item.id === field.value;
                    }));

                    break;

                case ArticleFilterKey.Theme:

                    this.themeFilterComponent.handleItemSelection(this.themes.find((item: { id: number }): boolean => {

                        return item.id === field.value;
                    }));

                    break;

                case ArticleFilterKey.Region:

                    this.regionFilterComponent.handleItemSelection(this.regions.find((item: { id: number }): boolean => {

                        return item.id === field.value;
                    }));

                    break;

                case ArticleFilterKey.Status:

                    this.statusFilterComponent.handleItemSelection(this.statusItems.find((item: { id: ArticleStatus }): boolean => {

                        return item.id === field.value;
                    }));

                    break;

                case ArticleFilterKey.UpdatedAt:

                    this.updatedAtFilterComponent.reset();

                    break;

                case ArticleFilterKey.PublishedAt:

                    this.publishedAtFilterComponent.reset();

                    break;
            }
        });
    }

    public initFilters(): void {

        // Langue

        if(this.articleSearchService.isFilterEnabled(ArticleFilterKey.Locale) && this.articleSearchService.hasSessionFilter(ArticleFilterKey.Locale)){

            this.localeFilterComponent.values = this.articleSearchService.getSessionFilter(ArticleFilterKey.Locale).value;
        }

        // Mes publications

        if(this.articleSearchService.isFilterEnabled(ArticleFilterKey.SelfAuthor) && this.articleSearchService.hasSessionFilter(ArticleFilterKey.SelfAuthor)){

            this.selfAuthorFilterComponent.value = this.articleSearchService.getSessionFilter(ArticleFilterKey.SelfAuthor).value || null;
        }

        // Auteur Ty-Win

        if(this.articleSearchService.isFilterEnabled(ArticleFilterKey.TywinAuthor) && this.articleSearchService.hasSessionFilter(ArticleFilterKey.TywinAuthor)){

            this.tywinAuthorFilterComponent.value = this.articleSearchService.getSessionFilter(ArticleFilterKey.TywinAuthor).value || null;
        }

        // Auteur

        if(this.articleSearchService.isFilterEnabled(ArticleFilterKey.Author) && this.articleSearchService.hasSessionFilter(ArticleFilterKey.Author)){

            this.authorFilterComponent.values = this.articleSearchService.getSessionFilter(ArticleFilterKey.Author).value;
        }

        // Destination

        if(this.articleSearchService.isFilterEnabled(ArticleFilterKey.Region) && this.articleSearchService.hasSessionFilter(ArticleFilterKey.Region)){

            this.regionFilterComponent.values = this.articleSearchService.getSessionFilter(ArticleFilterKey.Region).value;
        }

        // Thématique

        if(this.articleSearchService.isFilterEnabled(ArticleFilterKey.Theme) && this.articleSearchService.hasSessionFilter(ArticleFilterKey.Theme)){

            this.themeFilterComponent.values = this.articleSearchService.getSessionFilter(ArticleFilterKey.Theme).value;
        }

        // Statut

        if(this.articleSearchService.isFilterEnabled(ArticleFilterKey.Status) && this.articleSearchService.hasSessionFilter(ArticleFilterKey.Status)){

            this.statusFilterComponent.values = this.articleSearchService.getSessionFilter(ArticleFilterKey.Status).value;
        }

        // Date de mise à jour

        if(this.articleSearchService.isFilterEnabled(ArticleFilterKey.UpdatedAt) && this.articleSearchService.hasSessionFilter(ArticleFilterKey.UpdatedAt)){

            const value: DateIntervalFilterValue = this.articleSearchService.getSessionFilter(ArticleFilterKey.UpdatedAt).value;

            if(value){

                this.updatedAtFilterComponent.start = moment(value.start);

                this.updatedAtFilterComponent.end = moment(value.end);
            }
        }

        // Date de publication

        if(this.articleSearchService.isFilterEnabled(ArticleFilterKey.PublishedAt) && this.articleSearchService.hasSessionFilter(ArticleFilterKey.PublishedAt)){

            const value: DateIntervalFilterValue = this.articleSearchService.getSessionFilter(ArticleFilterKey.PublishedAt).value;

            if(value){

                this.publishedAtFilterComponent.start = moment(value.start);

                this.publishedAtFilterComponent.end = moment(value.end);
            }
        }
    }

    public filter(): void {

        this.filterComponents.forEach((filterComponent: FilterComponent): void => {

            filterComponent.filter();
        });

        this.filterBuilder.filter();

        this.submit.emit();
    }

    public reset(): void {

        this.articleSearchService.resetFilter$.next();

        this.submit.emit();
    }

    get filterBuilder(): FilterBuilder {

        return this.articleSearchService.filterBuilder;
    }
}
