<div class="element">

    <div class="actions actions-element">

        <button type="button" mat-stroked-button [disabled]="!isDecreaseAllowed" (click)="decreaseContent.emit()" matTooltip="{{ 'element.move.top.action' | translate }}" matTooltipPosition="above">
            <mat-icon>arrow_upward</mat-icon>
        </button>

        <button type="button" mat-stroked-button [disabled]="!isIncreaseAllowed" (click)="increaseContent.emit()" class="" matTooltip="{{ 'element.move.bottom.action' | translate }}" matTooltipPosition="above">
            <mat-icon>arrow_downward</mat-icon>
        </button>

        <button type="button" mat-stroked-button (click)="resetContent()" class="mat-warn" matTooltip="{{ 'element.reset.action' | translate }}" matTooltipPosition="above">
            <mat-icon>loop</mat-icon>
        </button>

        <button type="button" mat-stroked-button (click)="delete.emit()" class="mat-warn" matTooltip="{{ 'element.delete.action' | translate }}" matTooltipPosition="above">
            <mat-icon>delete</mat-icon>
        </button>

    </div>

    <ng-container *ngIf="element.type">
        <div class="row" style="margin-top: 20px">
            <div class="col_12">
                <div class="form-group">
                    <div class="form_part">
                        <p class="title_part x_center">{{ elementLabel }}</p>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>

    <div class="bloc">
        <div class="infos-bloc" *ngIf="!element.type">
            <div *ngIf="!element.type">
                <span class="label">{{ 'element.content.label' | translate }}</span>
                <select [(ngModel)]="element.type" (ngModelChange)="handleTypeSelection()">
                    <option [ngValue]="null">{{ 'element.content.choice' | translate }}</option>
                    <option *ngFor="let type of allowedTypes" [ngValue]="type.id">
                        {{ type.label.charAt(0).toUpperCase() + type.label.slice(1) }}
                    </option>
                    <option *ngFor="let type of customButtons" [ngValue]="type.id">
                        {{ type.label }}
                    </option>
                </select>
            </div>
        </div>
        <div class="element-bloc">

            <!-- Liste des types d'élément par défaut -->

            <app-text *ngIf="isType('text')" [content]="element.content" [apiUrl]="getApiUrl()" (update)="setContent($event)"></app-text>

            <app-file *ngIf="isType('file')" [content]="element.content" [apiUrl]="getApiUrl()" (update)="setContent($event)"></app-file>

            <app-button *ngIf="isType('button')" [content]="element.content" [apiUrl]="getApiUrl()" (update)="setContent($event)"></app-button>

            <app-iframe *ngIf="isType('iframe')" [content]="element.content" (update)="setContent($event)"></app-iframe>

            <app-element-form *ngIf="isType('form')" [content]="element.content" [apiUrl]="getApiUrl()" (update)="setContent($event)"></app-element-form>

            <app-video *ngIf="isType('video')" [content]="element.content" [apiUrl]="getApiUrl()" (update)="setContent($event)"></app-video>

            <cms-element-image *ngIf="isType('image')" [content]="element.content" [apiUrl]="getApiUrl()" (update)="setContent($event)"></cms-element-image>

            <cms-element-slideshow *ngIf="isType('slideshow')" [content]="element.content" [apiUrl]="getApiUrl()" (update)="setContent($event)"></cms-element-slideshow>

            <cms-element-accordion-item *ngIf="isType('accordionItem')" [content]="element.content" (update)="setContent($event)"></cms-element-accordion-item>

            <!-- Elements Custom -->

            <div class="custom-type" *ngFor="let customButton of customButtons">
                <app-button
                        *ngIf="element.type === customButton.id"
                        [content]="element.content"
                        [enableLink]="customButton.enableLink"
                        [title]="customButton.defaultTitle"
                        [help]="customButton.help"
                        (update)='setContent($event)'>
                </app-button>
            </div>
        </div>
    </div>
</div>
