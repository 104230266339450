import {Injectable} from '@angular/core';
import {AbstractControl, UntypedFormGroup} from "@angular/forms";
import {MatTabChangeEvent, MatTabGroup} from "@angular/material/tabs";

export interface FormTabValidationItem {

    tag: string;

    position: number;

    controls: AbstractControl[];
}

@Injectable()
export class OfferFormTabValidationService {

    private _tabGroup: MatTabGroup;

    private _form: UntypedFormGroup;

    private _items: FormTabValidationItem[] = [];

    private _currentItem: FormTabValidationItem = null;

    private _validationEnabled: boolean = true;

    private _checkValidityOnNavigation: boolean = true;

    constructor() {
    }

    private _handleDevMode(): void {

        //@ts-ignore
        window.enableFormTabValidation = (): void => {

            document.dispatchEvent(new Event(('ENABLE_FORM_TAB_VALIDATION')));
        };

        //@ts-ignore
        window.disableFormTabValidation = (): void => {

            document.dispatchEvent(new Event(('DISABLE_FORM_TAB_VALIDATION')));
        };

        document.addEventListener('ENABLE_FORM_TAB_VALIDATION', (): void => {

            this._validationEnabled = true;
        });

        document.addEventListener('DISABLE_FORM_TAB_VALIDATION', (): void => {

            this._validationEnabled = false;
        });
    }

    public init(tabGroup: MatTabGroup, form: UntypedFormGroup, itemsCallback: () => FormTabValidationItem[], defaultPosition?: number, checkValidityOnNavigation?: boolean): void {

        this._tabGroup = tabGroup;

        this._checkValidityOnNavigation = (checkValidityOnNavigation === undefined || checkValidityOnNavigation === null) ? true : checkValidityOnNavigation;

        this._tabGroup.selectedTabChange.subscribe((event: MatTabChangeEvent): void => {

            const item: FormTabValidationItem = this.getItemByPosition(event.index + 1);

            if(!item){

                return;
            }

            this.setCurrentItem(item.tag);
        });

        this._form = form;

        this.refreshItems(itemsCallback());

        this.form.valueChanges.subscribe((): void => {

            this.refreshItems(itemsCallback());
        });

        this._currentItem = this.getItemByPosition(defaultPosition || 1);

        if(this._currentItem){

            this._tabGroup.selectedIndex = this._currentItem.position - 1;
        }

        this._handleDevMode();
    }

    public refreshItems(items: FormTabValidationItem[]): void {

        this._items = items;
    }

    public isFirstItem(tag: string): boolean {

        const item: FormTabValidationItem = this.getItemByTag(tag);

        if(!item){

            return false;
        }

        return item.position === 1;
    }

    public isValidItem(tag: string): boolean {

        if(!this._validationEnabled){

            return true;
        }

        const item: FormTabValidationItem = this.getItemByTag(tag);

        if(!item){

            return false;
        }

        return item.controls.every((control: AbstractControl): boolean => {

            if(!control) {

                return true;
            }

            return control.valid;
        });
    }

    public isPreviousItemValid(tag: string): boolean {

        if(!this._validationEnabled){

            return true;
        }

        const item: FormTabValidationItem = this.getItemByTag(tag);

        if(!item){

            return false;
        }

        const previousItem: FormTabValidationItem = this.getItemByPosition(item.position - 1);

        if(!previousItem){

            return false;
        }

        return this.isValidItem(previousItem.tag);
    }

    public isSelectionItemAvailable(tag: string): boolean {

        if(!this._validationEnabled){

            return true;
        }

        const item: FormTabValidationItem = this.getItemByTag(tag);

        if(!this.isValidItem(tag)){

            return false;
        }

        return item.position <= this._currentItem.position;
    }

    public getItemByTag(tag: string): FormTabValidationItem {

        return this._items.find((item: FormTabValidationItem): boolean => {

            return item.tag === tag;
        });
    }

    public getItemByPosition(position: number): FormTabValidationItem {

        return this._items.find((item: FormTabValidationItem): boolean => {

            return item.position === position;
        });
    }

    public goToNextItem(): void {

        if(!this.isValidItem(this._currentItem.tag)){

            return;
        }

        this._tabGroup.selectedIndex = this._currentItem.position;
    }

    public setCurrentItem(tag: string): void {

        const item: FormTabValidationItem = this.getItemByTag(tag);

        if(this._checkValidityOnNavigation && (item.position > this._currentItem.position) && !this.isValidItem(this._currentItem.tag)){

            this._tabGroup.selectedIndex = this._currentItem.position - 1;

            return;
        }

        this._currentItem = item;
    }

    public getTabLabelClasses(tag: string, onlyPastOrCurrentItems: boolean = true): {[p: string]: boolean} {

        const item: FormTabValidationItem = this.getItemByTag(tag);

        if(!item || !this._currentItem) {

            return {};
        }

        return {
            valid: this.isValidItem(tag) && (onlyPastOrCurrentItems ? (item.position <= this._currentItem.position) : true),
            invalid: !this.isValidItem(tag) && (onlyPastOrCurrentItems ? (item.position <= this._currentItem.position) : true)
        };
    }

    public getTabLabelClass(tag: string): string {

        const item: FormTabValidationItem = this.getItemByTag(tag);

        if(!item) {

            return '';
        }

        return this.isValidItem(tag) ? 'valid' : 'invalid';

    }

    get form(): UntypedFormGroup {

        return this._form;
    }

    get isCurrentItemValid(): boolean {

        if(!this._validationEnabled){

            return true;
        }

        if(!this._currentItem){

            return false;
        }

        return this.isValidItem(this._currentItem.tag);
    }

    get isValid(): boolean {

        if(!this._validationEnabled){

            return true;
        }

        return this._items.every((item: FormTabValidationItem): boolean => {

            return this.isValidItem(item.tag);
        });
    }

    get currentItemIsLastItem(): boolean {

        if(!this._validationEnabled){

            return true;
        }

        if(!this._currentItem){

            return false;
        }

        return this._currentItem.position === this._items.length;
    }

    get validationEnabled(): boolean {

        return this._validationEnabled;
    }
}
