import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FilterBuilder, FilterOperator} from "@core/shared/models/filter";
import {FilterCollectionField} from "@core/shared/models/filter/filter-collection";
import {ArrayFilterField} from "@core/shared/models/filter/array-filter-field";

@Component({
  selector: 'app-core-checkbox-list-filter',
  templateUrl: './checkbox-list-filter.component.html',
  styleUrl: './checkbox-list-filter.component.scss'
})
export class CheckboxListFilterComponent<T> implements OnInit, OnChanges{

    @Input() builder: FilterBuilder;

    @Input() label: string;

    @Input() key: string;

    @Input() itemValueProperty: string;

    @Input() itemLabelProperty: string;

    @Input() itemTypeProperty: string;

    @Input() items: T[] = [];

    @Input() operator: FilterOperator;

    @Input() disableAutoSubmit: boolean;

    @Input() defaultCountDisplayedOptions: number = 4;

    @Output() selectionChanged: EventEmitter<any[]> = new EventEmitter<any[]>();

    @Output() valuesUpdated: EventEmitter<any[]> = new EventEmitter<any[]>();

    public values: any[] = [];

    public filterCollectionField: FilterCollectionField;

    public allOptionsDisplayed: boolean = false;

    ngOnInit(): void {

        this.filterCollectionField = new FilterCollectionField(this.key);

        this.filterCollectionField.choices = this.items;

        this.builder.addField(this.filterCollectionField);
    }

    ngOnChanges(changes: SimpleChanges) {

        if(('items' in changes) && !changes.items.isFirstChange()){

            this.filterCollectionField.choices = changes.items.currentValue;
        }
    }

    public handleItemSelection(item: T): void {

        if(this.isSelectedItem(item)){

            const index: number = this.values.findIndex((value: any): boolean => {

                return value === this.getKeyPropertyValue(item);
            });

            this.values.splice(index, 1);
        }
        else{

            this.values.push(this.getKeyPropertyValue(item));
        }

        this.valuesUpdated.emit(this.values);
    }

    public filter(): void {

        this.hydrateValue();

        this.selectionChanged.emit(this.values);

        if (this.disableAutoSubmit) {

            return;
        }

        this.builder.filter();
    }

    public hydrateValue(): void {

        this.filterCollectionField.clear();

        this.values.forEach((value: any): void => {

            this.filterCollectionField.fields.push(new ArrayFilterField(this.key, this.operator, value));
        });
    }

    public reset(): void {

        this.resetValues();

        this.filterCollectionField.clear();
    }

    public resetValues(): void {

        this.values = [];
    }

    public isSelectedItem(item: T): boolean {

        return this.values.includes(this.getKeyPropertyValue(item));
    }

    public getLabelPropertyValue(item: T): string {

        return this.itemLabelProperty.split('.').reduce((carry, key) => carry[key], item);
    }

    public getKeyPropertyValue(item: T): any {

        return this.itemValueProperty.split('.').reduce((carry, key) => carry[key], item);
    }

    public toggleOptionDisplay(): void {

        this.allOptionsDisplayed = !this.allOptionsDisplayed;
    }

    public isOptionDisplayed(index: number): boolean {

        if(this.allOptionsDisplayed){

            return true;
        }

        return index < this.defaultCountDisplayedOptions;
    }

    get displayedOptions(): T[] {

        return this.items.filter((item: T, index: number): boolean => {

           return this.isOptionDisplayed(index);
        });
    }

    get displayToggleOptionsAction(): boolean {

        return this.items.length > this.defaultCountDisplayedOptions;
    }
}
