import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Category} from "@core/shared/models/category";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {TranslationService} from "@core/shared/services/translation.service";
import {Location} from "@angular/common";
import {Page} from "@core/shared/models/page";

@Component({
    selector: 'page-category-read',
    templateUrl: './page-category-read.component.html',
    styleUrls: ['./page-category-read.component.scss']
})
export class PageCategoryReadComponent implements OnInit {

    public category: Category;

    constructor(
        private _activatedRoute: ActivatedRoute,
        private sanitizer: DomSanitizer,
        public translationService: TranslationService,
        private _location: Location
    ) {}

    ngOnInit() {

        this._activatedRoute.data.subscribe((data: { category: Category }): void => {

            this.category = data.category;
        });
    }

    public sanitize(string): SafeHtml {

        return this.sanitizer.bypassSecurityTrustHtml(string);
    }

    public getPagesByTranslate(translation): Page[] {

        /** @ts-ignore **/
        const pages = this.translationService.getFallbackTranslation(translation).pages;

        const tabPage: Page[] = [];

        pages.forEach((page): void => {

            if (page.locales.includes(this.translationService.getUserLocale())) {

                tabPage.push(page);
            }
        });

        return tabPage;
    }

    public backClicked(): void {

        this._location.back();
    }

    get backEnabled(): boolean {

        const param: string = this._activatedRoute.snapshot.queryParams['backEnabled'];

        return !!param ? Boolean(parseInt(param)) : false;
    }
}
