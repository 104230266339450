<div [formGroup]="form" class="form-group">
    <mat-label>{{config.attrs.label}}</mat-label>
    <ckeditor id="editor" [formControlName]="config.id" [editor]="config.editor" [config]="ckeditorConfig" (ready)="onReady($event)"></ckeditor>
    <mat-error>
        <div class="invalid-feedback" *ngIf="form.controls[config.id].invalid && (form.controls[config.id].dirty || form.controls[config.id].touched)">
            <div *ngIf="form.controls[config.id].errors.required || form.controls[config.id].errors.isRequired">{{ 'form.validation.error.required' | translate }}</div>
            <div *ngIf="form.controls[config.id].errors.textMaxLength">{{ 'form.validation.error.textMaxLength' | translate:{ count: config.attrs.maxLength } }}</div>
        </div>
    </mat-error>
    <ng-container *ngIf="config.attrs?.maxLength">
        <span class="form-control-input">{{count}} / {{config.attrs.maxLength}} {{ 'form.input.type' | translate }}</span>
    </ng-container>

</div>
