<div class="thumb-input">

    <div class="big-label">
        <span class="label">{{ 'title.value' | translate }}</span>
        <input type="text" [(ngModel)]="content.title" (ngModelChange)="setContent(content)">
    </div>

    <div class="big-label">
        <span class="label">{{ 'description.value' | translate }}</span>
        <div class="form-group">
            <textarea [(ngModel)]="content.description" (ngModelChange)="setContent(content)"></textarea>
        </div>
    </div>

</div>

