import {AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {DEFAULT_LOCALE} from "@app/data";

export interface TranslationItem {

    locale: string;
}

export interface LocaleItem {

    id: string;

    label: string;
}

export interface IdentifierHistory {

    id: number;

    locale: string;
}

export const LOCALE_ITEMS_USER: LocaleItem[] = [
    {
        id: 'fr',
        label: 'locale.fr.value'
    }
];

export const LOCALE_ITEMS: LocaleItem[] = [
    {
        id: 'fr',
        label: 'locale.fr.value'
    },
    {
        id: 'en',
        label: 'locale.en.value'
    },
    {
        id: 'es',
        label: 'locale.es.value'
    },
    {
        id: 'pt',
        label: 'locale.pt.value'
    },
    {
        id: 'it',
        label: 'locale.it.value'
    },
    {
        id: 'de',
        label: 'locale.de.value'
    },
    {
        id: 'nl',
        label: 'locale.nl.value'
    }
];

export class TranslationBuilder {

    private _form: AbstractControl;

    private _defaultLocale = DEFAULT_LOCALE;

    private _maxEnabledItems = 7;

    private _addItemCallback: (item: LocaleItem) => UntypedFormGroup;

    private _identifiersHistory: IdentifierHistory[] = [];

    constructor(
        private _formBuilder: UntypedFormBuilder
    ) {
    }

    public addDefaultItem(): void {

        const localeItem: LocaleItem = this.getLocaleItem(this._defaultLocale);

        this.addItemControl(localeItem);
    }

    public updateItems(locales: string[]): void {

        this.items.forEach((item: TranslationItem): void => {

            if(!locales.includes(item.locale)){

                this.removeItem(this.getItemIndexByLocale(item.locale));
            }
        });

        locales.forEach((locale: string): void => {

            if(!this.getItemByLocale(locale)){

                this.addItemControl(this.getLocaleItem(locale));
            }
        });
    }

    // A supprimer pour l'Admin V2
    public changeLocale(values: string[]): void {

        for (let i = 0; i < values.length || i < this.itemsControl.controls.length; i++) {

            if (i >= values.length) {

                this.itemsControl.controls.pop();

            } else if (i < this.itemsControl.controls.length) {

                this.itemsControl.controls[i].get('locale').patchValue(values[i]);
            }
        }
    }

    public addItemControl(localeItem: LocaleItem, value?: any): UntypedFormGroup {

        if(!this.addItemCallback){

            throw new Error('Fonction de callback non définie');
        }

        if(!this.addItemEnabled){

            return;
        }

        const control: UntypedFormGroup = this._addItemCallback(localeItem);

        control.addControl('locale', this._formBuilder.control(localeItem.id, [Validators.required]));

        if(!!value){

            control.patchValue(value);

            if('id' in value && value.id){

                this._identifiersHistory.push({
                    locale: localeItem.id,
                    id: value.id
                });
            }
        }
        else{

            const identifierHistory: IdentifierHistory = this.getIdentifierHistory(localeItem.id);

            if(identifierHistory){

                if(!control.get('id')){

                    control.addControl('id', this._formBuilder.control(null));
                }

                control.patchValue({
                    id: identifierHistory.id
                });
            }
        }

        this.itemsControl.push(control);

        return control;
    }

    public removeItem(index: number) {

        this.itemsControl.removeAt(index);
    }

    public clear(): void {

        while (this.itemsControl.length !== 0) {

            this.itemsControl.removeAt(0);
        }
    }

    public getLocaleItem(id: string): LocaleItem {

        return LOCALE_ITEMS.find((locale: LocaleItem): boolean => {

            return locale.id === id;
        });
    }

    public getLocaleLabel(id: string): string {

        return this.getLocaleItem(id).label;
    }

    public getItemControl(index: number): UntypedFormGroup {

        return this.itemsControl.controls[index] as UntypedFormGroup;
    }

    public getItemControlByLocale(id: string): UntypedFormGroup {

        return this.itemsControl.controls.find((control: UntypedFormGroup): boolean => {

            return control.get('locale').value === id;

        }) as UntypedFormGroup;
    }

    public getItemByLocale(id: string): TranslationItem {

        return (this.itemsControl.value as TranslationItem[]).find((translation: TranslationItem): boolean => {

            return translation.locale === id;
        });
    }

    public getItemIndexByLocale(id: string): number {

        return (this.itemsControl.value as TranslationItem[]).findIndex((translation: TranslationItem): boolean => {
            return translation.locale === id;
        });
    }

    public getIdentifierHistory(locale: string): IdentifierHistory {

        return this._identifiersHistory.find((item: IdentifierHistory): boolean => {

            return item.locale === locale;
        });
    }

    public indexAsString(index: number): string {

        return index.toString();
    }

    get defaultLocale() {

        return this._defaultLocale;
    }

    set defaultLocale(value) {

        this._defaultLocale = value;
    }

    set form(value: AbstractControl) {

        this._form = value;
    }

    get form(): AbstractControl {

        return this._form;
    }

    get items(): TranslationItem[] {

        return this.itemsControl.value;
    }

    get itemsControl(): UntypedFormArray {

        if(!this._form){

            throw new Error('Le formulaire cible n\'est pas définie');
        }

        return this._form.get('translations') as UntypedFormArray;
    }

    get itemLocales(): string[] {

        return this.items.map((item: TranslationItem): string => {

            return item.locale;
        });
    }

    get addItemEnabled(): boolean {

        return this.items.length < this._maxEnabledItems;
    }

    get addItemCallback(): (item: LocaleItem) => UntypedFormGroup {

        return this._addItemCallback;
    }

    set addItemCallback(value: (item: LocaleItem) => UntypedFormGroup) {

        this._addItemCallback = value;
    }
}
