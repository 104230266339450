export const SUPPORTED_LOCALES: string[] = ['fr', 'en', 'es', 'it', 'pt', 'de', 'nl'];

export const DEFAULT_LOCALE: string = 'fr';

export const DATE_FORMAT: string = 'YYYY-MM-DD';

export const DATE_UNDERSCORE_FORMAT: string = 'YYYY_MM_DD';

export const DATETIME_FORMAT: string = 'YYYY-MM-DD HH:mm:ss';

export const DEACTIVATE_LOADER_HEADER_IDENTIFIER: string = 'X-Deactivate-Loader';
