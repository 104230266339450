import {AfterViewInit, Component, ElementRef, Input, ViewChild} from '@angular/core';
import {ApiService} from "@core/shared/services/api.service";
import Swiper, {Navigation} from "swiper";
import {CmsContentService} from "@core/shared/services/cms/cms-content.service";
import {Image, ImageFormat} from "@lib/media/image/image";
import {ImageService} from "@lib/media/image/image.service";
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";
import {Observable, of} from "rxjs";
import {map, shareReplay} from "rxjs/operators";
import {ImageEventService} from "@lib/media/image/image.event.service";
import {Slideshow} from "@lib/cms/element/type/slideshow/slideshow";

@Component({
    selector: 'app-cms-slideshow',
    templateUrl: './slideshow.component.html',
    styleUrls: ['./slideshow.component.scss']
})
export class CmsSlideshowComponent implements AfterViewInit {

    @Input() slideshow: Slideshow;

    @Input() width: number = 1500;

    @Input() quality: number = 90;

    @ViewChild('swiper', {static: true}) swiperRef: ElementRef<HTMLElement>;

    @ViewChild('swiperButtonPrev', {static: true}) swiperButtonPrevRef: ElementRef<HTMLElement>;

    @ViewChild('swiperButtonNext', {static: true}) swiperButtonNextRef: ElementRef<HTMLElement>;

    private imageCache: { [key: number]: Observable<SafeUrl> } = {};

    constructor(
        private _sanitizer: DomSanitizer,
        private _apiService: ApiService,
        private _cmsContentService: CmsContentService,
        private _imageService: ImageService,
        private _imageEventService: ImageEventService
    ) {
    }

    ngAfterViewInit() {

        this._initSlider();
    }

    private _initSlider(): void {

        new Swiper(this.swiperRef.nativeElement, {
            loop: true,
            slidesPerView: 1,
            centeredSlides: true,
            autoplay: true,
            modules: [
                Navigation
            ],
            navigation: {
                nextEl: this.swiperButtonNextRef.nativeElement,
                prevEl: this.swiperButtonPrevRef.nativeElement,
            }
        });
    }

    public getSelectedFormat(formats: ImageFormat[]): string {

        if (!Array.isArray(formats))
            return null;

        const item: ImageFormat = formats.find((format: ImageFormat): boolean => {

            return format.selected;
        });

        return item ? item.name : 'landscape';
    }

    public getSourceUrl(image: Image): Observable<SafeUrl> {

        if (!this.imageCache[image.id]) {

            if(this._cmsContentService.imageAsBinary){

                const loadVisualCallback = (): void => {

                    this.imageCache[image.id] = this._imageService.getBinaryImage(image, this.getSelectedFormat(image.formats), this.width, this.quality, null).pipe(
                        shareReplay(1),
                        map((blob: Blob): SafeUrl => {

                            const objectURL: string = URL.createObjectURL(blob);

                            return this._sanitizer.bypassSecurityTrustUrl(objectURL);
                        })
                    );
                };

                loadVisualCallback();

                this._imageEventService.imageCropped.subscribe((item: Image): void => {

                    if(item.id !== image.id){

                        return;
                    }

                    loadVisualCallback();
                });

                if(this.slideshow.formatUpdated$){

                    this.slideshow.formatUpdated$.subscribe((): void => {

                        loadVisualCallback();
                    });
                }
            }
            else {

                if(image.watermark){

                    this.imageCache[image.id] = of(this._imageService.getImageUrlWithNoCache(`${this._apiService.getApiUrl(false, false)}/public/cms/image/${image.id}/filter/${this.getSelectedFormat(image.formats)}/${this.width}/${this.quality}/${image.watermark.id}`)).pipe(
                        shareReplay(1)
                    );
                }
                else{

                    this.imageCache[image.id] = of(this._imageService.getImageUrlWithNoCache(`${this._apiService.getApiUrl(false, false)}/public/cms/image/${image.id}/filter/${this.getSelectedFormat(image.formats)}/${this.width}/${this.quality}`)).pipe(
                        shareReplay(1)
                    );
                }
            }
        }

        return this.imageCache[image.id];
    }

    public getImages(images): Image[] {

        return images.sort((a, b) => a.position > b.position);
    }

    public getHeightWidth(event) {

        event.target.width = event.target.naturalWidth;
    }
}
