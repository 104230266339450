import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {FormService} from "@core/shared/services/form.service";
import {OfferAccessRequestCreateConfiguration} from "@core/shared/models/offer/offer-access-request/offer-access-request-create";
import {Offer} from "@core/shared/models/offer";
import {OfferCatalogService} from "@core/shared/services/offer/offer-catalog.service";
import {OfferCatalog} from "@core/shared/models/offer/offer-catalog";
import {Society} from "@core/shared/models/society";
import {DEFAULT_MARKUP, MIN_MARKUP} from "@core/shared/models/data";
import {REGEX_PRICE} from "@core/shared/models/regex";
import {parseMarkup} from "@core/shared/utils/markup";
import {Observable} from "rxjs";
import {UserService} from "@core/shared/services/user.service";
import {HashtagService} from "@core/shared/services/hashtag.service";
import {User} from "@core/shared/models/user";
import {SelectSearchConfiguration} from "@core/components/select/select-search/select-search.component";
import {Hashtag, ShortHashtag} from "@core/shared/models/hashtag";
import {map} from "rxjs/operators";
import {Pagination} from "@core/shared/models/pagination";
import {TranslationService} from "@core/shared/services/translation.service";

@Component({
    selector: 'app-core-offer-access-request-create',
    templateUrl: './offer-access-request-create.component.html',
    styleUrls: ['./offer-access-request-create.component.scss'],
    providers: [
        FormService
    ]
})
export class OfferAccessRequestCreateComponent implements OnInit {

    @Input() configuration: OfferAccessRequestCreateConfiguration;

    @Input() createItemSourceCallback: (data: object) => Observable<OfferCatalog>;

    @Input() offer: Offer;

    @Input() society: Society;

    @Output() create: EventEmitter<{item: OfferCatalog}> = new EventEmitter<{item: OfferCatalog}>();

    public currentUser: User;

    public minMarkupPercent: number = MIN_MARKUP;

    public defaultMarkupPercent: number = DEFAULT_MARKUP;

    public hashtagSearchConfiguration: SelectSearchConfiguration<ShortHashtag>;

    public hashtagSearchSourceCallback: (search: string) => Observable<ShortHashtag[]>;

    constructor(
        private _formBuilder: UntypedFormBuilder,
        private _translationService: TranslationService,
        private _offerCatalogService: OfferCatalogService,
        private _hashtagService: HashtagService,
        public formService: FormService,
        public userService: UserService
    ) {}

    ngOnInit(): void {

        this.currentUser = this.userService.currentUser.getValue();

        this._initForm();

        if(this.configuration.enableMarkupSelection){

            this._initMarkup();
        }

        if(this.configuration.enableHashtagSelection && this.userService.hasAccess(this.currentUser, 'HASHTAG_LIST_IS_MINE')){

            this._initHashtags();
        }
    }

    private _initForm(): void {

        this.formService.form = this._formBuilder.group({
            offer: [this.offer.id, [Validators.required]]
        });

        this.formService.submitCallback = (): void => {

            const data: object = Object.assign(this.form.value, {
                offer: {
                    id: this.form.get('offer').value
                },
                markup: this.form.get('markup') ? (this.form.get('markup').value / 100) : null,
                hashtags: this.form.get('hashtags') ? (this.form.get('hashtags').value as Hashtag[]).map((item: Hashtag): { hashtag: Hashtag } => {

                    return {
                        hashtag: item
                    };

                }) : []
            });

            this.createItemSourceCallback(data).subscribe((item: OfferCatalog): void => {

                this._loadSelfOfferCatalogs();

                this.create.emit({item : item});
            });
        };
    }

    private _initMarkup(): void {

        this.form.addControl('markup', this._formBuilder.control(this.society.enableMarkup ? this.parsedMarkup(this.society.markup) : this.defaultMarkupPercent, [
            Validators.required,
            Validators.pattern(REGEX_PRICE),
            Validators.min(MIN_MARKUP)]
        ));
    }

    private _initHashtags(): void {

        this._hashtagService.getPaginationSocietyShortItemsAPI(this.currentUser.society.id, [`page=1`, 'limit=50']).subscribe((pagination: Pagination<ShortHashtag>): void => {

            if(!pagination.totalItems){

                return;
            }

            this.hashtagSearchConfiguration = {
                multiple: true,
                currentSelectionLabel: 'hashtag.selection.current.value',
                searchActionLabel: 'hashtag.search.action.value',
                selectOptionActionLabel: 'hashtag.option.select.action.value',
                itemLabel: (item: ShortHashtag): string => {

                    return this._translationService.getFallbackTranslation(item.translations).name;
                }
            }

            this.hashtagSearchSourceCallback = (search: string): Observable<ShortHashtag[]> => {

                const params: string[] = [
                    `page=1`,
                    'limit=50'
                ];

                if(search && search.length){

                    params.push(...[
                        `translations.name[lk]=${search}`
                    ]);
                }

                return this._hashtagService.getPaginationSocietyShortItemsAPI(this.currentUser.society.id, params).pipe(map((pagination: Pagination<ShortHashtag>): ShortHashtag[] => {

                    return pagination.items;
                }));
            }

            this.form.addControl('hashtags', this._formBuilder.control([]));
        });
    }

    private _loadSelfOfferCatalogs(): void {

        this._offerCatalogService.getItemsAPI().subscribe((items: OfferCatalog[]): void => {

            this._offerCatalogService.selfItems.next(items);
        });
    }

    public parsedMarkup(markup: number): string {

        return parseMarkup(markup * 100);
    }

    get form(): UntypedFormGroup {

        return this.formService.form;
    }
}
