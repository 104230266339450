import {Component, Input} from '@angular/core';
import {BreadcrumbItem} from '@core/shared/models/breadcrumb/breadcrumb-item';

@Component({
    selector: 'app-core-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent {

    @Input() items: BreadcrumbItem[];
}
