import {Injectable} from '@angular/core';
import {ApiService} from "@core/shared/services/api.service";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {Lockable} from "@core/shared/models/lockable";
import {LOCKABLE_RESOURCE_HEADER_IDENTIFIER} from "@core/shared/data/lockable";

@Injectable({
    providedIn: 'root'
})
export class LockableService {

    constructor(
        private _apiService: ApiService,
        private _httpClient: HttpClient
    ) {}

    public lockItemAPI(data: Lockable): Observable<void> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json')
            .set(LOCKABLE_RESOURCE_HEADER_IDENTIFIER, `${data.class}-${data.identifier}`);

        return this._httpClient.post<void>(`${this._apiService.getApiUrl(false, true)}/lock`, data, {
            headers: headers
        });
    }

    public unlockItemAPI(data: Lockable): Observable<void> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.delete<void>(`${this._apiService.getApiUrl(false, true)}/unlock`, {
            headers: headers,
            body: data
        });
    }
}
