import {AfterViewInit, Component, Input} from '@angular/core';
import {INavigationItem} from '@app/navigation/shared/models/navigation';
import {NavigationService} from "@navigation/shared/services/navigation.service";
import {NavigationEnd, Router} from "@angular/router";
import {filter} from "rxjs/operators";
import {SidenavService} from "@core/shared/services/sidenav.service";

@Component({
    selector: 'app-navigation-sidenav-item',
    templateUrl: './sidenav-item.component.html',
    styleUrls: ['./sidenav-item.component.scss']
})
export class SidenavItemComponent implements AfterViewInit {

    @Input() item: INavigationItem;

    @Input() disableImage: boolean = false;

    private _defaultOpenedPanelTagsInitialized: boolean = false;

    constructor(
        private _router: Router,
        private _navigationService: NavigationService,
        public sidenavService: SidenavService
    ) {}

    ngAfterViewInit(): void {

        this._router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd): void => {

            this.item.opened = event.url === this.item.path;

            if(this.hasChildren && this._navigationService.hasChildOpened(this.item)) {

                this.item.opened = true;
            }

            this._defaultOpenedPanelTagsInitialized = true;
        });
    }

    public toggle(path: string): void {

        const matDrawer : HTMLElement = document.querySelector('mat-drawer');

        if(matDrawer.classList.contains('open')){

            this.item.opened = !this.item.opened;

        }else{

            this.item.opened = true

        }

        if(path){

            this._router.navigate([path]);
        }
    }

    public toggleSidenav(): void {

        const matDrawer : HTMLElement = document.querySelector('mat-drawer');

        matDrawer.classList.add('open');

    }

    get hasChildren(): boolean {

        return this.item.children.length > 0;
    }

    get parentClasses(): { [p: string]: boolean } {

        return {
            'open' : this.item.opened,
            'highlighted' : this.item.highlighted
        };
    }

    public showTooltip(event : MouseEvent): void {

        const hoverElement : HTMLElement = event.target as HTMLElement;

        const tooltip : HTMLElement = hoverElement.nextElementSibling as HTMLElement;

        tooltip.style.opacity = '1';
        tooltip.style.visibility = 'visible';
        tooltip.style.top = (hoverElement.getBoundingClientRect().top + (hoverElement.clientHeight / 2)) + 'px';

    }

    public hideTooltip(event : MouseEvent): void {

        const hoverElement : HTMLElement = event.target as HTMLElement;

        const tooltip : HTMLElement = hoverElement.nextElementSibling as HTMLElement;

        tooltip.style.opacity = '0';
        tooltip.style.visibility = 'hidden';

    }

}
