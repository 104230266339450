<div class="overlay_title">
    <p class="x_center">{{ 'offer.list.filter.value' | translate }}</p>
    <span class="close_panel icon-tywin-plus" (click)="close.emit()"></span>
</div>

<!-- Langue -->

<ng-container *ngIf="articleSearchService.isFilterEnabled(ArticleFilterKey.Locale)">

    <app-core-checkbox-list-filter
        #localeFilter
        [builder]="filterBuilder"
        [label]="'locale.plural.value' | translate"
        [key]="ArticleFilterKey.Locale"
        [itemValueProperty]="'id'"
        [itemLabelProperty]="'label'"
        [items]="locales"
        [disableAutoSubmit]="true"
    ></app-core-checkbox-list-filter>

</ng-container>

<!-- Auteur -->

<ng-container *ngIf="articleSearchService.isFilterEnabled(ArticleFilterKey.Author)">

    <app-core-checkbox-list-filter
        #authorFilter
        [builder]="filterBuilder"
        [label]="'author.plural.value' | translate"
        [key]="ArticleFilterKey.Author"
        [itemValueProperty]="'id'"
        [itemLabelProperty]="'label'"
        [items]="authors"
        [disableAutoSubmit]="true"
        (valuesUpdated)="tywinAuthorFilterComponent.reset();selfAuthorFilterComponent.reset();"
    >
        <div beforeOptions class="col_12" style="margin-bottom: 16px">

            <div class="row">

                <!-- Mes publications -->

                <div class="col_12 option_full" *ngIf="articleSearchService.isFilterEnabled(ArticleFilterKey.SelfAuthor)">
                    <div class="option no_padding option_toggle">
                        <app-core-toggle-boolean-filter
                            #selfAuthorFilter
                            [builder]="filterBuilder"
                            [label]="'publication.self.value' | translate"
                            [key]="ArticleFilterKey.SelfAuthor"
                            [disableAutoHydrate]="true"
                            [disableAutoSubmit]="true"
                            [labelPosition]="'after'"
                            (click)="authorFilterComponent.resetValues();tywinAuthorFilterComponent.reset();"
                        ></app-core-toggle-boolean-filter>
                    </div>
                </div>

                <!-- Auteur Ty-Win -->

                <div class="col_12 option_full" *ngIf="articleSearchService.isFilterEnabled(ArticleFilterKey.TywinAuthor)">
                    <div class="option no_padding option_toggle">
                        <app-core-toggle-boolean-filter
                            #tywinAuthorFilter
                            [builder]="filterBuilder"
                            [label]="'article.author.tywin.value' | translate"
                            [key]="ArticleFilterKey.TywinAuthor"
                            [disableAutoHydrate]="true"
                            [disableAutoSubmit]="true"
                            [labelPosition]="'after'"
                            (click)="authorFilterComponent.resetValues();selfAuthorFilterComponent.reset();"
                        ></app-core-toggle-boolean-filter>
                    </div>
                </div>

            </div>

        </div>

    </app-core-checkbox-list-filter>

</ng-container>

<!-- Destination -->

<ng-container *ngIf="articleSearchService.isFilterEnabled(ArticleFilterKey.Region)">

    <app-core-checkbox-list-filter
        #regionFilter
        [builder]="filterBuilder"
        [label]="'destination.plural.value' | translate"
        [key]="ArticleFilterKey.Region"
        [itemValueProperty]="'id'"
        [itemLabelProperty]="'label'"
        [items]="regions"
        [disableAutoSubmit]="true"
    ></app-core-checkbox-list-filter>

</ng-container>

<!-- Thématique -->

<ng-container *ngIf="articleSearchService.isFilterEnabled(ArticleFilterKey.Theme)">

    <app-core-checkbox-list-filter
        #themeFilter
        [builder]="filterBuilder"
        [label]="'offer.list.filter.themes.value' | translate"
        [key]="ArticleFilterKey.Theme"
        [itemValueProperty]="'id'"
        [itemLabelProperty]="'label'"
        [items]="themes"
        [disableAutoSubmit]="true"
    ></app-core-checkbox-list-filter>

</ng-container>

<!-- Statut -->

<ng-container *ngIf="articleSearchService.isFilterEnabled(ArticleFilterKey.Status)">

    <app-core-checkbox-list-filter
        #statusFilter
        [builder]="filterBuilder"
        [label]="'publication.status.value' | translate"
        [key]="ArticleFilterKey.Status"
        [itemValueProperty]="'id'"
        [itemLabelProperty]="'label'"
        [items]="statusItems"
        [disableAutoSubmit]="true"
    ></app-core-checkbox-list-filter>

</ng-container>

<div class="date-filter" *ngIf="articleSearchService.isFilterEnabled(ArticleFilterKey.UpdatedAt) || articleSearchService.isFilterEnabled(ArticleFilterKey.PublishedAt)">
    <ng-container *ngIf="articleSearchService.isFilterEnabled(ArticleFilterKey.UpdatedAt)">

        <div class="filters_list">
            <p class="title">{{ 'filter.dateInterval.maj.value' | translate }}</p>
            <app-core-date-interval-filter
                #updatedAtFilter
                [builder]="filterBuilder"
                [key]="ArticleFilterKey.UpdatedAt"
                [startOperator]="'gte'"
                [endOperator]="'lte'"
                [disableAutoHydrate]="true"
                [disableAutoSubmit]="true"
                [displayFilterAction]="false"
                [displayResetAction]="true"
                [startPlaceholder] ="'filter.dateInterval.maj.value' | translate"
                [endPlaceholder] = "''">
            </app-core-date-interval-filter>
        </div>

    </ng-container>

    <ng-container *ngIf="articleSearchService.isFilterEnabled(ArticleFilterKey.PublishedAt)">

        <div class="filters_list">
            <p class="title">{{ 'publication.date.value' | translate }}</p>
            <app-core-date-interval-filter
                #publishedAtFilter
                [builder]="filterBuilder"
                [key]="ArticleFilterKey.PublishedAt"
                [startOperator]="'gte'"
                [endOperator]="'lte'"
                [disableAutoHydrate]="true"
                [disableAutoSubmit]="true"
                [displayFilterAction]="false"
                [displayResetAction]="true"
                [startPlaceholder] ="'publication.date.value' | translate"
                [endPlaceholder] = "''">
            </app-core-date-interval-filter>
        </div>

    </ng-container>
</div>

<div class="actions">

    <div class="row marges x_center">

        <div class="col_content y_items_center">
            <button type="button" class="icon-tywin-reset reset_filters" (click)="reset()">
                {{ 'form.field.action.reset' | translate }}
            </button>
        </div>
        <div class="col_content y_items_center">
            <button type="button" class="btn_cta btn_lines btn_little" (click)="filter()">
                <span>{{ 'result.plural.display.action.value' | translate }}</span>
            </button>
        </div>

    </div>

</div>
