<div class="content_image" *ngIf="image.link">
    <a href="{{ image.link }}" target="_blank">
        <ng-container *ngTemplateOutlet="content"></ng-container>
    </a>
</div>

<div class="content_image" *ngIf="!image.link">
    <ng-container *ngTemplateOutlet="content"></ng-container>
</div>

<ng-template #content>

    <img *ngIf="image && image.id" [src]="sourceUrl" [alt]="(image.alt) ? image.alt : ''" [title]="(image.alt) ? image.alt : '' " />

    <div *ngIf="image.title || image.subtitle " class="texte-visuel">
        <p *ngIf="image.title" class="titre">{{ image.title }}</p>
        <p *ngIf="image.subtitle" class="soustitre">{{ image.subtitle }}</p>
    </div>

</ng-template>



