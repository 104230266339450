<div class="page_title_list">
    <div class="row marges">
        <div class="col_content y_items_center">
            <h1 class="title">{{ 'trend.management.value' | translate }}</h1>
        </div>

        <div class="col y_items_center">
            <div class="form-group list_search">
                <input #searchContent type="text" id="search-content" placeholder=" ">
                <label for="search-content">{{ 'article.filter.content.value' | translate }}</label>
                <span class="icon-tywin-search"></span>
                <span class="icon-tywin-plus clear_input" (click)="clearSearchInput()"></span>
            </div>
        </div>

        <ng-container *ngIf="userService.hasOneOfTheseAccesses(currentUser, ['ARTICLE_CREATE', 'ARTICLE_CREATE_IS_MINE'])">

            <div class="col_content">
                <button (click)="createItem()" class="btn_cta btn_highlight btn_little btn_add">{{ 'article.add.action.value' | translate }}</button>
            </div>

        </ng-container>

        <ng-container *ngIf="userService.hasOneOfTheseAccesses(currentUser, ['ARTICLE_CREATE_IS_MINE']) && currentUser.society.countAvailableArticles">

            <div class="licences">
                <span>{{ currentUser.society.countAvailableArticles | translationFromQuantity: null : 'article.available.singular.count.value' : 'article.available.plural.count.value' }} {{ displayAvailableArticlesDetail ? (' : ' + countAvailableArticlesDetail) : '' }}</span>
            </div>

        </ng-container>
    </div>
</div>

<div class="bloc_list">

    <div class="list_filters">

        <div class="filters" #filters>

            <app-core-article-filter (openSidePanelOverlay)="openSidePanelOverlay()"></app-core-article-filter>

            <div class="panel_right_container">
                <app-core-overlay [ngStyle]="sidePanelOverlayContainerStyles" [target]="filters" [styles]="sidePanelOverlayStyles(filters)" (closed)="closeSidePanelOverlay()">

                    <app-core-article-filter-panel (submit)="closeSidePanelOverlay()" (close)="closeSidePanelOverlay()"></app-core-article-filter-panel>

                </app-core-overlay>
            </div>

        </div>

        <div class="filters_checked">

            <ng-container *ngFor="let filter of currentFilters">

                <ng-container *ngIf="filter.formattedValue">
                    <span class="filter">
                        <span>{{ filter.formattedValue }}</span>
                        <span class="icon-tywin-plus close" (mousedown)="removeFilter(filter.field)"></span>
                    </span>
                </ng-container>

            </ng-container>

        </div>

    </div>

    <div class="list_items">
        <div class="row marges">
            <div class="col_12 col_md_6 col_lg_4 content_card" *ngFor="let item of items">
                <app-core-article-card [item]="item" [mode]="'view'"></app-core-article-card>
            </div>
        </div>
        <div class="row more_offer" *ngIf="sourceLoaded && hasMoreItems">
            <div class="col x_center">
                <button class="btn_cta btn_highlight" type="button" (click)="loadMoreItems()">
                    {{ 'article.more.value' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>
