<div class="page_service_subscription">

    <div class="back_to_services">
        <span class="ty-chevron" (click)="handleBackToServices()">{{ 'services.list.item.action.service.back.value' | translate }}</span>
    </div>

    <form [formGroup]="form" (ngSubmit)="formService.submit()">

        <ng-container *ngIf="societyServiceSummaries">

            <ng-container *ngTemplateOutlet="serviceSummary"></ng-container>

            <ng-container *ngTemplateOutlet="societyServiceForm"></ng-container>

            <ng-container *ngIf="isValidBilling">

                <ng-container *ngTemplateOutlet="billingSummary"></ng-container>

                <ng-container *ngTemplateOutlet="cardForm"></ng-container>

            </ng-container>

            <ng-container *ngIf="cardAlreadyRegistered; else cardToRegisterTemplate">

                <ng-container *ngIf="(card$ | async) as card">

                    <div class="row marges">
                        <div class="col_6">
                            <div class="create_card_information">
                                <p [innerHTML]="'services.subscription.creditCard.description.value' | translate:{ cardNumber: card.cardNumber, cardExpiryDate: (card.expirationDate | slice:0:2) + '/' + (card.expirationDate | slice:2:4) }"></p>
                            </div>
                            <br>
                        </div>
                    </div>

                </ng-container>

            </ng-container>

            <ng-template #cardToRegisterTemplate>

                <div class="row marges">
                    <div class="col_6">
                        <div class="create_card_information">
                            <p [innerHTML]="'services.subscription.noCreditCard.description.value' | translate"></p>
                        </div>
                        <br>
                    </div>
                </div>

            </ng-template>

            <ng-container *ngTemplateOutlet="termsAndConditions"></ng-container>

            <div class="row ligne_form">
                <div class="col_content">
                    <div class="form-group">
                        <button type="submit" class="btn_cta btn_little btn_validate_paiement" [disabled]="form.invalid || submitLoading">{{ paymentLabel | translate }}</button>
                    </div>
                </div>
            </div>

        </ng-container>

    </form>
</div>

<ng-template #serviceSummary [formGroup]="form">

    <div class="row marges ligne_form">
        <div class="col_6">

            <div class="recap_service">

                <p class="title"> {{ serviceTranslation.name }}</p>

                <!-- Récapitulatif du service    -->

                <div class="ck-content serviceContent" [innerHTML]="serviceTranslation.paymentDescription"></div>

                <ng-container *ngIf="warningContent">

                    <div class="create_card_information failed col_12">
                        <p>{{ warningContent }}</p>
                    </div>

                </ng-container>

            </div>

        </div>
    </div>

</ng-template>

<ng-template #billingSummary>

   <ng-container *ngIf="hasInformationsToShow">

       <div class="recap">

           <div class="ck-content">

               <!-- Récapitulatif du service -->

               <ng-container *ngIf="service.isWithSubscription">

                   <!--  L’utilisateur a déjà souscrit à un service dans la périodicité sélectionné -->

                   <ng-container *ngIf="hasServicesInPeriodicity(periodicity)">

                       <p [innerHTML]=" 'services.prorata.' + (periodicity === 'Y' ? 'yearly': 'monthly') + '.value' | translate:{ date: servicesInPeriodicity(periodicity).dateNextInvoice | date : 'shortDate' : null : localeId }"></p>

                   </ng-container>

               </ng-container>

               <!-- Si le montant à payer est de 0 et que l'utilisateur n'a pas de carte => on prélève 1 euros -->

               <div class="card_warning" *ngIf="(selectedSocietyServiceSummary && selectedSocietyServiceSummary.total === 0) && !cardAlreadyRegistered">

                   <p>{{ 'services.total_null.value' | translate }}</p>

               </div>

           </div>

       </div>

   </ng-container>

</ng-template>

<ng-template #societyServiceForm [formGroup]="form">

    <div class="row marges ligne_form">
        <div class="col_6">

            <div class="recap">

                <ng-container *ngIf="service.min !== service.max">

                    <!-- Choix de la quantité -->

                    <div class="subscription_quantity">
                        <div class="row marges ligne_form">

                            <div class="col_12 col_md_6">
                                <div class="form-group">
                                    <select class="form-control" id="quantity" formControlName="quantity" required="required">
                                        <option [ngValue]="null"></option>
                                        <option *ngFor="let quantity of quantities;" [ngValue]="quantity">
                                            {{ quantity }}
                                        </option>
                                    </select>
                                    <label class="required" for="quantity">{{ 'quantity.value' | translate }}</label>
                                    <mat-error *ngIf="form.get('quantity').errors && (form.get('quantity').dirty || form.get('quantity').touched)">
                                        <div class="invalid-feedback">
                                            <div *ngIf="form.get('quantity').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                        </div>
                                    </mat-error>
                                </div>
                            </div>

                        </div>
                    </div>

                </ng-container>

            </div>

            <div class="subscription_frequency_tiles_container">

                <div class="row marges step_content step_form">

                    <div class="col_12 form-group-expanded">

                        <div class="row marges subscription_frequency_tiles_row">

                            <ng-container *ngFor="let societySummary of societyServiceSummaries; let i = index">

                                <div class="col_12 col_md_6 subscription_frequency_tile_wrapper ">

                                    <div class="subscription_frequency_tile" [ngClass]="(!periodicityControl || societySummary.periodicity === periodicityControl.value ) ? 'tile_checked' : ''" (click)="checkPeriodicity(societySummary.periodicity)">

                                        <ng-container *ngIf="periodicityControl">

                                            <div class="radio">
                                                <input type="radio" value="{{ societySummary.periodicity }}" formControlName="periodicity" class="form-control" id="periodicity-{{ (societySummary.periodicity === 'Y' ? 'year': 'month') }}">
                                                <label [for]="'periodicity-'+ (societySummary.periodicity === 'Y' ? 'year': 'month')">{{'form.user.subscription.fields.periodicity.'+ (societySummary.periodicity === 'M' ? 'month': 'year') +'.value' | translate}}</label>
                                            </div>

                                        </ng-container>

                                        <div class="tile_top">
                                            <span class="ty-check"></span>

                                            <ng-container *ngIf="societySummary.service.isWithSubscription">

                                                <span class="freq_name">{{'services.subscription.frequency.' + (societySummary.periodicity === "M" ? 'monthly': 'yearly') + '.value' | translate}}</span>
                                                <p>{{ 'services.subscription.frequency.' + (societySummary.periodicity === "M" ? 'monthly': 'yearly') + '.description.value' | translate }}</p>

                                            </ng-container>

                                            <ng-container *ngIf="!societySummary.service.isWithSubscription">

                                                <span class="freq_name">{{'services.subscription.frequency.oneshot.value' | translate}}</span>
                                                <p>{{ 'services.subscription.frequency.oneshot.description.value' | translate }}</p>

                                            </ng-container>


                                        </div>

                                        <div class="tile_bottom">

                                            <div class="prices">

                                                <div class="price_item row marges">

                                                    <div class="col label ">{{ 'services.admissionPrice.value' | translate }}</div>

                                                    <div class="col_content price">

                                                        {{ formatPrice(getAdmissionPrice(i), 'TTC') }}
                                                        <span class="price_ht">({{ formatPrice(societySummary.admissionPrice, 'HT') }})</span>

                                                    </div>

                                                </div>

                                                <ng-container *ngIf="societySummary.hasPromotionAdmissionPrice">


                                                    <div class="price_item row marges">

                                                        <div class="col_label promotion" *ngIf="societySummary.admissionPrice !== societySummary.promotionAdmissionPrice">
                                                            {{ 'services.promotion.admissionPrice.value' | translate: { promotion: societySummary.promotionService.code} }}
                                                        </div>

                                                        <div class="col_label promotion" *ngIf="societySummary.admissionPrice === societySummary.promotionAdmissionPrice">
                                                            {{ 'services.promotion.admissionPrice.free.value' | translate : {promotion: societySummary.promotionService.code } }}
                                                        </div>

                                                        <div class="col_content price promotion" *ngIf="societySummary.admissionPrice !== societySummary.promotionAdmissionPrice">

                                                            -{{ formatPrice(societySummary.promotionAdmissionPrice * (1 + societySummary.vatPercent), 'TTC') }}
                                                            <span class="price_ht">(-{{ formatPrice(societySummary.promotionAdmissionPrice, 'HT') }})</span>

                                                        </div>

                                                    </div>

                                                </ng-container>

                                                <ng-container *ngIf="societySummary.service.isWithSubscription">

                                                    <div class="price_item row marges">

                                                        <div class="col label ">{{ 'services.periodicity.first.' + (societySummary.periodicity === "M" ? 'monthly': 'yearly')  +'.value' | translate }} </div>

                                                        <div class="col_content price">

                                                            {{ formatPrice(societySummary.price * (1 + societySummary.vatPercent), 'TTC') }}

                                                            <span class="price_ht">({{ formatPrice(societySummary.price, 'HT') }})</span>

                                                        </div>

                                                    </div>

                                                </ng-container>


                                            </div>

                                            <span>{{ ((societySummary.hasPromotionAdmissionPrice || societySummary.hasPromotionPrice) ? 'services.payment.total_promotion.value' : 'services.payment.total.value') | translate }}</span>

                                            <div class="prices">

                                                <div class="price_item row marges">

                                                    <div class="col label ">{{ 'services.subscription.frequency.pay_today.value' | translate }}</div>

                                                    <div class="col_content price">

                                                        {{ getAmountTTC(i) }}

                                                        <span class="price_ht">({{ formatPrice(societySummary.total, 'HT') }})</span>
                                                    </div>

                                                </div>

                                                <div class="price_item row marges">

                                                    <div class="col label ">{{ 'tva.amount.value' | translate: {percent: societySummary.vatPercent * 100}  }}</div>

                                                    <div class="col_content price">

                                                        <span class="current_price">{{ (societySummary.total * (societySummary.vatPercent / 100)) | parsePrice }}€ </span>

                                                    </div>

                                                </div>

                                                <ng-container *ngIf="societySummary.hasPromotionPrice">

                                                    <!-- Promotion sur les abonnements -->
                                                    <div class="price_item row marges">

                                                        <div class="col_label promotion">
                                                            {{ 'services.promotion.subscriptionPrice.value' | translate: {priceTTC: formatPrice(societySummary.promotionPrice * (1 + societySummary.vatPercent), 'TTC'), priceHT: formatPrice(societySummary.promotionPrice, 'HT')  } }}
                                                        </div>

                                                    </div>

                                                </ng-container>

                                            </div>

                                            <ng-container *ngIf="societySummary.service.isWithSubscription">

                                                <span>{{'services.payment.total.'+ (societySummary.periodicity === "M" ? 'monthly': 'yearly') + '.value' | translate}}</span>

                                                <div class="prices">

                                                    <div class="price_item row marges">

                                                        <div class="col label ">{{ 'services.form.price.' + (societySummary.periodicity === "M" ? 'monthly': 'yearly') + '.value' | translate }}</div>

                                                        <div class="col_content price">

                                                            <!-- Prix de l'abonnement mensuel ou annuel -->

                                                            {{ formatPrice( calculatePrice(societySummary, 'TTC'), 'TTC') }}

                                                            <span class="price_ht">({{ formatPrice( calculatePrice(societySummary, 'HT'), 'HT') }})</span>

                                                        </div>

                                                    </div>

                                                    <div class="price_item row marges">

                                                        <div class="col label ">{{ 'tva.amount.value' | translate: {percent: societySummary.vatPercent * 100}  }}</div>

                                                        <div class="col_content price">

                                                            <span class="current_price">{{ calculateTVA(societySummary) | parsePrice }}€ </span>

                                                        </div>

                                                    </div>

                                                </div>

                                                <!-- Promotion sur les abonnements -->
                                                <ng-container *ngIf="societySummary.recurringPromotionPrice && !(societySummary.recurringPromotionDuration === 0)">

                                                    <div class="price_item row marges">

                                                        <div class="col_label promotion">

                                                            {{ 'services.promotion.subscriptionPrice.value' | translate: {priceTTC: formatPrice(societySummary.recurringPromotionPrice * (1 + societySummary.vatPercent), 'TTC'), priceHT: formatPrice(societySummary.recurringPromotionPrice, 'HT')  } }}

                                                            <!-- Promotion égale à 1 mois/an -->
                                                            <ng-container *ngIf="societySummary.recurringPromotionDuration === 1">
                                                                {{ 'services.promotion.subscriptionPrice.' + (societySummary.periodicity === 'M' ? 'monthly' : 'yearly') + '.unique.value' | translate: { count: societySummary.recurringPromotionDuration } }}
                                                            </ng-container>

                                                            <!-- Promotion supérieure à 1 mois/an -->
                                                            <ng-container *ngIf="societySummary.recurringPromotionDuration > 1">
                                                                {{ 'services.promotion.subscriptionPrice.' + (societySummary.periodicity === 'M' ? 'monthly' : 'yearly') + '.value' | translate: { count: societySummary.recurringPromotionDuration } }}
                                                            </ng-container>

                                                            <!-- Promotion à vie -->
                                                            <ng-container *ngIf="societySummary.recurringPromotionDuration === null">
                                                                {{ 'services.promotion.subscriptionPrice.lifetime.value' | translate }}
                                                            </ng-container>

                                                        </div>

                                                    </div>
                                                </ng-container>

                                            </ng-container>

                                        </div>

                                    </div>
                                </div>

                            </ng-container>


                            <ng-container *ngIf="form.get('periodicity')">
                                <mat-error *ngIf="form.get('periodicity').errors && (form.get('periodicity').dirty || form.get('periodicity').touched)">-->
                                    <div class="invalid-feedback">
                                        <div *ngIf="form.get('periodicity').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                    </div>
                                </mat-error>
                            </ng-container>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>


</ng-template>

<ng-template #cardForm [formGroup]="form">

    <!-- Carte bancaire à saisir -->

    <ng-container *ngIf="!cardAlreadyRegistered && creditCardControl">

        <div class="payment_form" formArrayName="creditCard">

            <div class="row marges payment_methods x_center">
                <div class="col_content">
                    <span class="method_item cb"></span>
                </div>
                <div class="col_content">
                    <span class="method_item visa"></span>
                </div>
                <div class="col_content">
                    <span class="method_item mastercard"></span>
                </div>
                <div class="col_content">
                    <span class="method_item maestro"></span>
                </div>
            </div>

            <div class="row marges ligne_form">
                <div class="col_12">
                    <div class="form-group">
                        <input type="text" number-card class="form-control" formControlName="cardNumber" id="cardNumber" required="required" [control]="creditCardControl.get('cardNumber')">
                        <label for="cardNumber">{{'payment.fields.cardNumber.value' | translate}}</label>
                        <mat-error
                            *ngIf="creditCardControl.get('cardNumber').errors && (creditCardControl.get('cardNumber').dirty || creditCardControl.get('cardNumber').touched)">
                            <div class="invalid-feedback">
                                <div *ngIf="creditCardControl.get('cardNumber').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                <div *ngIf="creditCardControl.get('cardNumber').errors.invalidNUmberCard">{{ 'payment.fields.error.invalidNUmberCard.value' | translate }}</div>

                            </div>
                        </mat-error>
                    </div>
                </div>
            </div>

            <div class="row marges ligne_form">
                <div class="col_6">
                    <div class="form-group">
                        <input type="text" class="form-control" date-card formControlName="cardExpirationDate" id="cardExpirationDate" required="required" [control]="creditCardControl.get('cardExpirationDate')">
                        <label for="cardExpirationDate">{{'payment.fields.cardExpirationDate.value' | translate}}</label>
                        <mat-error
                            *ngIf="creditCardControl.get('cardExpirationDate').errors && (creditCardControl.get('cardExpirationDate').dirty || creditCardControl.get('cardExpirationDate').touched)">
                            <div class="invalid-feedback">
                                <div *ngIf="creditCardControl.get('cardExpirationDate').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                <div *ngIf="creditCardControl.get('cardExpirationDate').errors.invalidFormatDate">{{ 'payment.fields.error.invalidFormatDate.value' | translate }}</div>
                                <div *ngIf="creditCardControl.get('cardExpirationDate').errors.invalidDatePast">{{ 'payment.fields.error.invalidDatePast.value' | translate }}</div>

                            </div>
                        </mat-error>
                    </div>
                </div>
                <div class="col_6">
                    <div class="form-group">
                        <input type="text" class="form-control" cvv-card formControlName="cardCvx" id="cardCvx" required="required" [control]="creditCardControl.get('cardCvx')">
                        <label for="cardCvx">{{'payment.fields.cardCvx.value' | translate}}</label>
                        <mat-error *ngIf="creditCardControl.get('cardCvx').errors && (creditCardControl.get('cardCvx').dirty || creditCardControl.get('cardCvx').touched)">
                            <div class="invalid-feedback">
                                <div *ngIf="creditCardControl.get('cardCvx').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                <div *ngIf="creditCardControl.get('cardCvx').errors.invalidFormatCvv">{{ 'payment.fields.error.invalidFormatCvv.value' | translate }}</div>
                            </div>
                        </mat-error>
                    </div>
                </div>
            </div>

        </div>

        <ng-container *ngIf="!hasRole('ROLE_OFFER_CREATOR')">
            <p class="card_infos">{{'services.payment.card.information.value' | translate}}</p>
        </ng-container>

    </ng-container>

</ng-template>

<ng-template #termsAndConditions [formGroup]="form">

    <div class="ligne_form">
        <div class="form-group">
            <div class="checkbox">
                <input type="checkbox" id="acceptTermsAndConditions" formControlName="acceptTermsAndConditions">
                <label for="acceptTermsAndConditions">{{'acceptTermsAndConditions.success.action.description.value' | translate}}
                    <a [href]="termsAndConditionsService.getItemAPIUrl(localeId)" target="_blank">{{ 'acceptTermsAndConditions.value' | translate }}</a></label>
                <mat-error
                    *ngIf="form.get('acceptTermsAndConditions').errors && (form.get('acceptTermsAndConditions').dirty || form.get('acceptTermsAndConditions').touched)">
                    <div class="invalid-feedback">
                        <div *ngIf="form.get('acceptTermsAndConditions').errors.required">{{ 'acceptTermsAndConditions.form.invalid.value' | translate }}</div>
                    </div>
                </mat-error>
            </div>
        </div>
    </div>

</ng-template>
