import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ColumnService} from "./column.service";
import {IColumn, IColumnBackgroundColor} from "./column";
import {TranslateService} from "@ngx-translate/core";
import {IElement} from "../element/element";
import {ElementService} from "../element/element.service";
import {ITemplate} from "../template/template";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ConfirmDialogData} from "@lib/confirm-dialog/confirm-dialog";
import {ConfirmDialogComponent} from "@lib/confirm-dialog/confirm-dialog.component";
import {CmsService} from "@lib/cms/cms.service";

@Component({
  selector: 'app-column',
  templateUrl: './column.component.html',
  styleUrls: ['./column.component.scss']
})
export class ColumnComponent implements OnInit {

    @Input() column: IColumn;

    @Input() template: ITemplate;

    @Input() isDecreaseAllowed: boolean;

    @Input() isIncreaseAllowed: boolean;

    @Output() decreasePosition = new EventEmitter();

    @Output() increasePosition = new EventEmitter();

    public backgroundColors: IColumnBackgroundColor[] = [];

    constructor(
        private _translateService: TranslateService,
        private _dialog: MatDialog,
        private _columnService: ColumnService,
        private _elementService: ElementService,
        private _snackbar: MatSnackBar,
        public cmsService: CmsService
    ) {
    }

    /**
     * Initialisation du composant
     */
    ngOnInit() {
        this.backgroundColors = this._columnService.getBackgroundColors();
    }

    /**
     * Utilisé pour afficher la couleur de fond sélectionnée dans le déroulant
     *
     * @param {IElementBackgroundColor} first
     * @param {IElementBackgroundColor} second
     * @returns {boolean}
     */
    public compareByOptionTag(first: IColumnBackgroundColor, second: IColumnBackgroundColor): boolean {

        if(!first && !second)
            return true;

        return first && second && first.tag === second.tag;
    }

    /**
     * Retourne le style de la colonne
     *
     * @returns {any}
     */
    public getColumnStyle(): object {

        return {
            'width' : (this.column.colWidth * 100 / 12) + '%'
        }
    }

    /**
     * Ajout d'un élément (en dernière position)
     *
     */
    public addElement(): void {

        this._columnService.addElement(this.column);
    }

    public resetContent(): void {

        this._translateService.get(['column.reset.confirm.title', 'column.reset.confirm.content']).subscribe((messages: string[]) => {

            const data: ConfirmDialogData = {
                title : messages['column.reset.confirm.title'],
                content: messages['column.reset.confirm.content']
            };

            const dialogRef: MatDialogRef<ConfirmDialogComponent> = this._dialog.open(ConfirmDialogComponent, {
                width: '500px',
                data: data
            });

            dialogRef.componentInstance.confirm.subscribe((): void => {

                this._columnService.clearColumn(this.column);

                this._translateService.get(['column.reset.success', 'action.close']).subscribe((messages: string[]) => {
                    this._snackbar.open(messages['column.reset.success'], messages['action.close']);
                });
            });
        });
    }

    /**
     * Décrémentation de la position d'un élément
     * Sur l'interface le type ainsi que contenu de l'élement est donc décalé vers la gauche
     */
    public decreaseContentElement(element: IElement): void {

        this._elementService.decreaseContentElement(this.column, element);
    }

    /**
     * Incrémentation de la position d'une élément
     * Sur l'interface le type ainsi que contenu de l'élement est donc décalé vers la droite
     */
    public increaseContentElement(element: IElement): void {

        this._elementService.increaseContentElement(this.column, element);
    }

    /**
     * Détermine si un élément peut être décalé vers la gauche
     *
     * @returns {boolean}
     */
    public isDecreaseElementAllowed(element: IElement): boolean {

        return this._elementService.isDecreaseElementAllowed(this.column, element);
    }

    /**
     * Détermine si un élément peut être décalé vers la droite
     *
     * @returns {boolean}
     */
    public isIncreaseElementAllowed(element: IElement): boolean {

        return this._elementService.isIncreaseElementAllowed(this.column, element);
    }

    /**
     * Suppression d'un élément
     *
     * @param element
     */
    public deleteElement(element: IElement): void {

        this._translateService.get(['element.delete.confirm.title', 'element.delete.confirm.content']).subscribe((messages: string[]) => {

            const data: ConfirmDialogData = {
                title : messages['element.delete.confirm.title'],
                content: messages['element.delete.confirm.content']
            };

            const dialogRef: MatDialogRef<ConfirmDialogComponent> = this._dialog.open(ConfirmDialogComponent, {
                width: '500px',
                data: data
            });

            dialogRef.componentInstance.confirm.subscribe((): void => {

                this._elementService.deleteElement(this.column, element);

                this._translateService.get(['element.delete.success', 'action.close']).subscribe((messages: string[]) => {
                    this._snackbar.open(messages['element.delete.success'], messages['action.close']);
                });
            });
        });
    }
}
