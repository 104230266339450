import {
    AfterViewChecked,
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from '@angular/core';
import {TranslationService} from "./translation/translation.service";
import {TemplateService} from "./template/template.service";
import {ElementService} from "./element/element.service";
import {ICustomButton} from "./element/element";
import {Config, Data, ICmsParams} from "./cms";
import {ApiService} from "./api/api.service";
import {CmsService} from "./cms.service";
import {AbstractControl} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";
import {HttpErrorResponse} from "@angular/common/http";
import {TextService} from "./element/type/text/text.service";
import {ColumnService} from "./column/column.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {IAllowedType, ITemplate} from "@lib/cms/template/template";
import {IContent} from "@lib/cms/content/content";
import {CmsContentService} from "@core/shared/services/cms/cms-content.service";

@Component({
    selector: 'cms-root',
    templateUrl: './cms.component.html',
    styleUrls: [
        './cms.component.scss'
    ],
    encapsulation: ViewEncapsulation.None
})

/**
 * Point d'entrée de l'application Angular
 */
export class CmsComponent implements OnInit, AfterViewChecked, OnDestroy {

    @Input() params: ICmsParams;

    @Input() contentFormControl: AbstractControl;

    @Input() data: Data;

    public initialized: boolean = false; // Le contenu de la page CMS n'est visible que lorsque initialized = TRUE

    constructor(
        private _apiService: ApiService,
        private _cmsService: CmsService,
        private _elementService: ElementService,
        private _columnService: ColumnService,
        private _translationService: TranslationService,
        private _templateService: TemplateService,
        private _translateService: TranslateService,
        private _cmsContentService: CmsContentService,
        private _textService: TextService,
        private _snackbar: MatSnackBar,
        private _elementRef: ElementRef
    ) {
    }

    public ngOnInit(): void {

        this._apiService.setApiUrl(this.params.apiUrl);

        this._cmsService.setEnableMultilingual(this.params.enableMultilingual);

        this._cmsService.setContext(this.params.context);

        this._cmsService.setBlocLabel(this.params.blocLabel);

        this._cmsService.setEntityName(this.params.entityName);

        this._cmsService.setEntityFieldName(this.params.entityFieldName);

        this._cmsService.setOptionsImage(this.params.optionsImage);

        this._cmsService.setColumnParams(Object.assign(this._cmsService.getColumnParams(), this.params.columnParams || {}));

        this.params.customButtons.map((customButton: ICustomButton): void => {

            this._elementService.addCustomButton(customButton);
        });

        if (!this.data) {

            const contentData = {
                id: this.params.tag,
                translations: [],
                context: this.params.context,
                entityName: this.params.entityName,
                entityFieldName: this.params.entityFieldName,
            };

            this.data = {
                content: contentData,
                config: null
            };
        }

        this.contentFormControl.patchValue(this.data.content);

        // Configuration de la page

        this._cmsService.setContent(this.data.content); // Mise à jour du contenu de la page

        this.params.configSource.subscribe((data: Config): void => {

            this._initConfig(data, this.data.content);

            this.initialized = true; // Une fois le contenu de la page chargé, on peut l'afficher

        }, (responseError: HttpErrorResponse): void => {

            this._translateService.get(['action.close']).subscribe((messages: string[]): void => {

                this._snackbar.open(responseError.error, messages['action.close']);
            });
        });
    }

    public ngAfterViewChecked(): void {

        this._manageTemplateContainer(false);
    }

    public ngOnDestroy(): void {

        this._manageTemplateContainer(true);
    }

    private _manageTemplateContainer(reset: boolean): void {

        const parent: HTMLElement = this.params.parentContainer;

        if ((typeof parent === 'undefined')) {

            return;
        }

        const elementInDOM: boolean = document.body.contains(document.querySelector(this._elementRef.nativeElement.tagName.toLowerCase()));

        switch (true) {

            case elementInDOM && reset:

                parent.style.paddingBottom = '0';

                break;

            case elementInDOM && !reset:

                const element: HTMLElement = document.getElementById('liste-templates');

                if(element){

                    parent.style.paddingBottom = element.clientHeight + "px";
                }

                break;

            default:

                parent.style.paddingBottom = '0';
        }
    }

    private _initConfig(config: Config, content: IContent): void {

        // Mise à jour de la langue par défaut
        this._translationService.setDefaultLocale(config.defaultLocale);

        // Mise à jour de la langue affichée

        if(content.translations.length){

            this._translationService.setCurrentLocale(content.translations[0].locale);
        }

        this._cmsContentService.updateCurrentLocale$.subscribe((locale: string): void => {

            this._translationService.setCurrentLocale(locale);
        });

        // Mise à jour des langues disponible sur le site
        this._translationService.setAvailableLocales(config.locales);

        // Mise à jour des templates

        if(this.params.templateContext){

            const templateContextConfiguration: { [p: string]: { col: number[], types: string[] } } = config.contexts[this.params.templateContext];

            const templates: ITemplate[] = config.templates.filter((template: ITemplate): boolean => {

                return Object.keys(templateContextConfiguration).includes(template.id);
            });

            this._templateService.setTemplates(templates.map((template: ITemplate): ITemplate => {

                return Object.assign(template, {
                    allowedTypes: template.allowedTypes.filter((allowedType: IAllowedType): boolean => {

                        return templateContextConfiguration[template.id].types.includes(allowedType.id);
                    })
                } as Partial<ITemplate>);
            }));
        }
        else{

            this._templateService.setTemplates(config.templates);
        }

        // Mise à jour de la configuration des éléments
        this._elementService.setConfig(config.element);

        // Mise à jour de la configuration des éléments
        this._columnService.setConfig(config.column);

        // Mise à jour de la configuration des textes
        this._textService.editor = this.params.ckeditor.editor;

        this._textService.ckeditorConfig = this.params.ckeditor.config;

        // Mise à jour de la configuration pour les images
        //this._imageService.setConfig(config.image);

        // Mise à jour de la configuration des couleurs
        //this._imageService.setColors(config.colors);

        // Mise à jour de la configuration pour les slideshow
        //this._slideshowService.setConfig(config.element.types.slideshow);
    }

}
