<div>

    <div class="page_title">
        <div class="row marges">
            <div class="col y_items_center">
                <h1 class="title">{{ 'collaborator.list.management.value' | translate }}</h1>
            </div>
            <div class="col_content y_items_center">
                <div matTooltip="{{ createTooltipLabel | translate }}" matTooltipPosition="left" [matTooltipDisabled]="!createTooltipLabel" matTooltipClass="add-collaborator-tooltip">
                    <button [disabled]="createTooltipLabel" (click)="addItem()" *ngIf="hasAccessCreate()" class="btn_cta btn_little btn_add">{{ 'collaborator.add.action.value' | translate }}</button>
                </div>
            </div>
        </div>
        <div class="row marges">
            <div class="col_12">
                <div class="subscription_information">
                    <p [innerHTML]="('collaborator.list.description.value' | translate:{ signinUrl : signinUrl })"></p>
                </div>
            </div>
        </div>
    </div>

    <div class="content_table">
        <table mat-table matSort [dataSource]="items" class="mat-elevation-z8">

            <!-- Filtres -->

            <ng-container>

                <!--Actions-->

                <ng-container [matColumnDef]="getFilterColumnDef('actions')">
                    <th mat-header-cell *matHeaderCellDef>
                        <button (click)="resetFilters()" mat-icon-button>
                            <mat-icon>loop</mat-icon>
                        </button>
                    </th>
                </ng-container>

                <!-- Nom de la société -->

                <ng-container [matColumnDef]="getFilterColumnDef('societyName')">
                    <th mat-header-cell *matHeaderCellDef>
                    </th>
                </ng-container>

                <!-- Ville de la societe -->

                <ng-container [matColumnDef]="getFilterColumnDef('societyCity')">
                    <th mat-header-cell *matHeaderCellDef>
                    </th>
                </ng-container>

                <!-- Code postal societe -->

                <ng-container [matColumnDef]="getFilterColumnDef('societyZipcode')">
                    <th mat-header-cell *matHeaderCellDef>
                    </th>
                </ng-container>

                <!-- Nom contact -->

                <ng-container [matColumnDef]="getFilterColumnDef('lastName')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-text-filter #filter [builder]="filterBuilder" [label]="'collaborator.list.fields.lastName.value' | translate" [key]="'lastName'" [operator]="'lk'"></app-core-text-filter>
                    </th>
                </ng-container>

                <!-- Prenom contact -->

                <ng-container [matColumnDef]="getFilterColumnDef('firstName')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-text-filter #filter [builder]="filterBuilder" [label]="'collaborator.list.fields.firstName.value' | translate" [key]="'firstName'" [operator]="'lk'"></app-core-text-filter>
                    </th>
                </ng-container>

                <!-- Email contact -->

                <ng-container [matColumnDef]="getFilterColumnDef('email')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-text-filter #filter [builder]="filterBuilder" [label]="'collaborator.list.fields.email.value' | translate" [key]="'email'" [operator]="'lk'"></app-core-text-filter>
                    </th>
                </ng-container>

                <!-- Telephone fixe -->

                <ng-container [matColumnDef]="getFilterColumnDef('switchboardPhone')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-text-filter #filter [builder]="filterBuilder" [label]="'collaborator.list.fields.switchboardPhone.value' | translate" [key]="'switchboardPhone'" [operator]="'lk'"></app-core-text-filter>
                    </th>
                </ng-container>

                <!-- Langue -->

                <ng-container [matColumnDef]="getFilterColumnDef('locale')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-select-filter #filter [builder]="filterBuilder" [label]="'collaborator.list.fields.locale.value' | translate" [key]="'locale'" [operator]="'eq'" [items]="locales" [itemLabelProperty]="'label'" [itemValueProperty]="'id'"></app-core-select-filter>
                    </th>
                </ng-container>

                <!-- Service -->

                <ng-container [matColumnDef]="getFilterColumnDef('service')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-text-filter #filter [builder]="filterBuilder" [label]="'collaborator.list.fields.service.value' | translate" [key]="'service'" [operator]="'lk'"></app-core-text-filter>
                    </th>
                </ng-container>

                <!-- Commentaire -->

                <ng-container [matColumnDef]="getFilterColumnDef('comment')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-text-filter #filter [builder]="filterBuilder" [label]="'collaborator.list.fields.comment.value' | translate" [key]="'comment'" [operator]="'lk'"></app-core-text-filter>
                    </th>
                </ng-container>

            </ng-container>

            <!-- Ligne de données -->

            <ng-container>

                <!--Actions-->

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef>{{'actions.label.value' | translate}}</th>
                    <mat-cell *matCellDef="let element; let i = index">
                        <button mat-button *ngIf="hasAccessEdit()" (click)="editItem(element.id)">
                            <mat-icon>edit</mat-icon>
                        </button>
                        <button mat-button *ngIf="hasAccessDelete()" (click)="openDeleteItemDialog(element.id)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </mat-cell>
                </ng-container>

                <!-- Nom de la société -->

                <ng-container matColumnDef="societyName">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'collaborator.list.fields.societyName.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{getModel(element).society.name}}</td>
                </ng-container>

                <!-- Ville de la société -->

                <ng-container matColumnDef="societyCity">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'collaborator.list.fields.societyCity.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{getModel(element).address.city}}</td>
                </ng-container>

                <!-- Code postal de la société -->

                <ng-container matColumnDef="societyZipcode">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'collaborator.list.fields.societyZipcode.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{getModel(element).address.zipcode}}</td>
                </ng-container>

                <!-- Nom contact -->

                <ng-container matColumnDef="lastName">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'collaborator.list.fields.lastName.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{getModel(element).lastName}}</td>
                </ng-container>

                <!-- Prenom contact -->

                <ng-container matColumnDef="firstName">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'collaborator.list.fields.firstName.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{getModel(element).firstName }}</td>
                </ng-container>

                <!-- Email contant -->

                <ng-container matColumnDef="email">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'collaborator.list.fields.email.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{getModel(element).email}}</td>
                </ng-container>

                <!-- Telephone fixe -->

                <ng-container matColumnDef="switchboardPhone">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'collaborator.list.fields.switchboardPhone.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{getModel(element).switchboardPhone}}</td>
                </ng-container>

                <!-- Langue -->

                <ng-container matColumnDef="locale">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'collaborator.list.fields.locale.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{getLocaleItem(element.locale).label | translate}}</td>
                </ng-container>

                <!-- Service -->

                <ng-container matColumnDef="service">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'collaborator.list.fields.service.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{getModel(element).service}}</td>
                </ng-container>

                <!-- Commentaire -->

                <ng-container matColumnDef="comment">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'collaborator.list.fields.comment.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{getModel(element).comment}}</td>
                </ng-container>

            </ng-container>

            <!-- Entêtes de colonne -->

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

            <!-- Filtres -->

            <tr mat-header-row *matHeaderRowDef="displayedFilterColumns"></tr>

            <!-- Ligne de données -->

            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        </table>
    </div>

    <!--Pagination-->

    <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" [length]="totalItems"></mat-paginator>
</div>

