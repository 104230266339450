import {AfterViewInit, Component, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {Router} from '@angular/router';
import {MatDialogRef} from '@angular/material/dialog';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmDialogComponent} from '@lib/confirm-dialog/confirm-dialog.component';
import {TranslateService} from "@ngx-translate/core";
import {FilterBuilder, FilterComponent} from "@core/shared/models/filter";
import {merge} from "rxjs";
import {map, startWith, switchMap} from "rxjs/operators";
import {DEFAULT_PAGE_SIZE, DEFAULT_PAGE_SIZE_OPTIONS, Pagination} from "@core/shared/models/pagination";
import {MatSort} from "@angular/material/sort";
import {MatSnackBar} from '@angular/material/snack-bar';
import {Page} from "@core/shared/models/page";
import {PageService} from "@core/shared/services/page.service";
import {PageTranslation} from "@core/shared/models/page-translation";
import {TranslationService} from "@core/shared/services/translation.service";
import {LOCALE_ITEMS, LocaleItem} from "@core/shared/models/translation";

@Component({
    selector: 'page-list',
    templateUrl: './page-list.component.html',
    styleUrls: ['./page-list.component.scss']
})
export class PageListComponent implements OnInit, AfterViewInit {

    @ViewChild(MatPaginator) paginator: MatPaginator;

    @ViewChild(MatSort) sort: MatSort;

    public totalItems: number = 0;

    public displayedColumns: string[] = [
        'actions',
        'translations.title',
        'locales'
    ];

    public locales: LocaleItem[] = [];

    filterBuilder: FilterBuilder;

    @ViewChildren("filter") filterComponents: QueryList<FilterComponent>;

    public items: Page[];

    constructor(
        private _router: Router,
        private _dialog: MatDialog,
        private _translateService: TranslateService,
        private _pageService: PageService,
        private _snackBar: MatSnackBar,
        public translationService: TranslationService
    ) {
    }

    ngOnInit() {

        this._initFilterBuilder();
        this._initLocales();
    }

    private _initLocales(): void {

        this.locales = LOCALE_ITEMS.map((item: LocaleItem): LocaleItem => {
            return {
                id: item.id,
                label: this._translateService.instant(item.label)
            };
        });
    }

    getParsedLocales(item: Page): string {

        return item.locales.map((locale: string): string => {

            return this._translateService.instant(`locale.${locale}.value`);

        }).join(', ');
    }

    ngAfterViewInit(): void {

        this.sort.sortChange.subscribe(() => {

            this._resetPagination();
        });

        merge(this.sort.sortChange, this.paginator.page)
            .pipe(
                startWith({}),
                switchMap(() => {

                    return this._pageService.getPaginationItemsAPI(this.itemsApiParams);
                }),
                map(this.mapApiResult),
            ).subscribe((data: Page[]): void => {

            this.items = data;
        })
    }

    private _initFilterBuilder(): void {

        this.filterBuilder = new FilterBuilder();

        this.filterBuilder.filterCallback = (): void => {

            this._resetPagination();

            this._pageService.getPaginationItemsAPI(this.itemsApiParams).pipe(
                map(this.mapApiResult)
            ).subscribe((data: Page[]): void => {

                this.items = data;
            });
        };
    }

    private _resetPagination(): void {

        this.paginator.pageIndex = 0;
    }

    public resetFilters(): void {

        this.filterBuilder.resetFields();

        this.filterComponents.forEach((filterComponent: FilterComponent): void => {

            filterComponent.reset();
        });

        this._resetPagination();

        this._pageService.getPaginationItemsAPI(this.itemsApiParams).pipe(map(this.mapApiResult)).subscribe((data: Page[]): void => {

            this.items = data;
        });
    }

    public edit(id: number): void {

        this._router.navigate(['page/update/', id]);
    }

    public duplicate(id: number): void {

        const page: Page = this.items.find((item: Page): boolean => {

            return item.id === id;
        });

        const translation: PageTranslation = this.translationService.getFallbackTranslation(page.translations);

        const title : string = this._translateService.instant('page.actions.duplicate.value');

        const content : string = this._translateService.instant('page.actions.duplicate.content.value', {
            page: translation.title
        });

        const dialogRef: MatDialogRef<ConfirmDialogComponent> = this._dialog.open(ConfirmDialogComponent, {
            width: '500px',
            data: {
                title: title,
                content: content
            }
        });

        dialogRef.componentInstance.confirm.subscribe((): void => {

            this._pageService.duplicateItemAPI(page.id).subscribe((id): void => {

                this._snackBar.open(this._translateService.instant('page.actions.duplicate.success.value'), this._translateService.instant('notification.close.action.value'), {
                    duration: 5000
                });

                this._router.navigate(['page/update/', id]);
            });
        });
    }

    public openDeleteItemDialog(id: number): void {

        const page: Page = this.items.find((item: Page): boolean => {

            return item.id === id;
        });

        const translation: PageTranslation = this.translationService.getFallbackTranslation(page.translations);

        const title : string = this._translateService.instant('page.restricted.actions.delete.value');

        const content : string = this._translateService.instant('page.restricted.actions.delete.content.value', {
            page: translation.title
        });

        const dialogRef: MatDialogRef<ConfirmDialogComponent> = this._dialog.open(ConfirmDialogComponent, {
            width: '500px',
            data: {
                title: title,
                content: content
            }
        });

        dialogRef.componentInstance.confirm.subscribe((): void => {

            this._pageService.deleteItemAPI(page.id).subscribe((): void => {

                this._snackBar.open(this._translateService.instant('page.restricted.actions.delete.success.value'), this._translateService.instant('notification.close.action.value'), {
                    duration: 5000
                });

                this._resetPagination();

                this._pageService.getPaginationItemsAPI(this.itemsApiParams).pipe(map(this.mapApiResult)).subscribe((data: Page[]): void => {
                    this.items = data;
                });
            });
        });
    }

    public addItem(): void {

        this._router.navigate(['page/create']);
    }

    public getModel(item: Page): Page {

        return item;
    }

    get displayedFilterColumns(): string[] {

        return this.displayedColumns.map((column: string): string => {

            return this.getFilterColumnDef(column);
        });
    }

    public getFilterColumnDef(value: string): string {

        return `${value}-filter`;
    }

    public get itemsApiParams(): string[] {

        const params: string[] = [
            `page=${this.paginator.pageIndex + 1}`,
            `limit=${this.paginator.pageSize}`
        ];

        if(this.sort.active && this.sort.direction !== ''){

            params.push(`sort[${this.sort.active}]=${this.sort.direction.toUpperCase()}`);
        } else {
            params.push(`sort[createdAt]=DESC`);
        }

        if(this.filterBuilder.hasFilters){

            params.push(this.filterBuilder.serializedRequest);
        }

        return params;
    }

    get mapApiResult(): (data: Pagination<Page>) => Page[] {

        return (data: Pagination<Page>) => {

            this.totalItems = data.totalItems;

            return data.items;
        };
    }

    get pageSize(): number {

        return DEFAULT_PAGE_SIZE;
    }

    get pageSizeOptions(): number[] {

        return DEFAULT_PAGE_SIZE_OPTIONS;
    }
}
