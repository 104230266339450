import {Component} from '@angular/core';
import {Observable} from "rxjs";
import {UserService} from "@core/shared/services/user.service";
import {User} from "@core/shared/models/user";
import {Router} from "@angular/router";

@Component({
    selector: 'app-core-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent  {

    public user$:Observable<User>;

    constructor(
        private _router: Router,
        private _userService: UserService
    ) {
        this.user$ = this._userService.currentUser.asObservable();
    }

    public logout(): void {

        this._router.navigate(['account/logout']);
    }
}
