<div>

    <div class="page_title">
        <div class="row marges">
            <div class="col y_items_center">
                <h1 class="title">{{ 'channel.list.management.value' | translate }}</h1>
            </div>

            <ng-container *ngIf="userHasAccess('CHANNEL_CREATE_IS_MINE')">
                <div class="col_content y_items_center">
                    <button (click)="addItem()" *ngIf="hasAccessCreate()" class="btn_cta btn_little btn_add">{{ 'channel.add.action.value' | translate }}</button>
                </div>
            </ng-container>
        </div>

        <div class="licences" *ngIf="!hasOneOfThisRoles(['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'])">
            <span> {{ 'channel.list.count.value' | translate }} : {{ society.countEnableChannels }}<ng-container *ngIf="!userHasAccess('UNLIMITED_CHANNEL') && society.countAllowedChannels !== null"> / {{ society.countAllowedChannels }}</ng-container></span>
        </div>

        <ng-container *ngIf="hasOneOfThisRoles(['ROLE_INSTITUTIONAL', 'ROLE_FEDERATION'])">
            <div class="subscription_information">
                <p [innerHTML]="'channel.comissioning.description.value' | translate"></p>
            </div>
        </ng-container>
    </div>

    <div class="content_table">
        <table mat-table matSort [dataSource]="items" class="mat-elevation-z8">

            <!-- Filtres -->

            <ng-container>

                <!--Actions-->

                <ng-container [matColumnDef]="getFilterColumnDef('actions')">
                    <th mat-header-cell *matHeaderCellDef>
                        <button (click)="resetFilters()" mat-icon-button>
                            <mat-icon>loop</mat-icon>
                        </button>
                    </th>
                </ng-container>

                <!-- Nom -->

                <ng-container [matColumnDef]="getFilterColumnDef('translations.name')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-text-filter #filter [builder]="filterBuilder" [label]="'channel.restricted.list.fields.name.value' | translate" [key]="'translations.name'" [operator]="'lk'"></app-core-text-filter>
                    </th>
                </ng-container>

                <!-- Nom Société-->

                <ng-container *ngIf="user && hasOneOfThisRoles(['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'])" [matColumnDef]="getFilterColumnDef('society.name')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-text-filter #filter [builder]="filterBuilder" [label]="'channel.restricted.list.fields.societyName.value' | translate" [key]="'society.name'" [operator]="'lk'"></app-core-text-filter>
                    </th>
                </ng-container>

                <!-- Titre -->

                <ng-container [matColumnDef]="getFilterColumnDef('translations.title')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-text-filter #filter [builder]="filterBuilder" [label]="'channel.restricted.list.fields.title.value' | translate" [key]="'translations.title'" [operator]="'lk'"></app-core-text-filter>
                    </th>
                </ng-container>

                <!-- Site web -->

                <ng-container [matColumnDef]="getFilterColumnDef('marketplace')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-text-filter #filter [builder]="filterBuilder" [label]="'channel.restricted.list.fields.marketplace.value' | translate" [key]="'marketplace.website'" [operator]="'lk'"></app-core-text-filter>
                    </th>
                </ng-container>

                <!-- Activé -->

                <ng-container [matColumnDef]="getFilterColumnDef('enable')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-select-filter #filter [builder]="filterBuilder" [label]="'channel.restricted.list.fields.enable.value' | translate" [key]="'enable'" [operator]="'eq'" [itemValueProperty]="'id'" [itemLabelProperty]="'label'" [items]="isEnableItems"></app-core-select-filter>
                    </th>
                </ng-container>

                <!-- Distributeur -->

                <ng-container [matColumnDef]="getFilterColumnDef('isDistributor')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-select-filter #filter [builder]="filterBuilder" [label]="'channel.restricted.list.fields.distributor.value' | translate" [key]="'isDistributor'" [operator]="'eq'" [itemValueProperty]="'id'" [itemLabelProperty]="'label'" [items]="isDistributorsItems"></app-core-select-filter>
                    </th>
                </ng-container>

                <!-- Devise -->

                <ng-container [matColumnDef]="getFilterColumnDef('currency')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-select-filter #filter [builder]="filterBuilder" [label]="'offer.list.filter.currency.value' | translate" [key]="'currency.id'" [operator]="'eq'" [itemValueProperty]="'id'" [itemLabelProperty]="'label'" [items]="currencies"></app-core-select-filter>
                    </th>
                </ng-container>

                <!-- Langue -->

                <ng-container [matColumnDef]="getFilterColumnDef('locale')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-select-filter #filter [builder]="filterBuilder" [label]="'offer.list.filter.locale.value' | translate" [key]="'locale'" [operator]="'eq'" [itemValueProperty]="'id'" [itemLabelProperty]="'label'" [items]="locales"></app-core-select-filter>
                    </th>
                </ng-container>

                <!-- Nombre d'offres -->
                <ng-container [matColumnDef]="getFilterColumnDef('countOffers')">
                    <th mat-header-cell *matHeaderCellDef>
                    </th>
                </ng-container>

                <!-- Date de creation -->

                <ng-container [matColumnDef]="getFilterColumnDef('createdAt')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-date-interval-filter #filter [builder]="filterBuilder" [key]="'createdAt'" [startOperator]="'gte'" [endOperator]="'lte'"></app-core-date-interval-filter>
                    </th>
                </ng-container>

                <!-- Date de mise à jour -->

                <ng-container [matColumnDef]="getFilterColumnDef('updatedAt')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-date-interval-filter #filter [builder]="filterBuilder" [key]="'updatedAt'" [startOperator]="'gte'" [endOperator]="'lte'"></app-core-date-interval-filter>
                    </th>
                </ng-container>

                <!-- Nom du propriétaire -->

                <ng-container [matColumnDef]="getFilterColumnDef('owner.lastName')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-text-filter #filter [builder]="filterBuilder" [label]="'channel.restricted.list.fields.ownerLastName.search.value' | translate" [key]="'owner.lastName'" [operator]="'lk'"></app-core-text-filter>
                    </th>
                </ng-container>

                <!-- Prénom du propriétaire -->

                <ng-container [matColumnDef]="getFilterColumnDef('owner.firstName')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-text-filter #filter [builder]="filterBuilder" [label]="'channel.restricted.list.fields.ownerFirstName.search.value' | translate" [key]="'owner.firstName'" [operator]="'lk'"></app-core-text-filter>
                    </th>
                </ng-container>

                <!-- Service du propriétaire -->

                <ng-container [matColumnDef]="getFilterColumnDef('owner.service')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-text-filter #filter [builder]="filterBuilder" [label]="'channel.restricted.list.fields.ownerService.search.value' | translate" [key]="'owner.service'" [operator]="'lk'"></app-core-text-filter>
                    </th>
                </ng-container>

                <!-- Type d'intégration -->

                <ng-container [matColumnDef]="getFilterColumnDef('integrationType')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-select-filter #filter [builder]="filterBuilder" [label]="'channel.restricted.list.fields.integrationType.value' | translate" [key]="'typeIntegration'" [operator]="'eq'" [itemValueProperty]="'id'" [itemLabelProperty]="'label'" [items]="types"></app-core-select-filter>
                    </th>
                </ng-container>

                <!-- Commentaire -->

                <ng-container [matColumnDef]="getFilterColumnDef('comment')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-text-filter #filter [builder]="filterBuilder" [label]="'channel.restricted.list.fields.comment.value' | translate" [key]="'comment'" [operator]="'lk'"></app-core-text-filter>
                    </th>
                </ng-container>


            </ng-container>

            <!-- Ligne de données -->

            <ng-container>

                <!-- Actions -->

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef>{{'actions.label.value' | translate}}</th>
                    <mat-cell *matCellDef="let element; let i = index">
                        <button mat-button *ngIf="hasOneOfThisRoles(['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'])" (click)="viewItem(element.id)">
                            <mat-icon>visibility</mat-icon>

                        </button>
                        <button mat-button *ngIf="hasAccessEdit() && !hasOneOfThisRoles(['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'])" (click)="editItem(element.id)">
                            <mat-icon>edit</mat-icon>
                        </button>
                        <ng-container *ngIf="hasAccessDelete() && !hasOneOfThisRoles(['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'])">
                            <button mat-button (click)="openDeleteItemDialog(element.id)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </ng-container>
                        <ng-container *ngIf="!!goToLink(element)">
                            <a mat-button [href]="goToLink(element)" target="_blank">
                                <mat-icon>exit_to_app</mat-icon>
                            </a>
                        </ng-container>
                    </mat-cell>
                </ng-container>

                <!-- Nom -->

                <ng-container matColumnDef="translations.name">
                    <th mat-header-cell *matHeaderCellDef>{{'channel.restricted.list.fields.name.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{translationService.getFallbackTranslation(getModel(element).translations).name}}</td>
                </ng-container>

                <!-- Nom  societé -->

                <ng-container *ngIf="user && hasOneOfThisRoles(['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'])"  matColumnDef="society.name">
                    <th mat-header-cell *matHeaderCellDef>{{'channel.restricted.list.fields.societyName.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{getModel(element).society.name}}</td>
                </ng-container>

                <!-- Titre -->

                <ng-container matColumnDef="translations.title">
                    <th mat-header-cell *matHeaderCellDef>{{'channel.restricted.list.fields.title.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{translationService.getFallbackTranslation(getModel(element).translations).title}}</td>
                </ng-container>

                <!-- Site web -->

                <ng-container matColumnDef="marketplace">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'channel.restricted.list.fields.marketplace.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{ getModel(element).marketplace ? getModel(element).marketplace.website : null }}</td>
                </ng-container>

                <!-- Activé -->

                <ng-container matColumnDef="enable">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'channel.restricted.list.fields.enable.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{getIsEnable(getModel(element))}}</td>
                </ng-container>

                <!-- Distributeur -->

                <ng-container matColumnDef="isDistributor">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'channel.restricted.list.fields.distributor.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{getIsDistributor(getModel(element))}}</td>
                </ng-container>

                <!-- Devise -->

                <ng-container matColumnDef="currency">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'offer.list.filter.currency.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{translationService.getFallbackTranslation(getModel(element).currency.translations).label  }}</td>
                </ng-container>

                <!-- Langue -->

                <ng-container matColumnDef="locale">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'offer.list.filter.locale.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{getLocaleItem(getModel(element).locale).label | translate }}</td>
                </ng-container>

                <!-- Nombre d'offres -->

                <ng-container matColumnDef="countOffers">
                    <th mat-header-cell *matHeaderCellDef>{{'channel.restricted.list.fields.countOffers.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{ getModel(element).offerCatalogs.length }}</td>
                </ng-container>

                <!-- Date de creation -->

                <ng-container matColumnDef="createdAt">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'channel.restricted.list.fields.createdAt.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{getModel(element).createdAt | date : 'shortDate' : null : localeId}}</td>
                </ng-container>

                <!-- Date de mise à jour -->

                <ng-container matColumnDef="updatedAt">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'channel.restricted.list.fields.updatedAt.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{getModel(element).updatedAt | date : 'shortDate' : null : localeId}}</td>
                </ng-container>

                <!-- Nom du propriétaire -->

                <ng-container matColumnDef="owner.lastName">
                    <th mat-header-cell *matHeaderCellDef>{{'channel.restricted.list.fields.ownerLastName.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{getModel(element).owner?.lastName}}</td>
                </ng-container>

                <!-- Prénom du propriétaire -->

                <ng-container matColumnDef="owner.firstName">
                    <th mat-header-cell *matHeaderCellDef>{{'channel.restricted.list.fields.ownerFirstName.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{getModel(element).owner?.firstName}}</td>
                </ng-container>

                <!-- Service du propriétaire -->

                <ng-container matColumnDef="owner.service">
                    <th mat-header-cell *matHeaderCellDef>{{'channel.restricted.list.fields.ownerService.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{getModel(element).owner?.service}}</td>
                </ng-container>

                <!-- Type d'intégration -->

                <ng-container matColumnDef="integrationType">
                    <th mat-header-cell *matHeaderCellDef>{{'channel.restricted.list.fields.integrationType.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{getIntegrationType(getModel(element))}}</td>
                </ng-container>

                <!-- Commentaire -->

                <ng-container matColumnDef="comment">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'channel.restricted.list.fields.comment.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{getModel(element).comment}}</td>
                </ng-container>


            </ng-container>

            <!-- Entêtes de colonne -->

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

            <!-- Filtres -->

            <tr mat-header-row *matHeaderRowDef="displayedFilterColumns"></tr>

            <!-- Ligne de données -->

            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        </table>
    </div>

    <!--Pagination-->

    <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" [length]="totalItems"></mat-paginator>

</div>

