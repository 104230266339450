<div class="auth-wrapper">

    <div class="header_auth">
        <div class="box_xl">
            <div class="logo">
                <img *ngIf="(loginLogoUrl$ | async) as sourceUrl" [src]="sourceUrl" alt="Ty-win" title="Ty-win" class="main_logo" />
                <img *ngIf="poweredByTywin" src="assets/images/logo_powered_tywin.png" alt="Powered By Ty-Win" title="Powered By Ty-Win" class="powered">
            </div>
        </div>
    </div>

    <div class="auth">
        <div class="row">
            <div class="col_12 bloc_auth">
                <div class="auth_title">
                    <span>{{ 'user.password.reset.self.value' | translate }}</span>
                </div>

                <div class="content_auth">

                    <form [formGroup]="form" (ngSubmit)="formService.submit()">

                        <!-- Mot de passe -->

                        <div class="form-group">
                            <div class="form_password">
                                <input type="password"  value="" class="form-control" id="password" formControlName="password" placeholder=" " #inputPassword />
                                <label for="password">{{ 'user.password.value'|translate|translate }}</label>
                                <mat-icon appShowHidePassword [input]="inputPassword" matSuffix></mat-icon>
                            </div>
                            <mat-error *ngIf="form.get('password').errors && (form.get('password').dirty || form.get('password').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="form.get('password').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                    <div *ngIf="form.get('password').errors.minlength">{{ 'form.control.error.minLength.value' | translate:{ minLength: minLengthPassword } }}</div>
                                </div>
                            </mat-error>
                        </div>

                        <!-- Confirmation du mot de passe -->

                        <div class="form-group">
                            <div class="form_password">
                                <input type="password" value="" class="form-control" id="confirmPassword" formControlName="confirmPassword" placeholder=" " #confirmPassword />
                                <label for="confirmPassword">{{ 'user.confirmPassword.value'|translate }}</label>
                                <mat-icon appShowHidePassword [input]="confirmPassword" matSuffix></mat-icon>
                            </div>
                              <mat-error *ngIf="form.get('confirmPassword').errors && (form.get('confirmPassword').dirty || form.get('confirmPassword').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="form.get('confirmPassword').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                    <div *ngIf="form.get('confirmPassword').errors.mustMatch">{{ 'user.validateAccount.password.error.mustMatch.value' | translate:{ minLength: minLengthPassword } }}</div>
                                </div>
                            </mat-error>
                        </div>

                        <!-- Soumission -->

                        <div class="form-group submit">
                            <button class="btn_cta" type="submit">
                                {{ 'form.submit.action.value'|translate }}
                            </button>
                        </div>


                    </form>
                </div>

            </div>
        </div>
    </div>

</div>
