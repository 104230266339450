<h1 mat-dialog-title class="x_center">{{ 'article.charter.publication.title.value' | translate }}</h1>
<mat-dialog-content>

    <div class="ck-content">

        <p [innerHTML]="'article.charter.publication.description.value' | translate"></p>

        <p class="titre_h4">{{'article.charter.publication.subtitle_1.value' | translate }}</p>

        <p [innerHTML]="'article.charter.publication.content_1.value' | translate"></p>

        <p class="titre_h4">{{'article.charter.publication.subtitle_2.value' | translate }}</p>

        <p [innerHTML]="'article.charter.publication.content_2.value' | translate"></p>

        <ul>
            <li>
                <mat-icon class="check">check_box</mat-icon><span [innerHTML]="'article.charter.publication.content_2.sub_content_1.value' | translate"></span>
            </li>
            <li>
                <mat-icon class="check">check_box</mat-icon><span [innerHTML]="'article.charter.publication.content_2.sub_content_2.value' | translate"></span>
            </li>
            <li>
                <mat-icon class="check">check_box</mat-icon><span [innerHTML]="'article.charter.publication.content_2.sub_content_3.value' | translate"></span>
            </li>
            <li>
                <mat-icon class="check">check_box</mat-icon><span [innerHTML]="'article.charter.publication.content_2.sub_content_4.value' | translate"></span>
            </li>
            <li>
                <mat-icon class="check">check_box</mat-icon><span [innerHTML]="'article.charter.publication.content_2.sub_content_5.value' | translate"></span>
            </li>
            <li>
                <mat-icon class="check">check_box</mat-icon><span [innerHTML]="'article.charter.publication.content_2.sub_content_6.value' | translate"></span>
            </li>
        </ul>

        <p class="titre_h4">{{'article.charter.publication.subtitle_3.value' | translate }}</p>

        <p [innerHTML]="'article.charter.publication.content_3.value' | translate"></p>

        <ul>
            <li>
                <mat-icon class="close">close</mat-icon><span [innerHTML]="'article.charter.publication.content_3.sub_content_1.value' | translate"></span>
            </li>
            <li>
                <mat-icon class="close">close</mat-icon><span [innerHTML]="'article.charter.publication.content_3.sub_content_2.value' | translate"></span>
            </li>
            <li>
                <mat-icon class="close">close</mat-icon><span [innerHTML]="'article.charter.publication.content_3.sub_content_3.value' | translate"></span>
            </li>
            <li>
                <mat-icon class="close">close</mat-icon><span [innerHTML]="'article.charter.publication.content_3.sub_content_4.value' | translate"></span>
            </li>
            <li>
                <mat-icon class="close">close</mat-icon><span [innerHTML]="'article.charter.publication.content_3.sub_content_5.value' | translate"></span>
            </li>
        </ul>

        <p class="titre_h4">{{'article.charter.publication.subtitle_4.value' | translate }}</p>

        <p [innerHTML]="'article.charter.publication.content_4.value' | translate"></p>

        <p class="titre_h4">{{'article.charter.publication.subtitle_5.value' | translate }}</p>

        <p [innerHTML]="'article.charter.publication.content_5.value' | translate"></p>

        <ul>
            <li>
                <span [innerHTML]="'article.charter.publication.content_5.sub_content_1.value' | translate"></span>
            </li>
            <li>
                <span [innerHTML]="'article.charter.publication.content_5.sub_content_2.value' | translate"></span>
            </li>
            <li>
                <span [innerHTML]="'article.charter.publication.content_5.sub_content_3.value' | translate"></span>
            </li>
            <li>
                <span [innerHTML]="'article.charter.publication.content_5.sub_content_4.value' | translate"></span>
            </li>
        </ul>

        <p class="titre_h4">{{'article.charter.publication.subtitle_6.value' | translate }}</p>

        <p [innerHTML]="'article.charter.publication.content_6.value' | translate"></p>

    </div>

</mat-dialog-content>

<mat-dialog-actions>
    <div class="row x_center marges">
        <div class="col_content">
            <button class="btn_cta btn_little btn_green_lines" (click)="close.emit()">{{ 'article.charter.publication.confirm.value' | translate }}</button>
        </div>
    </div>
</mat-dialog-actions>
