import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {ActivatedRouteSnapshot, RouterStateSnapshot} from "@angular/router";
import {ArticleService} from "@core/shared/services/article.service";

@Injectable({
    providedIn: 'root'
})
export class ArticleItemResolver {

    constructor(
        private _articleService: ArticleService
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {

        return this._articleService.getItemAPI(parseInt(route.paramMap.get('id')))
    }
}
