import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AccordionItem} from "@lib/cms/element/type/accordion-item/accordion-item";

@Component({
  selector: 'cms-element-accordion-item',
  templateUrl: './accordion-item.component.html',
  styleUrl: './accordion-item.component.scss'
})
export class AccordionItemComponent implements OnInit {

    @Input() content: AccordionItem;

    @Output() update: EventEmitter<AccordionItem> = new EventEmitter<AccordionItem>();

    constructor(){}

    ngOnInit() {

        if (this.content) {

            return;
        }

        this.content = {
            title: null,
            description: null
        };
    }

    public setContent(item: AccordionItem): void {

        this.update.emit(item);
    }
}
