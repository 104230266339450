import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class SidenavService {

    private _open: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    public open(): void {

        this._open.next(true);
    }

    public close(): void {

        this._open.next(false);
    }

    public toggle(): void {

        const open: boolean = this._open.getValue();

        this._open.next(!open);
    }

    get isOpen(): boolean {

        return this._open.getValue();
    }

    get isClosed(): boolean {

        return !this.isOpen;
    }
}