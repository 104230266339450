import {Injectable} from '@angular/core';
import {ApiService} from "@core/shared/services/api.service";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class StatisticExportService {

    constructor(
        private _apiService: ApiService,
        private _httpClient: HttpClient
    ) {}

    public createItemAPI(data: object): Observable<void> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.post<void>(`${this._apiService.getApiUrl(false, true)}/statistics/export`, data, {
            headers: headers
        });
    }
}
