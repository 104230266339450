import {Injectable} from '@angular/core';
import {IApiSlideshowConfig, SlideshowConfig} from "./slideshow";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {Image} from "@lib/media/image/image";

@Injectable({
    providedIn: 'root'
})
export class SlideshowService {

    private API_URL: string;

    private readonly IMAGE_URL: string = '/image/';

    private readonly GALLERY_URL: string = '/galleries';

    private readonly PREFIX_URL: string = '/slideshow';

    private readonly FORMAT_URL: string = '/format';

    private readonly CONFIG_URL: string = '/config';

    constructor(
        private _http: HttpClient
    ) {}

    public setApiUrl(apiUrl: string): void {

        this.API_URL = apiUrl;
    }

    public loadConfigGallery(context: string): Observable<SlideshowConfig> {

        return this._http.get<SlideshowConfig>(`${this.API_URL}${ this.GALLERY_URL }${this.IMAGE_URL}${context}`);
    }

    public loadConfig(context: string): Observable<IApiSlideshowConfig> {

        return this._http.get<IApiSlideshowConfig>(`${this.API_URL}${this.PREFIX_URL}${this.CONFIG_URL}`, {
            params: {
                context: context
            }
        });
    }

    public setFormatImage(image: Image): Observable<void> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        const httpOptions: object = {
            headers: headers
        };

        return this._http.post<void>(`${ this.API_URL }/images/${ image.id }${ this.FORMAT_URL }`, {
            image: image
        }, httpOptions);
    }
}
