<h1 mat-dialog-title>{{ 'lockable.title.value' | translate }}</h1>

<mat-dialog-content>
    <div class="ck-content">
        <p [innerHTML]="'lockable.description.value' | translate"></p>
    </div>
</mat-dialog-content>

<mat-dialog-actions>

    <div class="row x_center marges">
        <div class="col_content">
            <button class="btn_cta btn_little" (click)="close()">{{ 'action.close' | translate }}</button>
        </div>
    </div>

</mat-dialog-actions>

