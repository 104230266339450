<ng-template [ngIf]="isAuthenticated" [ngIfThen]="isAuthenticatedTemplate" [ngIfElse]="isNotAuthenticatedTemplate">

    <!-- Affichage du template en fonction de l'authentification de l'internaute -->

</ng-template>

<!-- Internaute authentifié -->

<ng-template #isAuthenticatedTemplate>

    <ng-container *ngIf="displayCurrentUserData">

        <div class="current_user_roles">
            <span>{{ parsedCurrentUserRoles }}</span>
            <span *ngIf="userService.currentUser.getValue()">USER : {{ userService.currentUser.getValue().id }}</span>
            <span *ngIf="userService.currentUser.getValue() && userService.hasOneOfThisRoles(userService.currentUser.getValue(), ['ROLE_OFFER_CREATOR', 'ROLE_OFFER_DISTRIBUTOR', 'ROLE_PROVIDER', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION'])">SOCIETY : {{ userService.currentUser.getValue().society.id }}</span>
        </div>

    </ng-container>

    <!-- Header -->

    <app-core-header></app-core-header>

    <mat-drawer-container>

        <mat-drawer mode="side" [ngClass]="toggleSidenavClasses">

            <!-- Navigation -->

            <app-navigation-sidenav [items]="navigationItems" [ngClass]="displayPoweredBy ? 'has_padding' : ''"></app-navigation-sidenav>

        </mat-drawer>

        <mat-drawer-content id="admin-sidenav-content">

            <!-- Layout -->

            <main class="layout">
                <router-outlet></router-outlet>
            </main>

        </mat-drawer-content>

    </mat-drawer-container>

</ng-template>

<!-- Internaute non authentifié -->

<ng-template #isNotAuthenticatedTemplate>

    <!-- Layout -->

    <main class="layout">
        <router-outlet></router-outlet>
    </main>

</ng-template>

<!-- Loader pour les chargements asynchrones -->

<app-core-loader></app-core-loader>
