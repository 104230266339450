import {Component, Input, OnInit} from '@angular/core';
import {User} from "@core/shared/models/user";
import {UserService} from "@core/shared/services/user.service";
import {TranslationService} from "@core/shared/services/translation.service";
import {ImageService} from "@lib/media/image/image.service";
import {ApiService} from "@core/shared/services/api.service";
import moment from "moment";
import {Page} from '@core/shared/models/page';
import {Locale} from '@core/shared/models/locale';
import {Image} from '@lib/media/image/image';
import {PageTranslation} from '@core/shared/models/page-translation';
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";
import {ImageEventService} from "@lib/media/image/image.event.service";

export type Mode = 'preview' | 'view';

@Component({
    selector: 'app-core-page-card',
    templateUrl: './page-card.component.html',
    styleUrls: ['./page-card.component.scss']
})
export class PageCardComponent implements OnInit {

    @Input() page: Page;

    @Input() mode: Mode;

    @Input() locale?: Locale;

    protected readonly moment = moment;

    public currentUser: User = null;

    public visualUrl: SafeUrl = null;

    constructor(
        private _imageService: ImageService,
        private _imageEventService: ImageEventService,
        private _apiService: ApiService,
        private _sanitizer: DomSanitizer,
        public translationService: TranslationService,
        public userService: UserService
    ) {}

    ngOnInit() {

        this.currentUser = this.userService.currentUser.value;

        this._loadVisual();
    }

    private _loadVisual(): void {

        const pageTranslation: PageTranslation = this.translationService.getFallbackTranslation(this.page.translations, this.currentUser, this.locale);

        if(this.mode === 'view') {

            this.visualUrl = !!pageTranslation.picture.id ? `${this._apiService.getApiUrl(false, false)}/public/pages/${this.page.id}/picture/${pageTranslation.picture.id}/filter/rectangle/1500` : null;

        } else {

            const image: Image = pageTranslation.picture.image.image as Image;

            const loadVisualCallback = (): void => {

                this._imageService.getBinaryImage(image, 'rectangle', 1500, 90, null).subscribe((blob: Blob): void => {

                    const objectURL: string = URL.createObjectURL(blob);

                    this.visualUrl = this._sanitizer.bypassSecurityTrustUrl(objectURL);
                });
            };

            loadVisualCallback();

            this._imageEventService.imageCropped.subscribe((item: Image): void => {

                if(item.id !== image.id){

                    return;
                }

                loadVisualCallback();
            });
        }

    }
}
