<ng-container *ngIf="!hasChildren && item.path">
    <a [routerLink]="[item.path]" routerLinkActive="active" [target]="(item.target) ? item.target : '_self'" [routerLinkActiveOptions]="{ exact: true }" [ngClass]="item.highlighted ? 'highlighted' : ''"
       class="d_flex item_nav y_items_center" (mouseenter)="showTooltip($event)" (mouseleave)="hideTooltip($event)">
        <span *ngIf="item.image">
            <img *ngIf="!disableImage" src="{{item.image}}" alt="Icon" width="24px;" />
            <span>{{item.label | translate}}</span>
        </span>
        <span *ngIf="!item.image" class="{{ item.icon }}">
            <span>{{item.label | translate}}</span>
        </span>
    </a>
    <div class="tooltip_nav">{{item.label | translate}}</div>
</ng-container>
<ng-container *ngIf="hasChildren">
    <div routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" (click)="toggle(item.path);sidenavService.open()"
         class="d_flex item_nav y_items_center has_child" [ngClass]="parentClasses" (mouseenter)="showTooltip($event)" (mouseleave)="hideTooltip($event)">
        <span *ngIf="item.image">
            <img src="{{item.image}}" alt="Icon" width="24px;" />
            <span>{{item.label | translate}}</span>
        </span>
        <span *ngIf="!item.image" class="{{ item.icon }}">
            <span>{{item.label | translate}}</span>
        </span>
    </div>
    <div class="tooltip_nav">{{item.label | translate}}</div>
    <div class="subnav">
        <app-navigation-sidenav-item *ngFor="let child of item.children" [item]="child" [disableImage]="true"></app-navigation-sidenav-item>
    </div>
</ng-container>
