import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FilterBuilder, FilterOperator} from "@core/shared/models/filter";
import {FilterCollectionField} from "@core/shared/models/filter/filter-collection";
import {ArrayFilterField} from "@core/shared/models/filter/array-filter-field";

@Component({
    selector: 'app-core-target-market-filter-panel',
    templateUrl: './target-market-filter-panel.component.html',
    styleUrls: ['./target-market-filter-panel.component.scss']
})
export class TargetMarketFilterPanelComponent<T> implements OnInit, OnChanges {

    @Input() builder: FilterBuilder;

    @Input() label: string;

    @Input() key: string;

    @Input() itemValueProperty: string;

    @Input() itemLabelProperty: string;

    @Input() itemTypeProperty: string;

    @Input() items: T[] = [];

    @Input() operator: FilterOperator;

    @Output() selectionChanged: EventEmitter<any[]> = new EventEmitter<any[]>();

    @Output() parsedValuesUpdated: EventEmitter<string> = new EventEmitter<string>();

    public values: any[] = [];

    public filterCollectionField: FilterCollectionField;

    ngOnInit(): void {

        this.filterCollectionField = new FilterCollectionField(this.key);

        this.filterCollectionField.choices = this.items;

        this.builder.addField(this.filterCollectionField);

        setTimeout((): void => {

            this.parsedValuesUpdated.emit(this.parsedValues);
        });
    }

    ngOnChanges(changes: SimpleChanges) {

        if(('items' in changes) && !changes.items.isFirstChange()){

            this.filterCollectionField.choices = changes.items.currentValue;

            this.parsedValuesUpdated.emit(this.parsedValues);
        }
    }

    public hydrateValue(): void {

        this.filterCollectionField.clear();

        this.values.forEach((value: any): void => {

            this.filterCollectionField.fields.push(new ArrayFilterField(this.key, this.operator, value));
        });
    }

    public handleItemSelection(item: T): void {

        if(this.isSelectedItem(item)){

            const index: number = this.values.findIndex((value: any): boolean => {

                return value === this.getKeyPropertyValue(item);
            });

            this.values.splice(index, 1);
        }
        else{

            this.values.push(this.getKeyPropertyValue(item));
        }
    }

    public filter(): void {

        this.hydrateValue();

        this.selectionChanged.emit(this.values);

        this.builder.filter();

        this.parsedValuesUpdated.emit(this.parsedValues);
    }

    public reset(applyFilter: boolean = false): void {

        this.values = [];

        this.filterCollectionField.clear();

        if(applyFilter){

            this.filter();
        }
        else {

            this.parsedValuesUpdated.emit(this.parsedValues);
        }
    }

    public getLabelPropertyValue(item: T): string {

        return this.itemLabelProperty.split('.').reduce((carry, key) => carry[key], item);
    }

    public getKeyPropertyValue(item: T): any {

        return this.itemValueProperty.split('.').reduce((carry, key) => carry[key], item);
    }

    public isSelectedItem(item: T): boolean {

        return this.values.includes(this.getKeyPropertyValue(item));
    }

    public optionClasses(item: T): { [p: string]: boolean }{

        return {
            [`targetMarket-${(this.getKeyPropertyValue(item) as string).toLowerCase()}`]: true,
            'selected': this.isSelectedItem(item)
        };
    }

    get isEmptySelection(): boolean {

        return !Boolean(this.values.length);
    }

    get parsedValues(): string {

        const fields: ArrayFilterField[] = this.filterCollectionField.fields.filter((field: ArrayFilterField): boolean => {

            return !!this.items.find((item: T): boolean => {

                return this.getKeyPropertyValue(item) === field.value;
            });
        });

        if(!fields.length){

            return null;
        }

        return fields.map((field: ArrayFilterField): string => {

            return this.getLabelPropertyValue(this.items.find((item: T): boolean => {

                return this.getKeyPropertyValue(item) === field.value;
            }));

        }).join(', ');
    }
}
