<div class="card-article" [ngClass]="cardClasses" (click)="redirectToRead($event)">
    <div class="row h_full direction_y">

        <div class="col_content card_top">
            <div class="visuel">
                <img [src]="visualUrl" (error)="setDefaultVisualUrl()" [alt]="translationService.getFallbackTranslation(item.translations, currentUser, locale).title">
            </div>
            <div class="infos_top">
                <div class="row x_between">
                    <div class="col x_start y_items_center">
                        <span class="tag" *ngIf="item.isNew">
                            <span>{{'offer.list.item.new.value' | translationForLocaleWithFallback: locale | async}}</span>
                        </span>
                        <ng-container *ngIf="userService.hasOneOfTheseAccesses(currentUser, ['ARTICLE_EDIT']) || (userService.hasOneOfTheseAccesses(currentUser, ['ARTICLE_EDIT_IS_MINE']) && isMine)">
                            <span class="tag" [ngClass]="statusTagClasses">
                                <span>{{ statusLabels[item.status] | translate }}</span>
                            </span>
                        </ng-container>
                    </div>
                    <ng-container *ngIf="(mode === 'view') && ((userService.hasOneOfTheseAccesses(currentUser, ['ARTICLE_EDIT']) || (userService.hasOneOfTheseAccesses(currentUser, ['ARTICLE_EDIT_IS_MINE']) && isMine)) || (userService.hasOneOfTheseAccesses(currentUser, ['ARTICLE_DELETE']) || (userService.hasOneOfTheseAccesses(currentUser, ['ARTICLE_DELETE_IS_MINE']) && isMine)))">
                        <div class="col x_end y_items_center">
                            <span class="tag action_tag" (click)="toggleActionsPanel($event)"><span class="icon-tywin-more"></span></span>
                            <div class="actions_top" [ngClass]="actionsPanelClasses">
                                <ng-container *ngIf="userService.hasOneOfTheseAccesses(currentUser, ['ARTICLE_EDIT']) || (userService.hasOneOfTheseAccesses(currentUser, ['ARTICLE_EDIT_IS_MINE']) && isMine)">
                                    <span (click)="redirectToUpdate($event)">{{ 'article.card.update.value' | translationForLocaleWithFallback: locale | async }}</span>
                                </ng-container>
                                <ng-container *ngIf="userService.hasOneOfTheseAccesses(currentUser, ['ARTICLE_DELETE']) || (userService.hasOneOfTheseAccesses(currentUser, ['ARTICLE_DELETE_IS_MINE']) && isMine)">
                                    <span (click)="openDeleteDialog($event)">{{ 'article.card.delete.value' | translationForLocaleWithFallback: locale | async }}</span>
                                </ng-container>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="infos_bottom">
                <div class="row x_between">
                    <div class="col_content x_start y_items_center">
                        <span class="page_tag type_{{ item.configuration.type.tag }}">
                            <span>{{ translationService.getFallbackTranslation(item.configuration.type.translations, currentUser, locale).label }}</span>
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <div class="col card_bottom">

            <div class="row h_full direction_y">

                <div class="col">
                    <div class="ck-content infos_card">
                        <p class="titre_h4">{{ translationService.getFallbackTranslation(item.translations, currentUser, locale).title }}</p>
                        <p class="small">{{ translationService.getFallbackTranslation(item.translations, currentUser, locale).teaser }}</p>
                    </div>
                </div>

                <div class="col_content">
                    <div class="infos_published">
                        <div class="row marges">
                            <div class="col y_items_center">
                                <div class="txt icon-tywin-calendar">
                                    <p><strong>{{ 'publishedBy.value' | translationForLocaleWithFallback: locale | async }} {{ item.society ? item.society.name : 'Ty-Win' }}</strong></p>
                                    <p *ngIf="item.publishedAt">{{ 'date.nominative.value' | translationForLocaleWithFallback: locale | async }} {{ item.publishedAt | date:'shortDate' : null : localeId }}</p>
                                </div>
                            </div>
                            <div class="col_content y_items_center">
                                <div class="logo">
                                    <ng-container *ngIf="item.society">
                                        <img [src]="societyLogoUrl" (error)="setDefaultSocietyLogoUrl()" [alt]="item.society.name">
                                    </ng-container>
                                    <ng-container *ngIf="!item.society">
                                        <img src="assets/images/logo_tywin.png" alt="" title="" class="no_logo" />
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>

    </div>
</div>
