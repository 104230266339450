import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ICustomButton, IElement, IElementBackgroundColor} from "./element";
import {ElementService} from "./element.service";
import {IAllowedType} from "../template/template";
import {TranslateService} from "@ngx-translate/core";
import {ElementType, isType} from "./type/type";
import {ApiService} from "../api/api.service";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ConfirmDialogData} from "@lib/confirm-dialog/confirm-dialog";
import {ConfirmDialogComponent} from "@lib/confirm-dialog/confirm-dialog.component";
import {ImageEventService} from "@lib/media/image/image.event.service";
import {CmsContentService} from "@core/shared/services/cms/cms-content.service";

@Component({
    selector: 'app-element',
    templateUrl: './element.component.html',
    styleUrls: ['./element.component.scss']
})

/**
 * Element d'une ligne
 */
export class ElementComponent implements OnInit {

    @Input() element: IElement;

    @Input() allowedTypes: IAllowedType[];

    @Input() isDecreaseAllowed: boolean;

    @Input() isIncreaseAllowed: boolean;

    @Input() deleteable: boolean;

    @Output() decreaseContent = new EventEmitter();

    @Output() increaseContent = new EventEmitter();

    @Output() delete = new EventEmitter();

    public backgroundColors: IElementBackgroundColor[] = [];

    public customButtons: ICustomButton[] = [];

    public constructor(
        private _translateService: TranslateService,
        private _dialog: MatDialog,
        private _cmsContentService: CmsContentService,
        private _elementService: ElementService,
        private _imageEventService: ImageEventService,
        private _snackbar: MatSnackBar,
        private _apiService: ApiService
    ) {}

    ngOnInit(): void {

        this.backgroundColors = this._elementService.getBackgroundColors();

        this.customButtons = this._elementService.getCustomButtons();
    }

    /**
     * Modale de confirmation pour la réinitialisation du type ainsi que du contenu de l'élément
     */
    public resetContent(): void {

        this._translateService.get(['element.reset.confirm.title', 'element.reset.confirm.content']).subscribe((messages: string[]) => {

            const data: ConfirmDialogData = {
                title : messages['element.reset.confirm.title'],
                content: messages['element.reset.confirm.content']
            };

            const dialogRef: MatDialogRef<ConfirmDialogComponent> = this._dialog.open(ConfirmDialogComponent, {
                width: '500px',
                data: data
            });

            dialogRef.componentInstance.confirm.subscribe((): void => {

                this._elementService.clearElement(this.element);

                this._translateService.get(['element.reset.success', 'action.close']).subscribe((messages: string[]) => {
                    this._snackbar.open(messages['element.reset.success'], messages['action.close']);
                });
            });
        });
    }

    /**
     * Mise à jour du contenu de l'élément
     *
     * @param {any} content
     */
    public setContent(content: object): void {

        this.element.content = content;

        this._cmsContentService.contentUpdated$.next();
    }

    public isType(type: ElementType): boolean {

        return isType(type, this.element);
    }

    public getApiUrl(): string {

        return this._apiService.getApiUrl(true);
    }

    public handleTypeSelection(): void {

        const callbacks: Partial<{ [type in ElementType]: () => void }> = {
            'image': (): void => {

                this._imageEventService.openGalleryDialog$.next(true);
            }
        };

        if(!callbacks[this.element.type]){

            return;
        }

        callbacks[this.element.type]();
    }

    get elementLabel(): string {

        if(!this.element.type){

            return null;
        }

        const type: IAllowedType = this.allowedTypes.find((item: IAllowedType): boolean => {

            return item.id === this.element.type;

        });

        return type.label.charAt(0).toUpperCase() + type.label.slice(1);
    }
}
