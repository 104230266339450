import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren} from '@angular/core';
import {AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators} from "@angular/forms";
import {FormService} from "@core/shared/services/form.service";
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Currency} from "@core/shared/models/currency";
import {CurrencyService} from "@core/shared/services/currency.service";
import { OfferPriceTypeType} from "@core/shared/models/offer/offer-price-type";
import { OFFER_PRICE_PUBLICS,  OfferPricePublic, OfferPricePublicType } from "@core/shared/models/offer/offer-price-public";
import {OfferPriceLevel} from "@core/shared/models/offer/offer-price-level";
import {OFFER_PRICE_LEVEL_TYPES, OfferPriceLevelType, OfferPriceLevelTypeType} from "@core/shared/models/offer/offer-price/offer-price-level/offer-price-level-type";
import { OFFER_PRICE_LEVEL_INCREASE_AREAS, OfferPriceLevelIncreaseArea } from "@core/shared/models/offer/offer-price/offer-price-level/offer-price-level-increase-area";
import {OfferCircuitType, OfferLocation} from "@core/shared/models/offer/offer-location";
import {LOCALE_ITEMS, LocaleItem, TranslationBuilder, TranslationItem} from '@core/shared/models/translation';
import * as ClassicEditor from "@lib/ckeditor";
import {CkeditorConfig} from "@lib/form/fields/ckeditor/ckeditor.component";
import {TranslateService} from "@ngx-translate/core";
import {OfferPresential} from "@core/shared/models/offer/offer-presential";
import {OfferService} from '@core/shared/services/offer.service';
import {CustomerTypology} from '@core/shared/models/customer-typology';
import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {CustomerTypologyService} from '@core/shared/services/customer-typology.service';
import {TextFilterField} from '@core/shared/models/filter/text-filter-field';
import {OfferDuration} from '@core/shared/models/offer/offer-duration';
import {OfferDurationService} from '@core/shared/services/offer/offer-duration.service';
import {OfferDurationTranslation} from '@core/shared/models/offer/offer-duration-translation';
import {Society} from '@core/shared/models/society';
import {Offer, OFFER_WITHOUT_NIGHT, OfferStockType, OfferTranslationPicture} from '@core/shared/models/offer';
import {ActivatedRoute} from '@angular/router';
import {ApiService} from "@core/shared/services/api.service";
import {ImageConfig} from "@lib/form/fields/image/image.component";
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {LOCALE_PUBLISHED_ITEMS, LocalePublishedItem} from '@core/shared/models/published';
import {FieldCollection} from '@lib/form/field';
import {offerAttributesValidator} from "@core/shared/validators/offer/offer-attributes.validator";
import {TermsAndCondition, TermsAndConditionTranslation} from "@core/shared/models/terms-and-condition";
import {OfferIncluded} from "@core/shared/models/offer/offer-included";
import {OfferOption} from "@core/shared/models/offer/offer-option";
import {OfferOptionService} from "@core/shared/services/offer/offer-option.service";
import {FormTabValidationItem, OfferFormTabValidationService} from "@core/shared/services/form/form-tab-validation.service";
import {MatTabGroup} from "@angular/material/tabs";
import {ConfirmDialogComponent} from "@lib/confirm-dialog/confirm-dialog.component";
import {OfferLocationFormComponent} from "@core/components/offer/offer-location/offer-location-form/offer-location-form.component";
import {ArrayFilterField} from "@core/shared/models/filter/array-filter-field";
import {FilterBuilder} from "@core/shared/models/filter";
import {Role} from "@core/shared/models/role";
import {DEFAULT_MARKUP, MIN_MARKUP} from "@core/shared/models/data";
import {REGEX_ONLY_NUMBER, REGEX_PRICE, REGEX_SLUG} from "@core/shared/models/regex";
import {OfferSeo} from "@core/shared/models/offer/offer-seo";
import { offerPresentialAdultDefaultValidator, offerPresentialAdultIncrementalStepValidator, offerPresentialChildDefaultValidator, offerPresentialChildIncrementalStepValidator } from "@core/shared/validators/offer/offer-presential.validator";
import {UserService} from "@core/shared/services/user.service";
import {TranslationService} from "@core/shared/services/translation.service";
import {DragAndDropService} from "@core/shared/services/drag-and-drop.service";
import {Access} from "@core/shared/models/access";
import {User} from "@core/shared/models/user";
import { GiftVoucherType, OFFER_GIFTVOUCHER_TYPES, VoucherType } from "@core/shared/models/gift-voucher/gift-voucher-type";
import {OfferLocationProvider} from "@core/shared/models/offer/offer-location/offer-location-provider";
import {OfferInterest} from "@core/shared/models/offer/offer-interest";
import {OfferProgram} from "@core/shared/models/offer/offer-program";
import {OfferTranslationRequest} from "@core/shared/models/offer/offer-translation-request";
import {OfferTranslationService} from "@core/shared/services/offer/offer-translation.service";
import {Card} from "@core/shared/models/card";
import {CardService} from "@core/shared/services/card.service";
import {OfferTranslationFailedDialogComponent} from "@core/components/offer/offer-translation/offer-translation-failed/offer-translation-failed-dialog/offer-translation-failed-dialog.component";
import {TranslationPictureType} from "@core/shared/models/translation/translation-picture";
import {ImageService} from "@lib/media/image/image.service";
import {ImageEventService} from "@lib/media/image/image.event.service";
import {Image} from "@lib/media/image/image";
import { kebabCase } from 'lodash';
import {map, mergeMap} from "rxjs/operators";
import {OfferCatalog} from "@core/shared/models/offer/offer-catalog";
import {OfferCatalogService} from "@core/shared/services/offer/offer-catalog.service";

@Component({
    selector: 'app-core-page-offer-create',
    templateUrl: './page-offer-create.component.html',
    styleUrls: ['./page-offer-create.component.scss'],
    providers: [
        FormService,
        OfferFormTabValidationService,
        OfferService
    ]
})
export class PageOfferCreateComponent implements OnInit, AfterViewInit {

    @ViewChild('tabGroup', {static: true}) tabGroup: MatTabGroup;

    @ViewChild('generalData', {static: true}) generalDataRef: TemplateRef<any>;

    @ViewChild('contentManagement', {static: true}) contentManagementRef: TemplateRef<any>;

    @ViewChild('programData', {static: true}) programDataRef: TemplateRef<any>;

    @ViewChild('providerData', {static: true}) providerDataRef: TemplateRef<any>;

    @ViewChild('characteristicData', {static: true}) characteristicDataRef: TemplateRef<any>;

    @ViewChild('locationData', {static: true}) locationDataRef: TemplateRef<any>;

    @ViewChild('seoManagement', {static: true}) seoManagementRef: TemplateRef<any>;

    @ViewChild('internalComments', {static: true}) internalCommentsRef: TemplateRef<any>;

    //@ViewChild('options', {static: true}) optionsRef: TemplateRef<any>;

    @ViewChild('pricingData', {static: true}) pricingDataRef: TemplateRef<any>;

    @ViewChild('giftVoucherData', {static: true}) giftVoucherDataRef: TemplateRef<any>;

    @ViewChildren(OfferLocationFormComponent) offerLocationFormComponents: QueryList<OfferLocationFormComponent>;

    @ViewChild('enableAutomaticTranslationReference', { static: false }) enableAutomaticTranslationReference: ElementRef<HTMLDivElement>;

    @ViewChild('sourceLocaleDescriptionReference', { static: false }) sourceLocaleDescriptionReference: ElementRef<HTMLDivElement>;

    @ViewChild('destinationLocalesDescriptionReference', { static: false }) destinationLocalesDescriptionReference: ElementRef<HTMLDivElement>;

    @ViewChild('translationRequestSummaryReference', { static: false }) translationRequestSummaryReference: ElementRef<HTMLDivElement>;

    public currencies: Currency[] = [];

    public minMarkupPercent: number = MIN_MARKUP;

    public defaultMarkupPercent: number = DEFAULT_MARKUP;

    public translationBuilder: TranslationBuilder;

    public locationTranslationBuilders: TranslationBuilder[] = [];

    public seoTranslationBuilder: TranslationBuilder;

    public editor = ClassicEditor;

    public presentialMax: number = 19;

    public customerTypologies: CustomerTypology[];

    public customerTypologyType: string = '';

    public limitProgram: number = 0;

    public limitLocation: number = 3;

    public locales$: BehaviorSubject<{ item: LocaleItem, enabled: boolean }[]> = new BehaviorSubject<{item: LocaleItem; enabled: boolean}[]>([]);

    public publishes$: Observable<LocalePublishedItem[]>;

    public society: Society;

    public user: User;

    public fieldCollection = new FieldCollection();

    public offerDuration: Object = [];

    public formSubmitSubscription: Subject<object> = new Subject<object>();

    public attributesFormReference: UntypedFormGroup;

    public saveAndRedirectConfiguration: { active: boolean, target: ('availabilities') } = {
        active: false,
        target: null
    };

    public submitEnabled: boolean = true;

    public options$: Observable<OfferOption[]>;

    public tabItems: { tag: string, label: string, template: TemplateRef<any> }[] = [];

    public enableAutomaticTranslation: boolean = false;

    public enableAutomaticTranslationUpdated$: Subject<void> = new Subject();

    public sourceLocales$: BehaviorSubject<{ item: LocaleItem, enabled: boolean }[]> = new BehaviorSubject<{item: LocaleItem; enabled: boolean}[]>([]);

    public destinationLocales$: BehaviorSubject<{ item: LocaleItem, enabled: boolean }[]> = new BehaviorSubject<{item: LocaleItem; enabled: boolean}[]>([]);

    public automaticTranslationSourceLocale: string = null;

    public automaticTranslationSourceLocaleUpdated$: Subject<void> = new Subject();

    public automaticTranslationDestinationLocales: string[] = [];

    public automaticTranslationDestinationLocalesUpdated$: Subject<void> = new Subject();

    public translationRequestSummary$: BehaviorSubject<OfferTranslationRequest> = new BehaviorSubject<OfferTranslationRequest>({
        totalPriceTTC: 0
    });

    public card: Card;

    constructor(
        private _dialog: MatDialog,
        private _changeDetectorRef: ChangeDetectorRef,
        private _formBuilder: UntypedFormBuilder,
        private _translateService: TranslateService,
        private _currencyService: CurrencyService,
        private _customerTypologyService: CustomerTypologyService,
        private _offerDurationService: OfferDurationService,
        private _activatedRoute: ActivatedRoute,
        private _snackBar: MatSnackBar,
        private _router: Router,
        private _offerOptionService: OfferOptionService,
        private _userService: UserService,
        private _offerTranslationService: OfferTranslationService,
        private _imageService: ImageService,
        private _imageEventService: ImageEventService,
        private _cardService: CardService,
        private _offerCatalogService: OfferCatalogService,
        public offerService: OfferService,
        public dragDropService: DragAndDropService,
        public formService: FormService,
        public offerFormTabValidationService: OfferFormTabValidationService,
        public apiService: ApiService,
        public translationService: TranslationService
    ) {
    }

    ngOnInit(): void {

        this.user = this._userService.currentUser.value;

        this._initTabItems();

        this._activatedRoute.data.subscribe((data: { user: User, society: Society, durations: OfferDuration[] }): void => {

            this._userService.currentUser.next(data.user);

            this.user = this._userService.currentUser.value;

            this.society = data.society;

            this._initPublishes();

            if(this.hasAccessAutomaticTranslation){

                this._initCard();
            }

            const id: number[] = [];

            const name: string[] = [];

            // Permet de recuperer les id et les labels des durations

            data.durations.forEach((duration: OfferDuration) => {

                id.push(duration.id);

                duration.translations.forEach((translations: OfferDurationTranslation) => {

                    if (translations.locale === this.localeId) {

                        name.push(translations.label);
                    }
                })
            });

            // Permet de construire l'object qui va servir a pour le select-search

            for (let i = 0; i < id.length; i++) {

                this.offerDuration[i] = {
                    id: id[i],
                    name: name[i]
                }
            }
        });

        this.attributesFormReference = this._formBuilder.group({});

        this._initForm();

        this._initSelectForm();

        this.form.get('offerCreator').patchValue(this.society.name);

        this._initLocales();

        this._loadOptions();

        this._initCurrencies();

        this._initCustomerTypologies();

        this._updateCurrencyValidators();

        this._patchGiftVoucher();

        this.offerFormTabValidationService.init(this.tabGroup, this.form, this.formTabValidationItemsCallback);
    }

    ngAfterViewInit(): void {

        this.offerLocationFormComponents.changes.subscribe((): void => {

            setTimeout((): void => {

                this.offerLocationFormComponents.forEach((component: OfferLocationFormComponent): void => {

                    component.expensionPanel.expanded = false;
                });

                this.offerLocationFormComponents.last.expensionPanel.expanded = true;
            });
        });
    }

    private _initCard(): void {

        this._cardService.getSocietyItem(this.user.society.id).subscribe((item: Card|null): void => {

            this.card = item;
        });
    }

    private _initTabItems(): void {

        this.tabItems = [
            {
                tag: 'generalData',
                label: 'offer.generalData.value',
                template: this.generalDataRef
            },
            {
                tag: 'contentManagement',
                label: 'offer.contentManagement.value',
                template: this.contentManagementRef
            },
            {
                tag: 'programData',
                label: 'offer.programData.value',
                template: this.programDataRef
            },
            {
                tag: 'characteristicData',
                label: 'offer.characteristicData.value',
                template: this.characteristicDataRef
            },
            {
                tag: 'providerData',
                label: 'offer.providerData.value',
                template: this.providerDataRef
            },
            {
                tag: 'locationData',
                label: 'offer.location.data.value',
                template: this.locationDataRef
            },
            {
                tag: 'seoManagement',
                label: 'offer.seoManagement.value',
                template: this.seoManagementRef
            },
            {
                tag: 'internalComments',
                label: 'offer.internalComments.value',
                template: this.internalCommentsRef
            },
            /**{
                tag: 'options',
                label: 'offer.options.value',
                template: this.optionsRef
            },**/
            {
                tag: 'pricingData',
                label: 'offer.pricingData.value',
                template: this.pricingDataRef
            }
        ];

        if (this.hasAccessGiftVoucher()) {

            this.tabItems.push({
                tag: 'giftVoucherData',
                label: 'offer.onlineSale.value',
                template: this.giftVoucherDataRef
            })
        }

    }

    private _initForm(): void {

        this.formService.form = this._formBuilder.group({

            // Données générales

            publics: [[], [Validators.required]],
            minChildrenAge: [0, [Validators.required, Validators.pattern(/^[0-9]*$/)]],
            maxChildrenAge: [0, [Validators.required, Validators.pattern(/^[0-9]*$/)]],
            reference: [null],
            offerCreator: [''],
            locales: [[], [Validators.required]],
            optinAutomaticTranslation: [false, [(control: UntypedFormControl) => {

                if((!this.translationRequestSummary$.getValue().totalPriceTTC) || control.value){

                    return null;
                }

                return {
                    'isRequired': {
                        valid: false
                    }
                };
            }]],
            duration: this.createDurationGroup({
                id: null,
                limitProgram: 0,
                limitLocation: 0,
                value: null,
                translations: null,
            }),

            // Gestion de contenu

            translations: new UntypedFormArray([]),
            interests: new UntypedFormArray([]),
            included: new UntypedFormArray([]),
            trueIncluded: new UntypedFormArray([]),
            falseIncluded: new UntypedFormArray([]),
            translationPictureType: ['common' as TranslationPictureType, [Validators.required]],

            // Données programme

            limitLocation: [this.limitLocation, [Validators.required, Validators.pattern(/^[0-9]*$/)]],
            hasProgramsPictures: [false],
            programs: new UntypedFormArray([]),

            // Données prestataires

            providerSocieties: new UntypedFormControl([]),

            // Données caractéristiques

            attributes: new UntypedFormArray([], [offerAttributesValidator(this.attributesFormReference)]),
            customerTypology: this.createCustomerTypologyGroup({
                id: null,
                translations: [],
                type: ''
            }),

            // Données présentiels

            presential: this.createPresentialGroup({
                id: null,
                max: null,
                adultMin: null,
                adultMax: null,
                adultIncrementalStep: null,
                adultDefault: null,
                childMin: null,
                childMax: null,
                childIncrementalStep: null,
                childDefault: null
            }, null),

            // Données localisation

            locations: new UntypedFormArray([]),

            // Type de localisation

            enableMultipleMainLocation: [false, [Validators.required]],

            // Prestataires global pour les localisations

            providerLocations: new UntypedFormControl([]),

            // Gestion de la publication

            published: [null, [Validators.required]],
            isRestricted: [false, [Validators.required]],
            restrictedSocieties: new UntypedFormControl([]),

            // Gestion du SEO

            seo: this.createSeoGroup({
                id: null,
                follow: true,
                translations: []
            }),

            //  Indication vigilance

            isOnAlert: [false, [Validators.required]],
            commentAlert: [''],

            // Options

            options: [[]],

            // Données tarifaires

            timezone: [this._userService.currentUser.value.timezone, [Validators.required]],
            onRequest: [false],
            currency: [null],
            displayPrice: ['TTC' as OfferPriceTypeType, [Validators.required]],
            priceCalculationAutomatic: [false, [Validators.required]],
            vatPercent: [null, [Validators.pattern(REGEX_PRICE)]],
            internalStartFromPriceHT: [null, [Validators.pattern(REGEX_PRICE)]],
            internalStartFromPriceTTC: [null, [Validators.pattern(REGEX_PRICE)]],
            allowBookingRequest: [true, [Validators.required]],
            priceLevels: new UntypedFormArray([]),
            markup: [this.defaultMarkupPercent, [Validators.required, Validators.pattern(REGEX_PRICE), Validators.min(this.minMarkupPercent)]],

            // Vente en ligne
            enableGiftVoucher: [false],
            enableOnlineSale: [false],
            stockManagementType: ['dissociated' as OfferStockType],

            // Circuit - Itinérant
            circuitType: ['itinerant' as OfferCircuitType, Validators.required]
        });

        if(this.hasAccessGiftVoucher()){

            this.form.addControl('giftVoucher', this.createGiftVoucherGroup);
        }

        // Initialisation des champs multilingues

        this._initTranslations();

        // Validation des localisations

        this._resetLocationsControlValidation();

        this.locationsControl.push(this.createLocationGroup({
            id: null,
            address: null,
            zipcode: null,
            city: null,
            country: null,
            department: null,
            latitude: null,
            longitude: null,
            inseeCode: null,
            position: 1,
            isMain: false,
            providers: [],
            translations: []
        }));

        this.form.get('offerCreator').disable();

        this._initFormEvents();

        this.form.valueChanges.subscribe((): void => {

            this.formService.showValidationMessages(this.form);
        });

        this.formService.submitCallback = (): void => {

            this._setIncluded();

            for (let i = 0; i < this.form.get('programs').value.length; i++) {

                this.programsControl.controls[i].get('position').patchValue(i + 1);
            }

            const data: Offer = Object.assign(this.form.value, {
                minChildrenAge: parseInt(this.form.get('minChildrenAge').value),
                maxChildrenAge: parseInt(this.form.get('maxChildrenAge').value),
                currency: this.form.get('currency').value ? {
                    id: parseInt(this.form.get('currency').value)
                } : null,
                vatPercent: this.form.get('vatPercent').value ? (this.form.get('vatPercent').value / 100) : null,
                priceLevels: (this.priceLevelsControl.value as OfferPriceLevel[]).map((price: OfferPriceLevel): OfferPriceLevel => {

                    const clone: OfferPriceLevel = {...price};

                    return Object.assign(clone, {
                        value: clone.type === 'price' ? (clone.value * 100) : (clone.value / 100),
                        additionalValue: !!clone.additionalValue ? (clone.type === 'price' ? (clone.additionalValue * 100) : (clone.additionalValue / 100)) : null
                    });
                }),
                markup: this.form.get('markup').value / 100,
                included: (this.form.get('included').value as OfferIncluded[]).map((item: OfferIncluded, index: number): OfferIncluded => {

                    return Object.assign(item, {
                        position: index + 1
                    });
                }),
                options: (this.form.get('options').value as number[]).map((id: number): { id: number } => {
                    return {id: id}
                }),
                locations: (this.form.get('locations').getRawValue() as OfferLocation[]).map((item: OfferLocation) => {

                    const providers: OfferLocationProvider[] = (this.form.get('enableMultipleMainLocation').value && this.geolocationIsAllowed) ? item.providers.map((provider: Society): OfferLocationProvider => {

                        return {
                            provider: provider
                        };
                    }) : [];

                    return Object.assign(item, {
                        providers: providers,
                        isMain:  !!this.form.get('enableMultipleMainLocation').value
                    });

                }),
                restrictedSocieties: this.allRestrictedSocieties.map((item: Society): { id: number } => { return { id: item.id } }),
                internalStartFromPriceHT: this.form.get('internalStartFromPriceHT').value ? parseInt((this.form.get('internalStartFromPriceHT').value * 100).toFixed()) : null,
                internalStartFromPriceTTC: this.form.get('internalStartFromPriceTTC').value ? parseInt((this.form.get('internalStartFromPriceTTC').value * 100).toFixed()) : null
            });

            if(this.hasAccessGiftVoucher()) {

                data['giftVoucher'] = Object.assign({},{
                    enable: this.form.get('enableGiftVoucher').value,
                    adultPriceTTC:  this.giftVoucherControl.get('adultPriceTTC').value ? parseInt((this.giftVoucherControl.get('adultPriceTTC').value * 100).toFixed()) : 0,
                    childPriceTTC: this.giftVoucherControl.get('childPriceTTC').value ? parseInt((this.giftVoucherControl.get('childPriceTTC').value * 100).toFixed()) : 0,
                    isPhysical: this.isSelectedGiftVoucherType('isPhysical'),
                    isDematerialized: this.isSelectedGiftVoucherType('isDematerialized')
                });

                data['onlineSale'] = Object.assign({},{
                    enable: this.form.get('enableOnlineSale').value,
                    stockManagementType: this.form.get('stockManagementType').value
                });
            }

            if(!this.form.get('enableMultipleMainLocation').value) {

                data['locations'][0].isMain = true;
            }

            // Filtres sur les langues à traduire manuellement

            data.locales = data.locales.filter((locale: string): boolean => {

                return this.offerService.displayedLocales$.getValue().includes(locale);
            });

            data.translations = data.translations.filter((translation: TranslationItem): boolean => {

                return this.offerService.displayedLocales$.getValue().includes(translation.locale);
            });

            data.interests.forEach((item: OfferInterest): void => {

                item.translations = item.translations.filter((translation: TranslationItem): boolean => {

                    return this.offerService.displayedLocales$.getValue().includes(translation.locale);
                });
            });

            data.included.forEach((item: OfferIncluded): void => {

                item.translations = item.translations.filter((translation: TranslationItem): boolean => {

                    return this.offerService.displayedLocales$.getValue().includes(translation.locale);
                });
            });

            data.programs.forEach((item: OfferProgram): void => {

                item.translations = item.translations.filter((translation: TranslationItem): boolean => {

                    return this.offerService.displayedLocales$.getValue().includes(translation.locale);
                });
            });

            data.locations.forEach((item: OfferLocation): void => {

                item.translations = item.translations.filter((translation: TranslationItem): boolean => {

                    return this.offerService.displayedLocales$.getValue().includes(translation.locale);
                });
            });

            data.seo.translations = data.seo.translations.filter((translation: TranslationItem): boolean => {

                return this.offerService.displayedLocales$.getValue().includes(translation.locale);
            });

            // Cleaning data

            delete data['trueIncluded'];
            delete data['falseIncluded'];
            delete data['enableGiftVoucher'];
            delete data['enableOnlineSale'];
            delete data['providerLocations'];

            this.submitEnabled = false;

            this.offerService.createItemAPI(data, this.society.id)
                .pipe(
                    mergeMap((offer: Offer): Observable<OfferCatalog|Offer> => {

                        if(offer.published !== 'published') {

                            return of(offer);
                        }

                        // Ajout automatique de l'offre au catalogue

                        return this._offerCatalogService.createItemAPI({
                            offer: {
                                id: offer.id
                            },
                            markup: (offer.markup === undefined ? DEFAULT_MARKUP : offer.markup)
                        }).pipe(
                            map((item: OfferCatalog): Offer => {

                                return item.offer;
                            })
                        );
                    })
                )
                .subscribe((offer: Offer): void => {

                    const confirmNotificationCallback = (): void => {

                        this._snackBar.open(this._translateService.instant(data.published === 'published' ? 'offer.form.action.createAndAutomaticAddition.success.value' : 'offer.form.action.create.success.value'), this._translateService.instant('notification.close.action.value'), {
                            duration: 20000
                        });
                    };

                    const postSubmitCallback = (): void => {

                        if(this.saveAndRedirectConfiguration.active){

                            switch (this.saveAndRedirectConfiguration.target){

                                case 'availabilities':

                                    this._router.navigate(['account/availability/offer/update', offer.id]);

                                    break;

                                default:

                                    this._router.navigate(['account/offer/update', offer.id]);
                            }
                        }
                        else{

                            this._router.navigate(['account/offer/list/personnal-offers']);
                        }
                    };

                    if(this.enableAutomaticTranslation && this.automaticTranslationSourceLocale && this.automaticTranslationDestinationLocales.length){

                        // Traduction automatique des langues

                        const automaticTranslationData: { sourceLocale: string, targetLocales: string[] } = {
                            sourceLocale: this.automaticTranslationSourceLocale,
                            targetLocales: this.automaticTranslationDestinationLocales
                        };

                        this._offerTranslationService.requestAPI(offer.id, automaticTranslationData).subscribe((): void => {

                            confirmNotificationCallback();

                            postSubmitCallback();

                        }, (): void => {

                            this._dialog.open(OfferTranslationFailedDialogComponent, {
                                width: '500px'
                            });

                            postSubmitCallback();
                        });
                    }
                    else{

                        confirmNotificationCallback();

                        postSubmitCallback();
                    }

                }, (): void => {

                    this.saveAndRedirectConfiguration = {
                        active: false,
                        target: null
                    };

                    this.submitEnabled = true;
                });
        };
    }

    private _initSelectForm(): void {

        this.fieldCollection.addField({
            type: 'select-search',
            config: {
                id: 'duration',
                attrs: {
                    label: this._translateService.instant('offer.form.fields.duration.value'),
                    required: true,
                    choices: this.offerDuration,
                    multiple: false,
                }
            }
        })
    }

    private _loadOptions(): void {

        const filterBuilder = new FilterBuilder();

        (this.form.get('locales').value as string[]).forEach((locale: string): void => {

            filterBuilder.addField(new ArrayFilterField('locales', 'andlkin', locale));
        });

        this.options$ = this._offerOptionService.getItemsAPI(this.society.id, filterBuilder.serializedFilters);
    }

    private _initLocales(): void {

        this.locales$.next(LOCALE_ITEMS.map((localeItem: LocaleItem): { item: LocaleItem, enabled: boolean } => {

            return {
                item: localeItem,
                enabled: true
            };
        }));
    }

    private _initPublishes(): void {

        this.publishes$ = of(LOCALE_PUBLISHED_ITEMS.filter((item: LocalePublishedItem): boolean => {

            const items: string[] = ['draft'];

            if(this.isPublishable){

                items.push('published');
            }

            return items.includes(item.name);
        }));
    }

    private _initCurrencies(): void {

        this._currencyService.getItemsAPI().subscribe((currencies: Currency[]): void => {

            this.currencies = currencies.filter((currency: Currency): boolean => {

                return !['TWD','AED'].includes(currency.code);
            });
        });
    }

    private _initCustomerTypologies(): void {

        this._customerTypologyService.getItemsAPI().subscribe((items: CustomerTypology[]): void => {

            this.customerTypologies = items;

            const societyTermsAndConditionTypologies: CustomerTypology[] = this.society.termsAndConditions.map((termsAndCondition: TermsAndCondition): CustomerTypology => {

                return termsAndCondition.typology;
            });

            // Filtre des types de clientèles en fonction des documents CGV définis pour la société

            this.customerTypologies = this.customerTypologies.filter((customerTypology: CustomerTypology): boolean => {

                return societyTermsAndConditionTypologies.some((item: CustomerTypology): boolean => {

                    return customerTypology.id === item.id;
                });
            });
        });
    }

    private _initTranslations(): void {

        this.translationBuilder = new TranslationBuilder(this._formBuilder);

        this.translationBuilder.form = this.form;

        this.translationBuilder.addItemCallback = (): UntypedFormGroup => {

            return this._formBuilder.group({
                salesPitch: [''],
                name: ['', [Validators.required, Validators.pattern(/^(.{0,60})$/)]],
                teaser: ['', Validators.pattern(/^(.{0,150})$/)],
                description: [''],
                pictures: new UntypedFormArray([])
            });
        };

        //Pré-saisie des données SEO

        this.translationsControl.valueChanges.subscribe((): void => {

            // Méta-title et Méta-description

            (this.seoControl.get('translations') as UntypedFormArray).controls.forEach((seoTranslationControl: UntypedFormGroup): void => {

                const itemControl: UntypedFormGroup = this.translationBuilder.getItemControlByLocale(seoTranslationControl.get('locale').value);

                if(!itemControl) {

                    return;
                }

                seoTranslationControl.get('slug').patchValue(kebabCase(itemControl.get('name').value));

                seoTranslationControl.get('metaTitle').patchValue(itemControl.get('name').value);

                seoTranslationControl.get('metaDescription').patchValue(itemControl.get('teaser').value);
            });
        });
    }

    private _setIncluded(): void {

        this.includedControl.clear();

        for (let i = 0; i < this.trueIncludedControl.length; i++) {

            this.includedControl.push(this.trueIncludedControl.controls[i]);

        }

        for (let i = 0; i < this.falseIncludedControl.length; i++) {

            this.includedControl.push(this.falseIncludedControl.controls[i]);
        }
    }

    private _initFormEvents(): void {

        // Sélection des langues

        this.form.get('locales').valueChanges.subscribe((locales: string[]): void => {

            this.form.get('options').patchValue([]);

            this._loadOptions();

            this._handleTranslationControls();

            this._handleSeoTranslationControls();

            this._handleLocationTranslationsControls();

            this.sourceLocales$.next(this.locales$.getValue().map((locale: { item: LocaleItem, enabled: boolean }): { item: LocaleItem, enabled: boolean } => {

                return {
                    item: locale.item,
                    enabled: locales.includes(locale.item.id)
                };
            }))

            this.automaticTranslationSourceLocale = null;

            this.offerService.displayedLocales$.next(locales);

            this.automaticTranslationSourceLocaleUpdated$.next();
        });

        // Nombre maximum de localisations secondaires

        this.form.get('limitLocation').valueChanges.subscribe((): void => {

            this._resetLocationsControlValidation();
        });

        // Offre sur devis

        this.form.get('onRequest').valueChanges.subscribe((onRequest: boolean): void => {

            if(onRequest){

                this.priceLevelsControl.clear();
            }

            this._updateCurrencyValidators();

            this._patchGiftVoucher();
        });

        // Typologie de clientèle

        this.form.get('customerTypology').valueChanges.subscribe((): void => {

            if(this.form.get('customerTypology').valid){

                const societyTermsAndCondition: TermsAndCondition = this.user.society.termsAndConditions.find((termsAndCondition: TermsAndCondition): boolean => {

                    return termsAndCondition.typology.id === parseInt(this.form.get('customerTypology').value.id);
                });

                const societyTermsAndConditionLocales: string[] = societyTermsAndCondition.translations.map((translation: TermsAndConditionTranslation): string => {

                    return translation.locale;
                });

                this.locales$.next(LOCALE_ITEMS.map((localeItem : LocaleItem): { item: LocaleItem, enabled: boolean } => {

                    return {
                        item: localeItem,
                        enabled: societyTermsAndConditionLocales.includes(localeItem.id)
                    };
                }));
            }
            else{

                this.locales$.next(LOCALE_ITEMS.map((localeItem: LocaleItem): { item: LocaleItem, enabled: boolean } => {

                    return {
                        item: localeItem,
                        enabled: true
                    };
                }));
            }

            this.form.get('locales').patchValue([]);

            this.form.get('locales').updateValueAndValidity();

            this.form.get('attributes').updateValueAndValidity();
        });

        // Présentiel max (tout confondus)

        this.presentialControl.get('max').valueChanges.subscribe((): void => {

            this.presentialControl.get('adultMax').updateValueAndValidity();
            this.presentialControl.get('childMax').updateValueAndValidity();
        });

        this.form.get('translationPictureType').valueChanges.subscribe((): void => {

            if(this.isTranslationPictureType('common')){

                const locales: string[] = this.offerService.displayedLocales$.getValue().filter((locale: string): boolean => {

                    return this.translationBuilder.itemsControl.at(0).get('locale').value !== locale;
                });

                locales.forEach((locale: string): void => {

                    const translationControl = this.translationBuilder.getItemControlByLocale(locale);

                    const destinationPicturesControl: UntypedFormArray = translationControl.get('pictures') as UntypedFormArray;

                    const sourcePicturesControl: UntypedFormArray = this.translationBuilder.itemsControl.at(0).get('pictures') as UntypedFormArray;

                    destinationPicturesControl.clear();

                    sourcePicturesControl.controls.forEach((control: UntypedFormGroup, index: number): void => {

                        this.addTranslationPicture(translationControl.get('pictures'));

                        const sourcePictureControl: UntypedFormGroup = sourcePicturesControl.at(index) as UntypedFormGroup;

                        const destinationPictureControl: UntypedFormGroup = destinationPicturesControl.at(index) as UntypedFormGroup;

                        if(destinationPictureControl){

                            destinationPictureControl.get('copyright').patchValue(sourcePictureControl.get('copyright').value);

                            if(sourcePictureControl.get('image').get('image').value){

                                this._imageService.getCloneImage(sourcePictureControl.get('image').get('image').value.reference).subscribe((cloneImage: Image): void => {

                                    destinationPictureControl.get('image').get('image').patchValue(cloneImage);

                                    const destinationImage: Image = Object.assign(destinationPictureControl.get('image').get('image').value, {
                                        formats: sourcePictureControl.get('image').get('image').value.formats
                                    });

                                    this._imageService.crop(destinationImage).subscribe((): void => {

                                        destinationPictureControl.updateValueAndValidity();
                                    });
                                });
                            }
                        }
                    });
                });
            }

            this.handleContentManagementValidators();

            this.offerFormTabValidationService.refreshItems(this.formTabValidationItemsCallback());
        });

        // Proposé en bon cadeau

        this.form.get('enableGiftVoucher').valueChanges.subscribe((): void => {

            this._updateValidatorsGiftVoucher();
        });

        this.form.get('publics').valueChanges.subscribe((): void => {

            this._updateValidatorsGiftVoucher();
        });

        // Nombre maximum de localisations secondaires

        this.form.get('limitLocation').valueChanges.subscribe((): void => {

            if (!this.form.get('enableMultipleMainLocation').value) {

                this._resetLocationsControlValidation();
            }
        });

        // Suppression de la bride de 3 localisations par jour dans le cas de "plusieurs adresses principales"

        this.form.get('enableMultipleMainLocation').valueChanges.subscribe((enableMultipleMainLocation: boolean) : void => {

            this._resetLocationsControlValidation();

            if(enableMultipleMainLocation){

                this.form.get('circuitType').patchValue('itinerant' as OfferCircuitType);
            }
        });

        this.providerSocietiesControl.valueChanges.subscribe((items: Society[]) => {

            this.offerService.providersSocieties$.next(items);
        });

        this.offerService.providerLocationsUpdate$.subscribe((data): void => {

            this.locationsControl.controls.forEach((control) => {

                const controlProviders: Society[] = [...control.get('providers').value];

                const isAppliedToLocation: boolean = controlProviders.some((controlProvider: Society): boolean => {

                    return controlProvider.id === data.provider.id;
                });

                if(data.selected && !isAppliedToLocation){

                    controlProviders.push(data.provider);
                }

                if(!data.selected && isAppliedToLocation){

                    const index: number = controlProviders.findIndex((controlProvider: Society): boolean => {

                        return controlProvider.id === data.provider.id;
                    });

                    controlProviders.splice(index, 1);
                }

                (control.get('providers') as UntypedFormControl).patchValue(controlProviders);
            });
        });

        this.form.get('locations').valueChanges.subscribe((items: OfferLocation[]) : void => {

            if(this.form.get('enableMultipleMainLocation').value) {

                this._updateLocationsProviders(items);

            }
        });

        this.form.get('isRestricted').valueChanges.subscribe((): void => {

            this.restrictedSocietiesControl.patchValue([]);
        });

        this.form.get('hasProgramsPictures').valueChanges.subscribe((): void => {

            setTimeout((): void => {

                this.handleContentManagementValidators();
            });
        });

        this.enableAutomaticTranslationUpdated$.subscribe((): void => {

            this.automaticTranslationSourceLocale = null;

            this.offerService.displayedLocales$.next(this.form.get('locales').value);

            this.automaticTranslationSourceLocaleUpdated$.next();
        });

        this.automaticTranslationSourceLocaleUpdated$.subscribe((): void => {

            const locales: string[] = this.form.get('locales').value;

            this.destinationLocales$.next(this.locales$.getValue().map((locale: { item: LocaleItem, enabled: boolean }): { item: LocaleItem, enabled: boolean } => {

                return {
                    item: locale.item,
                    enabled: locales.includes(locale.item.id) && (this.automaticTranslationSourceLocale !== locale.item.id)
                };
            }))

            this.automaticTranslationDestinationLocales = [];

            this.automaticTranslationDestinationLocalesUpdated$.next();

            this.handleContentManagementValidators();
        });

        this.automaticTranslationDestinationLocalesUpdated$.subscribe((): void => {

            if(!this.enableAutomaticTranslation || !this.automaticTranslationDestinationLocales.length){

                this.translationRequestSummary$.next({
                    totalPriceTTC: 0
                });

                return;
            }

            if(this.automaticTranslationSourceLocale){

                const data: { sourceLocale: string, targetLocales: string[] } = {
                    sourceLocale: this.automaticTranslationSourceLocale,
                    targetLocales: this.automaticTranslationDestinationLocales
                };

                this._offerTranslationService.requestSummaryAPI(data).subscribe((item: OfferTranslationRequest): void => {

                    this.translationRequestSummary$.next(item);

                    setTimeout((): void => {

                        this.scrollToBottomOfElement(this.translationRequestSummaryReference);
                    });
                });
            }
            else {

                this.translationRequestSummary$.next({
                    totalPriceTTC: 0
                });
            }
        });

        this.translationRequestSummary$.subscribe((): void => {

            this.form.get('optinAutomaticTranslation').patchValue(false);

            this.form.get('optinAutomaticTranslation').updateValueAndValidity();
        });

        this._imageEventService.imageCropped.subscribe((image: Image): void => {

            if(this.isTranslationPictureType('dissociated')){

                return;
            }

            const index: number = (this.translationsControl.at(0).get('pictures').value as OfferTranslationPicture[]).findIndex((item: OfferTranslationPicture): boolean => {

                if(!item.image || !item.image.image){

                    return false;
                }

                return (item.image.image as unknown as Image).id === image.id;
            });

            if(index < 0){

                return;
            }

            const locales: string[] = this.offerService.displayedLocales$.getValue().filter((locale: string): boolean => {

                return this.translationBuilder.itemsControl.at(0).get('locale').value !== locale;
            });

            locales.forEach((locale: string): void => {

                const destinationPicturesControl: UntypedFormArray = (this.translationBuilder.getItemControlByLocale(locale).get('pictures') as UntypedFormArray);

                const destinationPictureControl: UntypedFormGroup = destinationPicturesControl.at(index) as UntypedFormGroup;

                if(destinationPictureControl){

                    const destinationImage: Image = Object.assign(destinationPictureControl.get('image').get('image').value, {
                        formats: image.formats
                    });

                    this._imageService.crop(destinationImage).subscribe((): void => {

                        this._changeDetectorRef.detectChanges();
                    });
                }
            });
        });
    }

    private _updateMultipleMainLocationControl(): void {

        if (!this.geolocationIsAllowed) {

            this.form.get('enableMultipleMainLocation').patchValue(false);
        }

        this.form.get('enableMultipleMainLocation').updateValueAndValidity();
    }

    private _handleTranslationControls(): void {

        const locales: string[] = this.form.get('locales').value;

        this.translationBuilder.updateItems(locales);

        setTimeout((): void => {

            if(this.isTranslationPictureType('common')){

                this.offerService.displayedLocales$.getValue().forEach((locale: string): void => {

                    const translationControl = this.translationBuilder.getItemControlByLocale(locale);

                    const destinationPicturesControl: UntypedFormArray = translationControl.get('pictures') as UntypedFormArray;

                    if(destinationPicturesControl.controls.length){

                        return;
                    }

                    const sourcePicturesControl: UntypedFormArray = this.translationBuilder.itemsControl.at(0).get('pictures') as UntypedFormArray;

                    sourcePicturesControl.controls.forEach((control: UntypedFormGroup, index: number): void => {

                        this.addTranslationPicture(translationControl.get('pictures'));

                        const sourcePictureControl: UntypedFormGroup = sourcePicturesControl.at(index) as UntypedFormGroup;

                        const destinationPictureControl: UntypedFormGroup = destinationPicturesControl.at(index) as UntypedFormGroup;

                        if(destinationPictureControl){

                            destinationPictureControl.get('copyright').patchValue(sourcePictureControl.get('copyright').value);

                            this._imageService.getCloneImage(sourcePictureControl.get('image').get('image').value.reference).subscribe((cloneImage: Image): void => {

                                destinationPictureControl.get('image').get('image').patchValue(cloneImage);

                                const destinationImage: Image = Object.assign(destinationPictureControl.get('image').get('image').value, {
                                    formats: sourcePictureControl.get('image').get('image').value.formats
                                });

                                this._imageService.crop(destinationImage).subscribe((): void => {});
                            });
                        }
                    });
                });
            }

            this.handleContentManagementValidators();

            this.offerFormTabValidationService.refreshItems(this.formTabValidationItemsCallback());
        });
    }

    private _handleSeoTranslationControls(): void {

        const locales: string[] = this.form.get('locales').value;

        this.seoTranslationBuilder.updateItems(locales);
    }

    private _handleLocationTranslationsControls(): void {

        const locales: string[] = this.form.get('locales').value;

        this.locationTranslationBuilders.forEach((builder: TranslationBuilder): void => {

            builder.updateItems(locales);
        });
    }

    private _updateCurrencyValidators(): void {

        this.form.get('currency').patchValue(null);

        this.form.get('currency').clearValidators();

        const validators: ValidatorFn[] = [];

        if (!this.form.get('onRequest').value) {

            validators.push(Validators.required);
        }

        this.form.get('currency').setValidators(validators);

        this.form.get('currency').updateValueAndValidity();
    }

    private _patchGiftVoucher() {

        if(this.form.get('onRequest').value){

            this.form.get('enableGiftVoucher').patchValue(false);

            this.form.get('enableOnlineSale').patchValue(false);
        }
    }

    private _resetLocationsControlValidation(): void {

        if (this.form.get('enableMultipleMainLocation').value) {

            this.locationsControl.setValidators([]);
        }
        else {

            this.locationsControl.setValidators([Validators.maxLength((this.form.get('limitLocation').value as number) + 1)]);
        }

        this.locationsControl.updateValueAndValidity();
    }

    private _updateLocationsProviders(offerLocations: OfferLocation[]): void {

        const providers: Society[][] = offerLocations.map((item: OfferLocation): Society[] => {

            return item.providers
        });

        if (!providers.length) {

            return;
        }

        const intersection = [...providers].reduce((a: Society[], b: Society[]) => {

            return a.filter((c: Society): boolean => {

                return b.some((d: Society): boolean => {

                    return d.id === c.id;
                });
            })
        });

        const locationsProviders = [...new Set(intersection)];

        this.providerLocations.patchValue(locationsProviders, { emitEvent: false });
    }

    private _updateValidatorsGiftVoucher() {

        if(!this.hasAccessGiftVoucher()){

            return;
        }

        const control: UntypedFormGroup = this.form.get('giftVoucher') as UntypedFormGroup;

        const childControlsToUpdate: AbstractControl[] = [
            control.get('types'),
            control.get('childPriceTTC'),
            control.get('adultPriceTTC'),
        ];

        childControlsToUpdate.forEach((childControl: AbstractControl): void => {

            childControl.clearValidators();
        });

        if(this.form.get('enableGiftVoucher').value){

            // Validateurs

            control.get('types').setValidators([Validators.required]);

            if (this.form.get('publics').value.includes('adult')) {

                control.get('adultPriceTTC').patchValue(null);

                control.get('adultPriceTTC').setValidators([Validators.required, Validators.pattern(REGEX_PRICE)]);

            } else {

                control.get('adultPriceTTC').patchValue(0);

                control.get('adultPriceTTC').setValidators([]);
            }

            if (this.form.get('publics').value.includes('child')) {

                // Valeurs

                control.get('childPriceTTC').patchValue(null);

                control.get('childPriceTTC').setValidators([Validators.pattern(REGEX_PRICE)]);

            } else {

                control.get('childPriceTTC').patchValue(0);

                control.get('childPriceTTC').setValidators([]);
            }

        }
        else {

            control.get('types').patchValue([]);

            control.get('adultPriceTTC').patchValue(0);

            control.get('childPriceTTC').patchValue(0);

            control.get('types').setValidators([]);

            control.get('adultPriceTTC').setValidators([]);

            control.get('childPriceTTC').setValidators([]);

        }

        childControlsToUpdate.forEach((childControl: AbstractControl): void => {

            childControl.updateValueAndValidity();
        });

    }

    public handleContentManagementValidators(): void {

        this.translationBuilder.itemsControl.controls.forEach((control: UntypedFormGroup): void => {

            const locale: string = control.get('locale').value;

            // Nom

            control.get('name').clearValidators();

            const nameValidators: ValidatorFn[] = [];

            if(!this.automaticTranslationDestinationLocales.includes(locale)){

                nameValidators.push(Validators.required, Validators.pattern(/^(.{0,60})$/));
            }

            control.get('name').setValidators(nameValidators);

            control.get('name').updateValueAndValidity();

            // Description

            control.get('description').clearValidators();

            const descriptionValidators: ValidatorFn[] = [];

            if(!this.automaticTranslationDestinationLocales.includes(locale)){

                descriptionValidators.push((control: AbstractControl): { [key: string]: any } => {

                    if (!control.value) {

                        return null;
                    }

                    if ((control.value.replace(/(<([^>]+)>)/gi, '').replace(/&nbsp;/gi, ' ')).length <= 7000) {

                        return null;
                    }

                    return {
                        'textMaxLength': {
                            valid: false
                        }
                    };
                });
            }

            control.get('description').setValidators(descriptionValidators);

            control.get('description').updateValueAndValidity();

            // Teaser

            control.get('teaser').clearValidators();

            const teaserValidators: ValidatorFn[] = [];

            if(!this.automaticTranslationDestinationLocales.includes(locale)){

                teaserValidators.push(Validators.pattern(/^(.{0,150})$/));
            }

            control.get('teaser').setValidators(teaserValidators);

            control.get('teaser').updateValueAndValidity();

            // Visuels

            const picturesControl: UntypedFormArray = control.get('pictures') as UntypedFormArray;

            picturesControl.controls.forEach((pictureControl: UntypedFormGroup): void => {

                // Image

                pictureControl.get('image').get('image').clearValidators();

                const imageValidator: ValidatorFn[] = [];

                if(!this.automaticTranslationDestinationLocales.includes(locale)){

                    imageValidator.push(Validators.required);
                }

                pictureControl.get('image').get('image').setValidators(imageValidator);

                pictureControl.get('image').get('image').updateValueAndValidity();

                // Copyright

                pictureControl.get('copyright').clearValidators();

                const copyrightValidator: ValidatorFn[] = [];

                if(!this.automaticTranslationDestinationLocales.includes(locale)){

                    copyrightValidator.push(Validators.required);
                }

                pictureControl.get('copyright').setValidators(copyrightValidator);

                pictureControl.get('copyright').updateValueAndValidity();
            });
        });

        // Intérêts

        const interestsControl: UntypedFormArray = this.form.get('interests') as UntypedFormArray;

        interestsControl.controls.forEach((interestControl): void => {

            const translationsControl: UntypedFormArray = interestControl.get('translations') as UntypedFormArray;

            translationsControl.controls.forEach((translationControl: UntypedFormGroup): void => {

                // Label

                translationControl.get('label').clearValidators();

                const translationLabelValidators: ValidatorFn[] = [];

                if(!this.automaticTranslationDestinationLocales.includes(translationControl.get('locale').value)){

                    translationLabelValidators.push(Validators.required, Validators.pattern(/^(.{0,100})$/));
                }

                translationControl.get('label').setValidators(translationLabelValidators);

                translationControl.get('label').updateValueAndValidity();
            });
        });

        // Inclus dans l'offre

        this.trueIncludedControl.controls.forEach((trueIncludedControl: UntypedFormArray): void => {

            const translationsControl: UntypedFormArray = trueIncludedControl.get('translations') as UntypedFormArray;

            translationsControl.controls.forEach((translationControl: UntypedFormGroup): void => {

                // Titre

                translationControl.get('title').clearValidators();

                const translationTitleValidators: ValidatorFn[] = [];

                if(!this.automaticTranslationDestinationLocales.includes(translationControl.get('locale').value)){

                    translationTitleValidators.push(Validators.required, Validators.maxLength(255));
                }

                translationControl.get('title').setValidators(translationTitleValidators);

                translationControl.get('title').updateValueAndValidity();

                // Description

                translationControl.get('description').clearValidators();

                const translationDescriptionValidators: ValidatorFn[] = [];

                if(!this.automaticTranslationDestinationLocales.includes(translationControl.get('locale').value)){

                    translationDescriptionValidators.push(Validators.maxLength(255));
                }

                translationControl.get('description').setValidators(translationDescriptionValidators);

                translationControl.get('description').updateValueAndValidity();
            });
        });

        // Non inclus dans l'offre

        this.falseIncludedControl.controls.forEach((trueIncludedControl: UntypedFormArray): void => {

            const translationsControl: UntypedFormArray = trueIncludedControl.get('translations') as UntypedFormArray;

            translationsControl.controls.forEach((translationControl: UntypedFormGroup): void => {

                // Titre

                translationControl.get('title').clearValidators();

                const translationTitleValidators: ValidatorFn[] = [];

                if(!this.automaticTranslationDestinationLocales.includes(translationControl.get('locale').value)){

                    translationTitleValidators.push(Validators.required, Validators.maxLength(255));
                }

                translationControl.get('title').setValidators(translationTitleValidators);

                translationControl.get('title').updateValueAndValidity();

                // Description

                translationControl.get('description').clearValidators();

                const translationDescriptionValidators: ValidatorFn[] = [];

                if(!this.automaticTranslationDestinationLocales.includes(translationControl.get('locale').value)){

                    translationDescriptionValidators.push(Validators.maxLength(255));
                }

                translationControl.get('description').setValidators(translationDescriptionValidators);

                translationControl.get('description').updateValueAndValidity();
            });
        });

        // Programme

        this.programsControl.controls.forEach((programControl: UntypedFormGroup): void => {

            const translationsControl: UntypedFormArray = programControl.get('translations') as UntypedFormArray;

            translationsControl.controls.forEach((translationControl: UntypedFormGroup): void => {

                // Titre

                translationControl.get('title').clearValidators();

                const translationTitleValidators: ValidatorFn[] = [];

                if(!this.automaticTranslationDestinationLocales.includes(translationControl.get('locale').value)){

                    translationTitleValidators.push(Validators.required);
                }

                translationControl.get('title').setValidators(translationTitleValidators);

                translationControl.get('title').updateValueAndValidity();

                // Description

                translationControl.get('description').clearValidators();

                const translationDescriptionValidators: ValidatorFn[] = [];

                if(!this.automaticTranslationDestinationLocales.includes(translationControl.get('locale').value)){

                    translationDescriptionValidators.push(Validators.required, (control: AbstractControl): { [key: string]: any } => {

                        if (!control.value) {

                            return null;
                        }

                        if ((control.value.replace(/(<([^>]+)>)/gi, '').replace(/&nbsp;/gi, ' ')).length <= 600) {

                            return null;
                        }

                        return {
                            'textMaxLength': {
                                valid: false
                            }
                        };
                    });
                }

                translationControl.get('description').setValidators(translationDescriptionValidators);

                translationControl.get('description').updateValueAndValidity();

                const pictureControl: UntypedFormGroup = translationControl.get('picture') as UntypedFormGroup;

                if(pictureControl){

                    if(pictureControl.get('image')){

                        // Image

                        pictureControl.get('image').get('image').clearValidators();

                        const imageValidator: ValidatorFn[] = [];

                        if(!this.automaticTranslationDestinationLocales.includes(translationControl.get('locale').value)){

                            imageValidator.push(Validators.required);
                        }

                        pictureControl.get('image').get('image').setValidators(imageValidator);

                        pictureControl.get('image').get('image').updateValueAndValidity();

                    }

                    if(pictureControl.get('copyright')){

                        // Copyright

                        pictureControl.get('copyright').clearValidators();

                        const copyrightValidator: ValidatorFn[] = [];

                        if(!this.automaticTranslationDestinationLocales.includes(translationControl.get('locale').value)){

                            copyrightValidator.push(Validators.required);
                        }

                        pictureControl.get('copyright').setValidators(copyrightValidator);

                        pictureControl.get('copyright').updateValueAndValidity();
                    }
                }
            });
        });

        // Localisation

        this.locationsControl.controls.forEach((locationControl: UntypedFormArray): void => {

            const translationsControl: UntypedFormArray = locationControl.get('translations') as UntypedFormArray;

            translationsControl.controls.forEach((translationControl: UntypedFormGroup): void => {

                // Description

                translationControl.get('description').clearValidators();

                const translationDescriptionValidators: ValidatorFn[] = [];

                if(!this.automaticTranslationDestinationLocales.includes(translationControl.get('locale').value)){

                    translationDescriptionValidators.push(Validators.pattern(/^(.{0,200})$/));
                }

                translationControl.get('description').setValidators(translationDescriptionValidators);

                translationControl.get('description').updateValueAndValidity();
            });
        });

        // SEO

        (this.seoControl.get('translations') as UntypedFormArray).controls.forEach((translationControl: UntypedFormGroup): void => {

            // Meta Title

            translationControl.get('metaTitle').clearValidators();

            const translationTitleValidators: ValidatorFn[] = [];

            if(!this.automaticTranslationDestinationLocales.includes(translationControl.get('locale').value)){

                translationTitleValidators.push(Validators.required);
            }

            translationControl.get('metaTitle').setValidators(translationTitleValidators);

            translationControl.get('metaTitle').updateValueAndValidity();

            // Meta Description

            translationControl.get('metaDescription').clearValidators();

            const translationDescriptionValidators: ValidatorFn[] = [];

            if(!this.automaticTranslationDestinationLocales.includes(translationControl.get('locale').value)){

                translationDescriptionValidators.push(Validators.required);

                translationDescriptionValidators.push(Validators.maxLength(255));
            }

            translationControl.get('metaDescription').setValidators(translationDescriptionValidators);

            translationControl.get('metaDescription').updateValueAndValidity();
        });
    }

    public changeDuration(event: any): void {

        this.groupDurationControl.get('id').patchValue(event.value);

        const idFilter: TextFilterField = new TextFilterField('id', 'eq', event.value);

        const params: string[] = [
            idFilter.serialize
        ];

        this._offerDurationService.getItemsAPI(params).subscribe((data: OfferDuration[]): void => {
            this.limitProgram = data[0].limitProgram;
            this.limitLocation = data[0].limitLocation;

            this.form.get('limitLocation').setValue(this.limitLocation);

            this._updateMultipleMainLocationControl();
        });
    }

    public handleOfferPriceType(event: any): void {

        const control: UntypedFormControl = this.form.get('types') as UntypedFormControl;

        const controlValue: OfferPriceTypeType[] = control.value;

        const value: OfferPriceTypeType = event.target.value;

        if (event.target.checked) {

            controlValue.push(value);

        } else {

            const index: number = controlValue.findIndex((i: OfferPriceTypeType): boolean => {

                return i === value;
            });

            controlValue.splice(index, 1);
        }

        control.patchValue(controlValue);

        // Mise à jour de la préférence d'affichage des tarifs

        this.form.get('displayPrice').patchValue('TTC' as OfferPriceTypeType);
    }

    public handleOfferPricePublic(event: any): void {

        const control: UntypedFormControl = this.form.get('publics') as UntypedFormControl;

        const controlValue: OfferPricePublicType[] = control.value;

        const value: OfferPricePublicType = event.target.value;

        if (event.target.checked) {

            controlValue.push(value);
        } else {

            const index: number = controlValue.findIndex((i: OfferPricePublicType): boolean => {

                return i === value;
            });

            controlValue.splice(index, 1);
        }

        control.patchValue(controlValue);

        this.updateValidatorsPresentiel();
    }

    public handleLocaleSelection(event: any): void {

        const control: UntypedFormControl = this.form.get('locales') as UntypedFormControl;

        const controlValue: string[] = control.value;

        const value: string = event.target.value;

        if (event.target.checked) {

            controlValue.push(value);

        } else {

            const index: number = controlValue.findIndex((item: string): boolean => {

                return item === value;
            });

            controlValue.splice(index, 1);
        }

        control.patchValue(controlValue);

        control.updateValueAndValidity();

        setTimeout((): void => {

            if(this.hasAccessAutomaticTranslation && this.form.get('locales').valid){

                this.scrollToBottomOfElement(this.enableAutomaticTranslation ? this.sourceLocaleDescriptionReference : this.enableAutomaticTranslationReference);
            }
        });
    }

    public handleEnableAutomaticTranslation(): void {

        this.enableAutomaticTranslationUpdated$.next();

        setTimeout((): void => {

            if(this.enableAutomaticTranslation){

                this.scrollToBottomOfElement(this.sourceLocaleDescriptionReference);
            }
        });
    }

    public handleSourceLocaleSelection(event: any): void {

        this.automaticTranslationSourceLocale = event.target.value;

        this.automaticTranslationSourceLocaleUpdated$.next();

        setTimeout((): void => {

            if(!!this.automaticTranslationSourceLocale){

                this.scrollToBottomOfElement(this.destinationLocalesDescriptionReference);
            }
        });
    }

    public handleDestinationLocaleSelection(event: any): void {

        // Mise à jour des langues à traduire automatiquement

        const value: string = event.target.value;

        if (event.target.checked) {

            this.automaticTranslationDestinationLocales.push(value);

        } else {

            const index: number = this.automaticTranslationDestinationLocales.findIndex((item: string): boolean => {

                return item === value;
            });

            this.automaticTranslationDestinationLocales.splice(index, 1);
        }

        this.automaticTranslationDestinationLocalesUpdated$.next();

        // Mise à jour des langues affichées à traduire manuellement

        const locales: string[] = this.form.get('locales').value;

        this.offerService.displayedLocales$.next(locales.filter((locale: string): boolean => {

            return !this.automaticTranslationDestinationLocales.includes(locale);
        }));

        // Mise à jour de la validation des champs

        this.handleContentManagementValidators();

        // Raffraîchissement de la validation des onglets

        this.offerFormTabValidationService.refreshItems(this.formTabValidationItemsCallback());
    }

    public hasRole(role: Role): boolean {

        return this.society.admin.roles.includes(role);
    }

    public hasAccessGiftVoucher(): boolean {

        return this.user.accesses.some((access: Access): boolean => {

            return access.tag === 'ONLINE_SALE';
        });
    }

    public updateValidatorsPresentiel() {

        const control: UntypedFormGroup = this.presentialControl;

        const childControlsToUpdate: AbstractControl[] = [
            control.get('adultMin'),
            control.get('adultMax'),
            control.get('adultIncrementalStep'),
            control.get('adultDefault'),
            control.get('childMin'),
            control.get('childMax'),
            control.get('childIncrementalStep'),
            control.get('childDefault')
        ];

        childControlsToUpdate.forEach((childControl: AbstractControl): void => {

            childControl.clearValidators();
        });

        if (this.form.get('publics').value.includes('adult')) {

            // Valeurs

            control.get('adultMin').patchValue(null);

            control.get('adultMax').patchValue(null);

            control.get('adultIncrementalStep').patchValue(null);

            control.get('adultDefault').patchValue(null);

            // Validateurs

            control.get('adultMin').setValidators([Validators.required, Validators.pattern(/^[0-9]*$/)]);

            control.get('adultMax').setValidators([Validators.required, Validators.pattern(/^[1-9][0-9]*$/), (adultMaxControl: UntypedFormControl) => {

                if(!adultMaxControl.value || !adultMaxControl.value.length){

                    return null;
                }

                if(!control.get('max') || !control.get('max').value || !control.get('max').value.length){

                    return null;
                }

                if(parseInt(adultMaxControl.value) <= parseInt(control.get('max').value)){

                    return null;
                }

                return {
                    'isTooHigh': {
                        valid: false
                    }
                };
            }]);

            control.get('adultIncrementalStep').setValidators([Validators.required, Validators.pattern(/^[0-9]*$/), Validators.min(1), offerPresentialAdultIncrementalStepValidator]);

            control.get('adultDefault').setValidators([Validators.required, Validators.pattern(/^[0-9]*$/), offerPresentialAdultDefaultValidator]);

        } else {

            // Valeurs

            control.get('adultMin').patchValue(0);

            control.get('adultMax').patchValue(0);

            control.get('adultIncrementalStep').patchValue(0);

            control.get('adultDefault').patchValue(0);

            // Validateurs

            control.get('adultMin').setValidators([]);

            control.get('adultMax').setValidators([]);

            control.get('adultIncrementalStep').setValidators([]);

            control.get('adultDefault').setValidators([]);
        }

        if (this.form.get('publics').value.includes('child')) {

            // Valeurs

            control.get('childMin').patchValue(null);

            control.get('childMax').patchValue(null);

            control.get('childIncrementalStep').patchValue(null);

            control.get('childDefault').patchValue(null);

            // Validateurs

            control.get('childMin').setValidators([Validators.required, Validators.pattern(/^[0-9]*$/)]);

            control.get('childMax').setValidators([Validators.required, Validators.pattern(/^[1-9][0-9]*$/), (childMaxControl: UntypedFormControl) => {

                if(!childMaxControl.value || !childMaxControl.value.length){

                    return null;
                }

                if(!control.get('max') || !control.get('max').value || !control.get('max').value.length){

                    return null;
                }

                if(parseInt(childMaxControl.value) <= parseInt(control.get('max').value)){

                    return null;
                }

                return {
                    'isTooHigh': {
                        valid: false
                    }
                };
            }]);

            control.get('childIncrementalStep').setValidators([Validators.required, Validators.pattern(/^[0-9]*$/), Validators.min(1), offerPresentialChildIncrementalStepValidator]);

            control.get('childDefault').setValidators([Validators.required, Validators.pattern(/^[0-9]*$/), offerPresentialChildDefaultValidator]);

        } else {

            // Valeurs

            control.get('childMin').patchValue(0);

            control.get('childMax').patchValue(0);

            control.get('childIncrementalStep').patchValue(0);

            control.get('childDefault').patchValue(0);

            // Validateurs

            control.get('childMin').setValidators([]);

            control.get('childMax').setValidators([]);

            control.get('childIncrementalStep').setValidators([]);

            control.get('childDefault').setValidators([]);
        }

        childControlsToUpdate.forEach((childControl: AbstractControl): void => {

            childControl.updateValueAndValidity();
        });
    }

    public isSelectedOfferPricePublic(type: OfferPricePublicType): boolean {

        return this.form.get('publics').value.includes(type);
    }

    public isSelectedLocale(locale: LocaleItem): boolean {

        return this.form.get('locales').value.includes(locale.id);
    }

    public isSelectedSourceLocale(locale: LocaleItem): boolean {

        return this.automaticTranslationSourceLocale === locale.id;
    }

    public isSelectedDestinationLocale(locale: LocaleItem): boolean {

        return this.automaticTranslationDestinationLocales.includes(locale.id);
    }

    public createCustomerTypologyGroup(data: CustomerTypology): UntypedFormGroup {

        return this._formBuilder.group({
            id: [data.id, Validators.required]
        });
    }

    public createDurationGroup(data: OfferDuration): UntypedFormGroup {

        return this._formBuilder.group({
            id: [data.id, Validators.required],
        });
    }

    public createSeoGroup(data: OfferSeo): UntypedFormGroup {

        const control: UntypedFormGroup = this._formBuilder.group({
            follow: [data.follow, Validators.required],
            translations: new UntypedFormArray([])
        });

        this.seoTranslationBuilder = new TranslationBuilder(this._formBuilder);

        this.seoTranslationBuilder.form = control;

        this.seoTranslationBuilder.addItemCallback = (): UntypedFormGroup => {

            return this._formBuilder.group({
                slug: ['', [(control: UntypedFormControl) => {

                    if(!control.parent){

                        return null;
                    }

                    if (!this.enableAutomaticTranslation) {

                        return control.value && Boolean(control.value.length) ? null : {
                            'isRequired': {
                                valid: false
                            }
                        };
                    }

                    const isOptional: boolean = this.destinationLocales$.getValue().some((locale): boolean => {

                        return locale.item.id === control.parent.get('locale').value;
                    });

                    if (!isOptional) {

                        return control.value && Boolean(control.value.length) ? null : {
                            'isRequired': {
                                valid: false
                            }
                        };
                    }

                    return null;

                }, Validators.pattern(REGEX_SLUG)]],
                metaTitle: ['', [Validators.required]],
                metaDescription: ['', [Validators.required, Validators.maxLength(255)]]
            });
        };

        return control;
    }

    public createPresentialGroup(data: OfferPresential, enableMax: boolean): UntypedFormGroup {

        return this._formBuilder.group({
            max: [data.max, enableMax ? [Validators.required, Validators.pattern(/^[0-9]*$/), Validators.max(this.presentialMax)] : []],
            adultMin: [data.adultMin, [Validators.required, Validators.pattern(/^[0-9]*$/)]],
            adultMax: [data.adultMax, [Validators.required, Validators.pattern(/^[1-9][0-9]*$/)]],
            adultIncrementalStep: [data.adultIncrementalStep, [Validators.required, Validators.pattern(/^[0-9]*$/), Validators.min(1), offerPresentialAdultIncrementalStepValidator]],
            adultDefault: [data.adultDefault, [Validators.required, Validators.pattern(/^[0-9]*$/), offerPresentialAdultDefaultValidator]],
            childMin: [data.childMin, [Validators.required, Validators.pattern(/^[0-9]*$/)]],
            childMax: [data.childMax, [Validators.required, Validators.pattern(/^[1-9][0-9]*$/)]],
            childIncrementalStep: [data.childIncrementalStep, [Validators.required, Validators.pattern(/^[0-9]*$/), Validators.min(1), offerPresentialChildIncrementalStepValidator]],
            childDefault: [data.childDefault, [Validators.required, Validators.pattern(/^[0-9]*$/), offerPresentialChildDefaultValidator]]
        });
    }

    public createPriceLevelGroup(data: OfferPriceLevel): UntypedFormGroup {

        return this._formBuilder.group({
            type: [data.type, Validators.required],
            priceIncreaseArea: [data.priceIncreaseArea, [Validators.required]],
            min: [data.min, [Validators.required, Validators.pattern(/^[0-9]*$/)]],
            max: [data.max, [Validators.required, Validators.pattern(/^[0-9]*$/)]],
            value: [data.value, [Validators.pattern(REGEX_PRICE), Validators.required]],
            additionalValue: [data.additionalValue ? (data.type === 'price' ? (data.additionalValue / 100) : (data.additionalValue * 100)) : null, [Validators.pattern(REGEX_PRICE), (control: UntypedFormControl) => {

                if(!control.parent){

                    return null;
                }

                if(control.parent.get('type').invalid){

                    return null;
                }

                if((control.parent.get('type').value as OfferPriceLevelTypeType) === 'percent'){

                    return null;
                }

                if(control.value && (control.value.length > 0)){

                    return null;
                }

                return {
                    'isRequired': {
                        valid: false
                    }
                };
            }]]
        });
    }

    public createLocationGroup(data: Partial<OfferLocation>): UntypedFormGroup {

        const control: UntypedFormGroup = this._formBuilder.group({
            address: [{ value: data.address, disabled: false }, [Validators.required]],
            zipcode: [{ value: data.zipcode, disabled: false }, [Validators.required]],
            city: [{ value: data.city, disabled: false }, [Validators.required]],
            department: [{ value: data.department, disabled: false }, [Validators.required]],
            country: [{ value: data.country, disabled: false }, [Validators.required]],
            latitude: [{ value: data.latitude, disabled: false }, [Validators.required, Validators.pattern(/^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,})?))$/)]],
            longitude: [{ value: data.longitude, disabled: false }, [Validators.required, Validators.pattern(/^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,})?))$/)]],
            inseeCode: [{ value: data.inseeCode, disabled: false }, [Validators.required, Validators.pattern(/^[a-zA-Z0-9]{5}$/)]],
            translations: new UntypedFormArray([]),
            providers: [[]],
            position: [data.position, [Validators.required]]
        });

        const locationTranslationBuilder = new TranslationBuilder(this._formBuilder);

        locationTranslationBuilder.form = control;

        locationTranslationBuilder.addItemCallback = (): UntypedFormGroup => {

            return this._formBuilder.group({
                title: [''],
                description: ['', Validators.pattern(/^(.{0,200})$/)]
            });
        };

        locationTranslationBuilder.updateItems(this.form.get('locales').value);

        this.locationTranslationBuilders.push(locationTranslationBuilder);

        return control;
    }

    public getCurrencyById(id: string | number): Currency {

        return this.currencies.find((currency: Currency): boolean => {

            return currency.id == id;
        });
    }

    public getCustomerTypologyType(event: any): void {

        const idFilter: TextFilterField = new TextFilterField('id', 'eq', event.target.value);

        const params: string[] = [
            idFilter.serialize
        ];

        this._customerTypologyService.getItemsAPI(params).subscribe((data: CustomerTypology[]): void => {

            this.customerTypologyType = data[0].type;

            this.presentialControl.get('max').clearValidators();

            this.presentialControl.get('max').patchValue(null);

            if (this.customerTypologyType === 'I') {

                this.presentialControl.get('max').setValidators([Validators.required, Validators.pattern(/^[0-9]*$/), Validators.max(this.presentialMax)]);
            }

            if (this.customerTypologyType === 'G') {

                this.presentialControl.get('max').setValidators([]);
            }

            this.presentialControl.get('max').updateValueAndValidity();
        });
    }

    public addPriceLevel(): void {

        this.priceLevelsControl.push(this.createPriceLevelGroup({
            id: null,
            type: null,
            min: null,
            max: null,
            priceIncreaseArea: null,
            value: null,
            additionalValue: null
        }));
    }

    public removePriceLevel(index: number) {

        this.priceLevelsControl.removeAt(index);
    }

    public handlePriceLevelValues(control: AbstractControl): void {

        control.get('value').patchValue(null);

        control.get('value').updateValueAndValidity();

        control.get('additionalValue').patchValue(null);

        control.get('additionalValue').updateValueAndValidity();
    }

    public getPriceLevelControl(index: number): AbstractControl {

        return this.priceLevelsControl.controls[index];
    }

    public indexAsString(index: number): string {

        return index.toString();
    }

    public addLocation(): void {

        this.locationsControl.push(this.createLocationGroup({
            id: null,
            address: null,
            zipcode: null,
            city: null,
            country: null,
            department: null,
            latitude: null,
            longitude: null,
            position: this.locationsControl.controls.length + 1,
            isMain: false,
            providers: [],
            translations: []
        }));

        setTimeout((): void => {

            this.handleContentManagementValidators();
        });
    }

    public removeLocation(index: number) {

        const translationBuilderIndex: number = this.locationTranslationBuilders.findIndex((builder: TranslationBuilder): boolean => {

            return builder.form === this.getLocationControl(index);
        });

        this.locationTranslationBuilders.splice(translationBuilderIndex, 1);

        this.locationsControl.removeAt(index);

        this.locationsControl.controls.forEach((control: UntypedFormGroup, index: number): void => {

            control.patchValue({
                position: index + 1
            });
        });

        setTimeout((): void => {

            this.handleContentManagementValidators();
        });

        if(this.locationsControl.controls.length <= 1){

            this.form.get('circuitType').patchValue('itinerant' as OfferCircuitType);
        }
    }

    public getLocationControl(index: number): AbstractControl {

        return this.locationsControl.controls[index];
    }

    public getLocationTranslationBuilder(index: number): TranslationBuilder {

        return this.locationTranslationBuilders.find((builder: TranslationBuilder): boolean => {

            return builder.form === this.getLocationControl(index);
        });
    }

    public getTranslation(index: number): UntypedFormGroup {

        return this.translationsControl.controls[index] as UntypedFormGroup;
    }

    public getSortedPictureControls(control: AbstractControl): AbstractControl[] {

        return (control.get('pictures') as UntypedFormArray).controls.sort((a: UntypedFormGroup, b: UntypedFormGroup): number => {

            return a.value.position - b.value.position;
        });
    }

    public increasePicturePosition(index: number): void {

        this.offerService.displayedLocales$.getValue().forEach((locale: string): void => {

            const control: UntypedFormArray = this.translationBuilder.getItemControlByLocale(locale).get('pictures') as UntypedFormArray;

            this.increaseTranslationPicturePosition(control, index);

            control.controls.sort((a: UntypedFormGroup, b: UntypedFormGroup): number => {

                return a.value.position - b.value.position;
            });
        });
    }

    public increaseTranslationPicturePosition(control: AbstractControl, index: number): void {

        const groupToIncrease: UntypedFormGroup = (control as UntypedFormArray).at(index) as UntypedFormGroup;

        const currentPosition: number = groupToIncrease.value.position;

        const groupToDecrease: AbstractControl = (control as UntypedFormArray).controls.find((group: UntypedFormGroup): boolean => {

            return group.value.position === currentPosition + 1;
        });

        groupToIncrease.patchValue({
            position: currentPosition + 1
        });

        groupToDecrease.patchValue({
            position: groupToDecrease.value.position - 1
        });
    }

    public decreasePicturePosition(index: number): void {

        this.offerService.displayedLocales$.getValue().forEach((locale: string): void => {

            const control: UntypedFormArray = this.translationBuilder.getItemControlByLocale(locale).get('pictures') as UntypedFormArray;

            this.decreaseTranslationPicturePosition(control, index);

            control.controls.sort((a: UntypedFormGroup, b: UntypedFormGroup): number => {

                return a.value.position - b.value.position;
            });
        });
    }

    public decreaseTranslationPicturePosition(control: AbstractControl, index: number): void {

        const groupToDecrease: UntypedFormGroup = (control as UntypedFormArray).at(index) as UntypedFormGroup;

        const currentPosition: number = groupToDecrease.value.position;

        const groupToIncrease: AbstractControl = (control as UntypedFormArray).controls.find((group: UntypedFormGroup): boolean => {

            return group.value.position === currentPosition - 1;
        });

        groupToDecrease.patchValue({
            position: currentPosition - 1
        });

        groupToIncrease.patchValue({
            position: groupToIncrease.value.position + 1
        });
    }

    public isIncreasePicturePositionAllowed(index: number): boolean {

        const control: UntypedFormArray = this.translationBuilder.itemsControl.at(0).get('pictures') as UntypedFormArray;

        return this.isIncreaseTranslationPicturePositionAllowed(control, index);
    }

    public isIncreaseTranslationPicturePositionAllowed(control: AbstractControl, index: number): boolean {

        const group: UntypedFormGroup = (control as UntypedFormArray).controls[index] as UntypedFormGroup;

        const position: number = group.value.position;

        return position !== (control as UntypedFormArray).controls.length;
    }

    public isDecreasePicturePositionAllowed(index: number): boolean {

        const control: UntypedFormArray = this.translationBuilder.itemsControl.at(0).get('pictures') as UntypedFormArray;

        return this.isDecreaseTranslationPicturePositionAllowed(control, index);
    }

    public isDecreaseTranslationPicturePositionAllowed(control: AbstractControl, index: number): boolean {

        const group: UntypedFormGroup = (control as UntypedFormArray).controls[index] as UntypedFormGroup;

        const position: number = group.value.position;

        return position !== 1;
    }

    public addPicture(): void {

        this.offerService.displayedLocales$.getValue().forEach((locale: string): void => {

            const translationControl = this.translationBuilder.getItemControlByLocale(locale);

            this.addTranslationPicture(translationControl.get('pictures'));
        });

        this.translationsControl.controls.forEach((translationControl: UntypedFormGroup): void => {

            translationControl.get('pictures').updateValueAndValidity();
        });

        const sourcePicturesControl: UntypedFormArray = this.translationBuilder.itemsControl.at(0).get('pictures') as UntypedFormArray;

        const index: number = sourcePicturesControl.length - 1;

        const sourcePictureControl: UntypedFormGroup = sourcePicturesControl.at(index) as UntypedFormGroup;

        sourcePictureControl.get('copyright').valueChanges.subscribe((): void => {

            if(this.isTranslationPictureType('dissociated')){

                return;
            }

            setTimeout((): void => {

                const locales: string[] = this.offerService.displayedLocales$.getValue().filter((locale: string): boolean => {

                    return this.translationBuilder.itemsControl.at(0).get('locale').value !== locale;
                });

                locales.forEach((locale: string): void => {

                    const destinationPicturesControl: UntypedFormArray = (this.translationBuilder.getItemControlByLocale(locale).get('pictures') as UntypedFormArray);

                    const destinationPictureControl: UntypedFormGroup = destinationPicturesControl.at(index) as UntypedFormGroup;

                    if(destinationPictureControl){

                        destinationPictureControl.get('copyright').patchValue(sourcePictureControl.get('copyright').value);
                    }
                });

                this.translationsControl.controls.forEach((translationControl: UntypedFormGroup): void => {

                    translationControl.get('pictures').updateValueAndValidity();
                });
            });
        });

        sourcePictureControl.get('image').valueChanges.subscribe((data: { image: { image } }): void => {

            if(this.isTranslationPictureType('dissociated')){

                return;
            }

            setTimeout((): void => {

                if(!data.image){

                    return;
                }

                const locales: string[] = this.offerService.displayedLocales$.getValue().filter((locale: string): boolean => {

                    return this.translationBuilder.itemsControl.at(0).get('locale').value !== locale;
                });

                locales.forEach((locale: string): void => {

                    const destinationPicturesControl: UntypedFormArray = (this.translationBuilder.getItemControlByLocale(locale).get('pictures') as UntypedFormArray);

                    const destinationPictureControl: UntypedFormGroup = destinationPicturesControl.at(index) as UntypedFormGroup;

                    if(destinationPictureControl){

                        this._imageService.getCloneImage(sourcePictureControl.get('image').get('image').value.reference).subscribe((cloneImage: Image): void => {

                            destinationPictureControl.get('image').get('image').patchValue(cloneImage);

                            this.translationsControl.controls.forEach((translationControl: UntypedFormGroup): void => {

                                translationControl.get('pictures').updateValueAndValidity();
                            });
                        });
                    }
                });
            });
        });
    }

    public addTranslationPicture(control: AbstractControl): void {

        const position: number = (control.value as []).length + 1;

        (control as UntypedFormArray).push(
            this._formBuilder.group({
                copyright: ['', [Validators.required]],
                image: this._formBuilder.group({
                    image: [null, [Validators.required]]
                }),
                position: [position, [Validators.required]]
            })
        );

        setTimeout((): void => {

            this.handleContentManagementValidators();
        });
    }

    public removePicture(index: number): void {

        this.offerService.displayedLocales$.getValue().forEach((locale: string): void => {

            const control: UntypedFormArray = this.translationBuilder.getItemControlByLocale(locale).get('pictures') as UntypedFormArray;

            this.removeTranslationPicture(control, index);
        });
    }

    public removeTranslationPicture(control, index) {

        control.removeAt(index);

        const groups: UntypedFormGroup[] = ((control as UntypedFormArray).controls as UntypedFormGroup[]).filter((group: UntypedFormGroup): boolean => {

            const item: OfferTranslationPicture = group.value;

            return item.position > (index + 1);
        });

        groups.forEach((group: UntypedFormGroup): void => {

            group.patchValue({
                position: group.value.position - 1
            });
        });

        setTimeout((): void => {

            this.handleContentManagementValidators();
        });
    }

    public redirectToList(): void {

        this._router.navigate(['account/offer/list/personnal-offers']);
    }

    public submit(saveAndRedirect: boolean, target?: ('availabilities')): void {

        this.saveAndRedirectConfiguration = {
            active: saveAndRedirect,
            target: target
        };

        this.form.get('attributes').updateValueAndValidity();

        this.formSubmitSubscription.next(this.form.value);

        this.formService.submit();
    }

    public openLeaveWithoutSubmitDialog(): void {

        const dialogRef: MatDialogRef<ConfirmDialogComponent> = this._dialog.open(ConfirmDialogComponent, {
            width: '500px',
            data: {
                title: this._translateService.instant('form.leaveWithoutSubmit.value'),
                content: this._translateService.instant('form.leaveWithoutSubmit.description.value')
            }
        });

        dialogRef.componentInstance.confirm.subscribe((): void => {

            this.redirectToList();
        });
    }

    public isSelectedGiftVoucherType(type: VoucherType): boolean {

        return this.giftVoucherControl.get('types').value.includes(type);
    }

    public handleGiftVoucherType(event: any): void {

        const control: UntypedFormControl = this.giftVoucherControl.get('types') as UntypedFormControl;

        const controlValue: VoucherType[] = control.value;

        const value: VoucherType = event.target.value;

        if (event.target.checked) {

            controlValue.push(value);
        } else {

            const index: number = controlValue.findIndex((i: VoucherType): boolean => {

                return i === value;
            });

            controlValue.splice(index, 1);
        }

        control.patchValue(controlValue);
    }

    public isTranslationPictureType(value: TranslationPictureType): boolean {

        return this.form.get('translationPictureType').value === value;
    }

    public scrollToBottomOfElement(element: ElementRef): void {

        if(!element || !element.nativeElement){

            return;
        }

        element.nativeElement.scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "nearest"
        });
    }

    get form(): UntypedFormGroup {

        return this.formService.form;
    }

    get seoControl(): UntypedFormGroup {

        return this.form.get('seo') as UntypedFormGroup;
    }

    get presentialControl(): UntypedFormGroup {

        return this.form.get('presential') as UntypedFormGroup;
    }

    get groupDurationControl(): UntypedFormGroup {

        return this.form.get('duration') as UntypedFormGroup;
    }

    get giftVoucherControl(): UntypedFormArray {

        return this.form.get('giftVoucher') as UntypedFormArray;
    }

    get programsControl(): UntypedFormArray {

        return this.form.get('programs') as UntypedFormArray;
    }

    get trueIncludedControl(): UntypedFormArray {

        return this.form.get('trueIncluded') as UntypedFormArray;
    }

    get falseIncludedControl(): UntypedFormArray {

        return this.form.get('falseIncluded') as UntypedFormArray;
    }

    get includedControl(): UntypedFormArray {

        return this.form.get('included') as UntypedFormArray;
    }

    get priceLevelsControl(): UntypedFormArray {

        return this.form.get('priceLevels') as UntypedFormArray;
    }

    get locationsControl(): UntypedFormArray {

        return this.form.get('locations') as UntypedFormArray;
    }

    get translationsControl(): UntypedFormArray {

        return this.form.get('translations') as UntypedFormArray;
    }

    get restrictedSocietiesControl(): UntypedFormControl {

        return this.form.get('restrictedSocieties') as UntypedFormControl;
    }

    get attributesControl(): UntypedFormArray {

        return this.form.get('attributes') as UntypedFormArray;
    }

    get providerSocietiesControl(): UntypedFormControl {

        return this.form.get('providerSocieties') as UntypedFormControl;
    }

    get customerTypologyControl(): UntypedFormArray {

        return this.form.get('customerTypology') as UntypedFormArray;
    }

    get providerLocations(): UntypedFormControl {

        return this.form.get('providerLocations') as UntypedFormControl;
    }

    get localeId(): string {

        return this._translateService.currentLang;
    }

    get offerPricePublics(): OfferPricePublic[] {

        return OFFER_PRICE_PUBLICS;
    }

    get offerGiftVoucherTypes(): GiftVoucherType[] {

        return OFFER_GIFTVOUCHER_TYPES;
    }

    get offerPriceLevelTypes(): OfferPriceLevelType[] {

        return OFFER_PRICE_LEVEL_TYPES;
    }

    get offerPriceLevelIncreaseAreas(): OfferPriceLevelIncreaseArea[] {

        return OFFER_PRICE_LEVEL_INCREASE_AREAS;
    }

    get salesPitchEditorConfig(): CkeditorConfig {

        return {
            id: 'salesPitch',
            editor: this.editor,
            attrs: {
                required: false
            }
        }
    }

    get descriptionEditorConfig(): CkeditorConfig {

        return {
            id: 'description',
            editor: this.editor,
            attrs: {
                required: false,
                maxLength: 7000
            }
        }
    }

    get picturesConfig(): ImageConfig {

        return {
            id: 'image',
            gallery_context: 'offer_picture',
            required: false,
            uploadApiUrl: this.apiService.getApiUrl(false, true),
            options: {
                enableTitle: false,
                enableSubtitle: false,
                enableAlt: false,
                enableLink: false,
                enableTargetBlank: false,
                label: this._translateService.instant('offer.form.fields.picture.value')
            },
        }
    }

    get createGiftVoucherGroup() {

        return this._formBuilder.group({
            adultPriceTTC: [0, [Validators.pattern(REGEX_ONLY_NUMBER)]],
            childPriceTTC: [0, [Validators.pattern(REGEX_ONLY_NUMBER)]],
            types: [[]]
        });
    }

    get formTabValidationItemsCallback(): () => FormTabValidationItem[] {

        return (): FormTabValidationItem[] => {

            const items: Partial<FormTabValidationItem>[] = [];

            // Données générales

            items.push({
                tag: 'generalData',
                controls: [
                    this.form.get('publics'),
                    this.form.get('minChildrenAge'),
                    this.form.get('maxChildrenAge'),
                    this.form.get('reference'),
                    this.form.get('locales'),
                    this.form.get('optinAutomaticTranslation'),
                    this.form.get('published'),
                    this.form.get('isRestricted'),
                    this.form.get('restrictedSocieties'),
                    this.form.get('presential'),
                    this.form.get('duration')
                ]
            });

            // Gestion de contenu

            items.push({
                tag: 'contentManagement',
                controls: [
                    ...(this.form.get('translations') as UntypedFormArray).controls.map((group: UntypedFormGroup) => group.get('name')),
                    ...(this.form.get('translations') as UntypedFormArray).controls.map((group: UntypedFormGroup) => group.get('teaser')),
                    ...(this.form.get('translations') as UntypedFormArray).controls.map((group: UntypedFormGroup) => group.get('description')),
                    ...(this.form.get('translations') as UntypedFormArray).controls.map((group: UntypedFormGroup) => group.get('pictures')),
                    this.form.get('interests'),
                    this.form.get('included'),
                    this.form.get('trueIncluded'),
                    this.form.get('falseIncluded')
                ]
            });

            // Données programme

            items.push({
                tag: 'programData',
                controls: [
                    this.form.get('limitLocation'),
                    this.form.get('programs')
                ]
            });

            // Données caractéristiques

            items.push({
                tag: 'characteristicData',
                controls: [
                    this.form.get('customerTypology'),
                    this.attributesFormReference,
                    this.form.get('attributes')
                ]
            });

            // Données prestataires

            items.push({
                tag: 'providerData',
                controls: [
                    this.form.get('providerSocieties')
                ]
            });

            // Données localisations

            items.push({
                tag: 'locationData',
                controls: [
                    this.form.get('enableMultipleMainLocation'),
                    this.form.get('locations')
                ]
            });

            // Gestion du SEO

            items.push({
                tag: 'seoManagement',
                controls: [
                    this.form.get('seo')
                ]
            });

            // Argumentaire de vente

            items.push({
                tag: 'internalComments',
                controls: [
                    ...(this.form.get('translations') as UntypedFormArray).controls.map((group: UntypedFormGroup) => group.get('salesPitch')),
                    this.form.get('isOnAlert'),
                    this.form.get('commentAlert')
                ]
            });

            /**
             // Options

             items.push({
                tag: 'options',

                controls: [
                    this.form.get('options')
                ]
            });
             **/

            // Données tarifaires

            items.push({
                tag: 'pricingData',
                controls: [
                    this.form.get('timezone'),
                    this.form.get('onRequest'),
                    this.form.get('currency'),
                    this.form.get('priceCalculationAutomatic'),
                    this.form.get('vatPercent'),
                    this.form.get('allowBookingRequest'),
                    this.form.get('priceLevels'),
                    this.form.get('markup'),
                    this.form.get('displayPrice')
                ]
            });

            // Bon cadeaux

            if(this.hasAccessGiftVoucher()){

                items.push({
                    tag: 'giftVoucherData',
                    controls: [
                        this.form.get('giftVoucher').get('types'),
                        this.form.get('giftVoucher').get('childPriceTTC'),
                        this.form.get('giftVoucher').get('adultPriceTTC'),
                    ]
                });
            }

            return items.map((item: Partial<FormTabValidationItem>, index: number): FormTabValidationItem => {

                return Object.assign(item, {
                    position: index + 1
                } as FormTabValidationItem);
            });
        };
    }

    get selectedLocales(): string[] {

        if(!this.form || !this.form.get('locales')){

            return [];
        }

        return this.form.get('locales').value;
    }

    get hasAccessMultipleGeolocation(): boolean {

        return this.user.accesses.some((access: Access): boolean => {

            return access.tag === 'MULTIPLE_GEOLOCATION';
        });
    }

    get geolocationIsAllowed(): boolean {

        return this.hasAccessMultipleGeolocation && this.limitProgram === OFFER_WITHOUT_NIGHT;
    }

    get allRestrictedSocieties(): Society[] {

        if(!this.form.get('isRestricted').value){

            return [];
        }

        const items: Society[] = [];

        // Récupération du créateur d'offre

        items.push(this.society);

        // Récupération des prestataires sélectionnés

        items.push(...this.providerSocietiesControl.value as Society[]);

        // Récupération des distributeurs sélectionnés

        items.push(...this.restrictedSocietiesControl.value as Society[]);

        return [...new Map(items.map((item: Society) => [item.id, item])).values()];
    }

    get hasAccessAutomaticTranslation(): boolean {

        return this.user.accesses.some((access: Access): boolean => {

            return access.tag === 'AUTOMATIC_TRANSLATION';
        });
    }

    get isPublishable(): boolean {

        return this.society.countAllowedPublishedOffers === null || (this.society.countAllowedPublishedOffers - this.society.countPublishedOffers) > 0;
    }
}
