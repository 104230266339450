import {Component, OnInit, Input, ViewChildren, QueryList, AfterViewInit, Output, EventEmitter, ChangeDetectorRef} from '@angular/core';
import {TranslationBuilder, TranslationItem} from '@core/shared/models/translation';
import {AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {ApiService} from '@core/shared/services/api.service';
import {FormService} from '@core/shared/services/form.service';
import {CkeditorConfig} from '@lib/form/fields/ckeditor/ckeditor.component';
import * as ClassicEditor from '@lib/ckeditor';
import {ImageConfig} from '@lib/form/fields/image/image.component';
import {Offer} from '@core/shared/models/offer';
import {OfferProgram, OfferProgramTranslation, OfferProgramTranslationPicture} from '@core/shared/models/offer/offer-program';
import {MatExpansionPanel} from '@angular/material/expansion';
import {InputCapitalizeDirective} from '@core/shared/directives/input-capitalize.directive';
import {OfferService} from "@core/shared/services/offer.service";
import {TranslationPictureType} from "@core/shared/models/translation/translation-picture";
import {ImageEventService} from "@lib/media/image/image.event.service";
import {Image} from "@lib/media/image/image";
import {ImageService} from "@lib/media/image/image.service";

@Component({
    selector: 'app-core-offer-programs-form',
    templateUrl: './offer-programs-form.component.html',
    styleUrls: ['./offer-programs-form.component.scss'],
    providers: [InputCapitalizeDirective]
})
export class OfferProgramsFormComponent implements OnInit, AfterViewInit {

    @ViewChildren(MatExpansionPanel) expensionPanels: QueryList<MatExpansionPanel>;

    @Input() parentForm: UntypedFormGroup;

    @Input() formArray: UntypedFormArray;

    @Input() limitProgram: number;

    @Input() offer: Offer;

    @Output() controlUpdated: EventEmitter<void> = new EventEmitter<void>();

    public programsTranslationBuilders: TranslationBuilder[] = [];

    public programsFileConfig: ImageConfig;

    public editor = ClassicEditor;

    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _formBuilder: UntypedFormBuilder,
        private _translateService: TranslateService,
        private _apiService: ApiService,
        private _imageService: ImageService,
        private _imageEventService: ImageEventService,
        public offerService: OfferService,
        public formService: FormService
    ) {
    }

    ngOnInit(): void {

        this._initFileConfig();

        if (this.offer){

            this._initData();
        }

        this._initEvents();
    }

    ngAfterViewInit(): void {

        setTimeout((): void => {

            if (this.expensionPanels && this.programsForm.length === 1){

                this.expensionPanels.first.expanded = true;
            }
        });
    }

    private _initData(): void {

        this.offer.programs.forEach((program: OfferProgram): void => {

            const group: UntypedFormGroup = this.programControl;

            group.patchValue(program);

            const translationBuilder: TranslationBuilder = this.generateTranslationBuilder;

            translationBuilder.form = group;

            program.translations.forEach((translation: OfferProgramTranslation): void => {

                translationBuilder.addItemControl(translationBuilder.getLocaleItem(translation.locale), translation);
            });

            this.programsTranslationBuilders.push(translationBuilder);

            this.formArray.push(group);
        });

        setTimeout((): void => {

            this._handleCommonPicturesEvents();
        });
    }

    private _initEvents(): void {

        this.parentForm.get('locales').valueChanges.subscribe((locales: string[]): void => {

            this.programsTranslationBuilders.forEach((translationBuilder: TranslationBuilder): void => {

                locales.forEach((locale: string): void => {

                    const indexExistingLocale: number = translationBuilder.getItemIndexByLocale(locale);

                    if (indexExistingLocale < 0){

                        translationBuilder.addItemControl(translationBuilder.getLocaleItem(locale));
                    }
                });

                const translationsToDelete: TranslationItem[] = (translationBuilder.itemsControl.value as TranslationItem[]).filter((translation: TranslationItem): boolean => {

                    return !locales.includes(translation.locale);
                });

                translationsToDelete.forEach((translation: TranslationItem): void => {

                    const index: number = translationBuilder.getItemIndexByLocale(translation.locale);

                    translationBuilder.removeItem(index);
                });

                if(this.parentForm.get('hasProgramsPictures').value && this.isTranslationPictureType('common')){

                    const locales: string[] = this.offerService.displayedLocales$.getValue().filter((locale: string): boolean => {

                        return translationBuilder.itemsControl.at(0).get('locale').value !== locale;
                    });

                    locales.forEach((locale: string): void => {

                        const sourcePictureControl: UntypedFormGroup = translationBuilder.itemsControl.at(0).get('picture') as UntypedFormGroup;

                        const destinationPictureControl: UntypedFormGroup = translationBuilder.getItemControlByLocale(locale).get('picture') as UntypedFormGroup;

                        if(destinationPictureControl){

                            destinationPictureControl.get('copyright').patchValue(sourcePictureControl.get('copyright').value);

                            this._imageService.getCloneImage(sourcePictureControl.get('image').get('image').value.reference).subscribe((cloneImage: Image): void => {

                                destinationPictureControl.get('image').get('image').patchValue(cloneImage);

                                const destinationImage: Image = Object.assign(destinationPictureControl.get('image').get('image').value, {
                                    formats: sourcePictureControl.get('image').get('image').value.formats
                                });

                                this._imageService.crop(destinationImage).subscribe((): void => {});
                            });
                        }
                    });
                }
            });
        });

        this.parentForm.get('hasProgramsPictures').valueChanges.subscribe((value: boolean) => {

            this._handleProgramsPictures(value);
        });

        this.parentForm.get('translationPictureType').valueChanges.subscribe((): void => {

            if(this.parentForm.get('hasProgramsPictures').value && this.isTranslationPictureType('common')){

                this.programsTranslationBuilders.forEach((translationBuilder: TranslationBuilder): void => {

                    const locales: string[] = this.offerService.displayedLocales$.getValue().filter((locale: string): boolean => {

                        return translationBuilder.itemsControl.at(0).get('locale').value !== locale;
                    });

                    locales.forEach((locale: string): void => {

                        const sourcePictureControl: UntypedFormGroup = translationBuilder.itemsControl.at(0).get('picture') as UntypedFormGroup;

                        const destinationPictureControl: UntypedFormGroup = translationBuilder.getItemControlByLocale(locale).get('picture') as UntypedFormGroup;

                        if(destinationPictureControl){

                            if(destinationPictureControl.get('copyright')){

                                destinationPictureControl.get('copyright').patchValue(null);
                            }

                            destinationPictureControl.get('copyright').patchValue(sourcePictureControl.get('copyright').value);

                            if(destinationPictureControl.get('image')){

                                destinationPictureControl.get('image').get('image').patchValue(null);
                            }

                            this._imageService.getCloneImage(sourcePictureControl.get('image').get('image').value.reference).subscribe((cloneImage: Image): void => {

                                destinationPictureControl.get('image').get('image').patchValue(cloneImage);

                                const destinationImage: Image = Object.assign(destinationPictureControl.get('image').get('image').value, {
                                    formats: sourcePictureControl.get('image').get('image').value.formats
                                });

                                this._imageService.crop(destinationImage).subscribe((): void => {

                                    destinationPictureControl.updateValueAndValidity();
                                });
                            });
                        }
                    });
                });

                this._handleCommonPicturesEvents();
            }
        });

        this._imageEventService.imageCropped.subscribe((image: Image): void => {

            if(!this.parentForm.get('hasProgramsPictures').value || this.isTranslationPictureType('dissociated')){

                return;
            }

            this.programsTranslationBuilders.forEach((translationBuilder: TranslationBuilder): void => {

                const item: OfferProgramTranslationPicture = translationBuilder.itemsControl.at(0).get('picture').value;

                if(!item.image || !item.image.image){

                    return;
                }

                if((item.image.image as unknown as Image).id !== image.id){

                    return;
                }

                const locales: string[] = this.offerService.displayedLocales$.getValue().filter((locale: string): boolean => {

                    return translationBuilder.itemsControl.at(0).get('locale').value !== locale;
                });

                locales.forEach((locale: string): void => {

                    const destinationPictureControl: UntypedFormGroup = translationBuilder.getItemControlByLocale(locale).get('picture') as UntypedFormGroup;

                    if(destinationPictureControl){

                        const destinationImage: Image = Object.assign(destinationPictureControl.get('image').get('image').value, {
                            formats: image.formats
                        });

                        this._imageService.crop(destinationImage).subscribe((): void => {

                            this._changeDetectorRef.detectChanges();
                        });
                    }
                });
            });
        });
    }

    private _initFileConfig(): void {

        this._translateService.get('file.extension.list.allowed.value', {list: '.png, .jpeg'}).subscribe((): void => {

            this.programsFileConfig = {
                id: 'image',
                gallery_context: 'offer_program_picture',
                required: false,
                uploadApiUrl: this._apiService.getApiUrl(false, true),
                options: {
                    enableTitle: false,
                    enableSubtitle: false,
                    enableAlt: false,
                    enableLink: false,
                    enableTargetBlank: false
                }
            };
        });
    }

    private _handleProgramsPictures(value: boolean): void {

        this.programsTranslationBuilders.forEach((translationBuilder: TranslationBuilder): void => {

            const translationControls = translationBuilder.itemsControl.controls;

            translationControls.forEach((control: UntypedFormGroup) => {

                if (value) {

                    control.setControl('picture', this.programPicturesGroup);
                }
                else {

                    control.setControl('picture', new UntypedFormControl(null));
                }

                control.updateValueAndValidity();
            });
        });

        if(value && this.isTranslationPictureType('common')){

            this._handleCommonPicturesEvents();
        }
    }

    private _handleCommonPicturesEvents(): void {

        this.programsTranslationBuilders.forEach((translationBuilder: TranslationBuilder): void => {

            const translationControls = translationBuilder.itemsControl.controls;

            translationControls.forEach(() => {

                const sourcePictureControl: UntypedFormGroup = translationBuilder.itemsControl.at(0).get('picture') as UntypedFormGroup;

                sourcePictureControl.get('copyright').valueChanges.subscribe((): void => {

                    if(this.isTranslationPictureType('dissociated')){

                        return;
                    }

                    setTimeout((): void => {

                        const locales: string[] = this.offerService.displayedLocales$.getValue().filter((locale: string): boolean => {

                            return translationBuilder.itemsControl.at(0).get('locale').value !== locale;
                        });

                        locales.forEach((locale: string): void => {

                            const destinationPictureControl: UntypedFormGroup = translationBuilder.getItemControlByLocale(locale).get('picture') as UntypedFormGroup;

                            if(destinationPictureControl && destinationPictureControl.get('copyright')){

                                destinationPictureControl.get('copyright').patchValue(sourcePictureControl.get('copyright').value);

                                destinationPictureControl.updateValueAndValidity();
                            }
                        });
                    });
                });

                sourcePictureControl.get('image').valueChanges.subscribe((data: { image: { image } }): void => {

                    if(this.isTranslationPictureType('dissociated')){

                        return;
                    }

                    setTimeout((): void => {

                        if(!data.image){

                            return;
                        }

                        const locales: string[] = this.offerService.displayedLocales$.getValue().filter((locale: string): boolean => {

                            return translationBuilder.itemsControl.at(0).get('locale').value !== locale;
                        });

                        locales.forEach((locale: string): void => {

                            const destinationPictureControl: UntypedFormGroup = translationBuilder.getItemControlByLocale(locale).get('picture') as UntypedFormGroup;

                            if(destinationPictureControl && destinationPictureControl.get('image')){

                                this._imageService.getCloneImage(sourcePictureControl.get('image').get('image').value.reference).subscribe((cloneImage: Image): void => {

                                    const sourceImage: Image = sourcePictureControl.get('image').get('image').value;

                                    const destinationImage: Image = Object.assign(cloneImage, {
                                        formats: sourceImage.formats
                                    } as Image);

                                    destinationPictureControl.get('image').get('image').patchValue(destinationImage);

                                    destinationPictureControl.updateValueAndValidity();

                                    this._imageService.crop(destinationImage).subscribe((): void => {

                                        this._changeDetectorRef.detectChanges();
                                    });
                                });
                            }
                        });
                    });
                });
            });
        });
    }

    public addProgram(): void {

        const group: UntypedFormGroup = this.programControl;

        group.patchValue({
            position: this.programsForm.controls.length + 1
        });

        const translationBuilder: TranslationBuilder = this.generateTranslationBuilder;

        translationBuilder.form = group;

        (this.parentForm.get('locales').value as string[]).forEach((locale: string): void => {

            translationBuilder.addItemControl(translationBuilder.getLocaleItem(locale));
        });

        this.programsTranslationBuilders.push(translationBuilder);

        if(this.parentForm.get('hasProgramsPictures').value){

            const sourcePictureControl: UntypedFormGroup = translationBuilder.itemsControl.at(0).get('picture') as UntypedFormGroup;

            sourcePictureControl.get('copyright').valueChanges.subscribe((): void => {

                if(this.isTranslationPictureType('dissociated')){

                    return;
                }

                setTimeout((): void => {

                    const locales: string[] = this.offerService.displayedLocales$.getValue().filter((locale: string): boolean => {

                        return translationBuilder.itemsControl.at(0).get('locale').value !== locale;
                    });

                    locales.forEach((locale: string): void => {

                        const destinationPictureControl: UntypedFormGroup = translationBuilder.getItemControlByLocale(locale).get('picture') as UntypedFormGroup;

                        if(destinationPictureControl){

                            destinationPictureControl.get('copyright').patchValue(sourcePictureControl.get('copyright').value);

                            destinationPictureControl.updateValueAndValidity();
                        }
                    });
                });
            });

            sourcePictureControl.get('image').valueChanges.subscribe((data: { image: { image } }): void => {

                if(this.isTranslationPictureType('dissociated')){

                    return;
                }

                setTimeout((): void => {

                    if(!data.image){

                        return;
                    }

                    const locales: string[] = this.offerService.displayedLocales$.getValue().filter((locale: string): boolean => {

                        return translationBuilder.itemsControl.at(0).get('locale').value !== locale;
                    });

                    locales.forEach((locale: string): void => {

                        const destinationPictureControl: UntypedFormGroup = translationBuilder.getItemControlByLocale(locale).get('picture') as UntypedFormGroup;

                        if(destinationPictureControl){

                            this._imageService.getCloneImage(sourcePictureControl.get('image').get('image').value.reference).subscribe((cloneImage: Image): void => {

                                destinationPictureControl.get('image').get('image').patchValue(cloneImage);

                                destinationPictureControl.updateValueAndValidity();
                            });
                        }
                    });
                });
            });
        }

        this.formArray.push(group);

        setTimeout((): void => {

            this.controlUpdated.emit();
        });
    }

    public deleteProgram(index: number): void {

        this.programsForm.removeAt(index);

        setTimeout((): void => {

            this.controlUpdated.emit();
        });
    }

    public indexAsString(index: number): string {

        return index.toString();
    }

    public getTranslationBuilder(index: number): TranslationBuilder {

        return this.programsTranslationBuilders.find((builder: TranslationBuilder): boolean => {

            return builder.form === this.getProgramControl(index);
        });
    }

    public getProgramControl(index: number): AbstractControl {

        return this.programsForm.controls[index];
    }

    public openNewPanel(): void {

        setTimeout((): void => {

            this.expensionPanels.last.expanded = true;
        });
    }

    public isTranslationPictureType(value: TranslationPictureType): boolean {

        return this.parentForm.get('translationPictureType').value === value;
    }

    get programsForm(): UntypedFormArray {

        return this.parentForm.get('programs') as UntypedFormArray;
    }

    get localeId(): string {

        return this._translateService.currentLang;
    }

    get descriptionEditorConfig(): CkeditorConfig {

        return {
            id: 'description',
            editor: this.editor,
            attrs: {
                required: true,
                maxLength: 600
            }
        }
    }

    get programControl(): UntypedFormGroup {

        return this._formBuilder.group({
            position: [0, [Validators.required]],
            translations: new UntypedFormArray([])
        });
    }

    get generateTranslationBuilder(): TranslationBuilder {

        const translationBuilder = new TranslationBuilder(this._formBuilder);

        translationBuilder.addItemCallback = (): UntypedFormGroup => {

            return this.programTranslationControl;
        };

        return translationBuilder;
    }

    get programTranslationControl(): UntypedFormGroup {

        const group: UntypedFormGroup = this._formBuilder.group({
            title: ['', Validators.required],
            description: ['', Validators.required],
        });

        if (this.parentForm.get('hasProgramsPictures').value){

            group.addControl('picture', this.programPicturesGroup);
        }

        return group;
    }

    get programPicturesGroup(): UntypedFormGroup {

        return  this._formBuilder.group({
            copyright: ['', [Validators.required]],
            image: this._formBuilder.group({
                image: ['', [Validators.required]]
            })
        });
    }

    get selectedLocales(): string[] {

        if (!this.parentForm || !this.parentForm.get('locales')){

            return [];
        }

        return this.parentForm.get('locales').value;
    }

    get form(): UntypedFormGroup {

        return this.parentForm;
    }
}
