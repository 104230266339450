import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from "@core/shared/services/authentication.service";

@Component({
  selector: 'app-core-page-logout',
  templateUrl: './page-logout.component.html',
  styleUrls: ['./page-logout.component.scss']
})
export class PageLogoutComponent implements OnInit {

    constructor(
        private _authenticationService: AuthenticationService,
    ) {}

    ngOnInit() {

        setTimeout((): void => {

            this._authenticationService.logout();
        });
    }
}
