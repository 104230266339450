import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UserService} from "@core/shared/services/user.service";
import {Article} from "@core/shared/models/article";
import {TranslationService} from "@core/shared/services/translation.service";
import {TranslationItem} from '@core/shared/models/translation';
import {ImageService} from '@lib/media/image/image.service';
import {ApiService} from '@core/shared/services/api.service';
import {Image} from '@lib/media/image/image';
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";
import {ImageEventService} from "@lib/media/image/image.event.service";

export type Mode = 'preview' | 'view';

@Component({
    selector: 'app-core-article-read',
    templateUrl: './article-read.component.html',
    styleUrls: ['./article-read.component.scss']
})
export class ArticleReadComponent implements OnInit {

    @Input() item: Article;

    @Input() mode: Mode;

    @Input() locale: string;

    @Output() onLocaleUpdated: EventEmitter<string> = new EventEmitter<string>(null);

    public visualUrl: SafeUrl = null;

    public defaultVisualUrl: string = 'assets/images/offer/preview/1.jpg';

    constructor(
        private _sanitizer: DomSanitizer,
        private _imageService: ImageService,
        private _imageEventService: ImageEventService,
        private _apiService: ApiService,
        public translationService: TranslationService,
        public userService: UserService
    ) {}

    ngOnInit() {

        this._loadVisual();
    }

    private _loadVisual(): void {

        if(this.mode === 'view'){

            this.visualUrl = this._imageService.getImageUrlWithNoCache(`${this._apiService.getApiUrl(false, false)}/public/articles/${this.item.id}/picture/filter/landscape/1500/90`);
        }
        else{

            const image: Image = this.item.picture.image.image as Image;

            const loadVisualCallback = (): void => {

                this._imageService.getBinaryImage(image, 'landscape', 1500, 90, null).subscribe((blob: Blob): void => {

                    const objectURL: string = URL.createObjectURL(blob);

                    this.visualUrl = this._sanitizer.bypassSecurityTrustUrl(objectURL);
                });
            };

            loadVisualCallback();

            this._imageEventService.imageCropped.subscribe((item: Image): void => {

                if(item.id !== image.id){

                    return;
                }

                loadVisualCallback();
            });
        }
    }

    public setDefaultVisualUrl(): void {

        this.visualUrl = this.defaultVisualUrl;
    }

    public getTranslation<T extends TranslationItem>(translations: Array<T>, localeFallback: boolean = false): T {

        return localeFallback ? this.translationService.getFallbackTranslation(translations, this.userService.currentUser.getValue(), this.locale) : translations.find((translation: T): boolean => {

            return translation.locale === this.locale;

        }) || translations[0];
    }
}
