import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from "rxjs/operators";
import {AuthenticationService} from "@core/shared/services/authentication.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";
import {environment} from "../../../../environments/environment";
import {LOCKABLE_RESOURCE_HEADER_IDENTIFIER} from "@core/shared/data/lockable";

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

    constructor(
        private _snackBar: MatSnackBar,
        private _translateService: TranslateService,
        private _authenticationService: AuthenticationService
    ) {
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

        return next.handle(request).pipe(
            catchError(err => {

                if([420].includes(err.status) || request.headers.has(LOCKABLE_RESOURCE_HEADER_IDENTIFIER)){

                    // Pas de notification affichée

                    return throwError(err);
                }

                let error: string = null;

                switch (err.status) {

                    case 401:

                        if(this._authenticationService.isAuthenticated && environment.logoutOn401Error){

                            this._authenticationService.logout();
                        }

                        error = this._getError(err);

                        break;

                    case 403:

                        if(this._authenticationService.isAuthenticated && environment.logoutOn403Error){

                            this._authenticationService.logout();
                        }

                        error = `${this._translateService.instant('access.forbidden.value')}, ${this._translateService.instant('authentication.disconnected.value').toLowerCase()}.`;

                        break;

                    case 413:

                        error = `${this._translateService.instant('file.upload.tooLarge.value')}`;

                        break;

                    case 498:

                        if(this._authenticationService.isAuthenticated){

                            this._authenticationService.logout();
                        }

                        error = this._getError(err);

                        break;

                    default:

                        error = this._getError(err);
                }

                this._snackBar.open(error, this._translateService.instant('error.notification.close.value'), {
                    duration: environment.production ? 20000 : 3000
                });

                return throwError(error);
            })
        )
    }

    private _getError(data): string {

        let error: string = '';

        switch (true) {

            case typeof data.error === 'string':

                if((data.error as string).includes('/doctrine/orm/Proxies')){

                    error = 'Erreur Proxy Doctrine';
                }
                else{

                    error = this._translateService.instant(data.error) || data.error;
                }

                break;

            case Array.isArray(data.error):

                const errors: string[] = (data.error as string[]).map((item: string): string => {

                    return this._translateService.instant(item) || item;
                });

                error = Array.from(new Set(errors)).join(' ');

                break;

            case typeof data.error === 'object':

                error = data.error.message ? (this._translateService.instant(data.error.message) || data.error.message) : (this._translateService.instant(data.statusText) || data.statusText);

                break;

            default:

                error = this._translateService.instant(data.statusText) || data.statusText;
        }

        return error;
    }
}
