<div class="breadcrumb">
    <ng-container *ngFor="let item of items">
        <ng-container *ngIf="item.onClickCallback; else noButtonLink">
            <span>
                <a (click)="item.onClickCallback()">{{ item.label }}</a>
            </span>
        </ng-container>

        <ng-template #noButtonLink>
            <span>
                <span>{{ item.label }}</span>
            </span>
        </ng-template>

    </ng-container>
</div>