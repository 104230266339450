<div class="content_article">
    <div class="box_lg marges">

        <ng-container *ngIf="backEnabled">
            <div class="breadcrumb">
                <span>
                    <a (click)="backClicked()" class="icon-tywin-chevron">{{ 'action.back' | translate}}</a>
                </span>
            </div>
        </ng-container>

        <app-core-page-read-content [item]="page" [mode]="'view'"></app-core-page-read-content>

    </div>
</div>

