<div class="content_cms">
    <div class="box_full">
        <div class="row">
            <div class="col_12">
                <ng-container *ngFor="let row of content.rows">
                    <div class="row cms_row">
                        <ng-container *ngFor="let column of row.columns">
                            <div class="col_12 col_md_{{ column.colWidth }} cms_column">
                                <div class="row h_full y_content_center" [ngClass]="(column.backgroundColor) ? column.backgroundColor.tag : 'without_bg'">
                                    <ng-container *ngFor="let element of column.elements">

                                        <div *ngIf="element.content" class="col_12 element_{{ element.type }}">

                                            <h2 *ngIf="element.title" class="titre_element">{{ element.title }}</h2>

                                            <span *ngIf="element.subtitle" class="sous_titre_element">{{ element.subtitle }}</span>

                                            <div *ngIf="element.type == 'button'">
                                                <app-cms-button [button]="element"></app-cms-button>
                                            </div>
                                            <div *ngIf="element.type == 'text'">
                                                <app-cms-text [text]="element"></app-cms-text>
                                            </div>
                                            <div *ngIf="element.type == 'file'">
                                                <app-cms-file [file]="element"></app-cms-file>
                                            </div>
                                            <div *ngIf="element.type == 'image'">
                                                <app-cms-image [image]="element.content"></app-cms-image>
                                            </div>
                                            <div *ngIf="element.type == 'slideshow'">
                                                <app-cms-slideshow [slideshow]="element.content"></app-cms-slideshow>
                                            </div>
                                            <div *ngIf="element.type == 'iframe'">
                                                <app-cms-iframe [iframe]="element"></app-cms-iframe>
                                            </div>
                                            <div *ngIf="element.type == 'video'">
                                                <app-cms-video [video]="element"></app-cms-video>
                                            </div>
                                            <div *ngIf="element.type == 'accordionItem'">
                                                <app-cms-accordion-item [item]="element.content"></app-cms-accordion-item>
                                            </div>

                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>
