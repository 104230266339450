import {Pipe, PipeTransform} from '@angular/core';
import {TranslationService} from "@core/shared/services/translation.service";

@Pipe({
    name: 'translationFromQuantity'
})
export class TranslationFromQuantityPipe implements PipeTransform {

    constructor(
        private _translationService: TranslationService
    ) {}

    transform(quantity: number, emptyTranslation: string, singularTranslation: string, pluralTranslation: string): string {

       return this._translationService.getTranslationFromQuantity(quantity, emptyTranslation, singularTranslation, pluralTranslation);
    }
}
