import {LocaleItem} from "@core/shared/models/locale";

export const SUPPORTED_LOCALE_ITEMS: LocaleItem[] = [
    {
        value: 'fr',
        label: 'locale.fr.value'
    },
    {
        value: 'en',
        label: 'locale.en.value'
    },
    {
        value: 'es',
        label: 'locale.es.value'
    },
    {
        value: 'it',
        label: 'locale.it.value'
    },
    {
        value: 'pt',
        label: 'locale.pt.value'
    },
    {
        value: 'de',
        label: 'locale.de.value'
    },
    {
        value: 'nl',
        label: 'locale.nl.value'
    }
];
