import {Component, Input, OnInit} from '@angular/core';
import {INavigationItem} from "@app/navigation/shared/models/navigation";
import {UserService} from "@core/shared/services/user.service";
import {User} from "@core/shared/models/user";
import {SidenavService} from "@core/shared/services/sidenav.service";

@Component({
    selector: 'app-navigation-sidenav',
    templateUrl: './sidenav.component.html',
    styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {

    @Input() items: INavigationItem[];

    constructor(
        private _userService: UserService,
        public sidenavService: SidenavService
    ) {
    }

    ngOnInit(): void {
    }

    get displayPoweredBy(): boolean {

        const currentUser: User = this._userService.currentUser.getValue();

        if(!currentUser) {

            return false;
        }

        if(!currentUser.society) {

            return false;
        }

        return !!currentUser.society.administrationLogo;
    }

}
