import {TranslationItem} from "@core/shared/models/translation";

export enum SubscriptionTag {
    Premium = "premium",
    Starter = "starter"
}

export interface Subscription {

    id: number;

    maxOffer: number;

    maxChannel: number;

    maxProvider: number;

    dateStart: string;

    dateNextInvoice: string;

    admissionPrice: number;

    yearlyPrice: number;

    monthlyPrice: number;

    rank: number;

    available: boolean;

    tag: SubscriptionTag;

    translations: SubscriptionTranslation[];

    isUnlimitedMaxOffer: boolean;
}

export interface SubscriptionTranslation extends TranslationItem {

    name: string;

}
