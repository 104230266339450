import {AfterViewInit, Component, OnInit} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import * as ClassicEditor from "@lib/ckeditor";
import {CkeditorConfig} from "@lib/form/fields/ckeditor/ckeditor.component";
import {FormService} from "@core/shared/services/form.service";
import {Config, Data as CmsData, ICmsParams} from '@lib/cms/cms';
import {ApiService} from "@core/shared/services/api.service";
import {Page} from "@core/shared/models/page";
import {ActivatedRoute, Router} from "@angular/router";
import {PageTranslation} from "@core/shared/models/page-translation";
import {LOCALE_ITEMS, LocaleItem, TranslationBuilder} from "@core/shared/models/translation";
import {ImageConfig} from "@lib/form/fields/image/image.component";
import {PageService} from "@core/shared/services/page.service";
import {TranslateService} from "@ngx-translate/core";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ShortSociety, Society} from "@core/shared/models/society";
import {Role} from "@core/shared/models/role";
import {FieldCollection} from "@lib/form/field";
import {Observable, of, Subject} from "rxjs";
import {getDefaultCkeditorConfig} from "@core/shared/data/ckeditor";
import {UserService} from "@core/shared/services/user.service";
import {ITranslation} from '@lib/cms/translation/translation';
import {Locale} from '@core/shared/models/locale';
import {Moment} from 'moment';
import * as moment from "moment";
import {CmsContentService} from "@core/shared/services/cms/cms-content.service";
import {SocietyService} from "@core/shared/services/society.service";
import {CmsService} from "@core/shared/services/cms.service";
import {map} from "rxjs/operators";
import {IElement, IElementConfigType} from "@lib/cms/element/element";
import {ITemplate} from "@lib/cms/template/template";
import {IRow} from "@lib/cms/row/row";
import {IColumn} from "@lib/cms/column/column";
import {AccordionItem} from "@lib/cms/element/type/accordion-item/accordion-item";
import {Slideshow} from "@lib/cms/element/type/slideshow/slideshow";

@Component({
    selector: 'app-page-update',
    templateUrl: './page-update.component.html',
    styleUrls: ['./page-update.component.scss'],
    providers: [
        FormService,
        CmsContentService
    ]
})
export class PageUpdateComponent implements OnInit, AfterViewInit {

    private _loadPreview: boolean = false;

    public dataCms: CmsData;

    public editor = ClassicEditor;

    public translationBuilder: TranslationBuilder;

    public locales$: Observable<LocaleItem[]>;

    public cmsParams: ICmsParams;

    public societies: ShortSociety[] = [];

    public roles : Role[] = [];

    public initialTarget : string;

    public fieldCollection: FieldCollection = new FieldCollection();

    public localeViewPreview: Locale;

    public localeCardPreview: Locale;

    public constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _formBuilder: UntypedFormBuilder,
        private _translateService: TranslateService,
        private _societyService: SocietyService,
        private _snackBar: MatSnackBar,
        private _apiService: ApiService,
        private _cmsService: CmsService,
        private _userService: UserService,
        private _cmsContentService: CmsContentService,
        public pageService: PageService,
        public formService: FormService
    ) {}

    ngOnInit(): void {

        this._configureCmsContentService();

        this._buildForm();

        this._loadSocieties();

        this._initCms();

        this._initLocales();

        this._initTranslationsForm();

        this._activatedRoute.data.subscribe((data: { page: Page, societies: Society[] }): void => {

            this.roles = data.page.roles;

            const pageDatas: any = {
                translations: [],
                published: data.page.published,
                typeUser: data.page.typeUser,
                roles: data.page.roles,
                target: (data.page.target) ? data.page.target.toString() : null,
                societies: [],
                content: data.page.translations.map((translation: PageTranslation): ITranslation => {

                    return translation.content;
                }),
                locales: data.page.locales
            };

            this.initialTarget = (data.page.target) ? data.page.target.toString() : null;

            data.page.societies.forEach((society: Society): void => {

                pageDatas.societies.push(society.id);
            });

            data.page.translations.forEach((translation: PageTranslation): void => {

                pageDatas.translations.push({
                    locale: translation.locale,
                    id: translation.id,
                    title: translation.title,
                    description: translation.description,
                    content: translation.content,
                    picture: translation.picture
                });
            });

            pageDatas.translations.forEach((translation: PageTranslation): void => {

                const group: UntypedFormGroup = this.translationBuilder.addItemControl(this.translationBuilder.getLocaleItem(translation.locale), translation);

                group.addControl('id', this._formBuilder.control(translation.id));
            });

            this.form.patchValue(pageDatas);

            this.dataCms = {
                content: {
                    context: 'cms_content_page',
                    id: 'tag',
                    entityFieldName: 'content',
                    entityName: 'Page',
                    translations: data.page.translations.map((translation: PageTranslation): ITranslation => {

                        translation.content.rows.forEach((row: IRow): void => {

                            row.columns.forEach((column: IColumn): void => {

                                const slideshowElements: IElement[] = column.elements.filter((element: IElement): boolean => {

                                    return element.type === 'slideshow';
                                });

                                slideshowElements.forEach((element: IElement): void => {

                                    const slideshow: Slideshow = element.content;

                                    slideshow.formatUpdated$ = new Subject();
                                });
                            });
                        });

                        return translation.content;
                    })
                }
            };
        });

        this.form.get('locales').valueChanges.subscribe((): void => {

            this._handleTranslationControls();

            this.form.get('locales').value.forEach((locale: string): void => {

                const exist: ITranslation = this.dataCms.content.translations.find((item: ITranslation): boolean => {

                    return item.locale === locale;
                });

                if (!exist) {

                    this.dataCms.content.translations.push({"id": null, "locale": locale, "rows": []});
                }
            });

            this.dataCms.content.translations.forEach((item: ITranslation, index: number): void => {

                if (!this.form.get('locales').value.includes(item.locale)) {

                    this.dataCms.content.translations.splice(index, 1)
                }
            });
        });

        this.formService.submitCallback = (): void => {

            const formValue = this.form.value;

            const data: object = Object.assign(formValue, {
                translations: formValue.translations.map((translation: Partial<PageTranslation>): PageTranslation => {

                    return Object.assign(translation, {
                        content: formValue.content.translations.find((item: ITranslation): boolean => {

                            item.rows.forEach((row: IRow): void => {

                                row.columns.forEach((column: IColumn): void => {

                                    column.elements.forEach((element: IElement): void => {

                                        switch(element.type){

                                            case 'slideshow':

                                                delete (element.content as Slideshow).formatUpdated$;

                                                break;

                                            case 'accordionItem':

                                                delete (element.content as AccordionItem).open;
                                                delete (element.content as AccordionItem).updateState$;

                                                break;
                                        }
                                    });
                                });
                            });

                            return item.locale === translation.locale;
                        })
                    } as Partial<PageTranslation>) as PageTranslation;
                })
            } as Partial<Page>);

            this.pageService.updateItemAPI(this._activatedRoute.snapshot.params['id'], data).subscribe((): void => {

                this._snackBar.open(this._translateService.instant('page.update.success.value'), this._translateService.instant('notification.close.action.value'), {
                    duration: 5000
                });

                this.redirectToList();
            });
        };
    }

    ngAfterViewInit(): void {

        this._loadPreview = true;
    }

    private _configureCmsContentService(): void {

        this._cmsContentService.imageAsBinary = true;
    }

    private _loadSocieties(): void {

        this._societyService.getShortItemsAPI().subscribe((items: ShortSociety[]): void => {

            this.societies.push(...items);
        });
    }

    private _initCms(): void {

        const templateContext: string = 'page';

        this.cmsParams = {
            configSource: this._cmsService.getConfig().pipe(map((config: Config): Config => {

                // Ajout de l'élément soufflet

                const accordionItemIdentifier: string = 'accordionItem';

                Object.assign(config.element.types, {
                    [accordionItemIdentifier]: {
                        label: this._translateService.instant('cms.element.type.accordionItem.value')
                    } as IElementConfigType
                });

                config.templates.forEach((template: ITemplate): void => {

                    Object.assign(template, {
                        allowedTypes: [...template.allowedTypes, {
                            id: accordionItemIdentifier,
                            label: this._translateService.instant('cms.element.type.accordionItem.value')
                        }]
                    } as ITemplate);
                });

                Object.keys(config.contexts[templateContext]).forEach((templateId: string): void => {

                    Object.assign(config.contexts[templateContext][templateId], {
                       types: [...config.contexts[templateContext][templateId].types, accordionItemIdentifier]
                    });
                });

                return config;
            })),
            context: 'cms_content_page',
            blocLabel: 'Bloc Label',
            ckeditor: {
                editor: this.editor,
                config: getDefaultCkeditorConfig(this._translateService, this._userService)
            },
            customButtons: [],
            entityName: 'Page',
            enableMultilingual: true,
            tag: 'tag',
            entityFieldName: 'content',
            parentContainer: document.getElementById('admin-sidenav-content'),
            apiUrl: `${this._apiService.getApiUrl(false, true)}`,
            optionsImage: {
                enableAlignment: true,
                enableTitle: true,
                enableSubtitle: false,
                enableAlt: false,
                enableLink: false,
                enableTargetBlank: false,
                enableWatermark: true
            },
            templateContext: templateContext
        };
    }

    private _initLocales(): void {

        this.locales$ = of(LOCALE_ITEMS);
    }

    private _buildForm(): void {

        this.formService.form = this._formBuilder.group({
            published: [''],
            content: [''],
            typeUser: ['role', Validators.required],
            roles: [['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']],
            societies: [[]],
            target: [['_self'], Validators.required],
            locales: [[], [Validators.required]],
            translations: new UntypedFormArray([]),
        });

        this.fieldCollection.addField({
            type: 'select',
            config: {
                id: 'target',
                attrs: {
                    required: true,
                    label: this._translateService.instant('page.target.value'),
                    placeholder: this._translateService.instant('page.target.value'),
                    choices: [
                        {
                            '_self': 'Non',
                            '_blank': 'Oui'
                        }
                    ]
                }
            }
        });

        this.fieldCollection.addField({
            type: 'select-search',
            config: {
                id: 'societies',
                attrs: {
                    label: this._translateService.instant('menu.users.title.value'),
                    required: false,
                    choices: this.societies,
                    multiple: true
                }
            }
        });
    }

    private _initTranslationsForm(): void {

        this.translationBuilder = new TranslationBuilder(this._formBuilder);

        this.translationBuilder.form = this.form;

        this.translationBuilder.addItemCallback = (): UntypedFormGroup => {

            return this._formBuilder.group({
                title: ['', [Validators.required]],
                description: ['', [Validators.required]],
                picture: this._formBuilder.group({
                    copyright: [''],
                    image: this._formBuilder.group({
                        image: ['', [Validators.required]]
                    })
                })
            });
        };
    }

    private _handleTranslationControls(): void {

        const locales: string[] = this.form.get('locales').value;

        if(!this.translationBuilder){

            return;
        }


        this.translationBuilder.updateItems(locales);
    }

    public patchRolesValue(role: Role): void {

        if(this.roles.indexOf(role) > -1){

            const index: number = this.roles.indexOf(role);

            this.roles.splice(index, 1);

        }
        else{

            this.roles.push(role);
        }

        this.form.get('roles').setValue(this.roles);
    }

    public changeSocieties(event): void {

        this.form.get('societies').patchValue(event.value);
    }

    public hasRole(role: Role): boolean {

        return this.roles.indexOf(role) >= 0;
    }

    public redirectToList(): void {

        this._router.navigate(['page/list']);
    }

    public getTranslation(index: number): UntypedFormGroup {

        return this.translationsControl.controls[index] as UntypedFormGroup;
    }

    get imageConfig(): ImageConfig {

        return {
            id: 'image',
            gallery_context: 'card_contenu',
            required: true,
            uploadApiUrl: this._apiService.getApiUrl(false, true),
            options: {
                enableTitle: false,
                enableSubtitle: false,
                enableAlt: false,
                enableLink: false,
                enableTargetBlank: false,
                enableFormatSelection: false
            }
        };
    }

    get descriptionEditorConfig(): CkeditorConfig {

        return {
            id: 'description',
            editor: this.editor,
            attrs: {
                required: true
            }
        };
    }

    get publishedConfig() {

        return {
            id: 'published',
            attrs: {
                required: true,
                label: "Publié"
            }
        };
    }

    get translationsControl(): UntypedFormArray {

        return this.form.get('translations') as UntypedFormArray;
    }

    get form(): UntypedFormGroup {

        return this.formService.form;
    }

    get selectedLocales(): string[] {

        if(!this.form || !this.form.get('locales')){

            return [];
        }

        return this.form.get('locales').value;
    }

    get pageFormValue(): Page {

        if (this.form.invalid || !this._loadPreview) {

            return;
        }

        const formValue = {...this.form.getRawValue()};

        const now: Moment = moment();

        const data: Partial<Page> = Object.assign(formValue, {
            publishedAt: now.format('YYYY-MM-DD'),
            updatedAt: now.format('YYYY-MM-DD'),
            translations: formValue.translations.map((translation: Partial<PageTranslation>): PageTranslation => {

                return Object.assign(translation, {
                    content: formValue.content.translations.find((item: ITranslation): boolean => {

                        return item.locale === translation.locale;
                    })
                } as Partial<PageTranslation>) as PageTranslation;
            })
        } as Partial<Page>);

        if(!this.localeViewPreview) {

            this.localeViewPreview = data.locales.find((): boolean => true);
        }

        if(!this.localeCardPreview) {

            this.localeCardPreview = data.locales.find((): boolean => true);
        }

        return data as Page;
    }
}
