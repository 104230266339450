import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from "rxjs";
import {Image} from "./image";

@Injectable({
    providedIn: 'root'
})
export class ImageEventService {

    public imageCropped: Subject<Image> = new Subject<Image>();

    public openGalleryDialog$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
}
