import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {CropperDialogComponent} from "./cropper-dialog/cropper-dialog.component";
import {Image, ImageAlignment, ImageFormat, OptionsImage} from "./image";
import {CropperDialogData, CropperDialogResult} from "./cropper-dialog/cropper-dialog";
import {ImageGalleryDialogComponent} from "./image-gallery-dialog/image-gallery-dialog.component";
import {ImageGalleryDialogData} from "./image-gallery-dialog/image-gallery-dialog";
import {FileUploadConfig} from "../file-upload/file-upload";
import {Gallery} from "../gallery";
import {ImageService} from "./image.service";
import {HttpErrorResponse} from "@angular/common/http";
import {TranslateService} from "@ngx-translate/core";
import {MatDialog} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ApiService} from "@core/shared/services/api.service";
import {environment} from "../../../../environments/environment";
import {ImageEventService} from "@lib/media/image/image.event.service";

@Component({
    selector: 'media-image',
    templateUrl: './image.component.html',
    styleUrls: ['./image.component.scss']
})
export class ImageComponent implements OnInit, OnChanges {

    @Input() public apiUrl: string;

    @Input() public image: Image;

    @Input() public gallery: Gallery;

    @Input() public options: OptionsImage;

    @Output() public onAdd: EventEmitter<Image> = new EventEmitter<Image>();

    @Output() public onDelete: EventEmitter<Image> = new EventEmitter<Image>();

    @Output() public onUpdate: EventEmitter<Image> = new EventEmitter<Image>();

    public fileUploadConfig: FileUploadConfig;

    public thumbnailWidth: number;

    public thumbnailQuality: number;

    public initButton: boolean = false;

    public alignments: ImageAlignment[] = [
        {
            type: 'top',
            label: 'element.types.image.alignment.type.top.value'
        },
        {
            type: 'center',
            label: 'element.types.image.alignment.type.center.value'
        },
        {
            type: 'bottom',
            label: 'element.types.image.alignment.type.bottom.value'
        }
    ];

    public isCopyrightAvailable: boolean = false;

    public constructor(
        public dialog: MatDialog,
        private _translateService: TranslateService,
        private _snackbar: MatSnackBar,
        private _imageService: ImageService,
        private _imageEventService: ImageEventService,
        private _apiService: ApiService
    ) {
        this.thumbnailWidth = 120;

        this.thumbnailQuality = 75;
    }

    ngOnInit(): void {

        this._imageService.setApiUrl(this.apiUrl);

        if(this.image === undefined){

            this.image = null;
        }
    }

    ngOnChanges(changes: SimpleChanges): void {

        this._imageService.setApiUrl(this.apiUrl);

        if (!changes.gallery) {
            return;
        }

        this.gallery = changes.gallery.currentValue;

        if (this.gallery) {

            this.initButton = true;

            this.fileUploadConfig = {
                allowedTypes: 'image/png;image/jpeg',
                maxSize: environment.galleryMaxFileSize,
                multiple: true,
                uploadApi: {
                    url: `${this._imageService.getApiUrl()}/galleries/${this.gallery.id}/images`
                },
                uploadBtnMsg: 'media.file.import.action.value'
            };

            if(this._imageEventService.openGalleryDialog$.getValue()){

                this.openGalleryDialog();

                this._imageEventService.openGalleryDialog$.next(false);
            }
        }
    }

    public openGalleryDialog(): void {

        const data: ImageGalleryDialogData = {
            apiUrl: this.apiUrl,
            gallery: this.gallery,
            fileUploadConfig: this.fileUploadConfig,
            totalAvailableAddition: this.image && this.image.path ? 0 : 1,
            thumbnailWidth: this.thumbnailWidth,
            usedImages: this.image ? [this.image] : [],
            type: 'image'
        };

        const dialogRef = this.dialog.open(ImageGalleryDialogComponent, {
            width: '800px',
            data: data
        });

        dialogRef.componentInstance.onAdd.subscribe((image: Image): void => {

            this.image = Object.assign(image, {
                alignment: null
            } as Partial<Image>);

            this.onSelectedFormat(this.image.formats[0]);

            this.image.title = null;

            this.onAdd.emit(image);

            this.isCopyrightAvailable = true;
        });

        dialogRef.componentInstance.updateImage.subscribe((image: Image) => {

            this.image = image;

            this.isCopyrightAvailable = true;
        });

        dialogRef.componentInstance.onRemove.subscribe((image: Image) => {

            this.onDelete.emit(image);

            this.isCopyrightAvailable = true;
        });
    }

    public openCropperDialog(format: ImageFormat): void {

        const source: string = `${this._apiService.getApiUrl(false, false)}/public/cms${this._imageService.getMediaUrl()}${this._imageService.getImageUrl()}/${this.image.id}`;

        const data: CropperDialogData = {
            source: source,
            options: {
                data: format.cropperData,
                aspectRatio: format.ratio
            }
        };

        const dialogRef = this.dialog.open(CropperDialogComponent, {
            width: '800px',
            data: data
        });

        dialogRef.afterClosed().subscribe((result: CropperDialogResult): void => {

            if ((typeof result === 'undefined') || !result.apply) {

                return;
            }

            format.cropperData = result.data;

            this._imageService.crop(this.image).subscribe((): void => {

                this._imageService.incrementIterator();

                this._imageEventService.imageCropped.next(this.image);
            });
        });
    }

    public delete(): void {

        this.image = null;

        this.onDelete.emit(this.image);

        this.isCopyrightAvailable = true;
    }

    /**
     * @param format
     */
    public getThumbnailPath(format: ImageFormat): string {

        return this._imageService.getPath(this.image, format, this.thumbnailWidth, this.thumbnailQuality) + '?' + this._imageService.getIterator();
    }

    public update(): void {

        this.onUpdate.emit(this.image);

        this.isCopyrightAvailable = true;
    }

    public onSelectedFormat(format: ImageFormat): void {

        if(this.options.enableFormatSelection === false){

            return;
        }

        this.image.formats.forEach((formatImage: ImageFormat): void => {

            if(this.options.format){

                if (formatImage.name == this.options.format) {

                    formatImage.selected = true;

                } else {

                    formatImage.selected = false;
                }

            } else{

                if (formatImage.name == format.name) {

                    formatImage.selected = true;

                } else {

                    formatImage.selected = false;
                }
            }
        });

        this._imageService.setFormatImage(this.image).subscribe(() => {

        }, (error: HttpErrorResponse) => {

            this._translateService.get(['action.close']).subscribe((messages: string[]) => {

                this._snackbar.open(error.error, messages['action.close']);
            });
        });

        this.onUpdate.emit(this.image);
    }

    public compareAlignment(a: ImageAlignment, b: ImageAlignment): boolean {

        if(!a && !b){

            return true;
        }

        if(!a || !b){

            return false;
        }

        return a.type === b.type;
    }
}
