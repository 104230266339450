import {Injectable} from '@angular/core';
import {ApiService} from "./api.service";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {Config} from "@lib/cms/cms";

@Injectable({
    providedIn: 'root'
})
export class CmsService {

    constructor(
        private _apiService: ApiService,
        private _httpClient: HttpClient
    ) {}

    public getConfig(): Observable<Config> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.get<Config>(`${this._apiService.getApiUrl(false, true)}/cms/config`, {
            headers: headers
        });
    }
}
