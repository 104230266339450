<div class="thumb-input" style="margin-bottom: 10px;">
    <div class="big-label">
        <span>Choisir un format : </span>
        <select [(ngModel)]="selectedFormat" (ngModelChange)="changeFormat()" [compareWith]="compareWith">
            <option *ngFor="let format of slideshowFormats" [ngValue]="format">
                {{ format.label }}
            </option>
        </select>
    </div>
</div>
<media-image-collection [options]="options" [apiUrl]="apiUrl" [gallery]="gallery" [images]="content.images" [max]="max" (onAdd)="add($event)" (onDelete)="delete($event)" (onPositionChanged)="changePosition($event)"></media-image-collection>
