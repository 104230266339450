import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UserService} from "@core/shared/services/user.service";
import {TranslationService} from "@core/shared/services/translation.service";
import {TranslationItem} from '@core/shared/models/translation';
import {Page} from '@core/shared/models/page';
import {DomSanitizer, SafeHtml, SafeUrl} from '@angular/platform-browser';
import {Image} from "@lib/media/image/image";
import {ImageService} from "@lib/media/image/image.service";
import {ApiService} from "@core/shared/services/api.service";
import {ImageEventService} from "@lib/media/image/image.event.service";

export type Mode = 'preview' | 'view';

@Component({
    selector: 'app-core-page-read-content',
    templateUrl: './page-read-content.component.html',
    styleUrls: ['./page-read-content.component.scss']
})
export class PageReadContentComponent implements OnInit {

    @Input() item: Page;

    @Input() mode: Mode;

    @Input() locale?: string;

    @Output() onLocaleUpdated: EventEmitter<string> = new EventEmitter<string>(null);

    public visualUrl: SafeUrl = null;

    public defaultVisualUrl: string = 'assets/images/offer/preview/1.jpg';

    constructor(
        private _sanitizer: DomSanitizer,
        private _apiService: ApiService,
        private _imageService: ImageService,
        private _imageEventService: ImageEventService,
        public translationService: TranslationService,
        public userService: UserService
    ) {}

    ngOnInit() {

        if(!this.locale) {

            this.locale = this.userService.currentUser.getValue().locale;
        }

        this._loadVisual();
    }

    private _loadVisual(): void {

        if(this.mode === 'view'){

            this.visualUrl = this._imageService.getImageUrlWithNoCache(`${this._apiService.getApiUrl(false, false)}/public/pages/${this.item.id}/picture/${this.getTranslation(this.item.translations).picture.id}/filter/landscape/1500/90`);
        }
        else{

            const image: Image = this.getTranslation(this.item.translations).picture.image.image as Image;

            const loadVisualCallback = (): void => {

                this._imageService.getBinaryImage(image, 'landscape', 1500, 90, null).subscribe((blob: Blob): void => {

                    const objectURL: string = URL.createObjectURL(blob);

                    this.visualUrl = this._sanitizer.bypassSecurityTrustUrl(objectURL);
                });
            };

            loadVisualCallback();

            this._imageEventService.imageCropped.subscribe((item: Image): void => {

                if(item.id !== image.id){

                    return;
                }

                loadVisualCallback();
            });
        }
    }

    public getTranslation<T extends TranslationItem>(translations: Array<T>): T {

        return this.translationService.getFallbackTranslation(translations, this.userService.currentUser.getValue(), this.locale) || translations[0];
    }

    public sanitize(string: string): SafeHtml {

        return this._sanitizer.bypassSecurityTrustHtml(string);
    }

    public setDefaultVisualUrl(): void {

        this.visualUrl = this.defaultVisualUrl;
    }
}
