import {Injectable} from '@angular/core';
import {ApiService} from "@core/shared/services/api.service";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {Statistic} from "@core/shared/models/statistic";

@Injectable({
    providedIn: 'root'
})
export class StatisticService {

    constructor(
        private _apiService: ApiService,
        private _httpClient: HttpClient
    ) {}

    public createItemAPI(item: Statistic): Observable<void> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/statistics/hydrate/${item.resourceType}`;

        if(item.hasResourceIdentifier){

            url = `${url}/${item.resourceIdentifier}`;
        }

        const data: object = {
            event: item.event,
            extraData: item.extraData,
            resourceLocale: item.resourceLocale
        } as Partial<Statistic>;

        return this._httpClient.post<void>(url, data, {
            headers: headers
        });
    }
}
