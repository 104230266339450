import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {ImageConfig} from "../image/image";
import {HttpErrorResponse} from "@angular/common/http";
import {SlideshowService} from "./slideshow.service";
import {CmsService} from "../../../cms.service";
import {IApiSlideshowConfig, Slideshow, SlideshowFormat} from "./slideshow";
import {Gallery} from "@lib/media/gallery";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Image, ImageFormat} from "@lib/media/image/image";
import {OptionsImage} from "@lib/cms/cms";
import {Subject} from "rxjs";

@Component({
    selector: 'cms-element-slideshow',
    templateUrl: './slideshow.component.html',
    styleUrls: ['./slideshow.component.scss']
})
export class SlideshowComponent implements OnInit {

    @Input() content: Slideshow;

    @Input() apiUrl: string;

    @Output() update: EventEmitter<Slideshow> = new EventEmitter<Slideshow>();

    public configInitialized: boolean = false;

    public gallery: Gallery;

    public slideshowFormats: SlideshowFormat[] = [];

    public options: OptionsImage;

    public max: number;

    public selectedFormat: SlideshowFormat = null;

    constructor(
        private _cmsService: CmsService,
        private _slideshowService: SlideshowService,
        private _translateService: TranslateService,
        private _snackbar: MatSnackBar
    ) {}

    ngOnInit() {

        this.max = 100;

        if (!this.content) {

            this.content = {
                images: [],
                format: {
                    label : null,
                    format : null
                }
            };
        }

        if(!this.content.formatUpdated$){

            this.content.formatUpdated$ = new Subject();
        }

        this._slideshowService.setApiUrl(this.apiUrl);

        const configOption: OptionsImage = this._cmsService.getOptionsImage();

        this.options = Object.assign(configOption, {
            enableTitle: configOption.enableTitle,
            enableSubtitle: configOption.enableSubtitle,
            enableAlt: configOption.enableAlt,
            enableLink: configOption.enableLink,
            enableTargetBlank: configOption.enableTargetBlank
        });

        const context: string = this._cmsService.getContext();

        this._slideshowService.loadConfig(context).subscribe((data: IApiSlideshowConfig): void => {

            this.slideshowFormats = data.formats;

            if(!this.content.format.format){

                this.content.format = data.formats[0];
            }

            this.selectedFormat = this.slideshowFormats.find((item: SlideshowFormat): boolean => {

                return item.format === this.content.format.format;
            });
        });

        this._slideshowService.loadConfigGallery(context).subscribe((config: ImageConfig): void => {

            this.gallery = config.gallery;

            this.configInitialized = true;

        }, (error: HttpErrorResponse): void => {

            this._translateService.get(['action.close']).subscribe((messages: string[]): void => {

                this._snackbar.open(error.error, messages['action.close']);
            });
        });
    }

    public add(images: Image[]): void {

        this.content.images = images;

        if (this.content.format) {

            this.changeFormat();

        } else {

            this.update.emit(this.content);
        }
    }

    public delete(images: Image[]): void {

        this.content.images = images;

        this.update.emit(this.content);
    }

    public changePosition(images: Image[]): void {

        this.content.images = images;

        this.update.emit(this.content);
    }

    public changeFormat(): void {

        this.content.format = this.selectedFormat;

        this.content.images.forEach((image: Image): void => {

            image.formats.forEach((formatImage: ImageFormat): void => {

                formatImage.selected = (formatImage.name === this.selectedFormat.format);
            });

            this._slideshowService.setFormatImage(image).subscribe((): void => {}, (error: HttpErrorResponse): void => {

                this._translateService.get(['action.close']).subscribe((messages: string[]): void => {

                    this._snackbar.open(error.error, messages['action.close']);
                });
            });
        });

        this.update.emit(this.content);

        this.content.formatUpdated$.next();
    }

    public compareWith(a: SlideshowFormat, b: SlideshowFormat): boolean {

        if(!a || !b){

            return false;
        }

        return a.format === b.format;
    }
}
