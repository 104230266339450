export enum StatisticTypeTag {
    Article = 'article'
}

export interface StatisticType {

    id: number;

    tag: StatisticTypeTag;

    label: string;
}
