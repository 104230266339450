<div class="visuel">
    <img [src]="visualUrl" (error)="setDefaultVisualUrl()" [alt]="getTranslation(item.translations).title">
    <div class="tags">
        <div class="row">
            <div class="col_content">
                <span class="page_tag type_{{ item.configuration.type.tag }}">
                    {{ getTranslation(item.configuration.type.translations, true).label }}
                </span>
            </div>
            <div class="col_content" *ngIf="item.isNew">
                 <span class="page_tag info_tag">
                    {{ 'offer.list.item.new.value' | translationForLocaleWithFallback: locale | async }}
                </span>
            </div>
            <div class="col_content">
                <span class="page_tag info_tag">
                    {{'offer.list.item.updatedAt.value' | translationForLocaleWithFallback: locale | async }} - {{ item.updatedAt | date:'shortDate' : null : translationService.getFallbackLocale(locale) }}
                </span>
            </div>
        </div>
    </div>
    <div class="form-group choice_language" *ngIf="item.locales.length > 1">
        <mat-select [(ngModel)]="locale" class="no_label" (ngModelChange)="onLocaleUpdated.emit(locale)">
            <mat-select-trigger>
                <span class="flag flag-{{ locale }}"></span>
                <span class="text">{{ 'locale.'+locale+ '.value' | translate }}</span>
            </mat-select-trigger>
            <mat-option *ngFor="let locale of item.locales" [value]="locale">
                <span class="flag flag-{{ locale }}"></span>
                <span class="text">{{ 'locale.'+locale+ '.value' | translate }}</span>
            </mat-option>
        </mat-select>
    </div>
</div>

<div class="article">
    <div class="ck-content infos_article">
        <h2 class="titre_h3 title">{{getTranslation(item.translations).title}}</h2>
        <p class="titre_h4 subtitle">{{getTranslation(item.translations).teaser}}</p>
        <div class="published">
            <p><strong>{{ 'publishedBy.value' | translationForLocaleWithFallback: locale | async }} {{ item.society ? item.society.name : 'Ty-Win' }}</strong></p>
            <p *ngIf="item.status === 'published'">{{ 'date.nominative.value' | translationForLocaleWithFallback: locale | async }} {{ item.publishedAt | date:'shortDate' : null : translationService.getFallbackLocale(locale) }}</p>
        </div>
    </div>

    <app-core-cms-content [content]="getTranslation(item.translations).content"></app-core-cms-content>
</div>
