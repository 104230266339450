<div class="upload_file row">
    <button type="button" class="btn_cta btn_lines btn_little" (click)="initUpload()">
        <mat-icon>cloud_upload</mat-icon>
        <span>{{ config.uploadBtnMsg | translate }}</span>
    </button>
    <span class="info col_content y_items_center" style="margin-left: 12px">{{ 'media.file_upload.maxLengthAllowed' | translate }} {{ getDisplayedSize(config.maxSize) }}</span>
    <div class="loader-files">
        <div class="file" *ngFor="let file of files" [@fadeInOut]="file.state">
            <mat-progress-bar mode="determinate" [value]="file.progress"></mat-progress-bar>
            <span>{{ file.data.name }}</span>
            <div>
                <a title="Retry" (click)="retryFile(file)" *ngIf="file.canRetry">
                    <mat-icon>refresh</mat-icon>
                </a>
                <a title="Cancel" (click)="cancelFile(file)" *ngIf="file.canCancel">
                    <mat-icon>cancel</mat-icon>
                </a>
            </div>
        </div>
    </div>
</div>

<input type="file" #file name="fileUpload" [multiple]="config.multiple" [accept]="config.allowedTypes" style="display:none;"/>
