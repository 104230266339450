import {Component, Input, OnInit} from '@angular/core';
import {ITranslation as CmsTranslationContent} from '@lib/cms/translation/translation';
import {CmsContentService} from "@core/shared/services/cms/cms-content.service";
import {IRow} from "@lib/cms/row/row";
import {IColumn} from "@lib/cms/column/column";
import {IElement} from "@lib/cms/element/element";
import {AccordionItem} from "@lib/cms/element/type/accordion-item/accordion-item";
import {Subject} from "rxjs";

@Component({
    selector: 'app-core-cms-content',
    templateUrl: './cms-content.component.html',
    styleUrls: ['./cms-content.component.scss']
})
export class CmsContentComponent implements OnInit {

    @Input() content: CmsTranslationContent;

    constructor(
        private _cmsContentService: CmsContentService)
    {}

    ngOnInit() {

        this._handleAccordionItemElements();

        this._cmsContentService.contentUpdated$.subscribe((): void => {

            this._handleAccordionItemElements();
        });
    }

    private _handleAccordionItemElements(): void {

        this.content.rows.forEach((row: IRow): void => {

            row.columns.forEach((column: IColumn): void => {

                const elements: IElement[] = column.elements.filter((element: IElement): boolean => {

                    return element.type === 'accordionItem';
                });

                elements.forEach((element: IElement, elementIndex: number): void => {

                    Object.assign(element.content, {
                        open: (elementIndex === 0),
                        updateState$: new Subject()
                    } as AccordionItem);

                    (element.content as AccordionItem).updateState$.subscribe((state): void => {

                        elements.forEach((element: IElement, index: number): void => {

                            (element.content as AccordionItem).open = (elementIndex === index) ? state.open : false;
                        });
                    });
                });
            });
        });
    }
}
