<div class="row marges x_between h_full" *ngIf="(user$ | async) as user">
    <div class="col_content y_items_center">
        <a [routerLink]="['/account/dashboard']" class="link-logo">
            <ng-container *ngIf="user.society?.administrationLogo; else defaultLogo">
                <img *ngIf="(user.society.administrationLogo | fileSource | async) as sourceUrl" [src]="sourceUrl" title="logo" alt="logo" class="logo">
            </ng-container>
            <ng-template #defaultLogo>
                <img src="assets/images/logo_tywin.png" alt="Ty-win" title="Ty-win" class="logo" />
            </ng-template>
        </a>
    </div>
    <div class="col_content y_items_center">
        <div>
            <a href="" class="icon-tywin-user">
                <div class="ty-user-info">
                    <span>{{ user.firstName }} {{ user.lastName }}</span>
                    <span *ngIf="user.society" class="society">{{ user.society.name }}</span>
                </div>
            </a>
            <div (click)="logout()" class="icon-tywin-logout" title="{{ 'logout.value' | translate }}"></div>
        </div>
    </div>
</div>
