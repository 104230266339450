import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate} from '@angular/router';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {StatisticService} from "@core/shared/services/statistic.service";
import {Statistic, StatisticRoutingConfiguration} from "@core/shared/models/statistic";
import {plainToClass} from "class-transformer";
import {UserService} from "@core/shared/services/user.service";
import {TranslateService} from "@ngx-translate/core";

@Injectable({
    providedIn: 'root'
})
export class StatisticGuard implements CanActivate {

    constructor(
        private _translateService: TranslateService,
        private _userService: UserService,
        private _statisticService: StatisticService
    ) {}

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {

        const routeData = route.data as { statisticConfiguration: StatisticRoutingConfiguration };

        if(!('statisticConfiguration' in routeData)){

            throw new Error('Missing Guard Parameter.');
        }

        if(!routeData.statisticConfiguration.enableCallback(this._userService)){

            return;
        }

        const statistic: Statistic = plainToClass(Statistic, {
            resourceType: routeData.statisticConfiguration.resourceType,
            resourceLocale: this._translateService.currentLang,
            event: routeData.statisticConfiguration.event
        } as Statistic);

        if(routeData.statisticConfiguration.hasResourceIdentifier){

            statistic.resourceIdentifier = route.paramMap.get(routeData.statisticConfiguration.resourceIdentifierRouteParam);
        }

        return this._statisticService.createItemAPI(statistic).pipe(
            map((): boolean => {

                return true;
            }),
            catchError((): Observable<boolean> => {

                return of(true);
            })
        );
    }
}
