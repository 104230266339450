import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-article-charter-publication-dialog',
  templateUrl: './article-charter-publication-dialog.component.html',
  styleUrls: ['./article-charter-publication-dialog.component.scss']
})
export class ArticleCharterPublicationDialogComponent implements OnInit {

    @Output() close: EventEmitter<void> = new EventEmitter<void>();

    constructor(
      private _dialogRef: MatDialogRef<ArticleCharterPublicationDialogComponent>
    ) {}

    ngOnInit(): void {

      this.close.subscribe((): void => {

          this._dialogRef.close();
      });
    }
}
