<form [formGroup]="form" (ngSubmit)="formService.submit()">

    <div class="page_title">
        <div class="row marges">

            <div class="col y_items_center">
                <h1 class="title">{{ (crudService.isCreateAction ? 'article.create.value' : 'article.update.value') | translate }}</h1>
            </div>

            <!-- Actions -->

            <div class="col_content y_items_center">

                <!-- Retour -->

                <button class="btn_cta btn_lines btn_little" type="button" (click)="redirectToList(true)">
                    {{ 'article.list.back.value' | translate }}
                </button>

                <!-- Suppression -->

                <ng-container *ngIf="crudService.isUpdateAction && userService.hasOneOfTheseAccesses(currentUser, ['ARTICLE_DELETE', 'ARTICLE_DELETE_IS_MINE'])">

                    <button class="btn_cta btn_lines btn_little" type="button" (click)="openDeleteDialog()">
                        {{ 'article.current.delete.action.value' | translate }}
                    </button>

                </ng-container>

                <ng-container *ngIf="offerFormTabValidationService.currentItemIsLastItem">

                    <!-- Enregistrer -->

                    <button class="btn_cta btn_little" type="submit">
                        {{ 'form.submit.action.value'|translate }}
                    </button>

                </ng-container>

                <ng-container *ngIf="!offerFormTabValidationService.currentItemIsLastItem">

                    <!-- Passer à l'étape suivante -->

                    <button class="btn_cta btn_little" type="button" [disabled]="!offerFormTabValidationService.isCurrentItemValid" (click)="offerFormTabValidationService.goToNextItem()">
                        {{ 'form.tab.next.value' | translate }}
                    </button>

                </ng-container>

            </div>
        </div>
    </div>

    <!-- Gestion des onglets -->

    <mat-tab-group #tabGroup>

        <ng-container *ngFor="let tabItem of tabItems">

            <mat-tab data-tag="{{ tabItem.tag }}">
                <ng-template mat-tab-label>
                    <span>{{ tabItem.label | translate }}</span>
                    <span [ngClass]="offerFormTabValidationService.getTabLabelClasses(tabItem.tag)"></span>
                </ng-template>
                <ng-container *ngTemplateOutlet="tabItem.template"></ng-container>
            </mat-tab>

        </ng-container>

        <mat-tab *ngIf="articleFormValue" [label]="'article.card.preview.value' | translate" data-tag="articleCardPreview">
            <ng-container *ngTemplateOutlet="articleCardPreview"></ng-container>
        </mat-tab>

        <mat-tab *ngIf="articleFormValue" [label]="'article.view.preview.value' | translate" data-tag="articleViewPreview">
            <ng-container *ngTemplateOutlet="articleViewPreview"></ng-container>
        </mat-tab>

    </mat-tab-group>

</form>

<!-- Données générales -->

<ng-template #generalData>

    <ng-container [formGroup]="form">

        <div class="content_tab">

            <!-- Visibilités -->

            <div class="ligne_form row_marges">
                <div class="col_12">
                    <div class="form-group">
                        <div class="form_part">
                            <p class="title_part">{{ 'article.visibility.title.value' | translate }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row marges ligne_form">
                <div class="col_12">
                    <div class="form-group">
                        <label>{{ 'article.visibility.value' | translate }} :</label>
                    </div>
                </div>
            </div>

            <div class="row marges ligne_form" style="margin-top: 12px">
                <div class="col_12">
                    <div class="form-group">
                        <ng-container *ngFor="let visibility of availableVisibilities">
                            <div class="checkbox min-size">
                                <input type="checkbox" id="visibility-{{visibility}}" [checked]="isSelectedVisibility(visibility)" [disabled]="!isMine || isDisabledVisibility(visibility)" (change)="handleVisibility(visibility)">
                                <label for="visibility-{{visibility}}" [ngStyle]="{ fontWeight: visibility === 'ALL' ? 'bold' : 'initial' }">{{ getVisibilityLabel(visibility) | translate }}</label>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>

            <div class="row marges ligne_form" style="margin-top: 12px" *ngIf="restrictionControl.get('visibilities').errors">
                <div class="col_12">
                    <div class="form-group">
                        <mat-error style="padding: 0">
                            <div class="invalid-feedback">
                                <div>{{ 'visibility.configuration.required.value' | translate }}</div>
                            </div>
                        </mat-error>
                    </div>
                </div>
            </div>

            <!-- Rôles -->

            <ng-container *ngIf="isSelectedVisibility('ROLE')">

                <div class="ligne_form row_marges">
                    <div class="col_12">
                        <div class="form-group">
                            <div style="margin-top: 12px">
                                <app-core-select-search [configuration]="roleSearchConfiguration" [sourceCallback]="roleSearchSourceCallback" [formGroupReference]="restrictionControl" [formControlNameReference]="'roles'"></app-core-select-search>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row marges ligne_form" style="margin-top: 12px" *ngIf="restrictionControl.get('roles').errors">
                    <div class="col_12">
                        <div class="form-group">
                            <mat-error style="padding: 0">
                                <div class="invalid-feedback">
                                    <div>{{ 'visibility.configuration.required.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>
                </div>

            </ng-container>

            <!-- Groupes d'utilisateurs -->

            <ng-container *ngIf="isSelectedVisibility('SOCIETY_GROUP')">

                <div class="ligne_form row_marges">
                    <div class="col_12">
                        <div class="form-group">
                            <div style="margin-top: 12px">
                                <app-core-select-search [configuration]="societyGroupSearchConfiguration" [sourceCallback]="societyGroupSearchSourceCallback" [formGroupReference]="restrictionControl" [formControlNameReference]="'societyGroups'"></app-core-select-search>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row marges ligne_form" style="margin-top: 12px" *ngIf="restrictionControl.get('societyGroups').errors">
                    <div class="col_12">
                        <div class="form-group">
                            <mat-error style="padding: 0">
                                <div class="invalid-feedback">
                                    <div>{{ 'visibility.configuration.required.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>
                </div>

            </ng-container>

            <!-- Langues -->

            <div class="ligne_form row_marges">
                <div class="col_12">
                    <div class="form-group">
                        <div class="form_part">
                            <p class="title_part">{{ 'locale.plural.value' | translate }}</p>
                        </div>
                        <div style="margin-top: 12px">
                            <app-core-select-search [configuration]="localeSearchConfiguration" [sourceCallback]="localeSearchSourceCallback" [formGroupReference]="form" [formControlNameReference]="'locales'"></app-core-select-search>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row marges ligne_form" style="margin-top: 12px" *ngIf="form.get('locales').errors">
                <div class="col_12">
                    <div class="form-group">
                        <mat-error style="padding: 0">
                            <div class="invalid-feedback">
                                <div>{{ 'visibility.configuration.required.value' | translate }}</div>
                            </div>
                        </mat-error>
                    </div>
                </div>
            </div>

            <ng-container *ngIf="translationBuilder.items.length">

                <div class="row marges ligne_form">
                    <div class="col_12">
                        <div class="mat-tab-translations">
                            <ng-container formArrayName="translations">

                                <mat-tab-group [@.disabled]="true">

                                    <div *ngFor="let _ of translationBuilder.itemsControl.controls; let it = index">

                                        <mat-tab label="{{ translationBuilder.getLocaleLabel(translationBuilder.getItemControl(it).value.locale) | translate }}">

                                            <ng-container [formGroupName]="translationBuilder.indexAsString(it)">

                                                <!-- Nom -->

                                                <div class="row marges ligne_form">

                                                    <div class="col_12">
                                                        <div class="form-group">
                                                            <input type="text" value="" placeholder=" " class="form-control" formControlName="title" id="{{it}}-title">
                                                            <label for="{{it}}-title" class="required">{{'title.value' | translate}}</label>
                                                            <mat-error *ngIf="translationBuilder.getItemControl(it).get('title').errors && (translationBuilder.getItemControl(it).get('title').dirty || translationBuilder.getItemControl(it).get('title').touched)">
                                                                <div class="invalid-feedback">
                                                                    <div *ngIf="translationBuilder.getItemControl(it).get('title').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                                    <div *ngIf="translationBuilder.getItemControl(it).get('title').errors.maxlength">{{ 'form.control.error.maxLength.value' | translate: { maxLength : maxLengths.title } }}</div>
                                                                </div>
                                                            </mat-error>
                                                            <div class="form-group col_content y_items_center">
                                                                <span class="message-help"><mat-icon class="tooltip_icon">info</mat-icon>{{ 'form.validation.error.maxLength' | translate:{ value: maxLengths.title } }}</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                                <!-- Accroche -->

                                                <div class="row marges ligne_form">
                                                    <div class="col_12">
                                                        <div class="form-group">
                                                            <input type="text" value="" placeholder=" " class="form-control" formControlName="teaser" id="{{it}}-teaser">
                                                            <label for="{{it}}-teaser" class="required">{{'article.teaser.value' | translate}}</label>
                                                            <mat-error *ngIf="translationBuilder.getItemControl(it).get('teaser').errors && (translationBuilder.getItemControl(it).get('teaser').dirty || translationBuilder.getItemControl(it).get('teaser').touched)">
                                                                <div class="invalid-feedback">
                                                                    <div *ngIf="translationBuilder.getItemControl(it).get('teaser').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                                    <div *ngIf="translationBuilder.getItemControl(it).get('teaser').errors.maxlength">{{ 'form.control.error.maxLength.value' | translate: { maxLength : maxLengths.teaser } }}</div>
                                                                </div>
                                                            </mat-error>
                                                            <div class="form-group col_content y_items_center">
                                                                <span class="message-help"><mat-icon class="tooltip_icon">info</mat-icon>{{ 'form.validation.error.maxLength' | translate:{ value: maxLengths.teaser } }}</span>
                                                                <span class="message-help"><mat-icon class="tooltip_icon">info</mat-icon>&nbsp;{{ 'article.teaser.info.value' | translate }}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </ng-container>
                                        </mat-tab>
                                    </div>

                                </mat-tab-group>

                            </ng-container>
                        </div>
                    </div>
                </div>

            </ng-container>

            <!-- Visuel -->

            <div class="ligne_form row_marges">
                <div class="col_12">
                    <div class="form-group">
                        <div class="form_part">
                            <p class="title_part">{{ 'visual.main.value' | translate }}</p>
                        </div>
                        <div style="margin-top: 12px">
                            <ng-container formGroupName="picture">

                                <div class="row marges ligne_form">

                                    <div class="col_4">
                                        <div class="form-group add_picture">
                                            <form-field-image [config]="pictureImageConfig" [form]="form.get('picture').get('image')" (imageAdded)="pictureCopyright.readOnly = false" (imageDeleted)="pictureCopyright.readOnly = true"></form-field-image>
                                        </div>
                                    </div>
                                </div>
                                <div class="ligne_form row marges">
                                    <div class="col_6">
                                        <div class="form-group">
                                            <input #pictureCopyright type="text" value="" placeholder=" " class="form-control" formControlName="copyright" id="picture_copyright" [readOnly]="true">
                                            <label class="required" for="picture_copyright">{{ 'offer.form.fields.copyright.value' | translate }}</label>
                                            <mat-error *ngIf="form.get('picture').get('copyright').errors && (form.get('picture').get('copyright').dirty || form.get('picture').get('copyright').touched)">
                                                <div class="invalid-feedback">
                                                    <div *ngIf="form.get('picture').get('copyright').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                </div>
                                            </mat-error>
                                        </div>
                                    </div>
                                </div>

                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>

            <div class="ligne_form row_marges">
                <div class="col_12">
                    <div class="form-group">
                        <div class="form_part">
                            <p class="title_part">{{ 'offer.plural.association.value' | translate }}</p>
                        </div>
                        <div style="margin-top: 12px">

                            <ng-container formGroupName="offerConfiguration">

                                <div class="form-group">
                                    <label>{{ 'article.offer.association.action.value' | translate}}</label>
                                    <div class="radio">
                                        <input type="radio" formControlName="enable" class="form-control" [value]="false" id="offer-configuration-enable-no">
                                        <label for="offer-configuration-enable-no">{{ 'no.value' | translate }}</label>
                                    </div>
                                    <div class="radio">
                                        <input type="radio" formControlName="enable" class="form-control" [value]="true" id="offer-configuration-enable-yes">
                                        <label for="offer-configuration-enable-yes">{{ 'yes.value' | translate }}</label>
                                    </div>
                                </div>

                            </ng-container>

                        </div>
                    </div>
                </div>
            </div>

            <ng-container *ngIf="offerConfigurationControl.get('enable').value && userService.hasOneOfThisRoles(currentUser, ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_OFFER_DISTRIBUTOR', 'ROLE_PROVIDER', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION'])">

                <ng-container formGroupName="offerConfiguration">

                    <div class="row marges ligne_form">

                        <div class="col_12">

                            <div class="form-group">

                                <label>{{ 'article.offer.association.type.value' | translate}}</label>

                                <div class="radio">
                                    <input type="radio" formControlName="mode" class="form-control" value="manual" id="offer-configuration-association-manual">
                                    <label for="offer-configuration-association-manual">{{ 'association.manual.value' | translate }}</label>
                                </div>

                                <div class="radio">
                                    <input type="radio" formControlName="mode" class="form-control" value="automatic" id="offer-configuration-association-automatic">
                                    <label for="offer-configuration-association-automatic">{{ 'association.automatic.value' | translate }}</label>
                                </div>
                            </div>

                            <div class="form-group" *ngIf="offerConfigurationControl.get('mode').value === 'manual'">
                                <span class="message-help"><mat-icon class="tooltip_icon">info</mat-icon><span>{{ 'article.offer.association.manual.info.value' | translate }}</span></span>
                            </div>

                            <div class="form-group" *ngIf="offerConfigurationControl.get('mode').value === 'automatic'">
                                <span class="message-help"><mat-icon class="tooltip_icon">info</mat-icon><span>{{ 'article.offer.association.automatic.info.value' | translate }}</span></span>
                            </div>

                        </div>

                    </div>

                </ng-container>

            </ng-container>

            <div class="ligne_form row_marges">
                <div class="col_12">
                    <div class="form-group">
                        <div class="form_part">
                            <p class="title_part">{{ 'feature.plural.value' | translate }}</p>
                        </div>
                        <div style="margin-top: 12px">

                            <div class="form-group">
                                <span class="message-help"><mat-icon class="tooltip_icon">info</mat-icon><span>{{ 'article.characteristic.info.value' | translate }}</span></span>
                            </div>

                            <ng-container formGroupName="configuration">

                                <div class="form-group">
                                    <mat-select class="form-control" formControlName="type" [compareWith]="compareById">
                                        <mat-option value=""></mat-option>
                                        <mat-option *ngFor="let type of types" [value]="type">
                                            {{ translationService.getFallbackTranslation(type.translations).label }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-label class="required">{{ 'article.type.value' | translate }}</mat-label>
                                    <mat-error *ngIf="configurationControl.get('type').errors && (configurationControl.get('type').dirty || configurationControl.get('type').touched)">
                                        <div class="invalid-feedback">
                                            <div *ngIf="configurationControl.get('type').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                        </div>
                                    </mat-error>
                                </div>

                                <div class="form-group">
                                    <mat-select class="form-control" formControlName="targetMarkets" multiple>
                                        <mat-option *ngFor="let targetMarket of targetMarkets" [value]="targetMarket">
                                            {{ getTargetMarketLabel(targetMarket) | translate }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-label class="required">{{ 'article.targetMarket.plural.value' | translate }}</mat-label>
                                    <mat-error *ngIf="configurationControl.get('targetMarkets').errors && (configurationControl.get('targetMarkets').dirty || configurationControl.get('targetMarkets').touched)">
                                        <div class="invalid-feedback">
                                            <div *ngIf="configurationControl.get('targetMarkets').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                        </div>
                                    </mat-error>
                                </div>

                                <div class="form-group">
                                    <mat-select class="form-control" formControlName="customerTypologies" multiple [compareWith]="compareById">
                                        <mat-option #allCustomerTypologiesOption [value]="{ id: null }" (click)="handleCustomerTypology(allCustomerTypologiesOption)"><span style="font-weight: bold">{{ 'customerTypology.all.value' | translate }}</span></mat-option>
                                        <mat-option #customerTypologyOption *ngFor="let customerTypology of customerTypologies" [value]="customerTypology" (click)="handleCustomerTypology(customerTypologyOption)">
                                            {{ translationService.getFallbackTranslation(customerTypology.translations).name }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-label [ngClass]="{ 'required': offerConfigurationControl.get('mode').value === 'automatic' }">{{ 'customerTypology.plural.value' | translate }}</mat-label>
                                    <mat-error *ngIf="configurationControl.get('customerTypologies').errors && (configurationControl.get('customerTypologies').dirty || configurationControl.get('customerTypologies').touched)">
                                        <div class="invalid-feedback">
                                            <div *ngIf="configurationControl.get('customerTypologies').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                        </div>
                                    </mat-error>
                                </div>

                                <div class="form-group">
                                    <mat-select formControlName="themes" multiple [compareWith]="compareById">
                                        <mat-option #allThemesOption [value]="{ id: null }" (click)="handleThemeAttribute(allThemesOption)"><span style="font-weight: bold">{{ 'attribute.theme.all.value' | translate }}</span></mat-option>
                                        <mat-option #themeOption *ngFor="let attribute of getAttributeObservable( 'themes') | async;" [value]="attribute" (click)="handleThemeAttribute(themeOption)">
                                            {{ translationService.getFallbackTranslation(attribute.translations).label }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-label [ngClass]="{ 'required': offerConfigurationControl.get('mode').value === 'automatic' }">{{ 'offer.list.filter.themes.value' | translate }}</mat-label>
                                    <mat-error *ngIf="configurationControl.get('themes').errors && (configurationControl.get('themes').dirty || configurationControl.get('themes').touched)">
                                        <div class="invalid-feedback">
                                            <div *ngIf="configurationControl.get('themes').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                        </div>
                                    </mat-error>
                                </div>

                                <div class="form-group">
                                    <mat-select formControlName="regions" multiple [compareWith]="compareById">
                                        <mat-option #allRegionsOption [value]="{ id: null }" (click)="handleRegionAttribute(allRegionsOption)"><span style="font-weight: bold">{{ 'attribute.region.all.value' | translate }}</span></mat-option>
                                        <mat-option #regionOption *ngFor="let attribute of getAttributeObservable( 'region') | async;" [value]="attribute" (click)="handleRegionAttribute(regionOption)">
                                            {{ translationService.getFallbackTranslation(attribute.translations).label }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-label [ngClass]="{ 'required': offerConfigurationControl.get('mode').value === 'automatic' }">{{ 'offer.list.filter.region.value' | translate }}</mat-label>
                                    <mat-error *ngIf="configurationControl.get('regions').errors && (configurationControl.get('regions').dirty || configurationControl.get('regions').touched)">
                                        <div class="invalid-feedback">
                                            <div *ngIf="configurationControl.get('regions').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                        </div>
                                    </mat-error>
                                </div>

                                <div class="form-group">
                                    <mat-select formControlName="types" multiple [compareWith]="compareById">
                                        <mat-option #allTypesOption [value]="{ id: null }" (click)="handleTypeAttribute(allTypesOption)"><span style="font-weight: bold">{{ 'attribute.type.all.value' | translate }}</span></mat-option>
                                        <mat-option #typeOption *ngFor="let attribute of getAttributeObservable( 'types') | async;" [value]="attribute" (click)="handleTypeAttribute(typeOption)">
                                            {{ translationService.getFallbackTranslation(attribute.translations).label }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-label [ngClass]="{ 'required': offerConfigurationControl.get('mode').value === 'automatic' }">{{ 'stay.plural.type.plural.value' | translate }}</mat-label>
                                    <mat-error *ngIf="configurationControl.get('types').errors && (configurationControl.get('types').dirty || configurationControl.get('types').touched)">
                                        <div class="invalid-feedback">
                                            <div *ngIf="configurationControl.get('types').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                        </div>
                                    </mat-error>
                                </div>

                            </ng-container>

                        </div>
                    </div>
                </div>
            </div>

            <div class="ligne_form row_marges">
                <div class="col_12">
                    <div class="form-group">
                        <div class="form_part">
                            <p class="title_part">{{ 'status.value' | translate }}</p>
                        </div>
                        <div style="margin-top: 12px">

                            <div class="form-group">
                                <div class="radio">
                                    <input type="radio" formControlName="status" class="form-control" value="draft" id="status-draft">
                                    <label for="status-draft">{{ 'article.status.draft.value' | translate }}</label>
                                </div>
                                <div class="radio">
                                    <input type="radio" formControlName="status" class="form-control" value="published" id="status-published">
                                    <label for="status-published">{{ 'article.status.published.value' | translate }}</label>
                                </div>
                            </div>

                            <div class="form-group" *ngIf="!updateStatusAllowed">
                                <span class="message-help"><mat-icon class="tooltip_icon">info</mat-icon><span>{{ 'article.status.update.disallow.info.value' | translate }}</span></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ng-container *ngIf="form.get('status').value === 'published'">

                <div class="ligne_form row_marges">
                    <div class="col_12">
                        <div class="form-group">
                            <div class="form_part">
                                <p class="title_part">{{ 'publication.value' | translate }}</p>
                            </div>
                            <div style="margin-top: 12px">

                                <div class="row marges ligne_form little_margin">

                                    <div class="col_12 col_md_4">
                                        <div class="form-group">
                                            <input type="text" class="required datetimepicker" id="dateStart-{{i}}" placeholder=" " matInput formControlName="dateStart" [matDatepicker]="dateStartPicker" (click)="dateStartPicker.open()" (dateChange)="form.get('dateEnd').patchValue(null); dateEndPicker.open()">
                                            <label for="dateStart-{{i}}" class="required">{{ 'filter.dateInterval.start.value' | translate }}</label>
                                            <mat-datepicker #dateStartPicker></mat-datepicker>
                                            <mat-error *ngIf="form.get('dateStart').errors && (form.get('dateStart').dirty || form.get('dateStart').touched)">
                                                <div class="invalid-feedback">
                                                    <div *ngIf="form.get('dateStart').errors.isRequired">{{ 'form.validation.error.required' | translate }}</div>
                                                </div>
                                            </mat-error>
                                        </div>
                                    </div>

                                    <div class="col_12 col_md_4">
                                        <div class="form-group">
                                            <input type="text" class="datetimepicker" id="dateEnd-{{i}}" placeholder=" " matInput formControlName="dateEnd" [matDatepicker]="dateEndPicker" [min]="form.get('dateStart').value" (click)="dateEndPicker.open()">
                                            <label for="dateEnd-{{i}}">{{ 'filter.dateInterval.end.value' | translate }}</label>
                                            <mat-datepicker #dateEndPicker></mat-datepicker>
                                            <mat-error *ngIf="form.get('dateEnd').errors && (form.get('dateEnd').dirty || form.get('dateEnd').touched)">
                                                <div class="invalid-feedback">
                                                    <div *ngIf="form.get('dateEnd').errors.isRequired">{{ 'form.validation.error.required' | translate }}</div>
                                                </div>
                                            </mat-error>
                                        </div>
                                    </div>

                                </div>

                                <div class="row marges ligne_form">
                                    <div class="col_12">
                                        <div class="form-group">
                                            <span class="message-help"><mat-icon class="tooltip_icon">info</mat-icon><span>{{ 'article.publication.info.value' | translate }}</span></span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </ng-container>

        </div>

    </ng-container>

</ng-template>

<!-- Contenu -->

<ng-template #contentData>

    <ng-container *ngIf="!userService.hasOneOfThisRoles(this.currentUser, ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'])">

        <div class="row">
            <div class="col_12">
                <div class="form-group">
                    <span class="message-help"><mat-icon class="tooltip_icon">info</mat-icon><span appArticleCharterPublicationDescription [replacedString]="'{{ACTION}}'" [actionCallback]="openPublicationCharterDialog.bind(this)" [contentString]="'article.form.charter.publication.link.value' | translate">{{ 'article.form.charter.publication.description.value' | translate }}</span></span>
                </div>
            </div>
        </div>

    </ng-container>

    <div class="row" [ngStyle]="{ 'margin-top': userService.hasOneOfThisRoles(this.currentUser, ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']) ? 0 : '12px' }" style="margin-top: 12px;">

        <cms-root [params]="cmsParams" [contentFormControl]="form.get('cmsContent')" [data]="cmsData"></cms-root>

    </div>

</ng-template>

<!-- Offres associés -->

<ng-template #offerAssociation>

    <div class="content_tab">

        <ng-container *ngIf="offerConfigurationControl.get('mode').value === 'manual'">

            <div [formGroup]="form" class="tab_content_overflow">

                <div class="row marges">

                    <div class="col y_items_center">

                        <div class="selected_offers" *ngIf="offerSearchService.selectedOffers.value.length">

                            <span>{{ 'offer.list.selected.value' | translate }} :</span>

                            <ul>
                                <li *ngFor="let offer of offerSearchService.selectedOffers.value">{{ translationService.getFallbackTranslation(offer.translations).name }}<mat-icon (click)="unselectOffer(offer)">close</mat-icon></li>
                            </ul>

                        </div>
                    </div>

                    <div class="col_content y_items_center" *ngIf="offerSearchService.selectedOffers.value.length">
                        <button type="button" class="btn_cta btn_little" (click)="resetOfferSelection() ">{{ 'offer.list.selection.reset.action.value' | translate}}</button>
                    </div>

                </div>

                <app-core-offer-search
                        [user]="currentUser"
                        [society]="currentUser.society"
                        [mode]="offerSearchMode"
                        [loadItemsSourceCallback]="loadOffersSourceCallback"
                ></app-core-offer-search>

            </div>

        </ng-container>

        <ng-container [formGroup]="form" *ngIf="offerConfigurationControl.get('mode').value === 'automatic'">


            <!-- Nombre d'offres remontées -->

            <div class="ligne_form row_marges">
                <div class="col_12">
                    <div class="form-group">
                        <div class="form_part">
                            <p class="title_part">{{ 'article.offer.association.search.refine.result.title.value' | translate }} : {{ countAvailableOffersFromRefineSearch }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row ligne_form">
                <div class="col_12">
                    <div class="form-group">
                        <span class="message-help"><mat-icon class="tooltip_icon">info</mat-icon><span>{{ 'article.offer.association.automatic.description.value' | translate }}</span></span>
                    </div>
                </div>
            </div>

            <ng-container formGroupName="offerConfiguration">

                <div class="row ligne_form">
                    <div class="col_12">
                        <div class="form-group">
                            <label>{{ 'article.offer.association.search.refine.action.value' | translate}}</label>
                            <div class="radio">
                                <input type="radio" formControlName="refineSearch" class="form-control" [value]="false" id="offer-configuration-association-refine-no">
                                <label for="offer-configuration-association-refine-no">{{ 'no.value' | translate }}</label>
                            </div>
                            <div class="radio">
                                <input type="radio" formControlName="refineSearch" class="form-control" [value]="true" id="offer-configuration-association-refine-yes">
                                <label for="offer-configuration-association-refine-yes">{{ 'yes.value' | translate }}</label>
                            </div>
                        </div>
                    </div>
                </div>

                <ng-container *ngIf="offerConfigurationControl.get('refineSearch').value">

                    <!-- Nombre d'adultes -->

                    <div class="row marges ligne_form">

                        <div class="col_12 col_md_4">
                            <div class="form-group">
                                <div class="checkbox">
                                    <input type="checkbox" class="form-control" [checked]="isEnabledRefineSearchField('nbAdult')" (change)="handleEnabledRefineSearchField('nbAdult')" id="refine-search-field-nbAdult">
                                    <label for="refine-search-field-nbAdult">{{ 'adult.count.value' | translate }}</label>
                                </div>
                            </div>
                        </div>

                        <ng-container *ngIf="isEnabledRefineSearchField('nbAdult')">

                            <div class="col_12 col_md_4">
                                <div class="form-group">
                                    <input type="text" value="" placeholder=" " class="form-control" formControlName="nbAdult" id="nbAdult">
                                    <label class="required" for="nbAdult">{{ 'adult.count.value' | translate }}</label>
                                    <mat-error>
                                        <div *ngIf="offerConfigurationControl.get('nbAdult').invalid && (offerConfigurationControl.get('nbAdult').dirty || offerConfigurationControl.get('nbAdult').touched)">
                                            <div *ngIf="offerConfigurationControl.get('nbAdult').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                            <div *ngIf="offerConfigurationControl.get('nbAdult').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                        </div>
                                    </mat-error>
                                </div>
                            </div>

                        </ng-container>

                    </div>

                    <!-- Nombre d'enfants -->

                    <div class="row marges ligne_form">

                        <div class="col_12 col_md_4">
                            <div class="form-group">
                                <div class="checkbox">
                                    <input type="checkbox" class="form-control" [checked]="isEnabledRefineSearchField('nbChild')" (change)="handleEnabledRefineSearchField('nbChild')" id="refine-search-field-nbChild">
                                    <label for="refine-search-field-nbChild">{{ 'child.count.value' | translate }}</label>
                                </div>
                            </div>
                        </div>

                        <ng-container *ngIf="isEnabledRefineSearchField('nbChild')">

                            <div class="col_12 col_md_4">
                                <div class="form-group">
                                    <input type="text" value="" placeholder=" " class="form-control" formControlName="nbChild" id="nbChild">
                                    <label class="required" for="nbChild">{{ 'child.count.value' | translate }}</label>
                                    <mat-error>
                                        <div *ngIf="offerConfigurationControl.get('nbChild').invalid && (offerConfigurationControl.get('nbChild').dirty || offerConfigurationControl.get('nbChild').touched)">
                                            <div *ngIf="offerConfigurationControl.get('nbChild').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                            <div *ngIf="offerConfigurationControl.get('nbChild').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                        </div>
                                    </mat-error>
                                </div>
                            </div>

                        </ng-container>

                    </div>

                    <!-- Période de départ -->

                    <div class="row marges ligne_form">

                        <div class="col_12 col_md_4">
                            <div class="form-group">
                                <div class="checkbox">
                                    <input type="checkbox" class="form-control" [checked]="isEnabledRefineSearchField('period')" (change)="handleEnabledRefineSearchField('period')" id="refine-search-field-period">
                                    <label for="refine-search-field-period">{{ 'departure.period.value' | translate }}</label>
                                </div>
                            </div>
                        </div>

                        <ng-container *ngIf="isEnabledRefineSearchField('period')">

                            <div class="col_12 col_md_4">
                                <div class="form-group">
                                    <input type="text" class="required datetimepicker" id="period-dateStart-{{i}}" placeholder=" " matInput formControlName="dateStart" [matDatepicker]="periodDateStartPicker" (click)="periodDateStartPicker.open()" (dateChange)="offerConfigurationControl.get('dateEnd').patchValue(null); periodDateEndPicker.open()">
                                    <label for="period-dateStart-{{i}}" class="required">{{ 'filter.dateInterval.start.value' | translate }}</label>
                                    <mat-datepicker #periodDateStartPicker></mat-datepicker>
                                    <mat-error *ngIf="offerConfigurationControl.get('dateStart').errors && (offerConfigurationControl.get('dateStart').dirty || offerConfigurationControl.get('dateStart').touched)">
                                        <div class="invalid-feedback">
                                            <div *ngIf="offerConfigurationControl.get('dateStart').errors.isRequired">{{ 'form.validation.error.required' | translate }}</div>
                                        </div>
                                    </mat-error>
                                </div>
                            </div>

                            <div class="col_12 col_md_4">
                                <div class="form-group">
                                    <input type="text" class="required datetimepicker" id="period-dateEnd-{{i}}" placeholder=" " matInput formControlName="dateEnd" [matDatepicker]="periodDateEndPicker" [min]="offerConfigurationControl.get('dateStart').value" (click)="periodDateEndPicker.open()">
                                    <label for="period-dateEnd-{{i}}" class="required">{{ 'filter.dateInterval.end.value' | translate }}</label>
                                    <mat-datepicker #periodDateEndPicker></mat-datepicker>
                                    <mat-error *ngIf="offerConfigurationControl.get('dateEnd').errors && (offerConfigurationControl.get('dateEnd').dirty || offerConfigurationControl.get('dateEnd').touched)">
                                        <div class="invalid-feedback">
                                            <div *ngIf="offerConfigurationControl.get('dateEnd').errors.isRequired">{{ 'form.validation.error.required' | translate }}</div>
                                        </div>
                                    </mat-error>
                                </div>
                            </div>

                        </ng-container>

                    </div>

                    <!-- Durée -->

                    <div class="row marges ligne_form">

                        <div class="col_12 col_md_4">
                            <div class="form-group">
                                <div class="checkbox">
                                    <input type="checkbox" class="form-control" [checked]="isEnabledRefineSearchField('durations')" (change)="handleEnabledRefineSearchField('durations')" id="refine-search-field-duration">
                                    <label for="refine-search-field-duration">{{ 'duration.value' | translate }}</label>
                                </div>
                            </div>
                        </div>

                        <ng-container *ngIf="isEnabledRefineSearchField('durations')">

                            <div class="col_12 col_md_4">
                                <div class="form-group">
                                    <mat-select [compareWith]="compareById" multiple formControlName="durations">
                                        <mat-option *ngFor="let duration of durations" [value]="duration">
                                            {{ translationService.getFallbackTranslation(duration.translations).label }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-label class="required">{{ 'duration.value' | translate }}</mat-label>
                                    <mat-error>
                                        <div *ngIf="offerConfigurationControl.get('durations').invalid && (offerConfigurationControl.get('durations').dirty || offerConfigurationControl.get('durations').touched)">
                                            <div *ngIf="offerConfigurationControl.get('durations').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                        </div>
                                    </mat-error>
                                </div>
                            </div>

                        </ng-container>

                    </div>

                    <!-- Ville -->

                    <div class="row marges ligne_form">

                        <div class="col_12 col_md_4">
                            <div class="form-group">
                                <div class="checkbox">
                                    <input type="checkbox" class="form-control" [checked]="isEnabledRefineSearchField('city')" (change)="handleEnabledRefineSearchField('city')" id="refine-search-field-city">
                                    <label for="refine-search-field-city">{{ 'town.value' | translate }}</label>
                                </div>
                            </div>
                        </div>

                        <ng-container *ngIf="isEnabledRefineSearchField('city')">

                            <div class="col_12 col_md_4">
                                <div class="form-group">
                                    <input type="text" value="" placeholder=" " class="form-control" formControlName="city" id="city">
                                    <label class="required" for="city">{{ 'town.value' | translate }}</label>
                                    <mat-error>
                                        <div *ngIf="offerConfigurationControl.get('city').invalid && (offerConfigurationControl.get('city').dirty || offerConfigurationControl.get('city').touched)">
                                            <div *ngIf="offerConfigurationControl.get('city').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                        </div>
                                    </mat-error>
                                </div>
                            </div>

                        </ng-container>

                    </div>

                    <!-- Hébergements -->

                    <div class="row marges ligne_form">

                        <div class="col_12 col_md_4">
                            <div class="form-group">
                                <div class="checkbox">
                                    <input type="checkbox" class="form-control" [checked]="isEnabledRefineSearchField('accommodations')" (change)="handleEnabledRefineSearchField('accommodations')" id="refine-search-field-accommodations">
                                    <label for="refine-search-field-accommodations">{{ 'accommodation.plural.value' | translate }}</label>
                                </div>
                            </div>
                        </div>

                        <ng-container *ngIf="isEnabledRefineSearchField('accommodations')">

                            <div class="col_12 col_md_8">
                                <div class="form-group">
                                    <mat-select formControlName="accommodations" multiple [compareWith]="compareById">
                                        <mat-option *ngFor="let attribute of getAttributeObservable( 'accommodations') | async;" [value]="attribute">
                                            {{ translationService.getFallbackTranslation(attribute.translations).label }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-label class="required">{{ 'accommodation.plural.value' | translate }}</mat-label>
                                    <mat-error>
                                        <div *ngIf="offerConfigurationControl.get('accommodations').invalid && (offerConfigurationControl.get('accommodations').dirty || offerConfigurationControl.get('accommodations').touched)">
                                            <div *ngIf="offerConfigurationControl.get('accommodations').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                        </div>
                                    </mat-error>
                                </div>
                            </div>

                        </ng-container>

                    </div>

                    <!-- Restaurations -->

                    <div class="row marges ligne_form">

                        <div class="col_12 col_md_4">
                            <div class="form-group">
                                <div class="checkbox">
                                    <input type="checkbox" class="form-control" [checked]="isEnabledRefineSearchField('restorations')" (change)="handleEnabledRefineSearchField('restorations')" id="refine-search-field-restorations">
                                    <label for="refine-search-field-restorations">{{ 'restoration.type.plural.value' | translate }}</label>
                                </div>
                            </div>
                        </div>

                        <ng-container *ngIf="isEnabledRefineSearchField('restorations')">

                            <div class="col_12 col_md_8">
                                <div class="form-group">
                                    <mat-select formControlName="restorations" multiple [compareWith]="compareById">
                                        <mat-option *ngFor="let attribute of getAttributeObservable( 'restoration') | async;" [value]="attribute">
                                            {{ translationService.getFallbackTranslation(attribute.translations).label }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-label class="required">{{ 'restoration.type.plural.value' | translate }}</mat-label>
                                    <mat-error>
                                        <div *ngIf="offerConfigurationControl.get('restorations').invalid && (offerConfigurationControl.get('restorations').dirty || offerConfigurationControl.get('restorations').touched)">
                                            <div *ngIf="offerConfigurationControl.get('restorations').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                        </div>
                                    </mat-error>
                                </div>
                            </div>

                        </ng-container>

                    </div>

                    <!-- Activités -->

                    <div class="row marges ligne_form">

                        <div class="col_12 col_md_4">
                            <div class="form-group">
                                <div class="checkbox">
                                    <input type="checkbox" class="form-control" [checked]="isEnabledRefineSearchField('activities')" (change)="handleEnabledRefineSearchField('activities')" id="refine-search-field-activities">
                                    <label for="refine-search-field-activities">{{ 'offer.form.fields.activity.value' | translate }}</label>
                                </div>
                            </div>
                        </div>

                        <ng-container *ngIf="isEnabledRefineSearchField('activities')">

                            <div class="col_12 col_md_8">
                                <div class="form-group">
                                    <mat-select formControlName="activities" multiple [compareWith]="compareById">
                                        <mat-option *ngFor="let attribute of getAttributeObservable( 'activities') | async;" [value]="attribute">
                                            {{ translationService.getFallbackTranslation(attribute.translations).label }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-label class="required">{{ 'offer.form.fields.activity.value' | translate }}</mat-label>
                                    <mat-error>
                                        <div *ngIf="offerConfigurationControl.get('activities').invalid && (offerConfigurationControl.get('activities').dirty || offerConfigurationControl.get('activities').touched)">
                                            <div *ngIf="offerConfigurationControl.get('activities').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                        </div>
                                    </mat-error>
                                </div>
                            </div>

                        </ng-container>

                    </div>

                </ng-container>

            </ng-container>

        </ng-container>
    </div>

</ng-template>

<!-- Prévisualisation de la miniature de l'article -->

<ng-template #articleCardPreview>

    <div class="content_tab" *ngIf="articleFormValue">

        <div class="offers_list">
            <div class="content_offers preview_card_list">
                <div class="mat-tab-translations translation-preview">
                    <ng-container>

                        <mat-tab-group [@.disabled]="true">

                            <div *ngFor="let locale of articleFormValue.locales">

                                <mat-tab label="{{ 'locale.' + locale + '.value' | translate }}">

                                    <ng-container>

                                        <div class="row">
                                            <div class="col_12 col_md_6 col_lg_4 content_card">
                                                <app-core-article-card [item]="articleFormValue" [locale]="locale" (clickCardEvent)="handleClickCard($event)" [mode]="'preview'"></app-core-article-card>
                                            </div>
                                        </div>

                                    </ng-container>
                                </mat-tab>
                            </div>

                        </mat-tab-group>

                    </ng-container>
                </div>

            </div>
        </div>

    </div>

</ng-template>

<!-- Prévisualisation de l'article -->

<ng-template #articleViewPreview>
    <ng-container *ngIf="articleFormValue">

        <div class="tab_content_overflow" *ngIf="articleFormValue">
            <div class="content_article">
                <div class="box_lg marges">
                    <app-core-article-read [item]="articleFormValue" [mode]="'preview'" [locale]="translationService.getFallbackTranslation(articleFormValue.translations).locale"></app-core-article-read>
                </div>
            </div>
        </div>

    </ng-container>
</ng-template>
