import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanDeactivate, Router, RouterStateSnapshot} from '@angular/router';
import { Observable } from 'rxjs';
import {LockableService} from "@core/shared/services/lockable.service";
import {map} from "rxjs/operators";
import {Lockable} from "@core/shared/models/lockable";

export interface UnlockableDeactivate {
    canDeactivate: () => Observable<boolean>;
}

@Injectable({
    providedIn: 'root',
})
export class UnlockableGuard implements CanDeactivate<UnlockableDeactivate> {

    constructor(
        private _router: Router,
        private _lockableService: LockableService
    ) {}

    canDeactivate(component: UnlockableDeactivate, route: ActivatedRouteSnapshot, state: RouterStateSnapshot, nextState: RouterStateSnapshot): (boolean|Observable<boolean>) {

        if(!nextState){

            return true;
        }

        const unlock: boolean = this._router.parseUrl(nextState.url).queryParamMap.has('unlock') ? Boolean(parseInt(this._router.parseUrl(nextState.url).queryParamMap.get('unlock'))) : false;

        if(!unlock){

            return true;
        }

        const routeData = route.data as { lockableClass: string };

        if(!('lockableClass' in routeData) || !route.paramMap.has('id')){

            throw new Error('Missing Guard Parameter.');
        }

        const data: Lockable = {
            class: routeData.lockableClass,
            identifier: route.paramMap.get('id')
        };

        return this._lockableService.unlockItemAPI(data).pipe(map(() => true));
    }
}
