import {Injectable} from '@angular/core';
import {ApiService} from "@core/shared/services/api.service";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {Pagination} from "@core/shared/models/pagination";
import {ArticleType} from "@core/shared/models/article/article-type";
import {AllPaginateItemsAPI} from "@core/shared/models/all-paginate-items-api";

@Injectable({
    providedIn: 'root'
})
export class ArticleTypeService {

    constructor(
        private _apiService: ApiService,
        private _httpClient: HttpClient
    ) {}

    public getItemsAPI(params?: string[]): Observable<ArticleType[]> {

        return new AllPaginateItemsAPI<ArticleType>(this.getPaginationItemsAPI.bind(this), params).request$;
    }

    public getPaginationItemsAPI(params?: string[]): Observable<Pagination<ArticleType>> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/articles/types`;

        params = params || [];

        if (params.length > 0) {

            url = `${url}?${params.join('&')}`;
        }

        return this._httpClient.get<Pagination<ArticleType>>(url, {
            headers: headers
        });
    }
}
