import {Injectable} from "@angular/core";
import {Subject} from "rxjs";

@Injectable()
export class CmsContentService {

    public contentUpdated$: Subject<void> = new Subject();

    public updateCurrentLocale$: Subject<string> = new Subject();

    public imageAsBinary: boolean = false;
}
