import {AfterViewInit, ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormService} from "@core/shared/services/form.service";
import {User} from "@core/shared/models/user";
import {Article, ArticleForm, ArticleStatus} from "@core/shared/models/article";
import {ActivatedRoute, Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, UntypedFormControl, ValidationErrors, Validators} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";
import {CrudService} from "@core/shared/services/crud.service";
import {UserService} from "@core/shared/services/user.service";
import {TranslationService} from "@core/shared/services/translation.service";
import {ArticleService} from "@core/shared/services/article.service";
import {TranslationBuilder} from "@core/shared/models/translation";
import * as ClassicEditor from "@lib/ckeditor";
import {ArticleTranslation, ArticleTranslationForm} from "@core/shared/models/article/article-translation";
import {Role, ROLE_LABELS, RoleLabel} from "@core/shared/models/role";
import {ArticleRestriction, ArticleRestrictionForm, ArticleRestrictionVisibility} from "@core/shared/models/article/article-restriction";
import {SelectSearchConfiguration} from "@core/components/select/select-search/select-search.component";
import {Observable, of, Subject} from "rxjs";
import {SocietyGroup} from "@core/shared/models/society-group";
import {debounceTime, distinctUntilChanged, map, tap} from "rxjs/operators";
import {Pagination} from "@core/shared/models/pagination";
import {SocietyGroupService} from "@core/shared/services/society-group.service";
import {LocaleItem} from '@core/shared/models/locale';
import {SUPPORTED_LOCALE_ITEMS} from "@core/shared/data/locale";
import {ImageConfig} from "@lib/form/fields/image/image.component";
import {ApiService} from "@core/shared/services/api.service";
import {ArticlePictureForm} from "@core/shared/models/article/article-picture";
import {MediaImageForm} from "@lib/media/image/image";
import {ArticleType} from "@core/shared/models/article/article-type";
import {ArticleTypeService} from "@core/shared/services/article/article-type.service";
import {ArticleConfiguration, ArticleConfigurationForm, ArticleConfigurationTargetMarket} from "@core/shared/models/article/article-configuration";
import {ARTICLE_CONFIGURATION_TARGET_MARKETS} from "@core/shared/data/article/article-configuration/article-configuration-target-market";
import {CustomerTypology} from "@core/shared/models/customer-typology";
import {CustomerTypologyService} from "@core/shared/services/customer-typology.service";
import {OfferAttributeTypeTagType} from "@core/shared/models/offer-attribute-type";
import {ArrayFilterField} from "@core/shared/models/filter/array-filter-field";
import {AttributeObservableData, OfferAttribute} from "@core/shared/models/offer-attribute";
import {OfferAttributeService} from "@core/shared/services/offer-attribute.service";
import {MatOption} from "@angular/material/core";
import moment, {Moment} from "moment";
import {DATE_FORMAT, DEACTIVATE_LOADER_HEADER_IDENTIFIER} from "@app/data";
import {Data as CmsData, ICmsParams} from "@lib/cms/cms";
import {ITranslation} from "@lib/cms/translation/translation";
import {ArticleOfferConfiguration, ArticleOfferConfigurationForm} from "@core/shared/models/article/article-offer/article-offer-configuration";
import {REGEX_ONLY_NUMBER} from "@core/shared/models/regex";
import {OfferDuration} from "@core/shared/models/offer/offer-duration";
import {OfferDurationService} from "@core/shared/services/offer/offer-duration.service";
import {OfferService} from "@core/shared/services/offer.service";
import {OfferCatalogService} from "@core/shared/services/offer/offer-catalog.service";
import {Offer} from "@core/shared/models/offer";
import {OfferCatalog} from "@core/shared/models/offer/offer-catalog";
import {AdditionalHeader} from "@core/shared/models/http-client";
import {OfferSearchService} from "@core/shared/services/offer/offer-search.service";
import {OfferListService} from "@core/shared/services/offer/offer-list.service";
import {OfferCardService} from "@core/shared/services/offer/offer-card.service";
import {ViewType} from "@core/components/offer/offer-search/offer-search.component";
import {OfferLocation} from "@core/shared/models/offer/offer-location";
import {OfferLocationService} from "@core/shared/services/offer/offer-location/offer-location-service";
import {ArticleOfferConfigurationOffer} from "@core/shared/models/article/article-offer/article-offer-configuration/article-offer-configuration-offer";
import {ModeType} from "@core/shared/models/offer/offer-list";
import {getArticleCkeditorConfig} from "@core/shared/data/article/article-ckeditor";
import {ArticleCardService} from '@core/shared/services/article/article-card.service';
import {Society} from '@core/shared/models/society';
import {CmsContentService} from "@core/shared/services/cms/cms-content.service";
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {CmsService} from "@core/shared/services/cms.service";
import {MatTab, MatTabChangeEvent, MatTabGroup} from '@angular/material/tabs';
import {FormTabValidationItem, OfferFormTabValidationService} from "@core/shared/services/form/form-tab-validation.service";
import {ArticleCharterPublicationDialogComponent} from '@core/components/article/article-charter-publication-dialog/article-charter-publication-dialog.component';
import {Slideshow} from "@lib/cms/element/type/slideshow/slideshow";
import {IRow} from "@lib/cms/row/row";
import {IColumn} from "@lib/cms/column/column";
import {IElement} from "@lib/cms/element/element";
import {ConfirmDialogComponent} from "@lib/confirm-dialog/confirm-dialog.component";
import {ConfirmDialogData} from "@lib/confirm-dialog/confirm-dialog";
import {ArticleSearchService} from "@core/shared/services/article/article-search.service";

type RefineSearchFieldIdentifier = 'nbAdult' | 'nbChild' | 'period' | 'durations' | 'city' | 'accommodations' | 'restorations' | 'activities' | 'customerTypologies' | 'regions' | 'types' | 'themes';

enum TabTag {
    GeneralData = 'generalData',
    ContentData = 'contentData',
    OfferAssociation = 'offerAssociation'
}

@Component({
    selector: 'app-core-article-form',
    templateUrl: './article-form.component.html',
    styleUrls: ['./article-form.component.scss'],
    providers: [
        OfferFormTabValidationService,
        FormService,
        OfferSearchService,
        OfferListService,
        OfferCardService,
        ArticleSearchService,
        ArticleCardService,
        CmsContentService
    ]
})
export class ArticleFormComponent implements OnInit, AfterViewInit {

    @ViewChild('tabGroup', {static: true}) tabGroup: MatTabGroup;

    @ViewChild('generalData', {static: true}) generalDataRef: TemplateRef<any>;

    @ViewChild('contentData', {static: false}) contentDataRef: TemplateRef<any>;

    @ViewChild('offerAssociation', {static: false}) offerAssociationRef: TemplateRef<any>;

    public currentUser: User;

    public article: Article;

    public translationBuilder: TranslationBuilder;

    public tabItems: { tag: string, label: string, template: TemplateRef<any> }[] = [];

    public maxLengths: { title: number, teaser: number } = {
        title: 80,
        teaser: 200
    };

    public editor = ClassicEditor;

    public availableVisibilities: ArticleRestrictionVisibility[] = ['ALL', 'ROLE', 'SOCIETY_GROUP'];

    public types: ArticleType[] = [];

    public targetMarkets: ArticleConfigurationTargetMarket[] = [];

    public customerTypologies: CustomerTypology[] = [];

    public regionAttributes: OfferAttribute[] = [];

    public typeAttributes: OfferAttribute[] = [];

    public themeAttributes: OfferAttribute[] = [];

    public durations: OfferDuration[];

    public attributeObservables$: AttributeObservableData[] = [];

    // Rôles utilisateur

    public roleSearchConfiguration: SelectSearchConfiguration<RoleLabel>;

    public roleSearchSourceCallback: (search: string) => Observable<RoleLabel[]>;

    // Groupes

    public societyGroupSearchConfiguration: SelectSearchConfiguration<SocietyGroup>;

    public societyGroupSearchSourceCallback: (search: string) => Observable<SocietyGroup[]>;

    // Langues

    public localeSearchConfiguration: SelectSearchConfiguration<LocaleItem>;

    public localeSearchSourceCallback: (search: string) => Observable<LocaleItem[]>;

    // Gestion du contenu

    public cmsData: CmsData = {
        content: {
            context: 'cms_content_page',
            translations: []
        }
    };

    public cmsParams: ICmsParams;

    // Affinage de la recherche

    public enabledRefineSearchFields: RefineSearchFieldIdentifier[] = [];

    public countAvailableOffersFromRefineSearch: number = 0;

    public offerCatalogs: OfferCatalog[] = [];

    public currentTabIndex: number = 0;

    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _dialog: MatDialog,
        private _snackBar: MatSnackBar,
        private _formBuilder: FormBuilder,
        private _apiService: ApiService,
        private _cmsService: CmsService,
        private _articleTypeService: ArticleTypeService,
        private _translateService: TranslateService,
        private _customerTypologyService: CustomerTypologyService,
        private _offerDurationService: OfferDurationService,
        private _cmsContentService: CmsContentService,
        private _offerAttributeService: OfferAttributeService,
        private _articleService: ArticleService,
        private _societyGroupService: SocietyGroupService,
        private _offerService: OfferService,
        private _offerLocationService: OfferLocationService,
        private _offerCatalogService: OfferCatalogService,
        public crudService: CrudService,
        public userService: UserService,
        public formService: FormService,
        public translationService: TranslationService,
        public offerFormTabValidationService: OfferFormTabValidationService,
        public offerSearchService: OfferSearchService
    ) {
    }

    ngOnInit() {

        this.currentUser = this.userService.currentUser.getValue();

        this.article = this.crudService.isUpdateAction ? (this._activatedRoute.snapshot.data as { article: Article }).article : null;

        if(this.crudService.isUpdateAction && this.article.society){

            this._hydrateOfferCatalogs(this.article.offerConfiguration.offers.map((item: ArticleOfferConfigurationOffer): OfferCatalog => {

                return item.offerCatalog;
            }));
        }

        this._initCms();

        this._configureCmsContentService();

        this._configureOfferSearchService();

        this._initTypes();

        this._initTargetMarkets();

        this._initCustomerTypologies();

        this._initDurations();

        this._initAttributes();

        this._initRoleConfiguration();

        this._initSocietyGroupConfiguration();

        this._initLocaleConfiguration();

        this._initForm();

        this._hydrateForm();

        this._initEvents();

        if(this.crudService.isUpdateAction) {

            this._refreshCountAvailableOffersFromRefineSearch();
        }
    }

    ngAfterViewInit() {

        this._initTabItems();

        this._initFormTabValidation();

        this.tabGroup.selectedTabChange.subscribe((item: MatTabChangeEvent): void  => {

            this.currentTabIndex = item.index;
        });

        this._changeDetectorRef.detectChanges();
    }

    private _initTabItems(): void {

        this.tabItems = [];

        this.tabItems.push({
            tag: TabTag.GeneralData,
            label: 'generalData.value',
            template: this.generalDataRef
        });

        if(this.isMine){

            this.tabItems.push({
                tag: TabTag.ContentData,
                label: 'content.value',
                template: this.contentDataRef
            });
        }

        if(this.isMine){

            this.tabItems.push({
                tag: TabTag.OfferAssociation,
                label: 'offer.plural.associated.value',
                template: this.offerAssociationRef
            });
        }
    }

    private _initFormTabValidation(): void {

        this.offerFormTabValidationService.init(this.tabGroup, this.form, this.formTabValidationItemsCallback);
    }

    private _initCms(): void {

        this.cmsParams = {
            configSource: this._cmsService.getConfig(),
            context: 'article_picture',
            blocLabel: 'Bloc Label',
            ckeditor: {
                editor: this.editor,
                config: getArticleCkeditorConfig(this._translateService, this.userService)
            },
            customButtons: [],
            entityName: 'Page',
            enableMultilingual: true,
            tag: 'tag',
            entityFieldName: 'content',
            parentContainer: document.getElementById('admin-sidenav-content'),
            apiUrl: `${this._apiService.getApiUrl(false, true)}`,
            optionsImage: {
                enableAlignment: true,
                enableTitle: true,
                enableSubtitle: false,
                enableAlt: false,
                enableLink: false,
                enableTargetBlank: false,
                enableWatermark: true
            },
            columnParams: {
                enableBackgroundColor: false
            },
            templateContext: 'article'
        };
    }

    private _initTypes(): void {

        const params: string[] = [];

        if(!this.userService.hasOneOfThisRoles(this.currentUser, ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'])){

            params.push('internal[eq]=0');
        }

        this._articleTypeService.getItemsAPI(params).subscribe((items: ArticleType[]): void => {

            this.types = items;
        });
    }

    private _initTargetMarkets(): void {

        this.targetMarkets = ARTICLE_CONFIGURATION_TARGET_MARKETS;
    }

    private _initCustomerTypologies(): void {

        this._customerTypologyService.getItemsAPI().subscribe((items: CustomerTypology[]): void => {

            this.customerTypologies = items;

            if(this.crudService.isCreateAction){

                return;
            }

            if(this.article.configuration.customerTypologies.length !== this.customerTypologies.length){

                return;
            }

            const selectedCustomerTypologies: Partial<CustomerTypology>[] = [...[{ id: null }] , ...this.configurationControl.get('customerTypologies').value];

            this.configurationControl.get('customerTypologies').patchValue(selectedCustomerTypologies);
        });
    }

    private _initDurations(): void {

        const params: string[] = [
            `sort[position]=ASC`
        ];

        this._offerDurationService.getItemsAPI(params).subscribe((items: OfferDuration[]): void => {

            this.durations = items;
        });
    }

    private _initAttributes(): void {

        this.attributeObservables$ = [];

        this.attributeObservables$.push(...this.attributeTypeTags.map((tag: OfferAttributeTypeTagType): AttributeObservableData => {

            const typeFilter: ArrayFilterField = new ArrayFilterField('type.tag', 'andin', tag);

            return {
                tag: tag,
                observable: this._offerAttributeService.getItemsAPI([
                    typeFilter.serialize
                ]).pipe(tap((items: OfferAttribute[]): void => {

                    switch (tag){

                        case 'types':

                            this.typeAttributes = items;

                            if(this.crudService.isCreateAction){

                                return;
                            }

                            if(this.article.configuration.types.length !== this.typeAttributes.length){

                                return;
                            }

                            const selectedTypes: Partial<OfferAttribute>[] = [...[{ id: null }] , ...this.configurationControl.get('types').value];

                            this.configurationControl.get('types').patchValue(selectedTypes);

                            break;

                        case 'region':

                            this.regionAttributes = items;

                            if(this.crudService.isCreateAction){

                                return;
                            }

                            if(this.article.configuration.regions.length !== this.regionAttributes.length){

                                return;
                            }

                            const selectedRegions: Partial<OfferAttribute>[] = [...[{ id: null }] , ...this.configurationControl.get('regions').value];

                            this.configurationControl.get('regions').patchValue(selectedRegions);

                            break;

                        case 'themes':

                            this.themeAttributes = items;

                            if(this.crudService.isCreateAction){

                                return;
                            }

                            if(this.article.configuration.themes.length !== this.themeAttributes.length){

                                return;
                            }

                            const selectedThemes: Partial<OfferAttribute>[] = [...[{ id: null }] , ...this.configurationControl.get('themes').value];

                            this.configurationControl.get('themes').patchValue(selectedThemes);

                            break;
                    }
                }))
            };
        }));
    }

    private _initRoleConfiguration(): void {

        this.roleSearchConfiguration = {
            multiple: true,
            enableSearch: true,
            currentSelectionLabel: 'role.plural.user.selected.value',
            searchActionLabel: 'role.search.action.value',
            selectOptionActionLabel: 'role.select.action.value',
            itemLabel: (item: RoleLabel): string => {

                return this._translateService.instant(item.label);
            },
            compareOptionsCallback: (a: RoleLabel, b: RoleLabel): boolean => {

                if (!a || !b) {

                    return false;
                }

                return a.identifier === b.identifier;
            }
        }

        this.roleSearchSourceCallback = (search: string): Observable<RoleLabel[]> => {

            const items: RoleLabel[] = ROLE_LABELS.filter((item: RoleLabel): boolean => {

                return !(['ROLE_USER', 'ROLE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_FEDERATION'] as Role[]).includes(item.identifier);
            });

            if (search && search.length) {

                return of(items.filter((item: RoleLabel): boolean => {

                    return (this._translateService.instant(item.label) as string).toLowerCase().includes(search.toLowerCase());
                }));
            }

            return of(items);
        }
    }

    private _initSocietyGroupConfiguration(): void {

        this.societyGroupSearchConfiguration = {
            multiple: true,
            enableSearch: true,
            currentSelectionLabel: 'societyGroup.plural.selected.value',
            searchActionLabel: 'societyGroup.search.action.value',
            selectOptionActionLabel: 'societyGroup.select.action.value',
            itemLabel: (item: SocietyGroup): string => {

                return `${item.name}`;
            }
        }

        this.societyGroupSearchSourceCallback = (search: string): Observable<SocietyGroup[]> => {

            const params: string[] = [
                `page=1`,
                'limit=10'
            ];

            if (search && search.length) {

                params.push(...[
                    `name[lk]=${search}`
                ]);
            }

            return this._societyGroupService.getPaginationItemsAPI(params).pipe(map((pagination: Pagination<SocietyGroup>): SocietyGroup[] => {

                return pagination.items;
            }));
        }
    }

    private _initLocaleConfiguration(): void {

        this.localeSearchConfiguration = {
            multiple: true,
            enableSearch: true,
            currentSelectionLabel: 'locale.plural.selected.value',
            searchActionLabel: 'locale.search.action.value',
            selectOptionActionLabel: 'locale.select.action.value',
            itemLabel: (item: LocaleItem): string => {

                return this._translateService.instant(item.label);
            },
            compareOptionsCallback: (a: LocaleItem, b: LocaleItem): boolean => {

                if (!a || !b) {

                    return false;
                }

                return a.value === b.value;
            }
        }

        this.localeSearchSourceCallback = (search: string): Observable<LocaleItem[]> => {

            const items: LocaleItem[] = SUPPORTED_LOCALE_ITEMS;

            if (search && search.length) {

                return of(items.filter((item: LocaleItem): boolean => {

                    return (this._translateService.instant(item.label) as string).toLowerCase().includes(search.toLowerCase());
                }));
            }

            return of(items);
        }
    }

    private _configureCmsContentService(): void {

        this._cmsContentService.imageAsBinary = true;
    }

    private _configureOfferSearchService(): void {

        this.offerSearchService.viewOfferAllowed = true;

        this.offerSearchService.selectOfferAllowed = true;
    }

    private _initForm(): void {

        this.formService.form = new FormGroup<ArticleForm>({
            translations: new FormArray([]),
            restriction: new FormGroup<ArticleRestrictionForm>({
                visibilities: new FormControl([], [Validators.required]),
                roles: new FormControl([], [(control: FormControl): ValidationErrors | null => {

                    if (!this.isSelectedVisibility('ROLE')) {

                        return null;
                    }

                    return (control.value as []).length ? null : {
                        'isRequired': {
                            valid: false
                        }
                    };
                }]),
                societyGroups: new FormControl([], [(control: FormControl): ValidationErrors | null => {

                    if (!this.isSelectedVisibility('SOCIETY_GROUP')) {

                        return null;
                    }

                    return (control.value as []).length ? null : {
                        'isRequired': {
                            valid: false
                        }
                    };
                }])
            }),
            configuration: new FormGroup<ArticleConfigurationForm>({
                type: new FormControl(null, [Validators.required]),
                targetMarkets: new FormControl([], [Validators.required]),
                customerTypologies: new FormControl([], [(control: FormControl<Partial<CustomerTypology>[]>) => {

                    if(!this.offerConfigurationControl){

                        return null;
                    }

                    if(this.offerConfigurationControl.get('mode').value === 'manual'){

                        return null;
                    }

                    return Boolean(control.value.length) ? null : {
                        'isRequired': {
                            valid: false
                        }
                    };
                }]),
                types: new FormControl([], [(control: FormControl<Partial<OfferAttribute>[]>) => {

                    if(!this.offerConfigurationControl){

                        return null;
                    }

                    if(this.offerConfigurationControl.get('mode').value === 'manual'){

                        return null;
                    }

                    return Boolean(control.value.length) ? null : {
                        'isRequired': {
                            valid: false
                        }
                    };
                }]),
                regions: new FormControl([], [(control: FormControl<Partial<OfferAttribute>[]>) => {

                    if(!this.offerConfigurationControl){

                        return null;
                    }

                    if(this.offerConfigurationControl.get('mode').value === 'manual'){

                        return null;
                    }

                    return Boolean(control.value.length) ? null : {
                        'isRequired': {
                            valid: false
                        }
                    };
                }]),
                themes: new FormControl([], [(control: FormControl<Partial<OfferAttribute>[]>) => {

                    if(!this.offerConfigurationControl){

                        return null;
                    }

                    if(this.offerConfigurationControl.get('mode').value === 'manual'){

                        return null;
                    }

                    return Boolean(control.value.length) ? null : {
                        'isRequired': {
                            valid: false
                        }
                    };
                }])
            }),
            offerConfiguration: new FormGroup<ArticleOfferConfigurationForm>({
                enable: new FormControl(true, [Validators.required]),
                mode: new FormControl('manual', [Validators.required]),
                refineSearch: new FormControl(false, [Validators.required]),
                nbAdult: new FormControl(null, [Validators.pattern(REGEX_ONLY_NUMBER), (control: UntypedFormControl) => {

                    if(!this.isEnabledRefineSearchField('nbAdult')){

                        return null;
                    }

                    return control.value ? null : {
                        'isRequired': {
                            valid: false
                        }
                    };
                }]),
                nbChild: new FormControl(null, [Validators.pattern(REGEX_ONLY_NUMBER), (control: UntypedFormControl) => {

                    if(!this.isEnabledRefineSearchField('nbChild')){

                        return null;
                    }

                    return control.value ? null : {
                        'isRequired': {
                            valid: false
                        }
                    };
                }]),
                dateStart: new FormControl(null, [(control: UntypedFormControl) => {

                    if(!this.isEnabledRefineSearchField('period')){

                        return null;
                    }

                    return control.value ? null : {
                        'isRequired': {
                            valid: false
                        }
                    };
                }]),
                dateEnd: new FormControl(null, [(control: UntypedFormControl) => {

                    if(!this.isEnabledRefineSearchField('period')){

                        return null;
                    }

                    return control.value ? null : {
                        'isRequired': {
                            valid: false
                        }
                    };
                }]),
                durations: new FormControl([], [(control: UntypedFormControl) => {

                    if(!this.isEnabledRefineSearchField('durations')){

                        return null;
                    }

                    return control.value.length ? null : {
                        'isRequired': {
                            valid: false
                        }
                    };
                }]),
                city: new FormControl(null, [(control: UntypedFormControl) => {

                    if(!this.isEnabledRefineSearchField('city')){

                        return null;
                    }

                    return (control.value && control.value.length) ? null : {
                        'isRequired': {
                            valid: false
                        }
                    };
                }]),
                accommodations: new FormControl([], [(control: UntypedFormControl) => {

                    if(!this.isEnabledRefineSearchField('accommodations')){

                        return null;
                    }

                    return control.value.length ? null : {
                        'isRequired': {
                            valid: false
                        }
                    };
                }]),
                restorations: new FormControl([], [(control: UntypedFormControl) => {

                    if(!this.isEnabledRefineSearchField('restorations')){

                        return null;
                    }

                    return control.value.length ? null : {
                        'isRequired': {
                            valid: false
                        }
                    };
                }]),
                activities: new FormControl([], [(control: UntypedFormControl) => {

                    if(!this.isEnabledRefineSearchField('activities')){

                        return null;
                    }

                    return control.value.length ? null : {
                        'isRequired': {
                            valid: false
                        }
                    };
                }]),
                offers: new FormControl([])
            }),
            locales: new FormControl([], [Validators.required]),
            picture: new FormGroup<ArticlePictureForm>({
                image: new FormGroup<MediaImageForm>({
                    image: new FormControl(null, [Validators.required]),
                    position: new FormControl(null)
                }),
                copyright: new FormControl(null, [Validators.required])
            }),
            status: new FormControl({ value: 'draft', disabled: !this.updateStatusAllowed }, [Validators.required]),
            dateStart: new FormControl(null, [(control: FormControl<Moment>) => {

                if(!this.form){

                    return null;
                }

                const isPublished: boolean = this.form.get('status').value === 'published';

                if(!isPublished){

                    return null;
                }

                return control.value ? null : {
                    'isRequired': {
                        valid: false
                    }
                };
            }]),
            dateEnd: new FormControl(null, [(control: FormControl<string>) => {

                if(!this.form){

                    return null;
                }

                const isPublished: boolean = this.form.get('status').value === 'published';

                if(!isPublished){

                    return null;
                }

                return control.value ? null : {
                    'isRequired': {
                        valid: false
                    }
                };
            }]),
            cmsContent: new FormControl(null)
        });

        this._initTranslationBuilder();

        this.formService.submitCallback = (): void => {

            if (this.form.invalid) {

                return;
            }

            const formValue = {...this.form.getRawValue()};

            const data: object = Object.assign(formValue, {
                locales: formValue.locales.map(item => item.value),
                dateStart: formValue.dateStart ? formValue.dateStart.format(DATE_FORMAT) : null,
                dateEnd: formValue.dateEnd ? formValue.dateEnd.format(DATE_FORMAT) : null,
                configuration: Object.assign(formValue.configuration, {
                    id: this.crudService.isUpdateAction ? this.article.configuration.id : null,
                    customerTypologies: formValue.configuration.customerTypologies.filter((item: CustomerTypology): boolean => {

                        return item.id !== null;
                    }),
                    regions: formValue.configuration.regions.filter((item: OfferAttribute): boolean => {

                        return item.id !== null;
                    }),
                    types: formValue.configuration.types.filter((item: OfferAttribute): boolean => {

                        return item.id !== null;
                    }),
                    themes: formValue.configuration.themes.filter((item: OfferAttribute): boolean => {

                        return item.id !== null;
                    })
                } as Partial<ArticleConfiguration>),
                restriction: Object.assign(formValue.restriction, {
                    id: this.crudService.isUpdateAction ? this.article.restriction.id : null,
                    roles: formValue.restriction.roles.map(item => item.identifier)
                } as Partial<ArticleRestriction>),
                translations: formValue.translations.map((translation: Partial<ArticleTranslation>): ArticleTranslation => {

                    return Object.assign({...translation}, {
                        content: [...formValue.cmsContent.translations].find((item: ITranslation): boolean => {

                            item.rows.forEach((row: IRow): void => {

                                row.columns.forEach((column: IColumn): void => {

                                    column.elements.forEach((element: IElement): void => {

                                        if(element.type === 'slideshow'){

                                            delete (element.content as Slideshow).formatUpdated$;
                                        }
                                    });
                                });
                            });

                            return item.locale === translation.locale;
                        })
                    } as Partial<ArticleTranslation>) as ArticleTranslation;
                }),
                offerConfiguration: Object.assign(formValue.offerConfiguration, {
                    id: this.crudService.isUpdateAction ? this.article.offerConfiguration.id : null,
                    mode: formValue.offerConfiguration.enable ? formValue.offerConfiguration.mode : null,
                    dateStart: formValue.offerConfiguration.dateStart ? formValue.offerConfiguration.dateStart.format(DATE_FORMAT) : null,
                    dateEnd: formValue.offerConfiguration.dateEnd ? formValue.offerConfiguration.dateEnd.format(DATE_FORMAT) : null,
                    offers: formValue.offerConfiguration.offers.map((offer: Offer): Partial<ArticleOfferConfigurationOffer> => {

                        if(this.userService.hasOneOfThisRoles(this.currentUser, ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'])){

                            return {
                                offer: offer
                            };
                        }

                        return {
                            offer: offer,
                            offerCatalog: this.offerCatalogs.find((offerCatalog: OfferCatalog): boolean => {

                                return offerCatalog.offer.id === offer.id;
                            })
                        };
                    })
                } as Partial<ArticleOfferConfiguration>)
            } as Partial<Article>);

            if (this.crudService.isCreateAction) {

                const observable: Observable<Article> = this.userService.hasOneOfThisRoles(this.currentUser, ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']) ? this._articleService.createItemAPI(data) : this._articleService.createSocietyItemAPI(this.currentUser.society.id, data);

                observable.subscribe((): void => {

                    this._snackBar.open(this._translateService.instant('article.create.success.value'), this._translateService.instant('notification.close.action.value'), {
                        duration: 5000
                    });

                    this.redirectToList(true);
                });
            }

            if (this.crudService.isUpdateAction) {

                this._articleService.updateItemAPI(this.article.id, data).subscribe((): void => {

                    this._snackBar.open(this._translateService.instant('article.update.success.value'), this._translateService.instant('notification.close.action.value'), {
                        duration: 5000
                    });

                    this.redirectToList(true);
                });
            }
        }
    }

    private _hydrateForm(): void {

        if (this.crudService.isCreateAction) {

            return;
        }

        this.form.patchValue({
            locales: this.article.locales.map((locale: string): LocaleItem => SUPPORTED_LOCALE_ITEMS.find((item: LocaleItem) => item.value === locale)),
            restriction: {
                visibilities: this.article.restriction.visibilities,
                roles: this.article.restriction.roles.map((role: Role): RoleLabel => ROLE_LABELS.find((item: RoleLabel) => item.identifier === role)),
                societyGroups: this.article.restriction.societyGroups
            },
            configuration: {
                type: this.article.configuration.type,
                targetMarkets: this.article.configuration.targetMarkets,
                customerTypologies: this.article.configuration.customerTypologies,
                themes: this.article.configuration.themes,
                regions: this.article.configuration.regions,
                types: this.article.configuration.types
            },
            picture: this.article.picture,
            status: this.article.status,
            dateStart: this.article.dateStart ? moment(this.article.dateStart) : null,
            dateEnd: this.article.dateEnd ? moment(this.article.dateEnd) : null,
            offerConfiguration: {
                enable: this.article.offerConfiguration.enable,
                mode: this.article.offerConfiguration.mode || 'manual',
                refineSearch: this.article.offerConfiguration.refineSearch,
                nbAdult: this.article.offerConfiguration.nbAdult,
                nbChild: this.article.offerConfiguration.nbChild,
                dateStart: this.article.offerConfiguration.dateStart ? moment(this.article.offerConfiguration.dateStart) : null,
                dateEnd: this.article.offerConfiguration.dateEnd ? moment(this.article.offerConfiguration.dateEnd) : null,
                city: this.article.offerConfiguration.city,
                durations: this.article.offerConfiguration.durations,
                activities: this.article.offerConfiguration.activities,
                restorations: this.article.offerConfiguration.restorations,
                accommodations: this.article.offerConfiguration.accommodations,
                offers: this.article.offerConfiguration.offers.map((item: ArticleOfferConfigurationOffer): Offer => {

                    return item.offer;
                })
            }
        });

        // Gestion du contenu

        this.cmsData.content.translations.push(...this.article.translations.map((translation: ArticleTranslation): ITranslation => {

            translation.content.rows.forEach((row: IRow): void => {

                row.columns.forEach((column: IColumn): void => {

                    const slideshowElements: IElement[] = column.elements.filter((element: IElement): boolean => {

                        return element.type === 'slideshow';
                    });

                    slideshowElements.forEach((element: IElement): void => {

                        const slideshow: Slideshow = element.content;

                        slideshow.formatUpdated$ = new Subject();
                    });
                });
            });

            return translation.content;
        }));

        this.form.get('cmsContent').patchValue(this.cmsData.content);

        // Traductions

        this.article.translations.forEach((item: ArticleTranslation): void => {

            const control: FormGroup<ArticleTranslationForm> = this.translationBuilder.addItemControl(this.translationBuilder.getLocaleItem(item.locale));

            control.patchValue({
                title: item.title,
                teaser: item.teaser
            });

            if('id' in item){

                control.addControl('id', new FormControl<number>(item.id, [Validators.required]));
            }
        });

        // Configuration manuelle

        this.offerSearchService.selectedOffers.next(this.offerConfigurationControl.get('offers').value);

        // Configuration automatique

        if(this.article.offerConfiguration.nbAdult){

            this.enabledRefineSearchFields.push('nbAdult');
        }

        if(this.article.offerConfiguration.nbChild){

            this.enabledRefineSearchFields.push('nbChild');
        }

        if(this.article.offerConfiguration.dateStart || this.article.offerConfiguration.dateEnd){

            this.enabledRefineSearchFields.push('period');
        }

        if(this.article.offerConfiguration.durations.length){

            this.enabledRefineSearchFields.push('durations');
        }

        if(this.article.offerConfiguration.city){

            this.enabledRefineSearchFields.push('city');
        }

        if(this.article.offerConfiguration.accommodations.length){

            this.enabledRefineSearchFields.push('accommodations');
        }

        if(this.article.offerConfiguration.restorations.length){

            this.enabledRefineSearchFields.push('restorations');
        }

        if(this.article.offerConfiguration.activities.length){

            this.enabledRefineSearchFields.push('activities');
        }

        // Mise à jour de l'état des champs de formulaire

        if(!this.isMine){

            const controls: AbstractControl[] = [
                this.form.get('translations'),
                this.form.get('restriction'),
                this.form.get('locales'),
                this.form.get('status'),
                this.form.get('dateStart'),
                this.form.get('dateEnd'),
                this.form.get('offerConfiguration'),
                this.form.get('picture'),
                this.form.get('cmsContent')
            ];

            controls.forEach((control: AbstractControl): void => {

                control.disable();
            });
        }
    }

    private _hydrateOfferCatalogs(items: OfferCatalog[]): void {

        items.forEach((item: OfferCatalog): void => {

            const alreadyHydrated: boolean = this.offerCatalogs.some((offerCatalog: OfferCatalog): boolean => {

                return offerCatalog.id === item.id;
            });

            if(!alreadyHydrated){

                this.offerCatalogs.push(item);
            }
        });
    }

    private _initEvents(): void {

        // Langues

        this.form.get('locales').valueChanges.subscribe((localeItems: LocaleItem[]): void => {

            this._handleTranslationControls();

            localeItems.forEach((localeItem: LocaleItem): void => {

                const exist: boolean = this.cmsData.content.translations.some((translation: ITranslation): boolean => {

                    return translation.locale === localeItem.value;
                });

                if (!exist) {

                    this.cmsData.content.translations.push({
                        id: null,
                        locale: localeItem.value,
                        rows: []
                    });
                }
            });

            this.cmsData.content.translations.forEach((translation: ITranslation, index: number): void => {

                const match: boolean = localeItems.some((localeItem: LocaleItem): boolean => {

                    return translation.locale === localeItem.value;
                });

                if (!match) {

                    this.cmsData.content.translations.splice(index, 1);
                }
            });

            if(!this.cmsData.content.translations.length){

                return;
            }

            this._cmsContentService.updateCurrentLocale$.next(this.cmsData.content.translations[0].locale);
        });

        // Statut

        this.form.get('status').valueChanges.subscribe((value: ArticleStatus): void => {

            this.form.get('dateStart').patchValue(value === 'draft' ? null : moment());

            this.form.get('dateEnd').patchValue(value === 'draft' ? null : moment());
        });

        // Sélection des offres

        this.offerSearchService.selectedOffers.subscribe((selectedItems: Offer[]): void => {

            this.offerConfigurationControl.get('offers').patchValue(selectedItems);
        });

        // Association d'offres

        this.offerConfigurationControl.get('enable').valueChanges.subscribe((): void => {

            this.offerConfigurationControl.get('mode').patchValue('manual');

            this.offerConfigurationControl.get('refineSearch').patchValue(false);

            this._resetRefineSearchFields();

            this._refreshCountAvailableOffersFromRefineSearch();
        });

        // Type d'association

        this.offerConfigurationControl.get('mode').valueChanges.subscribe((): void => {

            this.resetOfferSelection();

            this.offerConfigurationControl.get('refineSearch').patchValue(false);

            this._resetRefineSearchFields();

            this._refreshCountAvailableOffersFromRefineSearch();
        });

        // Affinage de la recherche

        this.offerConfigurationControl.get('refineSearch').valueChanges.subscribe((): void => {

            this._resetRefineSearchFields();

            this._refreshCountAvailableOffersFromRefineSearch();
        });

        this._handleAvailableOffersFromRefineSearch();
    }

    private _resetRefineSearchFields(): void {

        // Réinitialisation des options cochées

        this.enabledRefineSearchFields = [];

        // Réinitialisation des valeurs

        this.offerConfigurationControl.get('nbAdult').patchValue(null);

        this.offerConfigurationControl.get('nbChild').patchValue(null);

        this.offerConfigurationControl.get('dateStart').patchValue(null);

        this.offerConfigurationControl.get('dateEnd').patchValue(null);

        this.offerConfigurationControl.get('durations').patchValue([]);

        this.offerConfigurationControl.get('city').patchValue(null);

        this.offerConfigurationControl.get('accommodations').patchValue([]);

        this.offerConfigurationControl.get('restorations').patchValue([]);

        this.offerConfigurationControl.get('activities').patchValue([]);
    }

    private _initTranslationBuilder(): void {

        this.translationBuilder = new TranslationBuilder(this._formBuilder);

        this.translationBuilder.form = this.form;

        this.translationBuilder.addItemCallback = (): FormGroup<ArticleTranslationForm> => {

            return new FormGroup<ArticleTranslationForm>({
                title: new FormControl(null, [Validators.required, Validators.maxLength(this.maxLengths.title)]),
                teaser: new FormControl(null, [Validators.required, Validators.maxLength(this.maxLengths.teaser)])
            });
        };
    }

    private _handleTranslationControls(): void {

        const locales: LocaleItem[] = this.form.get('locales').value;

        this.translationBuilder.updateItems(locales.map(item => item.value));
    }

    private _handleAvailableOffersFromRefineSearch(): void {

        const controls: AbstractControl[] = [
            this.configurationControl.get('customerTypologies'),
            this.configurationControl.get('regions'),
            this.configurationControl.get('themes'),
            this.configurationControl.get('types'),
            this.offerConfigurationControl.get('nbAdult'),
            this.offerConfigurationControl.get('nbChild'),
            this.offerConfigurationControl.get('dateStart'),
            this.offerConfigurationControl.get('dateEnd'),
            this.offerConfigurationControl.get('durations'),
            this.offerConfigurationControl.get('city'),
            this.offerConfigurationControl.get('accommodations'),
            this.offerConfigurationControl.get('restorations'),
            this.offerConfigurationControl.get('activities')
        ];

        controls.forEach((control: AbstractControl): void => {

            control.valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe((): void => {

                this._refreshCountAvailableOffersFromRefineSearch();
            });
        });
    }

    private _refreshCountAvailableOffersFromRefineSearch(): void {

        const paginationParams: string[] = [
            'page=1',
            'limit=1'
        ];

        const searchParams: string[] = [];

        // Nombre de voyageurs

        if(this.offerConfigurationControl.get('nbAdult').value){

            searchParams.push(`adultMin[lte]=${this.offerConfigurationControl.get('nbAdult').value}`);

            searchParams.push(`adultMax[gte]=${this.offerConfigurationControl.get('nbAdult').value}`);
        }

        if(this.offerConfigurationControl.get('nbChild').value){

            searchParams.push(`childMin[lte]=${this.offerConfigurationControl.get('nbChild').value}`);

            searchParams.push(`childMax[gte]=${this.offerConfigurationControl.get('nbChild').value}`);
        }

        // Période de départ

        if(this.offerConfigurationControl.get('dateStart').value && this.offerConfigurationControl.get('dateEnd').value && this.offerConfigurationControl.get('dateStart').value.isSameOrBefore(this.offerConfigurationControl.get('dateEnd').value, 'days')){

            searchParams.push(`date[gte]=${this.offerConfigurationControl.get('dateStart').value.format(DATE_FORMAT)}`);

            searchParams.push(`date[lte]=${this.offerConfigurationControl.get('dateEnd').value.format(DATE_FORMAT)}`);
        }

        // Durées

        this.offerConfigurationControl.get('durations').value.forEach((item: OfferDuration): void => {

            searchParams.push(`duration.id[in][]=${item.id}`);
        });

        // Ville

        if(this.offerConfigurationControl.get('city').value && this.offerConfigurationControl.get('city').value.length){

            searchParams.push(`locations.city[lk]=${this.offerConfigurationControl.get('city').value}`);
        }

        // Régions

        this.configurationControl.get('regions').value.forEach((item: OfferAttribute): void => {

            searchParams.push(`attributes.attribute.region[in][]=${item.id}`);
        });

        // Thématiques

        this.configurationControl.get('themes').value.forEach((item: OfferAttribute): void => {

            searchParams.push(`attributes.attribute.themes[in][]=${item.id}`);
        });

        // Types de séjours

        this.configurationControl.get('types').value.forEach((item: OfferAttribute): void => {

            searchParams.push(`attributes.attribute.types[in][]=${item.id}`);
        });

        // Hébergements

        this.offerConfigurationControl.get('accommodations').value.forEach((item: OfferAttribute): void => {

            searchParams.push(`attributes.attribute.accommodation[in][]=${item.id}`);
        });

        // Restaurations

        this.offerConfigurationControl.get('restorations').value.forEach((item: OfferAttribute): void => {

            searchParams.push(`attributes.attribute.restoration[in][]=${item.id}`);
        });

        // Activités

        this.offerConfigurationControl.get('activities').value.forEach((item: OfferAttribute): void => {

            searchParams.push(`attributes.attribute.activities[in][]=${item.id}`);
        });

        // Types de clientèle

        this.configurationControl.get('customerTypologies').value.forEach((item: CustomerTypology): void => {

            searchParams.push(`customerTypology.id[in][]=${item.id}`);
        });

        if(!searchParams.length){

            this.countAvailableOffersFromRefineSearch = 0;

            return;
        }

        const params: string[] = [...paginationParams, ...searchParams.map((param: string): string => {

            if(this.userService.hasRole(this.currentUser, 'ROLE_OFFER_CREATOR') && !this.userService.hasRole(this.currentUser, 'ROLE_OFFER_DISTRIBUTOR')){

                return `offer.${param}`;
            }

            return param;
        })];

        const headers: AdditionalHeader[] = [{name: DEACTIVATE_LOADER_HEADER_IDENTIFIER, value: 1}]

        const observable: Observable<Pagination<Offer|OfferCatalog>> = this.userService.hasRole(this.currentUser, 'ROLE_OFFER_CREATOR') && !this.userService.hasRole(this.currentUser, 'ROLE_OFFER_DISTRIBUTOR') ? this._offerCatalogService.getPaginationItemsAPI(params, headers) : this._offerService.getPaginationItemsAPI(params, headers);

        observable.pipe(map((pagination: Pagination<Offer|OfferCatalog>): number => pagination.totalItems)).subscribe((count: number): void => {

            this.countAvailableOffersFromRefineSearch = count;
        });
    }

    public getAttributeObservable(tag: OfferAttributeTypeTagType): Observable<OfferAttribute[]> {

        const match: AttributeObservableData = this.attributeObservables$.find((attributeObservable: AttributeObservableData ): boolean => {

            return attributeObservable.tag === tag;
        });

        return match ? match.observable : of([]);
    }

    public compareById(a: { id: number }, b: { id: number }): boolean {

        if(!a || !b){

            return false;
        }

        return a.id === b.id;
    }

    public handleCustomerTypology(option: MatOption): void {

        const isAllCustomerTypologiesOption: boolean = option.value.id === null;

        if(isAllCustomerTypologiesOption){

            this.configurationControl.get('customerTypologies').patchValue(option.selected ? [...[{id: null}],...this.customerTypologies] : []);
        }
        else{

            const currentCustomerTypologies: { id: number }[] = [...(this.configurationControl.get('customerTypologies').value as CustomerTypology[])].filter((item: CustomerTypology): boolean => {

                return item.id !== null;
            });

            const isAllCustomerTypology: boolean = (currentCustomerTypologies.length === this.customerTypologies.length);

            if(isAllCustomerTypology){

                currentCustomerTypologies.push({id: null});
            }

            this.configurationControl.get('customerTypologies').patchValue(currentCustomerTypologies);
        }
    }

    public handleRegionAttribute(option: MatOption): void {

        const isAllRegionsOption: boolean = option.value.id === null;

        if(isAllRegionsOption){

            this.configurationControl.get('regions').patchValue(option.selected ? [...[{id: null}],...this.regionAttributes] : []);
        }
        else{

            const currentRegions: { id: number }[] = [...(this.configurationControl.get('regions').value as OfferAttribute[])].filter((item: OfferAttribute): boolean => {

                return item.id !== null;
            });

            const isAllRegion: boolean = (currentRegions.length === this.regionAttributes.length);

            if(isAllRegion){

                currentRegions.push({id: null});
            }

            this.configurationControl.get('regions').patchValue(currentRegions);
        }
    }

    public handleTypeAttribute(option: MatOption): void {

        const isAllTypesOption: boolean = option.value.id === null;

        if(isAllTypesOption){

            this.configurationControl.get('types').patchValue(option.selected ? [...[{id: null}],...this.typeAttributes] : []);
        }
        else{

            const currentTypes: { id: number }[] = [...(this.configurationControl.get('types').value as OfferAttribute[])].filter((item: OfferAttribute): boolean => {

                return item.id !== null;
            });

            const isAllType: boolean = (currentTypes.length === this.typeAttributes.length);

            if(isAllType){

                currentTypes.push({id: null});
            }

            this.configurationControl.get('types').patchValue(currentTypes);
        }
    }

    public handleThemeAttribute(option: MatOption): void {

        const isAllThemesOption: boolean = option.value.id === null;

        if(isAllThemesOption){

            this.configurationControl.get('themes').patchValue(option.selected ? [...[{id: null}],...this.themeAttributes] : []);
        }
        else{

            const currentThemes: { id: number }[] = [...(this.configurationControl.get('themes').value as OfferAttribute[])].filter((item: OfferAttribute): boolean => {

                return item.id !== null;
            });

            const isAllTheme: boolean = (currentThemes.length === this.themeAttributes.length);

            if(isAllTheme){

                currentThemes.push({id: null});
            }

            this.configurationControl.get('themes').patchValue(currentThemes);
        }
    }

    public handleVisibility(value: ArticleRestrictionVisibility): void {

        if (this.isSelectedVisibility(value)) {

            const visibilities: ArticleRestrictionVisibility[] = this.restrictionControl.get('visibilities').value;

            const index: number = visibilities.findIndex((visibility: ArticleRestrictionVisibility): boolean => {

                return visibility === value;
            });

            visibilities.splice(index, 1);

            this.restrictionControl.get('visibilities').patchValue(visibilities);

        } else {

            const visibilities: ArticleRestrictionVisibility[] = [];

            visibilities.push(value);

            if (value !== 'ALL') {

                visibilities.push(...(this.restrictionControl.get('visibilities').value).filter((visibility: ArticleRestrictionVisibility): boolean => {

                    return visibility !== 'ALL';
                }));
            }

            this.restrictionControl.get('visibilities').patchValue(visibilities);
        }

        const associations: {[p in ArticleRestrictionVisibility]: AbstractControl[]} = {
            'ALL': [
                this.restrictionControl.get('roles'),
                this.restrictionControl.get('societyGroups')
            ],
            'ROLE': [this.restrictionControl.get('roles')],
            'SOCIETY_GROUP': [this.restrictionControl.get('societyGroups')]
        };

        associations[value].forEach((item: AbstractControl): void => {

            item.patchValue([]);

            item.updateValueAndValidity({onlySelf: true, emitEvent: false});
        });
    }

    public isSelectedVisibility(value: ArticleRestrictionVisibility): boolean {

        if(!this.restrictionControl){

            return false;
        }

        const visibilities: ArticleRestrictionVisibility[] = this.restrictionControl.get('visibilities').value;

        return visibilities.includes(value);
    }

    public isDisabledVisibility(value: ArticleRestrictionVisibility): boolean {

        if (value === 'ALL') {

            return false;
        }

        return this.isSelectedVisibility('ALL');
    }

    public getVisibilityLabel(value: ArticleRestrictionVisibility): string {

        return `article.visibility.${value.toString().toLowerCase()}.value`;
    }

    public getTargetMarketLabel(value: ArticleConfigurationTargetMarket): string {

        return `article.targetMarket.${value.toString().toLowerCase()}.value`;
    }

    public redirectToList(unlock: boolean): void {

        this._router.navigate(['account/article/search'], { queryParams : { unlock: +unlock }});
    }

    public isEnabledRefineSearchField(identifier: RefineSearchFieldIdentifier): boolean {

        return this.enabledRefineSearchFields.includes(identifier);
    }

    public handleEnabledRefineSearchField(identifier: RefineSearchFieldIdentifier): void {

        if(this.isEnabledRefineSearchField(identifier)){

            const index: number = this.enabledRefineSearchFields.findIndex((item: RefineSearchFieldIdentifier): boolean => {

                return item === identifier;
            })

            this.enabledRefineSearchFields.splice(index, 1);
        }
        else {

            this.enabledRefineSearchFields.push(identifier);
        }

        const items: { [p in RefineSearchFieldIdentifier]: () => void } = {
            nbAdult: () : void => {

                this.offerConfigurationControl.get('nbAdult').patchValue(null);
            },
            nbChild: () : void => {

                this.offerConfigurationControl.get('nbChild').patchValue(null);
            },
            period: () : void => {

                this.offerConfigurationControl.get('dateStart').patchValue(null);

                this.offerConfigurationControl.get('dateEnd').patchValue(null);
            },
            durations: () : void => {

                this.offerConfigurationControl.get('durations').patchValue([]);
            },
            city: () : void => {

                this.offerConfigurationControl.get('city').patchValue(null);
            },
            accommodations: () : void => {

                this.offerConfigurationControl.get('accommodations').patchValue([]);
            },
            restorations: () : void => {

                this.offerConfigurationControl.get('restorations').patchValue([]);
            },
            activities: () : void => {

                this.offerConfigurationControl.get('activities').patchValue([]);
            },
            customerTypologies: () : void => {

                this.configurationControl.get('customerTypologies').patchValue([]);
            },
            themes: () : void => {

                this.configurationControl.get('themes').patchValue([]);
            },
            regions: () : void => {

                this.configurationControl.get('regions').patchValue([]);
            },
            types: () : void => {

                this.configurationControl.get('types').patchValue([]);
            }
        };

        items[identifier]();
    }

    public unselectOffer(offer: Offer): void {

        this.offerSearchService.unselectOffer(offer);

        const offers: Offer[] = [...this.offerConfigurationControl.get('offers').value];

        const index: number = offers.findIndex((item: Offer): boolean => {

            return item.id === offer.id;
        });

        offers.splice(index, 1);

        this.offerConfigurationControl.get('offers').patchValue(offers);
    }

    public resetOfferSelection(): void {

        this.offerSearchService.selectedOffers.next([]);

        this.offerConfigurationControl.get('offers').patchValue([]);
    }

    public handleClickCard(event: Event): void {

        event.preventDefault();

        const tab: MatTab = this.tabGroup._allTabs.find((item: MatTab): boolean => {

            const element: any = item.content.viewContainerRef.element.nativeElement;

            return (element.dataset.tag) === 'articleViewPreview';
        });

        this.tabGroup.selectedIndex = this.currentTabIndex + tab.position;
    }

    public openPublicationCharterDialog(): void {

        const dialogRef: MatDialogRef<ArticleCharterPublicationDialogComponent> = this._dialog.open(ArticleCharterPublicationDialogComponent, {
            width: '900px',
            autoFocus: false
        });

        dialogRef.componentInstance.close.subscribe((): void => {

            dialogRef.close();
        });
    }

    public openDeleteDialog(): void {

        if(this.crudService.isCreateAction || !this.userService.hasOneOfTheseAccesses(this.currentUser, ['ARTICLE_DELETE', 'ARTICLE_DELETE_IS_MINE'])){

            return;
        }

        const title : string = this._translateService.instant('article.delete.value');

        const content : string = this._translateService.instant('article.delete.content.value');

        const dialogRef: MatDialogRef<ConfirmDialogComponent> = this._dialog.open(ConfirmDialogComponent, {
            width: '500px',
            data: {
                title: title,
                content: content,
                contentAlignment: 'center'
            } as ConfirmDialogData
        });

        dialogRef.componentInstance.confirm.subscribe((): void => {

            this._articleService.deleteItemAPI(this.article.id).subscribe((): void => {

                this._snackBar.open(this._translateService.instant('article.delete.success.value'), this._translateService.instant('notification.close.action.value'), {
                    duration: 5000
                });

                this.redirectToList(false);
            });
        });
    }

    get form(): FormGroup<ArticleForm> {

        return this.formService.form;
    }

    get restrictionControl(): FormGroup<ArticleRestrictionForm> {

        if(!this.form){

            return null;
        }

        return this.form.get('restriction') as FormGroup;
    }

    get configurationControl(): FormGroup<ArticleConfigurationForm> {

        if(!this.form){

            return null;
        }

        return this.form.get('configuration') as FormGroup;
    }

    get offerConfigurationControl(): FormGroup<ArticleOfferConfigurationForm> {

        if(!this.form){

            return null;
        }

        return this.form.get('offerConfiguration') as FormGroup;
    }

    get isMine(): boolean {

        if(this.crudService.isCreateAction){

            return true;
        }

        if(this.article.society){

            return Boolean(this.currentUser.society) && (this.article.society.id === this.currentUser.society.id);
        }
        else{

            return this.userService.hasOneOfThisRoles(this.currentUser, ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'])
        }
    }

    get pictureImageConfig(): ImageConfig {

        return {
            id: 'image',
            gallery_context: 'article_picture',
            required: true,
            uploadApiUrl: this._apiService.getApiUrl(false, true),
            options: {
                enableTitle: false,
                enableSubtitle: false,
                enableAlt: false,
                enableLink: false,
                enableTargetBlank: false,
                enableFormatSelection: false,
                label: this._translateService.instant('offer.form.fields.picture.value'),
                disabled: !this.isMine
            }
        };
    }

    get attributeTypeTags(): OfferAttributeTypeTagType[] {

        return ['region', 'themes', 'types', 'accommodations', 'restoration', 'activities'];
    }

    get loadOffersSourceCallback(): (view: ViewType, params?: string[]) => Observable<Pagination<Offer>|OfferLocation[]> {

        return (view: ViewType, params?: string[]): Observable<Pagination<Offer>|OfferLocation[]> => {

            const items: { [p in ViewType]: Observable<Pagination<Offer>|OfferLocation[]> } = {
                list: this.userService.hasOneOfThisRoles(this.currentUser, ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']) ? this._offerService.getPaginationItemsAPI(params) : this._offerCatalogService.getPaginationItemsAPI(params).pipe(
                    tap((pagination: Pagination<OfferCatalog>): void => {

                        this._hydrateOfferCatalogs(pagination.items);
                    }),
                    map((pagination: Pagination<OfferCatalog>): Pagination<Offer> => {

                        return {
                            pagesCount: pagination.pagesCount,
                            totalItems: pagination.totalItems,
                            items: pagination.items.map((item: OfferCatalog): Offer => {

                                return item.offer;
                            })
                        };
                    })
                ),
                map: this.userService.hasOneOfThisRoles(this.currentUser, ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']) ? this._offerLocationService.getItemsAPI(params) : this._offerLocationService.getItemsCatalogAPI(params)
            };

            return items[view];
        }
    }

    get offerSearchMode(): ModeType {

        return this.userService.hasOneOfThisRoles(this.currentUser, ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']) ? 'article-reservoir' : 'article-catalog';
    }

    get updateStatusAllowed(): boolean {

        if(this.userService.hasOneOfThisRoles(this.currentUser, ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'])){

            return true;
        }

        if(this.crudService.isCreateAction){

            return this.currentUser.society.countAvailableArticles > this.currentUser.society.countPublishedArticles;
        }

        return (this.article.status === 'published') || (this.currentUser.society.countAvailableArticles > this.currentUser.society.countPublishedArticles);
    }

    get articleFormValue(): Article
    {
        if (this.form.invalid) {

            return;
        }

        const formValue = {...this.form.getRawValue()};

        const now: Moment = moment();

        const society: Partial<Society> = this.article && this.article.society ? this.article.society : this.userService.currentUser.getValue().society ? this.userService.currentUser.getValue().society : null;

        const data: Partial<Article> = Object.assign(formValue, {
            society: society,
            publishedAt: this.article && this.article.publishedAt ? this.article.publishedAt : now.format('YYYY-MM-DD'),
            updatedAt: this.article && this.article.updatedAt ? this.article.updatedAt : now.format('YYYY-MM-DD'),
            locales: formValue.locales.map(item => item.value),
            isNew: !this.article || this.article.isNew,
            translations: formValue.translations.map((translation: Partial<ArticleTranslation>): ArticleTranslation => {

                return Object.assign(translation, {
                    content: formValue.cmsContent?.translations.find((item: ITranslation): boolean => {

                        return item.locale === translation.locale;
                    })
                } as Partial<ArticleTranslation>) as ArticleTranslation;
            })
        } as Partial<Article>);

        return data as Article;
    }

    get formTabValidationItemsCallback(): () => FormTabValidationItem[] {

        return (): FormTabValidationItem[] => {

            const items: Partial<FormTabValidationItem>[] = [];

            // Données générales

            const generalDataControls: AbstractControl[] = [
                this.configurationControl
            ];

            if(this.isMine){

                generalDataControls.push(
                    this.form.get('translations'),
                    this.restrictionControl,
                    this.form.get('locales'),
                    this.form.get('picture'),
                    this.form.get('dateStart'),
                    this.form.get('dateEnd'),
                    this.offerConfigurationControl.get('enable'),
                    this.offerConfigurationControl.get('mode')
                );

                if(this.updateStatusAllowed){

                    generalDataControls.push(this.form.get('status'));
                }
            }

            items.push({
                tag: TabTag.GeneralData,
                controls: generalDataControls
            });

            if(this.isMine) {

                // Contenu

                items.push({
                    tag: TabTag.ContentData,
                    controls: [
                        this.form.get('cmsContent')
                    ]
                });

                // Offres associées

                items.push({
                    tag: TabTag.OfferAssociation,
                    controls: [
                        this.offerConfigurationControl.get('refineSearch'),
                        this.offerConfigurationControl.get('nbAdult'),
                        this.offerConfigurationControl.get('nbChild'),
                        this.offerConfigurationControl.get('dateStart'),
                        this.offerConfigurationControl.get('dateEnd'),
                        this.offerConfigurationControl.get('durations'),
                        this.offerConfigurationControl.get('city'),
                        this.offerConfigurationControl.get('accommodations'),
                        this.offerConfigurationControl.get('restorations'),
                        this.offerConfigurationControl.get('activities'),
                        this.offerConfigurationControl.get('offers')
                    ]
                });
            }

            return items.map((item: Partial<FormTabValidationItem>, index: number): FormTabValidationItem => {

                return Object.assign(item, {
                    position: index + 1
                } as FormTabValidationItem);
            });
        };
    }
}
