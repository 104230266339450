import {Component, Input, OnInit} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {Iframe} from "@lib/cms/element/type/iframe/iframe";

@Component({
    selector: 'app-cms-iframe',
    templateUrl: './iframe.component.html',
    styleUrls: ['./iframe.component.scss']
})
export class CmsIframeComponent implements OnInit {

    @Input() iframe : { content: Iframe };

    public source: SafeResourceUrl;

    constructor(
        private _sanitizer: DomSanitizer
    ) {}

    ngOnInit() {

        this.source = this._sanitizer.bypassSecurityTrustResourceUrl(this.iframe.content['source']);
    }
}
