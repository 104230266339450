<media-file-upload *ngIf="configInitialized" [config]="fileUploadConfig" (postUpload)="postUpload($event)"></media-file-upload>

<div *ngIf="content">

    <div class="thumb-input">
        <div class="big-label">
            <span class="label">Choisir un template : </span>
            <select [(ngModel)]="content.template" [compareWith]="compareByOptionClass">
                <option [ngValue]="null" disabled selected>--</option>
                <option *ngFor="let template of templates" [ngValue]="template">
                    {{ template.label }}
                </option>
            </select>
        </div>

        <div class="row marges ligne_form">
            <div class="col_12">
                <div class="form-group">
                    <div class="message">
                        <span class="message-help">
                            <mat-icon class="tooltip_icon">info</mat-icon> {{ 'element.types.file.label.maxLength.help' | translate: { count: 30 } }}
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <div class="file-component">

            <mat-icon class="icon">attach_file</mat-icon>

            <input type="text" [(ngModel)]="content.name" [placeholder]="'Titre'"/>

            <a [href]="getFilePath(content)" target="_blank" class="link" matTooltip="{{ 'element.types.file.watch.action' | translate }}" matTooltipPosition="right">
                <mat-icon>remove_red_eye</mat-icon>
            </a>
        </div>

        <div *ngIf="content.template">
            <span class="label">Aperçu du bouton : </span>
            <a class="button-component btn_cta" [ngClass]="getPreviousButtonClass(content.template)">
                <span>{{ content.name }}</span>
            </a>
        </div>

    </div>

</div>
