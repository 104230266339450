import {CKEditor5} from "@ckeditor/ckeditor5-angular";
import {TranslateService} from "@ngx-translate/core";
import {UserService} from '@core/shared/services/user.service';

export const getArticleCkeditorConfig = (translateService: TranslateService, userService: UserService): CKEditor5.Config => {

    const config: CKEditor5.Config = {
        toolbar: {
            items: [
                'heading',
                '|',
                'bold',
                'italic',
                'underline',
                'strikethrough',
                'fontSize',
                'FontColor',
                'highlight',
                '|',
                'alignment',
                'horizontalLine',
                'specialCharacters',
                '|',
                'bulletedList',
                'numberedList',
                'todoList',
                'indent',
                'outdent',
                '|',
                'link',
                '|',
                'undo',
                'redo',
                '|'
            ]
        },
        heading: {
            options: [
                {
                    model: "heading2",
                    view: {
                        "name" : "h2",
                        "classes" : "titre_h2"
                    },
                    title: translateService.instant('ckeditor.heading.title.h2.value'),
                    class: "titre_h2"
                },
                {
                    model: "heading3",
                    view: {
                        name : "h3",
                        classes : "titre_h3"
                    },
                    title: translateService.instant('ckeditor.heading.title.h3.value'),
                    class: "titre_h3"
                },
                {
                    model: "paragraph",
                    title: translateService.instant('ckeditor.heading.title.text.value'),
                    class: "txt"
                }
            ]
        },
        fontColor: {
            colors: [
                {
                    color: '#000000',
                    label: 'Noir'
                },
                {
                    color: '#1d2028',
                    label: 'Dark grey'
                },
                {
                    color: '#969696',
                    label: 'Grey'
                },
                {
                    color: '#34e0a1',
                    label: 'Green'
                },
                {
                    color: '#208d65',
                    label: 'Dark green'
                },
                // More colors.
                // ...
            ]
        },
        highlight: {
            options: [
                {
                    model: 'blackMarker',
                    class: 'tywin-marker-black',
                    title: 'Black marker',
                    color: '#000000',
                    type: 'marker'
                },
                {
                    model: 'drakGreyMarker',
                    class: 'tywin-marker-dark-grey',
                    title: 'Dark Grey marker',
                    color: '#1d2028',
                    type: 'marker'
                },
                {
                    model: 'greyMarker',
                    class: 'tywin-marker-grey',
                    title: 'Grey marker',
                    color: '#969696',
                    type: 'marker'
                },
                {
                    model: 'lightGreyMarker',
                    class: 'tywin-marker-light-grey',
                    title: 'Light Grey marker',
                    color: '#fafafa',
                    type: 'marker'
                },
                {
                    model: 'greenMarker',
                    class: 'tywin-marker-green',
                    title: 'Green marker',
                    color: '#34e0a1',
                    type: 'marker'
                },
                {
                    model: 'darkGreenMarker',
                    class: 'tywin-marker-dark-green',
                    title: 'Dark Green marker',
                    color: '#208d65',
                    type: 'marker'
                },
                {
                    model: 'lightGreenMarker',
                    class: 'tywin-marker-light-green',
                    title: 'Light Green marker',
                    color: '#d9e2df',
                    type: 'marker'
                }
            ]
        },
        list: {
            properties: {
                styles: true,
                startIndex: true,
                reversed: true
            }
        },
        fontSize: {
            options: [8, 9, 10, 11, 12, 14, 18, 24, 30]
        },
        link: {
            addTargetToExternalLinks: true
        },
        language: {
            ui: translateService.currentLang
        }
    };

    if(!userService.hasOneOfThisRoles(userService.currentUser.getValue(), ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'])) {

        return config;
    }

    config.toolbar.items.push(...['link', '|']);

    return config;
}
