<form [formGroup]="form" (ngSubmit)="formService.submit()">
    <div class="page_title">
        <div class="row">
            <div class="col y_items_center">
                <h1 class="title">{{ 'page.add.action.value' | translate }}</h1>
            </div>

            <div class="col_content y_items_center">

                <!-- Retour -->

                <button class="btn_cta btn_lines btn_little" type="button" (click)="redirectToList()">
                    {{ 'page.list.back.value' | translate }}
                </button>

                <!-- Soumission -->

                <button class="btn_cta btn_little" type="submit">
                    {{ 'form.submit.action.value'|translate }}
                </button>

            </div>
        </div>
    </div>
    <mat-tab-group [@.disabled]="true">
        <mat-tab label="Données générales">

            <div class="row ligne_form">
                <div class="col_12">
                    <div class="form-group">
                        <app-toggle [config]="publishedConfig" [form]="form"></app-toggle>
                    </div>
                </div>
            </div>

            <!-- Langue -->
            <div class="ligne_form row">
                <div class="col_12">
                    <div class="form-group">
                        <mat-select formControlName="locales" multiple>
                            <mat-option  *ngFor="let locale of locales$ | async;" [value]="locale.id">{{ locale.label | translate }}</mat-option>
                        </mat-select>
                        <mat-label class="required">{{ 'collaborator.list.fields.locale.value' | translate }}</mat-label>
                        <mat-error *ngIf="form.get('locales').invalid && (form.get('locales').dirty || form.get('locales').touched)">
                            <div class="invalid-feedback">
                                <div *ngIf="form.get('locales').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                            </div>
                        </mat-error>
                    </div>
                </div>
            </div>
            <div formArrayName="translations" class="ligne_form ligne_form">
                <mat-tab-group [@.disabled]="true">
                    <div *ngFor="let locale of selectedLocales">
                        <mat-tab label="{{translationBuilder.getLocaleLabel(locale) | translate }}">
                            <ng-container [formGroupName]="translationBuilder.getItemIndexByLocale(locale)">
                                <div class="row ligne_form">
                                    <div class="col_12">
                                        <div class="form-group">
                                            <input type="text" value="" placeholder=" " class="form-control" formControlName="title"
                                                   id="title-{{translationBuilder.getItemIndexByLocale(locale)}}" required="required">
                                            <label for="title-{{translationBuilder.getItemIndexByLocale(locale)}}">{{'page.list.fields.title.value' | translate}}</label>
                                            <mat-error
                                                    *ngIf="translationBuilder.getItemControlByLocale(locale).get('title').errors && (translationBuilder.getItemControlByLocale(locale).get('title').dirty || translationBuilder.getItemControlByLocale(locale).get('title').touched)">
                                                <div class="invalid-feedback">
                                                    <div *ngIf="translationBuilder.getItemControlByLocale(locale).get('title').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                </div>
                                            </mat-error>
                                        </div>

                                        <div class="form-group">
                                            <app-ckeditor [form]="translationBuilder.getItemControlByLocale(locale)" [config]="descriptionEditorConfig"></app-ckeditor>
                                        </div>

                                        <div class="form-group add_picture big">
                                            <div class="row">
                                                <div class="col_6">
                                                    <form-field-image [config]="imageConfig" [form]="translationBuilder.getItemControlByLocale(locale).get('picture').get('image')" (imageAdded)="pictureCopyright.readOnly = false" (imageDeleted)="pictureCopyright.readOnly = true"></form-field-image>
                                                </div>
                                            </div>
                                            <div class="row" style="margin-top: 12px">
                                                <div class="col_6">
                                                    <div class="form-group" formGroupName="picture">
                                                        <input #pictureCopyright type="text" value="" placeholder=" " class="form-control" formControlName="copyright" id="{{translationBuilder.getItemIndexByLocale(locale)}}-picture_copyright" [readOnly]="true">
                                                        <label for="{{translationBuilder.getItemIndexByLocale(locale)}}-picture_copyright">{{ 'offer.form.fields.copyright.value' | translate }}</label>
                                                        <mat-error *ngIf="translationBuilder.getItemControlByLocale(locale).get('picture').get('copyright').errors && (translationBuilder.getItemControlByLocale(locale).get('picture').get('copyright').dirty || translationBuilder.getItemControlByLocale(locale).get('picture').get('copyright').touched)">
                                                            <div class="invalid-feedback">
                                                                <div *ngIf="translationBuilder.getItemControlByLocale(locale).get('picture').get('copyright').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                            </div>
                                                        </mat-error>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </mat-tab>
                    </div>
                </mat-tab-group>
            </div>
            <div class="row ligne_form">
                <div class="col_12">
                    <div class="form-group">
                        <app-select value="_self" [config]="fieldCollection.getFieldConfig('target')" [form]="form"></app-select>
                    </div>
                </div>
            </div>

            <div class="row ligne_form">
                <div class="col_12">
                    <div class="form-group">
                        <label>{{'menu.type_user.title.value' | translate}}</label>
                        <div class="radio">
                            <input type="radio" value="all" formControlName="typeUser" class="form-control" id="isTypeUserAll">
                            <label for="isTypeUserAll">{{'menu.type_user.all.value' | translate}}</label>
                        </div>
                        <div class="radio">
                            <input type="radio" value="role" formControlName="typeUser" class="form-control" id="isTypeUserRole">
                            <label for="isTypeUserRole">{{'menu.type_user.role.value' | translate}}</label>
                        </div>
                        <div class="radio">
                            <input type="radio" value="user" formControlName="typeUser" class="form-control" id="isTypeUserUser">
                            <label for="isTypeUserUser">{{'menu.type_user.user.value' | translate}}</label>
                        </div>
                        <mat-error *ngIf="form.get('typeUser').errors && (form.get('typeUser').dirty || form.get('typeUser').touched)">
                            <div class="invalid-feedback">
                                <div *ngIf="form.get('typeUser').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                            </div>
                        </mat-error>
                    </div>

                    <div class="form-group" *ngIf="form.get('typeUser').value == 'role'">
                        <label>{{'menu.roles.title.value' | translate}}</label>
                        <div class="checkbox">
                            <input type="checkbox" value="ROLE_ADMIN" class="form-control" [disabled]="true"  [checked]="hasRole('ROLE_ADMIN')" (change)="patchRolesValue('ROLE_ADMIN')" id="isRoleAdmin">
                            <label for="isRoleAdmin">{{'menu.roles.admin.value' | translate}}</label>
                        </div>
                        <div class="checkbox">
                            <input type="checkbox" value="ROLE_SUPER_ADMIN" class="form-control" [disabled]="true"  [checked]="hasRole('ROLE_SUPER_ADMIN')" (change)="patchRolesValue('ROLE_SUPER_ADMIN')" id="isRoleSuperAdmin">
                            <label for="isRoleSuperAdmin">{{'menu.roles.super_admin.value' | translate}}</label>
                        </div>
                        <div class="checkbox">
                            <input type="checkbox" value="ROLE_PROVIDER" class="form-control"  [checked]="hasRole('ROLE_PROVIDER')" (change)="patchRolesValue('ROLE_PROVIDER')" id="isRolePrestataire">
                            <label for="isRolePrestataire">{{'menu.roles.provider.value' | translate}}</label>
                        </div>
                        <div class="checkbox">
                            <input type="checkbox" value="ROLE_OFFER_CREATOR"  [checked]="hasRole('ROLE_OFFER_CREATOR')"  class="form-control" (change)="patchRolesValue('ROLE_OFFER_CREATOR')" id="isRoleCreateur">
                            <label for="isRoleCreateur">{{'menu.roles.creator.value' | translate}}</label>
                        </div>
                        <div class="checkbox">
                            <input type="checkbox" value="ROLE_OFFER_DISTRIBUTOR"  class="form-control"  [checked]="hasRole('ROLE_OFFER_DISTRIBUTOR')" (change)="patchRolesValue('ROLE_OFFER_DISTRIBUTOR')" id="isRoleDistributor">
                            <label for="isRoleDistributor">{{'menu.roles.distributor.value' | translate}}</label>
                        </div>
                        <div class="checkbox">
                            <input type="checkbox" value="ROLE_INSTITUTIONAL"  class="form-control"  [checked]="hasRole('ROLE_INSTITUTIONAL')" (change)="patchRolesValue('ROLE_INSTITUTIONAL')" id="isRoleInstitutional">
                            <label for="isRoleInstitutional">{{'menu.roles.institutional.value' | translate}}</label>
                        </div>
                        <div class="checkbox">
                            <input type="checkbox" value="ROLE_FEDERATION"  class="form-control"  [checked]="hasRole('ROLE_FEDERATION')" (change)="patchRolesValue('ROLE_FEDERATION')" id="isRoleFederation">
                            <label for="isRoleFederation">{{'menu.roles.federation.value' | translate}}</label>
                        </div>
                    </div>

                    <div class="form-group" *ngIf="form.get('typeUser').value == 'user'">
                        <app-select-search [config]="fieldCollection.getFieldConfig('societies')" [form]="form" (valueChange)="changeSocieties($event)"></app-select-search>
                    </div>

                </div>
            </div>
        </mat-tab>

        <mat-tab label="Gestion du contenu" *ngIf="form.get('locales').value.length > 0">
            <cms-root [params]="cmsParams" [contentFormControl]="form.get('content')" [data]="dataCms"></cms-root>
        </mat-tab>

        <mat-tab label="Prévisualisation de la miniature" *ngIf="pageFormValue">
            <div class="tab_content_overflow" *ngIf="pageFormValue">

                <div class="row">

                    <div class="col_12" *ngIf="pageFormValue.locales.length > 1">
                        <div class="form-group choice_language">
                            <mat-select [(ngModel)]="localeCardPreview" [ngModelOptions]="{standalone: true}">
                                <mat-option *ngFor="let locale of pageFormValue.locales" [value]="locale">
                                    <span class="flag flag-{{ locale }}"></span>
                                    <span class="text">{{ 'locale.'+locale+ '.value' | translate }}</span>
                                </mat-option>
                            </mat-select>
                            <mat-label>{{ 'offer.read.choiceLanguage.value' | translate }} :</mat-label>
                        </div>
                    </div>

                    <div class="col_12 col_md_6 col_lg_4 col_xl_3 content_card">
                        <app-core-page-card [page]="pageFormValue" [locale]="localeCardPreview"  [mode]="'preview'"></app-core-page-card>
                    </div>

                </div>


            </div>
        </mat-tab>

        <mat-tab label="Prévisualisation de la page" *ngIf="pageFormValue">
            <div class="tab_content_overflow" *ngIf="pageFormValue">

                <div class="col_12" *ngIf="pageFormValue.locales.length > 1">
                    <div class="form-group choice_language">
                        <mat-select [(ngModel)]="localeViewPreview" [ngModelOptions]="{standalone: true}">
                            <mat-option *ngFor="let locale of pageFormValue.locales" [value]="locale">
                                <span class="flag flag-{{ locale }}"></span>
                                <span class="text">{{ 'locale.'+locale+ '.value' | translate }}</span>
                            </mat-option>
                        </mat-select>
                        <mat-label>{{ 'offer.read.choiceLanguage.value' | translate }} :</mat-label>
                    </div>
                </div>

                <div class="content_article" style="margin-top:24px;">
                    <div class="box_lg marges">
                        <app-core-page-read-content [item]="pageFormValue" [mode]="'preview'" [locale]="localeViewPreview"></app-core-page-read-content>
                    </div>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>
</form>

