import {Pipe, PipeTransform} from '@angular/core';
import {ApiService} from "@core/shared/services/api.service";
import {Offer, OfferTranslationPicture} from "@core/shared/models/offer";
import {ImageService} from "@lib/media/image/image.service";

@Pipe({
    name: 'offerPictureSource'
})
export class OfferPictureSourcePipe implements PipeTransform {

    constructor(
        private _apiService: ApiService,
        private _imageService: ImageService
    ){
    }

    transform(offer: Offer, picture: OfferTranslationPicture, format: string, width: number, quality: number): string {

        return this._imageService.getImageUrlWithNoCache(`${this._apiService.getApiUrl(false, false)}/public/offers/${offer.id}/pictures/${picture.id}/filter/${format}/${width}/${quality}`);
    }
}
