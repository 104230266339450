<ng-container *ngIf="mode === 'view'">
    <a class="card-article card-page"
       [routerLink]="['/page/read', page.id]"
       [target]="page.target"
       [queryParams]="{ backEnabled: (page.target === '_self') ? 1 : 0 }"
    >
        <ng-container *ngTemplateOutlet="pageCardContent"></ng-container>
    </a>
</ng-container>

<!-- Visualisation d'une page (mode preview) -->
<ng-container *ngIf="mode === 'preview'">
    <a class="card-article card-page" target="#" >
        <ng-container *ngTemplateOutlet="pageCardContent"></ng-container>
    </a>
</ng-container>

<ng-template #pageCardContent>
    <div class="row h_full direction_y">

        <div class="col_content card_top">
            <div class="visuel">
                <img [src]="visualUrl" alt="{{ translationService.getFallbackTranslation(page.translations, currentUser, locale).title }}"/>
            </div>
        </div>

        <div class="col card_bottom">

            <div class="row h_full direction_y">

                <div class="col">
                    <div class="ck-content infos_card">
                        <p class="titre_h4">{{ translationService.getFallbackTranslation(page.translations, currentUser, locale).title }}</p>
                        <p class="small" [innerHTML]="(translationService.getFallbackTranslation(page.translations, currentUser, locale).description.length>200)? (translationService.getFallbackTranslation(page.translations, currentUser, locale).description | slice:0:200)+'...':(translationService.getFallbackTranslation(page.translations, currentUser, locale).description)|safeHtml"></p>
                    </div>
                </div>

                <div class="col_content">
                    <div class="infos_published">
                        <div class="row marges">
                            <div class="col y_items_center">
                                <div class="txt icon-tywin-calendar">
                                    <p><strong>{{ 'publishedBy.value' | translate }} Ty-win</strong></p>
                                    <p>{{ 'date.nominative.value' | translate }} {{ moment(page.createdAt).format('DD/MM/YYYY') }}</p>
                                </div>
                            </div>
                            <div class="col_content y_items_center">
                                <div class="logo">
                                    <img src="assets/images/logo_tywin.png" alt="Ty-win">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>

    </div>
</ng-template>

