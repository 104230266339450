import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IText} from "./text";
import {TextService} from "./text.service";
import {FormBuilder, FormGroup} from "@angular/forms";
import {CKEditor5} from "@ckeditor/ckeditor5-angular";

@Component({
    selector: 'app-text',
    templateUrl: './text.component.html',
    styleUrls: ['../../element.component.scss']
})

/**
 * Contenu de type TEXTE
 */
export class TextComponent implements OnInit {

    @Input() content: IText;

    @Input() apiUrl: string;

    @Output() update: EventEmitter<IText> = new EventEmitter<IText>();

    public configInitialized: boolean = false;

    public form: FormGroup;

    constructor(
        private _formBuilder: FormBuilder,
        private _textService: TextService
    ) {
    }

    /**
     * Initialisation du composant
     */

    ngOnInit() {

        this._textService.setApiUrl(this.apiUrl);

        this.form = this._formBuilder.group({
            content: this._formBuilder.control('')
        });

        if(this.content){

            this.form.get('content').patchValue(this.content.html || '')
        }
        else {

            this.content = {
                html: null
            };
        }

        this.form.get('content').valueChanges.subscribe((value: string): void => {

            this.content.html = value;

            this.update.emit(this.content);
        });
    }

    get editor(): any {

        return this._textService.editor;
    }

    get ckeditorConfig(): CKEditor5.Config {

        return this._textService.ckeditorConfig;
    }
}
