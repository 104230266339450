import {Component, OnInit} from '@angular/core';
import {FormService} from "@core/shared/services/form.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {StatisticExportForm} from "@core/shared/models/statistic/statistic-export";
import {ShortSociety} from "@core/shared/models/society";
import {SelectSearchConfiguration} from "@core/components/select/select-search/select-search.component";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {Pagination} from "@core/shared/models/pagination";
import {SocietyService} from "@core/shared/services/society.service";
import {StatisticTypeService} from "@core/shared/services/statistic/statistic-type.service";
import {StatisticType} from "@core/shared/models/statistic/statistic-type";
import {StatisticExportService} from "@core/shared/services/statistic/statistic-export.service";
import {DATE_FORMAT} from "@app/data";
import moment, {Moment} from "moment";
import {Router} from "@angular/router";

enum View {
    Form,
    Confirmation
};

@Component({
    selector: 'app-core-page-statistic-export',
    templateUrl: './page-statistic-export.component.html',
    styleUrl: './page-statistic-export.component.scss',
    providers: [
        FormService
    ]
})
export class PageStatisticExportComponent implements OnInit {

    protected readonly View: typeof View = View;

    public societySearchConfiguration: SelectSearchConfiguration<ShortSociety>;

    public societySearchSourceCallback: (search: string) => Observable<ShortSociety[]>;

    public types: StatisticType[] = [];

    public maxDate: Moment = moment();

    public defaultDateStart: Moment = moment().subtract(1, 'months');

    public defaultDateEnd: Moment = moment();

    public currentView: View = View.Form;

    constructor(
        private _router: Router,
        private _societyService: SocietyService,
        private _statisticTypeService: StatisticTypeService,
        private _statisticExportService: StatisticExportService,
        public formService: FormService
    ) {}

    ngOnInit() {

        this._initSocietyConfiguration();

        this._initTypes();

        this._initForm();

        this._initEvents();
    }

    private _initSocietyConfiguration(): void {

        this.societySearchConfiguration = {
            multiple: true,
            enableSearch: true,
            currentSelectionLabel: 'user.plural.selected.value',
            searchActionLabel: 'user.search.action.value',
            selectOptionActionLabel: 'user.select.action.value',
            itemLabel: (item: ShortSociety): string => {

                return `${item.name}`;
            }
        }

        this.societySearchSourceCallback = (search: string): Observable<ShortSociety[]> => {

            const params: string[] = [
                `page=1`,
                'limit=10'
            ];

            if (search && search.length) {

                params.push(...[
                    `name[lk]=${search}`
                ]);
            }

            return this._societyService.getPaginationShortItemsAPI(params).pipe(map((pagination: Pagination<ShortSociety>): ShortSociety[] => {

                return pagination.items;
            }));
        }
    }

    private _initTypes(): void {

        this._statisticTypeService.getItemsAPI().subscribe((items: StatisticType[]): void => {

            this.types = items;
        });
    }

    private _initForm(): void {

        this.formService.form = new FormGroup<StatisticExportForm>({
            allSocieties: new FormControl(true),
            societies: new FormControl([], [(control: FormControl<ShortSociety[]>) => {

                if(!this.form){

                    return null;
                }

                if(this.form.get('allSocieties').value){

                    return null;
                }

                return control.value.length ? null : {
                    'isRequired': {
                        valid: false
                    }
                };
            }]),
            types: new FormControl([], [Validators.required]),
            dateStart: new FormControl(this.defaultDateStart, [Validators.required]),
            dateEnd: new FormControl(this.defaultDateEnd, [Validators.required])
        });

        this.formService.submitCallback = (): void => {

            if (this.form.invalid) {

                return;
            }

            const formValue = {...this.form.getRawValue()};

            const data: object = Object.assign(formValue, {
                dateStart: formValue.dateStart.format(DATE_FORMAT),
                dateEnd: formValue.dateEnd.format(DATE_FORMAT)
            });

            this._statisticExportService.createItemAPI(data).subscribe((): void => {

                this.currentView = View.Confirmation;
            });
        }
    }

    private _initEvents(): void {

        this.form.get('allSocieties').valueChanges.subscribe((): void => {

            this.form.get('societies').patchValue([]);
        });
    }

    public isCurrentView(value: View): boolean {

        return this.currentView === value;
    }

    public refreshRoute(): void {

        this._router.navigate(['account/statistic/export']);
    }

    get form(): FormGroup<StatisticExportForm> {

        return this.formService.form;
    }
}
