import {AfterViewInit, Directive, ElementRef, Input, Renderer2} from '@angular/core';

@Directive({
    selector: '[appArticleCharterPublicationDescription]'
})
export class ArticleCharterPublicationDescriptionDirective implements AfterViewInit {

    @Input() actionCallback: () => void;

    @Input() replacedString: string;

    @Input() contentString: string;

    constructor(
        private _el: ElementRef,
        private _renderer: Renderer2
    ) {
    }

    ngAfterViewInit() {

        this._renderer.setProperty(this._el.nativeElement, 'innerHTML', this._el.nativeElement.innerText.replace(this.replacedString, `
            <a style="cursor: pointer;">
                <span>${this.contentString}</span>
            </a>
        `.trim()));

        const element: HTMLAnchorElement = this._el.nativeElement.querySelector('a');

        element.onclick = this.actionCallback;
    }
}
