<div class="content_slideshow">
    <div class="slider slider_cms">
        <div #swiper class="swiper-container">
            <div class="swiper-wrapper">
                <div class="swiper-slide" *ngFor="let image of getImages(slideshow.images)">

                    <a *ngIf="image.link; else img" href="{{ image.link }}" target="_blank">
                        <ng-container *ngTemplateOutlet="img"></ng-container>
                    </a>

                    <ng-template #img>
                        <img *ngIf="getSourceUrl(image) | async as imageUrl" [src]="imageUrl" [alt]="(image.alt) ? image.alt : '' " [title]="(image.alt) ? image.alt : ''" (load)="getHeightWidth($event)"/>
                        <div class="texte-visuel">
                            <p *ngIf="image.title" class="titre">{{ image.title }}</p>
                            <p *ngIf="image.subtitle" class="soustitre">{{ image.subtitle }}</p>
                        </div>
                    </ng-template>
                </div>
            </div>
            <div #swiperButtonPrev class="swiper-button-prev"></div>
            <div #swiperButtonNext class="swiper-button-next"></div>
        </div>
    </div>
</div>
