import {Component} from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";

@Component({
    selector: 'app-core-lockable-waiting-dialog',
    templateUrl: './lockable-waiting-dialog.component.html',
    styleUrls: ['./lockable-waiting-dialog.component.scss']
})
export class LockableWaitingDialogComponent {

    constructor(
        private _dialogRef: MatDialogRef<LockableWaitingDialogComponent>
    ) {
    }

    public close(): void {

        this._dialogRef.close();
    }
}
