<div class="page_title_list">
    <div class="row marges">
        <div class="col y_items_center">
            <div>
                <ng-container *ngIf="backEnabled">
                    <p>
                        <a (click)="backClicked()" class="btn_cta btn_little btn_back">< {{ 'action.back' | translate}}</a>
                    </p>
                </ng-container>
                <h1 class="title">{{ translationService.getFallbackTranslation(category.translations).titre }}</h1>
                <div class="ck-content" [innerHTML]="sanitize(translationService.getFallbackTranslation(category.translations).description)"></div>
            </div>
        </div>

    </div>
</div>

<div class="bloc_list">
    <div class="list_items">
        <div class="row marges">
            <div class="col_12 col_md_6 col_lg_4 content_card" *ngFor="let page of getPagesByTranslate(category.translations)">
                <app-core-page-card [page]="page" [mode]="'view'"></app-core-page-card>
            </div>
        </div>
    </div>
</div>