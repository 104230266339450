<ng-content></ng-content>
<div class="content-page">
    <div class="nav-cms" *ngIf="enableMultilingual">

        <div class="translation-list">

            <!-- Liste des langues définies pour la page -->

            <div class="translation-item" *ngFor="let translation of getTranslations(true);">
                <button type="button" class="mat-tab-label" (click)="setCurrentTranslation(translation)" [ngClass]="getTranslationButtonClass(translation)">
                    {{ getLocaleById(translation.locale).name }}
                </button>
            </div>
        </div>

    </div>

    <!-- Contenu de la langue affichée -->

    <app-translation *ngFor="let translation of getTranslations(false)"
        [translation]="translation"
        [hidden]="!isCurrentLocale(translation)">
    </app-translation>
</div>
