import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate} from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {LockableService} from "@core/shared/services/lockable.service";
import {Lockable} from "@core/shared/models/lockable";
import {MatDialog} from "@angular/material/dialog";
import {LockableWaitingDialogComponent} from "@core/components/lockable/lockable-waiting-dialog/lockable-waiting-dialog.component";

@Injectable({
    providedIn: 'root'
})
export class LockableGuard implements CanActivate {

    constructor(
        private _dialog: MatDialog,
        private _lockableService: LockableService
    ) {}

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {

        const routeData = route.data as { lockableClass: string };

        if(!('lockableClass' in routeData) || !route.paramMap.has('id')){

            throw new Error('Missing Guard Parameter.');
        }

        const data: Lockable = {
            class: routeData.lockableClass,
            identifier: route.paramMap.get('id')
        };

        return this._lockableService.lockItemAPI(data).pipe(
            map((): boolean => {

                return true;
            }),
            catchError((): Observable<boolean> => {

                this._dialog.open(LockableWaitingDialogComponent, {
                    width: '500px',
                    data: data
                });

                return of(false);
            })
        );
    }
}
