import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import {Observable} from 'rxjs';
import {LoaderService} from "../services/loader.service";
import {finalize} from "rxjs/operators";
import {DEACTIVATE_LOADER_HEADER_IDENTIFIER} from "@app/data";

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {

    constructor(
        private _loaderService: LoaderService
    ) {
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

        if(!(request.headers.has(DEACTIVATE_LOADER_HEADER_IDENTIFIER) && Boolean(parseInt(request.headers.get(DEACTIVATE_LOADER_HEADER_IDENTIFIER))))){

            this._loaderService.show();
        }

        return next.handle(request).pipe(
            finalize(() => {

                this._loaderService.hide()
            })
        );
    }
}
