export const ARTICLE_SEARCH_SESSION_STORAGE_IDENTIFIER: string = 'article-search';

export enum ArticleFilterKey {
    Type = 'TYPE',
    TargetMarket = 'TARGET_MARKET',
    CustomerTypology = 'CUSTOMER_TYPOLOGY',
    Locale = 'LOCALE',
    SelfAuthor = 'SELF_AUTHOR',
    TywinAuthor = 'TYWIN_AUTHOR',
    Author = 'AUTHOR',
    Region = 'REGION',
    Theme = 'THEME',
    UpdatedAt = 'UPDATED_AT',
    PublishedAt = 'PUBLISHED_AT',
    Status = 'STATUS'
}

export interface ArticleSearchSessionFilter {

    key: ArticleFilterKey;

    value: any;

    extraData?: { [p: string]: any };
}
